import React from "react";
import styled from "styled-components/native";

import * as CssConstants from "../../widgets/CssConstans";
import OpcionCerrar from "./OpcionCerrar";
import Config from "../../utils/Config";
import FooterDescription from "./FooterDescription";

export default class ElementsAccountList
    extends React.Component {
    render() {
        const {
            closeAction,
            details,
        } = this.props;

        return (
            <TypeStorySeenListComponentAccountLi onPress={closeAction}>
                <FooterDescription closeAction={closeAction} details={details} />
                <ContainerOpcionCerrar>
                    <OpcionCerrar />
                </ContainerOpcionCerrar>
            </TypeStorySeenListComponentAccountLi>
        );
    }
}

const TypeStorySeenListComponentAccountLi = styled.Pressable`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 24px;
    position: relative;
    
`;

const ContainerOpcionCerrar = styled.View`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 12px;
    position: relative;
`;
