import React from "react";
import styled from "styled-components/native";
import {
  H2Bold,
  BodyXlargeSemibold,
  BodyMediumSemibold,
  BodyMediumRegular,
  ValignTextMiddle,
  BodyLargeSemibold
} from "../../widgets/CssConstans";
import Constants from "../../Constants"
import { Pressable, StyleSheet, Text } from 'react-native'

import Button from "./Button"

import Button2 from "./Button2"
import Divider from "../../widgets/Divider";
import MyInput from "../../widgets/MyInput";
import MyCheckbox from "../../widgets/MyCheckbox";

// witespace
// borrar min-content
// buscar var( y reempazar
// gap ->   margin-top: 10px; margin-bottom: 10px; a los elementos hijos
// font-awesome
// <Button2 details={button2Facebook} alert={this.props.alert} />

const DIMENSIONS = {
  width: Constants.SIZE.VIEWS.width,
  maxWidth: Constants.SIZE.VIEWS.maxWidth,
  height: "730px",
  margin: Constants.SIZE.VIEWS.margin,
};

export default class X2RegistroEmail extends React.Component {
  render() {
    const {
      creaTuCuenta,
      oContinaCon,
      yaEresMiembro,
      iniciaTuSesin,
      input5Props2,
      input22Props,
      checkboxProps,
      aceptaCondiciones,
      buttonProps,
      button2Google,
      button2Apple,
      button2Facebook,
      recuperaClave,
    } = this.props;

    return (
      <ContainerCenterHorizontal>
        <X2RegistroEmailScreen>
          <AutoLayoutVertical>
            <CreaTuCuenta>{creaTuCuenta}</CreaTuCuenta>

            <AutoLayoutVertical1>
              <AutoLayoutVertical2>
                <StyledInput>
                  <MyInput details={input5Props2}
                  />
                </StyledInput>
                <StyledInput>
                  <MyInput details={input22Props}
                  />
                </StyledInput>
                <MyCheckbox details={checkboxProps}></MyCheckbox>
                <MyCheckbox details={aceptaCondiciones}></MyCheckbox>
                <Button details={buttonProps}></Button>
                <Pressable onPress={recuperaClave.onPress}>
                  <OlvidasteTuContrasea>{recuperaClave.label}</OlvidasteTuContrasea>
                </Pressable>
              </AutoLayoutVertical2>
            </AutoLayoutVertical1>

            <AutoLayoutVertical3>
              <AutoLayoutHorizontal>
                <Divider />
                <OContinaCon>{"O continúa con"}</OContinaCon>
                <Divider />
              </AutoLayoutHorizontal>
              <AutoLayoutHorizontal1>
                <Button2 details={button2Google} alert={this.props.alert} />
                <Button2 details={button2Apple} alert={this.props.alert} />
                
              </AutoLayoutHorizontal1>
            </AutoLayoutVertical3>

            <AutoLayoutHorizontal2>
              <YaEresMiembro>{yaEresMiembro}</YaEresMiembro>
              <Pressable onPress={iniciaTuSesin.onPress}>
                <IniciaTuSesin>{iniciaTuSesin.label}</IniciaTuSesin>
              </Pressable>
            </AutoLayoutHorizontal2>
          </AutoLayoutVertical>
        </X2RegistroEmailScreen>
      </ContainerCenterHorizontal>
    );
  }
}

const StyledInput = styled.View`
  margin-top: 10px;
  margin-bottom: 10px;
    width: 100%;
`;


const OlvidasteTuContrasea = styled.Text`
  ${ValignTextMiddle}
  ${BodyLargeSemibold}
  align-self: stretch;
  font-weight: 600;
  color: ${props => props.theme.__others__deep_orange};
  text-align: center;
  line-height: 22.4px;
  margin-top: 20px;
`;

const AutoLayoutVertical = styled.View`
  display: flex;
  position: relative;
  flex-direction: column;
  height: ${DIMENSIONS.height};
  width: ${DIMENSIONS.width};
  ${DIMENSIONS.maxWidth}
  margin: ${DIMENSIONS.margin};
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 48px;
  background-color: ${props => props.theme.__background_dark};
`;

const CreaTuCuenta = styled.Text`
  ${ValignTextMiddle}
  ${H2Bold}
  width: 100%;
  font-weight: 700;
  color: ${props => props.theme.__others__white};
  line-height: 44px;
`;

const AutoLayoutVertical1 = styled.View`
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

const AutoLayoutVertical2 = styled.View`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  align-items: center;
  justify-content: center;
  
  position: relative;
`;

const AutoLayoutVertical3 = styled.View`
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: center;
  gap: 20px;
  align-self: stretch;
`;

const AutoLayoutHorizontal = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 10px;
  position: relative;
  align-self: stretch;
`;

const OContinaCon = styled.Text`
  ${ValignTextMiddle}
  ${BodyXlargeSemibold}
  min-width: 123px;
  margin-top: -1px;
  font-weight: 600;
  color: ${props => props.theme.__others__white};
  text-align: center;
  line-height: 25.2px;
  
`;

const AutoLayoutHorizontal1 = styled.View`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  align-self: stretch;
`;

const AutoLayoutHorizontal2 = styled.View`
  display: flex;
  flex-direction: row;
  
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  align-self: stretch;
`;

const YaEresMiembro = styled.Text`
  ${ValignTextMiddle}
  ${BodyMediumRegular}
  min-width: 122px;
  margin-top: -1px;
  font-weight: 400;
  color: ${props => props.theme.__others__white};
  text-align: right;
  line-height: 19.6px;
  margin-right: 4px;
`;

const IniciaTuSesin = styled.Text`
  ${ValignTextMiddle}
  ${BodyMediumSemibold}
  min-width: 93px;
  margin-top: -1px;
  font-weight: 600;
  color: ${props => props.theme.__others__deep_orange};
  line-height: 19.6px;
  
`;

const X2RegistroEmailScreen = styled.View`
    align-items: flex-start;
    background-color: ${props => props.theme.__background_dark};
    display: flex;
    flex-direction: column;
    position:relative;
    width: 100%;
`;

const ContainerCenterHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;