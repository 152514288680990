import React from "react";
import styled from "styled-components/native";

import * as CssConstants from "../../widgets/CssConstans";

class Radio
    extends React.Component {
    render() {
        const {
            value,
            currentValue,
        } = this.props.details;
        return (
            <StyleNoneThemeDefaultStateSelectedC>
                {value===currentValue && <Ellipse></Ellipse>}
            </StyleNoneThemeDefaultStateSelectedC>
        );
    }
}

export default Radio;

const StyleNoneThemeDefaultStateSelectedC = styled.View`
    ${CssConstants.Border3pxOthersDeepOrange}
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
`;

const Ellipse = styled.View`
    position: relative;
    width: 12px;
    height: 12px;
    top: 1px;
    left: 1px;
    background-color: ${props => props.theme.__others__deep_orange};
    border-radius: 5.83px;
`;