import dateFormat, { masks, i18n } from "dateformat";

i18n.dayNames = [
    "Dom",
    "Lun",
    "Mar",
    "Mié",
    "Jue",
    "Vie",
    "Sab",
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
];

i18n.monthNames = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
];

i18n.timeNames = ["a", "p", "am", "pm", "A", "P", "AM", "PM"];

masks.opcion1 = 'dddd, mmmm d, yyyy';
masks.opcion2 = 'dddd, mmmm d';
masks.opcion3 = 'h:MM TT'

masks.simple1 = 'ddd d mmm, yyyy';
masks.simple2 = 'ddd d mmm';

const offset = new Date().getTimezoneOffset() / 60;
export class MyDates {
    static createDuration(dias = 0, horas = 0, minutos = 0) {
        const actual = new Date(1970, 0, dias + 1, horas - offset, minutos).getTime();
        return actual;
    }
    static getDaysFromDuration(epoch) {
        return new Date(epoch).getUTCDate() - 1;
    }
    static getHoursFromDuration(epoch) {
        return new Date(epoch).getUTCHours();
    }
    static getMinutesFromDuration(epoch) {
        return new Date(epoch).getUTCMinutes();
    }
    static formatDate(now) {
        if (now instanceof Date) {
            if (new Date().getFullYear() == now.getFullYear()) {
                return dateFormat(now, "opcion2");
            } else {
                return dateFormat(now, "opcion1");
            }
        } else {
            return "Día / Mes / Año";
        }
    }
    static getDayAsContinuosNumber(fecha) {
        const anio = fecha.getFullYear();
        const mes = fecha.getMonth() + 1;
        const dia = fecha.getDate();
        return dia + 100 * mes + anio * 10000;
    }
    static isToday(someDate) {
        const fecha = new Date(someDate);
        const ahora = new Date();
        const anio0 = ahora.getFullYear();
        const mes0 = ahora.getMonth();
        const dia0 = ahora.getDate();

        const isToday = (fecha.getFullYear() == anio0 && fecha.getMonth() == mes0 && fecha.getDate() == dia0);
        return isToday;
    }
    static formatDateSimple(now) {
        if (now instanceof Date) {
            if (new Date().getFullYear() == now.getFullYear()) {
                return dateFormat(now, "simple2");
            } else {
                return dateFormat(now, "simple1");
            }
        } else {
            return "Día / Mes / Año";
        }
    }
    static formatTime(now) {
        if (now instanceof Date) {
            return dateFormat(now, "opcion3");
        } else {
            return "Hora / Minuto";
        }
    }

    static lPad2(n) {
        return ('0' + n).slice(-2);
    }

    static getRemainingHoursCountDown(epochTxt, defaultIfNegative = "0h 0m 0s") {
        const AHORA = new Date().getTime();
        const epoch = parseInt(epochTxt);
        if (isNaN(epoch)) {
            return "? Horas";
        } else {
            let segDiff = parseInt((epoch / 1000) - (AHORA / 1000));
            let prefix = "";
            if (segDiff < 0) {
                prefix = "-";
                segDiff *= -1;
            }
            let minutos = Math.floor(segDiff / 60);
            const segundos = segDiff - minutos * 60;
            let horas = Math.floor(minutos / 60);
            let horasTotales = horas;
            minutos = minutos - horas * 60;
            let dias = Math.floor(horas / 24);
            horas = horas - dias * 24;

            if (prefix == "-") {
                return defaultIfNegative;
            }
            if (horasTotales < 24) {
                return MyDates.lPad2(horas) + "h " + MyDates.lPad2(minutos) + "m " + MyDates.lPad2(segundos) + "s";
            } else {
                if (dias == 1) {
                    return dias + " día " + MyDates.lPad2(horas) + "h " + MyDates.lPad2(minutos) + "m";
                } else {
                    return dias + " días " + MyDates.lPad2(horas) + "h " + MyDates.lPad2(minutos) + "m";
                }
            }
        }
    }

    static formatDatetime(texto) {
        const epoch = parseInt(texto);
        if (isNaN(epoch)) {
            return "";
        } else {
            const fecha = new Date(epoch);
            return MyDates.formatDateSimple(fecha) + " a las " + MyDates.formatTime(fecha);
        }
    }

    static formatDatetimeRange(texto1, texto2) {
        const arreglo = [];
        const formateado1 = MyDates.formatDatetime(texto1);
        if ([null, undefined, ""].indexOf(formateado1) < 0) {
            arreglo.push(formateado1);
        }
        const formateado2 = MyDates.formatDatetime(texto2);
        if ([null, undefined, ""].indexOf(formateado2) < 0) {
            arreglo.push(formateado2);
        }
        return arreglo.join("\n");
    }

    static get48MinMaxDonationRange(EXPIRATION_HOURS, ahoraDate = null) {
        if (ahoraDate == null) {
            ahoraDate = new Date();
        }
        const ahoraEpoch = ahoraDate.getTime();
        let numero = parseInt(EXPIRATION_HOURS);
        if (isNaN(numero)) {
            numero = 48;
        }
        const minDate = new Date(new Date(ahoraEpoch).setHours(ahoraDate.getHours() + numero));
        const actual = new Date(new Date(ahoraEpoch).setHours(ahoraDate.getHours() + numero + 1));
        const maxDate = new Date(new Date(ahoraEpoch).setMonth(ahoraDate.getMonth() + 1));
        return { minDate, maxDate, actual };
    }

    static listGenerator(min, max, pad = false) {
        const response = [];
        if (!pad) {
            for (let i = min; i <= max; i++) {
                response.push({ label: "" + i, value: "" + i })
            }
        } else {
            for (let i = min; i <= max; i++) {
                response.push({ label: MyDates.lPad2(i), value: "" + i })
            }
        }
        return response;
    }

    static getHoursLimited(fechaActual, minDate, maxDate) {
        // 20230225 : string;
        // Date
        // Date
        const minDateTxt = MyDates.toAAAAMMDD(minDate);
        const maxDateTxt = MyDates.toAAAAMMDD(maxDate);
        const minDateMinutes = minDate.getHours() * 60 + minDate.getMinutes();
        const maxDateMinutes = maxDate.getHours() * 60 + maxDate.getMinutes();

        if (fechaActual == minDateTxt) {
            // Quito las horas iniciales
            const respuesta = MyDates.getAllHours();
            return respuesta.filter((dato) => {
                return (dato.number >= minDateMinutes);
            });
        } else if (fechaActual == maxDateTxt) {
            // Quito las horas finales
            const respuesta = MyDates.getAllHours();
            return respuesta.filter((dato) => {
                return (dato.number <= maxDateMinutes);
            });
        } else {
            return MyDates.getAllHours();
        }
    }

    static getAllHours() {
        const temp = [
            { label: "12:00 am", number: (0) },
            { label: "12:15 am", number: (15) },
            { label: "12:30 am", number: (30) },
            { label: "12:45 am", number: (45) },

            { label: "01:00 am", number: (1 * 60 + 0) },
            { label: "01:15 am", number: (1 * 60 + 15) },
            { label: "01:30 am", number: (1 * 60 + 30) },
            { label: "01:45 am", number: (1 * 60 + 45) },

            { label: "02:00 am", number: (2 * 60 + 0) },
            { label: "02:15 am", number: (2 * 60 + 15) },
            { label: "02:30 am", number: (2 * 60 + 30) },
            { label: "02:45 am", number: (2 * 60 + 45) },

            { label: "03:00 am", number: (3 * 60 + 0) },
            { label: "03:15 am", number: (3 * 60 + 15) },
            { label: "03:30 am", number: (3 * 60 + 30) },
            { label: "03:45 am", number: (3 * 60 + 45) },

            { label: "04:00 am", number: (4 * 60 + 0) },
            { label: "04:15 am", number: (4 * 60 + 15) },
            { label: "04:30 am", number: (4 * 60 + 30) },
            { label: "04:45 am", number: (4 * 60 + 45) },

            { label: "05:00 am", number: (5 * 60 + 0) },
            { label: "05:15 am", number: (5 * 60 + 15) },
            { label: "05:30 am", number: (5 * 60 + 30) },
            { label: "05:45 am", number: (5 * 60 + 45) },

            { label: "06:00 am", number: (6 * 60 + 0) },
            { label: "06:15 am", number: (6 * 60 + 15) },
            { label: "06:30 am", number: (6 * 60 + 30) },
            { label: "06:45 am", number: (6 * 60 + 45) },

            { label: "07:00 am", number: (7 * 60 + 0) },
            { label: "07:15 am", number: (7 * 60 + 15) },
            { label: "07:30 am", number: (7 * 60 + 30) },
            { label: "07:45 am", number: (7 * 60 + 45) },

            { label: "08:00 am", number: (8 * 60 + 0) },
            { label: "08:15 am", number: (8 * 60 + 15) },
            { label: "08:30 am", number: (8 * 60 + 30) },
            { label: "08:45 am", number: (8 * 60 + 45) },

            { label: "09:00 am", number: (9 * 60 + 0) },
            { label: "09:15 am", number: (9 * 60 + 15) },
            { label: "09:30 am", number: (9 * 60 + 30) },
            { label: "09:45 am", number: (9 * 60 + 45) },

            { label: "10:00 am", number: (10 * 60 + 0) },
            { label: "10:15 am", number: (10 * 60 + 15) },
            { label: "10:30 am", number: (10 * 60 + 30) },
            { label: "10:45 am", number: (10 * 60 + 45) },

            { label: "11:00 am", number: (11 * 60 + 0) },
            { label: "11:15 am", number: (11 * 60 + 15) },
            { label: "11:30 am", number: (11 * 60 + 30) },
            { label: "11:45 am", number: (11 * 60 + 45) },

            { label: "12:00 pm", number: (12 * 60 + 0) },
            { label: "12:15 pm", number: (12 * 60 + 15) },
            { label: "12:30 pm", number: (12 * 60 + 30) },
            { label: "12:45 pm", number: (12 * 60 + 45) },

            { label: "01:00 pm", number: (13 * 60 + 0) },
            { label: "01:15 pm", number: (13 * 60 + 15) },
            { label: "01:30 pm", number: (13 * 60 + 30) },
            { label: "01:45 pm", number: (13 * 60 + 45) },

            { label: "02:00 pm", number: (14 * 60 + 0) },
            { label: "02:15 pm", number: (14 * 60 + 15) },
            { label: "02:30 pm", number: (14 * 60 + 30) },
            { label: "02:45 pm", number: (14 * 60 + 45) },

            { label: "03:00 pm", number: (15 * 60 + 0) },
            { label: "03:15 pm", number: (15 * 60 + 15) },
            { label: "03:30 pm", number: (15 * 60 + 30) },
            { label: "03:45 pm", number: (15 * 60 + 45) },

            { label: "04:00 pm", number: (16 * 60 + 0) },
            { label: "04:15 pm", number: (16 * 60 + 15) },
            { label: "04:30 pm", number: (16 * 60 + 30) },
            { label: "04:45 pm", number: (16 * 60 + 45) },

            { label: "05:00 pm", number: (17 * 60 + 0) },
            { label: "05:15 pm", number: (17 * 60 + 15) },
            { label: "05:30 pm", number: (17 * 60 + 30) },
            { label: "05:45 pm", number: (17 * 60 + 45) },

            { label: "06:00 pm", number: (18 * 60 + 0) },
            { label: "06:15 pm", number: (18 * 60 + 15) },
            { label: "06:30 pm", number: (18 * 60 + 30) },
            { label: "06:45 pm", number: (18 * 60 + 45) },

            { label: "07:00 pm", number: (19 * 60 + 0) },
            { label: "07:15 pm", number: (19 * 60 + 15) },
            { label: "07:30 pm", number: (19 * 60 + 30) },
            { label: "07:45 pm", number: (19 * 60 + 45) },

            { label: "08:00 pm", number: (20 * 60 + 0) },
            { label: "08:15 pm", number: (20 * 60 + 15) },
            { label: "08:30 pm", number: (20 * 60 + 30) },
            { label: "08:45 pm", number: (20 * 60 + 45) },

            { label: "09:00 pm", number: (21 * 60 + 0) },
            { label: "09:15 pm", number: (21 * 60 + 15) },
            { label: "09:30 pm", number: (21 * 60 + 30) },
            { label: "09:45 pm", number: (21 * 60 + 45) },

            { label: "10:00 pm", number: (22 * 60 + 0) },
            { label: "10:15 pm", number: (22 * 60 + 15) },
            { label: "10:30 pm", number: (22 * 60 + 30) },
            { label: "10:45 pm", number: (22 * 60 + 45) },

            { label: "11:00 pm", number: (23 * 60 + 0) },
            { label: "11:15 pm", number: (23 * 60 + 15) },
            { label: "11:30 pm", number: (23 * 60 + 30) },
            { label: "11:45 pm", number: (23 * 60 + 45) },
        ];
        for (let i = 0; i < temp.length; i++) {
            const actual = temp[i];
            actual.value = "" + actual.number;
        }
        return temp;
    }

    static toAAAAMMDD(siguiente) {
        if (typeof siguiente == "number") {
            siguiente = new Date(siguiente);
        }
        const anio1 = siguiente.getFullYear();
        const mes1 = siguiente.getMonth();
        const dia1 = siguiente.getDate();
        return anio1 + MyDates.lPad2(mes1) + MyDates.lPad2(dia1);
    }

    static toAAAAMMDDHHmmss(siguiente, predeterminado = "") {
        if (typeof siguiente == "string") {
            siguiente = parseInt(siguiente);
        }
        if (typeof siguiente == "number") {
            if (isNaN(siguiente)) {
                return predeterminado;
            }
            siguiente = new Date(siguiente);
        }
        if (!(siguiente instanceof Date)) {
            return predeterminado;
        }
        const anio1 = siguiente.getFullYear();
        const mes1 = siguiente.getMonth() + 1;
        const dia1 = siguiente.getDate();
        const hora = siguiente.getHours();
        const minutos = siguiente.getMinutes();
        const segundos = siguiente.getSeconds();
        return `${anio1}/${MyDates.lPad2(mes1)}/${MyDates.lPad2(dia1)} ${MyDates.lPad2(hora)}:${MyDates.lPad2(minutos)}:${MyDates.lPad2(segundos)}`;
    }

    static generateDates(minDate, maxDays) {
        const lista = [];
        if (maxDays.getTime() <= minDate.getTime()) {
            return lista;
        }
        const anio = minDate.getFullYear();
        const mes = minDate.getMonth();
        const dia = minDate.getDate();
        let i = 0;
        while (true) {
            const siguiente = new Date(anio, mes, dia + i);
            if (siguiente.getTime() <= maxDays.getTime()) {
                lista.push({ label: MyDates.formatDateSimple(siguiente), value: MyDates.toAAAAMMDD(siguiente) });
            } else {
                break;
            }
            i++;
        }
        return lista;
    }
}
