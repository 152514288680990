import styled from "styled-components/native";
import React from "react";
import theme from "../Theme";
import AssetsImg from "../AssetsImg";
import { Animated, Easing } from "react-native";


export default class BackgroundPage extends React.Component {
    constructor(props) {
        super(props);
    }
    componentWillUnmount() {

    }
    runAnimation() {

    }
    render() {
        return (
            <ContainerLoading blurRadius={5} resizeMode="cover" source={{ uri: this.props.src}}>
                <Frame resizeMode="contain" source={{ uri: this.props.src}}></Frame>
            </ContainerLoading>
        );
    }
}

const ContainerLoading = styled.ImageBackground`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.__dark__dark_1};
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Frame = styled.ImageBackground`
  width: 100%;
  height: 100%;
`;

