import React from 'react'
import MyHome from '../screens/home/MyHome';
import { MountedComponent } from "../utils/MountedComponent";
import { MyFileService } from '../utils/MyFileService';
import { DonacionesSrv } from "../srv/DonacionesSrv";
import RealTime from '../srv/RealTime';
import MyLog from '../srv/MyLog';
import CONFIG from '../Constants';

export default class MyHomeScreen extends MountedComponent {
    constructor(props) {
        super(props);
        MyLog.log(`Constructor`, this);
        this.donationSrv = new DonacionesSrv();
        this.promesaCurrentUser = null;
        this.state = Object.assign(this.state, {
            donations: null,
        });
        this.actualizarDonacionesThis = this.actualizarDonaciones.bind(this);
    }
    showAbout() {
        const actions = [];
        if (super.isAdmin()) {
            actions.push({
                label: "Administración",
                onPress: () => {
                    this.props.navigation.reset({ index: 0, routes: [{ name: 'ReportsPage', params: {} }] });
                }
            });
        }
        this.props.alert(`PANAL Comunidad V${CONFIG.VERSION}`, "PANAL Comunidad", actions);
    }
    async onShowAndUser() {
        MyLog.log("onShow", this);
        RealTime.get().addListener("donations", this.actualizarDonacionesThis);
    }
    async onLeave() {
        MyLog.log("onLeave", this);
        RealTime.get().removeListener("donations", this.actualizarDonacionesThis);
    }
    async onUser(payloadUser) {
        MyLog.log("onUser", this);
        const ultima = await RealTime.get().getLast("donations");
        this.actualizarDonaciones(ultima);
        if (payloadUser.user !== null) {
            await this.registerPush();
        }
    }
    actualizarDonaciones(donations) {
        MyLog.log(`actualizarDonaciones...`, this);
        this.setState({ donations: donations });
    }
    async goToCreateDonation(payload) {
        if (!(await this.alertLogedUser())) {
            return;
        }
        const nuevaImagen = await MyFileService.pickFile();
        if (typeof nuevaImagen == "string") {
            const nuevaDonacion = {
                image: nuevaImagen,
                type: payload.donationType,
            };
            // Go to next page
            this.props.navigation.reset({ index: 0, routes: [{ name: 'WDonationPage3', params: nuevaDonacion }] });
        }
    }
    async fetchNextDonations() {

    }
    donationHasDesition(donation) {
        if (donation.rel) {
            if ([true, false].indexOf(donation.rel.desition) >= 0) {
                return true;
            }
        }
        return false;
    }
    donationHasRejectedDesition(donation) {
        if (donation.rel) {
            if ([false].indexOf(donation.rel.desition) >= 0) {
                return true;
            }
        }
        return false;
    }
    async goToDonation(donation, index) {
        const params = {
            origin: "MyHomeScreen",
            donations: this.state.donations,
            index: index + 1,
            currentUserEmail: this.state.user?.email,
        };
        // Se corrige el índice evitando las donaciones que ya tienen una desición
        let fixedIndex = params.index;
        for (let i = params.index; i >= 0; i--) {
            const currentDonation = this.state.donations[i];
            const hasDesition = this.donationHasRejectedDesition(currentDonation);
            if (hasDesition) {
                fixedIndex--;
            }
        }
        params.index = fixedIndex;
        MyLog.log(`Go to donation ${params.index}`, this);
        this.props.navigation.push('WDonationDetailPage', params);
    }
    render() {
        const goToCreateDonation = this.goToCreateDonation.bind(this);
        const goToDonation = this.goToDonation.bind(this);
        const fetchNextDonations = this.fetchNextDonations.bind(this);
        const showAboutThis = this.showAbout.bind(this);

        const details = {
            showAbout: showAboutThis,
            showTime: this.showTime,
            picture: this.state.userBack?.picture,
            homeAction: async () => {
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyHomePage' }] });
            },
            notificationAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("notificationAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'NotificationsPage' }] });
            },
            plusAction: async (payload) => {
                await goToCreateDonation(payload);
            },
            chatAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("chatAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'PeoplePage' }] });
            },
            profileAction: async () => {
                if (!(await this.alertLogedUser(false))) {
                    return;
                }
                MyLog.log("profileAction", this);
                const params = {
                    email: this.state.user.email,
                };
                this.props.navigation.reset({ index: 0, routes: [{ name: 'ProfilePage', params: params }] });
            },
            visitAcceptedDonationsAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyAcceptedPage' }] });
            },
            goToDonation: goToDonation,
            fetchNextDonations: fetchNextDonations,
            mainDonation: {

            },
            donations: [],
            userRealTime: this.state.userRealTime,
        };

        if (this.state.donations instanceof Array) {
            const copia = JSON.parse(JSON.stringify(this.state.donations));
            if (copia.length > 0) {
                details.mainDonation = copia.shift();
                //details.mainDonation = copia[0];
            }
            details.donations = copia;
        }

        return (
            <MyHome
                alert={this.props.alert}
                windowSize={{
                    width: this.state.width,
                    height: this.state.height,
                }}
                details={details}
            />
        )
    }
}
