import React from "react";
import styled from "styled-components/native";
import AssetsImg from "../../AssetsImg";

import Constants from "../../Constants"
import * as CssConstants from "../../widgets/CssConstans";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Config from "../../utils/Config";
import MyLog from "../../srv/MyLog";

export default class Participant extends React.Component {
    render() {
        const {
            showTime,
            selectVote,
            goToPersonSpace,
            canVote,
            details,
        } = this.props;
        const colorDonorType = Constants.getDropletFeature(details.donorType + "");
        const hayNombre = (typeof details.name == "string" && details.name.length > 0);
        const hayPodium = ((details.podium === true || details.won === true) && details.canceled !== true);
        const rate = (typeof details.rate == "number" ? details.rate : 0);
        const isSelected = (details.selected === true);
        const isCanceled = (details.canceled === true);
        let textName = details.name;
        return (
            <AutoLayoutHorizontal>
                {hayNombre && <AutoLayoutHorizontal1>
                    <AutoLayoutVertical>
                        <LabelPerson
                            podium={hayPodium}
                            numberOfLines={1}>{textName}</LabelPerson>
                        <Line1 podium={hayPodium} />
                        <VoteNumber>{`${details.votes} votos`}</VoteNumber>
                    </AutoLayoutVertical>
                    <AutoLayoutVertical2>
                        <Line1 podium={hayPodium} />
                        <AvatarContainer
                            onPress={goToPersonSpace}
                            colorDonorType={colorDonorType.color}
                            rate={rate}>
                            <Avatar canceled={isCanceled} source={{ uri: Config.getNewPath(details.picture, "_xs", showTime) }} />
                        </AvatarContainer>
                    </AutoLayoutVertical2>
                    <AutoLayoutVertical3>
                        <Line1 podium={hayPodium} />
                    </AutoLayoutVertical3>
                </AutoLayoutHorizontal1>}
                {hayNombre && canVote && <RadioLocal
                    onPress={() => {
                        if (!isSelected) {
                            selectVote(details.email);
                        }
                    }}
                    value={isSelected}>
                    {isSelected &&
                        <FontAwesomeIcon icon={'check'} style={{
                            color: 'white',
                            marginLeft: 1,
                            marginTop: 1,
                        }} size={15} />
                    }
                </RadioLocal>}
            </AutoLayoutHorizontal>
        );
    }
}

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    width: 100%;
    margin-bottom: 28px;
    padding-top: 2px;
`;

const AutoLayoutHorizontal1 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    flex: 1;
    margin-right: 12px;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const AutoLayoutVertical2 = styled(AutoLayoutVertical)`
    flex: 1;
`;

const AutoLayoutVertical3 = styled(AutoLayoutVertical)`
    width: 57px;
    z-index: -1;
`;

const LabelPerson = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumSemibold}
    align-self: stretch;
    font-weight: 600;
    color: ${props => (props.podium ? props.theme.__others__white : props.theme.__greyscale__600)};
    line-height: 19.6px;
    margin-right: 10px; 
    margin-bottom: 4px;
    width: 110px;
    overflow: hidden;
`;

const Line1 = styled.View`
    width: 100%;
    height: 4px;
    margin-right: -77px;
    background-color: ${props => (props.podium ? props.theme.__others__white : props.theme.__greyscale__600)};
`;

const VoteNumber = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumMedium}
    align-self: stretch;
    font-weight: 500;
    color: ${props => props.theme.__greyscale__400};
    line-height: 19.6px;
    margin-top: 4px;
`;

//Usar de una prop el porcentaje...
const AvatarContainer = styled.Pressable`
    position: absolute;
    width: 60px;
    height: 60px;
    top: -28px;
    left: ${props => props.rate}%;
    border: 3px solid;
    border-color: ${props => props.colorDonorType};
    border-radius: 100px;
    overflow: hidden;
    background-color: rgb(26, 26, 34);
`;

const Avatar = styled.ImageBackground`
    width: 54px;
    height: 54px;
    background-size: contain;
    border-radius: 100px;
    overflow: hidden;
    ${props => props.canceled ? 'opacity: 0.2;' : ''}
`;

const RadioLocal = styled.Pressable`
    min-width: 20px;
    height: 20px;
    border-radius: 8px;
    border: 3px solid;
    border-color: ${props => props.theme.__others__deep_orange};
    background: ${props => props.value ? props.theme.__others__deep_orange : props.theme.__background_dark};
`;

