import React from "react";
import styled from "styled-components/native";
import MyLog from "../../srv/MyLog";
import Divider from "../../widgets/Divider";
import MyDateTimePicker from "../../widgets/MyDateTimePicker";
import MyInput from "../../widgets/MyInput";

import Button from "../signup/Button";
import FormOptionMenu from "../wdonation3/FormOptionMenu";
import * as CssConstants from "../../widgets/CssConstans";
import MyCheckbox from "../../widgets/MyCheckbox";
import MyRadio from "../../widgets/MyRadio";
import theme from "../../Theme";

class XSelect extends React.Component {
    constructor(props) {
        super(props);
        this.myrefs = {};
        const {
            options,
        } = this.props.details;
        for (let i = 0; i < options.length; i++) {
            const actual = options[i];
            if (actual.der?.details?.name) {
                this.myrefs[actual.der.details.name] = React.createRef();
            }
        }
    }
    render() {
        const {
            submit,
            instructions,
            options,
            handleChange,
            handleBlur,
            errors,
            values,
            autoSubmit,
            searchFunction,
            actionDefinition,
        } = this.props.details;

        function getInputDetails(details) {
            details.onChangeText = handleChange(details.name);
            details.onBlur = handleBlur(details.name);
            details.errors = errors[details.name];
            details.value = values[details.name];
            details.editable = details.editable;
            return details;
        }

        const LISTA_WIDGETS_EXISTENTES = ["datetime", "input-text", "page", "radio", "radiobutton"];
        const fechaTxt = `${new Date().getTime()}`;
        const listItems = options.map((elemento, index) => {

            const usarView = !!(elemento.der && LISTA_WIDGETS_EXISTENTES.indexOf(elemento.der.type) >= 0);
            if (!usarView) {
                return (<SomeElement key={`${fechaTxt}-${index}`}>
                    <Divider marginTop={"0px"} marginBottom={"10px"} />
                    <AutoLayoutHorizontalTouch highlight={elemento.highlight} onPress={() => {
                        if (typeof elemento.onPress == "function") {
                            elemento.onPress(elemento);
                        }
                        if (autoSubmit === true) {
                            submit();
                        }
                    }}>
                        {elemento.izq.label && <ContainerLabel>{elemento.izq.label}</ContainerLabel>}
                        {elemento.izq.labelLight && <ContainerLabelLight>{elemento.izq.labelLight}</ContainerLabelLight>}
                        {elemento.der && elemento.der.type == "text" &&
                            <LunAgo15A12pmCOT>{elemento.der.label}</LunAgo15A12pmCOT>
                        }
                    </AutoLayoutHorizontalTouch>
                </SomeElement>)
            } else {
                return (<SomeElement key={index}>
                    <Divider marginTop={"10px"} marginBottom={"10px"} />
                    <AutoLayoutHorizontalView highlight={elemento.highlight} onPress={() => {
                        if (typeof elemento.onPress == "function") {
                            elemento.onPress(elemento);
                        }
                        if (autoSubmit === true) {
                            submit();
                        }
                    }}>
                        {elemento.izq.label && <ContainerLabel>{elemento.izq.label}</ContainerLabel>}
                        {elemento.izq.labelLight && <ContainerLabelLight>{elemento.izq.labelLight}</ContainerLabelLight>}
                        {elemento.der && elemento.der.type == "input-text" &&
                            <MyInput ref={this.myrefs[elemento.der.details.name]} details={getInputDetails(elemento.der.details)}></MyInput>
                        }
                        {elemento.der && elemento.der.type == "datetime" &&
                            <MyDateTimePicker windowSize={this.props.windowSize} details={getInputDetails(elemento.der.details)}></MyDateTimePicker>
                        }
                        {elemento.der && elemento.der.type == "page" &&
                            <FormOptionMenu details={elemento.der.details} />
                        }
                        {elemento.der && elemento.der.type == "radio" &&
                            <MyCheckbox details={getInputDetails(elemento.der.details)} />
                        }
                        {elemento.der && elemento.der.type == "radiobutton" &&
                            <MyRadio details={getInputDetails(elemento.der.details)} />
                        }
                    </AutoLayoutHorizontalView>
                </SomeElement>)
            }
        }
        );

        const optionsTextoBuscable = {
            onChangeText: searchFunction,
            leftIcon: "search",
            label: "Escribe aquí para buscar...",
        };

        return (
            <ParentContainer>
                <MyContent>
                    <AutoLayoutVertical>
                        {searchFunction != null && <MyInput details={optionsTextoBuscable}></MyInput>}
                        {listItems}
                        <Divider marginTop={"10px"} marginBottom={"10px"} />
                        <SeleccionaUnaFecha>{instructions}</SeleccionaUnaFecha>
                    </AutoLayoutVertical>
                </MyContent>
                {autoSubmit !== true &&
                    <MyFooter>
                        <AutoLayoutVertical>
                            <AutoLayoutHorizontal>
                                <Button details={{
                                    label: (actionDefinition?.primary?.label ? actionDefinition?.primary?.label : "Continuar"),
                                    onPress: submit,
                                }}></Button>
                            </AutoLayoutHorizontal>
                            {typeof actionDefinition?.secondary?.onPress == "function" && <AutoLayoutHorizontal>
                                <Button details={{
                                    label: (actionDefinition?.secondary?.label ? actionDefinition?.secondary?.label : "Cancelar"),
                                    onPress: (typeof actionDefinition?.secondary?.onPress == "function" ? actionDefinition?.secondary?.onPress : submit),
                                    bgColor: theme.__background_dark_button,
                                    charColor: theme.__others__white,
                                }}></Button>
                            </AutoLayoutHorizontal>}
                        </AutoLayoutVertical>
                    </MyFooter>
                }
            </ParentContainer>

        );
    }
}

export default XSelect;

const SomeElement = styled.View`
    width: 100%;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyContent = styled.ScrollView`
    width: 100%;
    flex: 1;
    padding-left: 24px;
    padding-right: 24px;
`;

const MyFooter = styled.View`
    width: 100%;
    padding: 24px;
`;

// gap: 24px;
const AutoLayoutVertical = styled.View`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
`;

// gap: 16px;
const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const AutoLayoutHorizontalView = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    ${props => props.highlight ? `border-radius: 16px; background-color: ${props.theme.__background_dark_button};` : ""};
`;

const AutoLayoutHorizontalTouch = styled.Pressable`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    ${props => props.highlight ? `border-radius: 16px; background-color: ${props.theme.__background_dark_button};` : ""};
`;

// white-space: nowrap;
const ContainerLabel = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXlargeSemibold}
    flex: 1;
    margin-bottom: 10px;
    font-weight: 600;
    color: ${props => props.theme.__others__white};
    line-height: 25.2px;
    padding-left: 10px;
    
`;

const ContainerLabelLight = styled.Text`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: ${props => props.theme.__font_family_urbanist}; 
    font-size: ${props => props.theme.__font_size_xl2}; 
    letter-spacing: 0.2px;
    font-weight: 400;
    font-style: normal;
    flex: 1;
    margin-bottom: 10px;
    color: ${props => props.theme.__others__white};
    line-height: 25.2px;
    padding-left: 10px;
`;

// white-space: nowrap;
const LunAgo15A12pmCOT = styled.Text`
${CssConstants.ValignTextMiddle}
${CssConstants.BodyMediumRegular}
width: 100%;
font-weight: 400;
color: ${props => props.theme.__others__white};
text-align: left;
line-height: 19.6px;

`;

// white-space: nowrap;
const SeleccionaUnaFecha = styled.Text`
${CssConstants.ValignTextMiddle}
${CssConstants.BodyMediumRegular}
padding-top: 12px;
font-weight: 400;
color: ${props => props.theme.__others__white};
line-height: 19.6px;

`;
