import { Listas } from "../srv/Listas";
import Constants from '../Constants';
import { Platform } from 'react-native';
import Cities from "./Cities";
import CitiesUsa from "./CitiesUsa";
import CiiuList from "./CiiuList";
import CountryList from "./CountryList";

// 1 Hour of cache
const MAX_CACHE_TIME = Constants.API.CACHE_TIME_LISTS;
const CACHE = {};
class Config {
    // Config.getNewPath(, "_xs")
    static getNewPath(path, suffix = "", ahora = null) {
        if (typeof path == "string") {
            if (path.startsWith("http")) {
                if (ahora === null) {
                    ahora = new Date().getTime();
                }
                return Config.getSuffixPath(path, suffix) + "?t=" + ahora;
            } else {
                return path;
            }
        } else {
            return path;
        }
    }
    static getSuffixPath(keyName, suffix) {
        const keyNameXs = keyName.replace(/^(.+)\.([^./]+)$/ig, `$1${suffix}.$2`);
        if (keyNameXs == keyName) {
            // fallback when no extension
            return keyName + suffix;
        }
        return keyNameXs;
    }

    static getQueryParam(name) {
        if (Platform.OS == "web") {
            const parsedUrl = new URL(window.location.href);
            return parsedUrl.searchParams.get(name);
        } else {
            return null;
        }
    }

    static getValueSync(name) {
        const autoValue = Config.getAutoValues(name);
        if (autoValue != null) {
            return autoValue;
        }
        if (name in CACHE) {
            const actual = CACHE[name];
            return actual.value;
        } else {
            return null;
        }
    }
    static getCategoryValue(numero) {
        return Config.getGenericValue(numero, "categories");
    }
    static getCityValue(numero) {
        return Config.getGenericValue(numero, "cities");
    }
    static getCityUsaValue(id) {
        return Config.getGenericValue(id, "usa_cities_comunidad");
    }
    static getCiiuValue(id) {
        return Config.getGenericValue(id, "ciiu_comunidad");
    }
    static getCountryValue(id) {
        return Config.getGenericValue(id, "countries_comunidad");
    }
    static getInstitucionValue(id) {
        return Config.getGenericValue(id, "instituciones");
    }
    static getVirtualValue(valor) {
        const MAPA = { "true": "Virtual", "false": "Presencial" };
        return MAPA[valor];
    }
    static getGenericValue(numero, domain) {
        const lista = Config.getValueSync(domain);
        if (lista != null) {
            for (let i = 0; i < lista.length; i++) {
                const actual = lista[i];
                if (actual.key == numero) {
                    return actual.txt;
                }
            }
        }
        return null;
    }
    static getAutoValues(name) {
        if (["cities", "countries_comunidad", "usa_cities_comunidad", "ciiu_comunidad"].indexOf(name) >= 0) {
            if (name == "cities") {
                // Ciudades de Colombia
                return Cities;
            } else if (name == "countries_comunidad") {
                // Paises del mundo
                return CountryList;
            } else if (name == "usa_cities_comunidad") {
                // Ciudades de Estados Unidos
                return CitiesUsa;
            } else if (name == "ciiu_comunidad") {
                // Clasificación de la industria tipo código ciiu
                return CiiuList;
            }
        }
        return null;
    }
    static async getValue(name, withCache = true) {
        const autoValue = Config.getAutoValues(name);
        if (autoValue != null) {
            return autoValue;
        }
        const ahora = new Date().getTime();
        if (name in CACHE && withCache) {
            const actual = CACHE[name];
            if (ahora > actual.expTime) {
                // expiró
                const nuevo = await Listas.getList(name);
                CACHE[name] = {
                    expTime: ahora + MAX_CACHE_TIME,
                    value: nuevo,
                }
                return nuevo;
            } else {
                // Es vigente en caché
                return actual.value;
            }
        } else {
            // No está en caché
            const nuevo = await Listas.getList(name);
            CACHE[name] = {
                expTime: ahora + MAX_CACHE_TIME,
                value: nuevo,
            }
            return nuevo;
        }
    }
}

export default Config;
