import React from 'react'
import { MountedComponent } from "../utils/MountedComponent";
import Constants from '../Constants';

import X5LightWelcomeScreen from '../screens/register/X5LightWelcomeScreen';
import AcceptTerms from "../widgets/AcceptTerms"

export default class RegisterScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign(this.state, {
            aceptacion: false,
        });
    }
    async onUser(payloadUser) {
        await this.onUserDefaultGoHome(payloadUser);
    }
    render() {
        return (
            <X5LightWelcomeScreen
                windowSize={{
                    width: this.state.width,
                    height: this.state.height,
                }}
                alert={this.props.alert}
                letsYouIn="Regístrate"
                or="o"
                facebookButton={{
                    label: "Continua con Facebook",
                    webClientId: Constants.LOGIN.facebookClientId,
                    acceptTerms: this.state.aceptacion,
                }}
                googleButton={{
                    label: "Continua con Google",
                    webClientId: Constants.LOGIN.googleClientId,
                    acceptTerms: this.state.aceptacion,
                }}
                appleButton={{
                    label: "Continua con Apple",
                    webClientId: null,
                    acceptTerms: this.state.aceptacion,
                }}
                buttonProps={{
                    label: "Regístrate con email",
                    onPress: () => {
                        this.props.navigation.navigate('SignUpPage');
                    },
                }}
                aceptaCondiciones={{
                    label: (<AcceptTerms></AcceptTerms>),
                    onChange: (event) => {
                        this.setState({ aceptacion: event.target.value })
                    },
                    value: false
                }}
                yaEresMiembro={{
                    dontHaveAnAccount: "¿Ya eres miembro?",
                    signUp: "Inicia tu sesión",
                    onPress: () => {
                        this.props.navigation.navigate('SignInPage');
                    },
                }}
            />
        )
    }
}