import { MyDates } from '../utils/MyDates';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_1 = "dateStartMeet";
const FIELD_KEY_NAME_2 = "dateStartMeet2";

export default class WDonationDatesScreen extends SelectScreen {
    constructor(props) {
        super(props);
        this.state.showSecondDate = true;
        this.minDate = this.props.route.params.minDate;
        this.maxDate = this.props.route.params.maxDate;
        super.initValue(FIELD_KEY_NAME_1);
        super.initValue(FIELD_KEY_NAME_2);
    }
    onSubmit(values) {
        super.redirectWithParams([
            FIELD_KEY_NAME_1,
            FIELD_KEY_NAME_2,
        ], values);
    }
    validate(values) {
        const errors = {};
        const fecha1 = parseInt(values[FIELD_KEY_NAME_1]);
        if (isNaN(fecha1)) {
            errors[FIELD_KEY_NAME_1] = "Para continuar debes ingresar una fecha y hora";
        }
        return errors;
    }
    render() {
        const self = this;
        const listaOpciones = [
            {
                izq: { label: "Hora de Inicio" },
                der: {
                    type: "datetime",
                    details: {
                        name: FIELD_KEY_NAME_1,
                        onChange: (val) => {
                            const nuevo = {};
                            const data = this.state.data;
                            if (val == null) {
                                nuevo[FIELD_KEY_NAME_1] = undefined;
                                data[FIELD_KEY_NAME_1] = undefined;
                            } else {
                                nuevo[FIELD_KEY_NAME_1] = val;
                                data[FIELD_KEY_NAME_1] = val;
                                // se le suma una hora
                                const siguiente = new Date(val);
                                siguiente.setMinutes(siguiente.getMinutes() + 60);
                                const siguienteEpoch = siguiente.getTime();
                                nuevo[FIELD_KEY_NAME_2] = siguienteEpoch;
                                data[FIELD_KEY_NAME_2] = siguienteEpoch;
                                refDates2.der.details.value = siguienteEpoch;
                                refDates2.der.details.ref.setExternalDate(siguienteEpoch);
                            }
                            nuevo.data = data;
                            self.setState(nuevo);
                        },
                    }
                }
            },
        ];
        if (this.minDate) {
            listaOpciones[0].der.details.minDate = new Date(this.minDate);
        }
        if (this.maxDate) {
            listaOpciones[0].der.details.maxDate = new Date(this.maxDate);
        }


        listaOpciones.push({
            izq: { label: "Hora de Finalización" },
            der: {
                type: "datetime",
                details: {
                    editable: (this.state[FIELD_KEY_NAME_1] !== undefined),
                    name: FIELD_KEY_NAME_2,
                    ref: {},
                }
            }
        });

        const refDates2 = listaOpciones[1];
        const ahora = new Date();

        if (this.state[FIELD_KEY_NAME_1] === undefined) {
            refDates2.der.details.minDate = new Date();
            const endTime = new Date();
            endTime.setFullYear(ahora.getFullYear() + 1);
            refDates2.der.details.maxDate = endTime;
        } else {
            const epochNumber = parseInt(this.state[FIELD_KEY_NAME_1]);
            const minVal = new Date(epochNumber);
            minVal.setMinutes(minVal.getMinutes() + 30);
            refDates2.der.details.minDate = minVal;
            const maxVal = new Date(epochNumber);
            maxVal.setMonth(maxVal.getMonth() + 6);
            refDates2.der.details.maxDate = maxVal;
        }


        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = "Indica la fecha en la que esta oportunidad tomará lugar, asegúrate que esta sea máximo 1 mes a partir de hoy. Si aún no estás seguro pon una fecha tentativa.";
        return super.myRender(listaOpciones, instructions, onSubmit, validate);
    }
}
