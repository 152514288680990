import React from "react";
import styled from "styled-components/native";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import theme from "../../Theme";
import AssetsImg from "../../AssetsImg";

export default class OptionsAccept
    extends React.Component {
    render() {
        const {
            disabled,
            useHandHearth,
            acceptAction,
        } = this.props.details;
        const customStyle = {};
        if (disabled === true) {
            customStyle.opacity = 0.5;
            customStyle.backgroundColor = theme.__background_dark_button;
        }

        //disabled={disabled === true}
        return (
            <AutoLayoutVertical>
                <Group onPress={acceptAction} disabled={false} style={customStyle}>
                    <IconlyBoldHeart>
                        <Heart>
                            {useHandHearth !== true && <ImageTag source={AssetsImg.IMG.HANDS}></ImageTag>}
                            {useHandHearth === true && <ImageTag source={AssetsImg.IMG.HAND_HEART}></ImageTag>}
                        </Heart>
                    </IconlyBoldHeart>
                </Group>
                <LabelBoton>Participar</LabelBoton>
            </AutoLayoutVertical>
        );
    }
}

const LabelBoton = styled.Text`
    font-family: ${theme.__font_family_urbanist};
    font-size: ${theme.__font_size_m2}; 
    font-weight: 700;
    color: ${props => theme.__others__white};
`;

const IconlyBoldHeart = styled.View`
    position: relative;
    width: 43px;
    height: 43px;
    top: 9px;
    left: 8px;
    display: flex;
    padding: 0px;
    align-items: flex-end;
`;

const Heart = styled.View`
    width: 42px;
    height: 42px;
    margin-left: 1px;
`;

const ImageTag = styled.ImageBackground`
    width: 42px;
    height: 42px;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-top: -0.5px;
    margin-bottom: -0.5px;
    
    margin-left: 40px;
`;

const Group = styled.Pressable`
    position: relative;
    min-width: 60px;
    height: 60px;
    background-color: ${props => props.theme.__others__deep_orange};
    border-radius: 100px;
`;