import SelectScreen from './SelectScreen';

export default class WDonationCityScreen extends SelectScreen {
    constructor(props) {
        super(props);
        this.state.list = this.props.route.params?.list;
        this.state.keyName = this.props.route.params?.keyName;
        if (!(typeof this.state.list == "string")) {
            this.state.list = "cities";
        }
        if (!(typeof this.state.keyName == "string")) {
            this.state.keyName = "city";
        }
        if (this.props.route.params) {
            this.state[this.state.keyName] = this.props.route.params[this.state.keyName];
        }
        this.state.myOptions = [];
        this.actualizarOpciones();
    }
    selectChoice(elemento) {
        this.props.route.params[this.state.keyName] = elemento.value;
        const objeto = {};
        objeto[this.state.keyName] = elemento.value;
        this.setState(objeto);
    }
    onSubmit(values) {
        if ([null, undefined].indexOf(this.state[this.state.keyName]) >= 0) {
            this.props.alert("Para continuar debes seleccionar al menos una opción");
            return;
        }
        super.myLocalGoBack(this.props.route.params);
    }
    async actualizarOpciones(texto = null) {
        if (typeof this.state[this.state.keyName] == "string" && texto == null) {
            const allOptions = await super.getList(this.state.list);
            for (let i = 0; i < allOptions.length; i++) {
                const oneEntry = allOptions[i];
                const key = oneEntry.key;
                if (this.state[this.state.keyName] == key) {
                    texto = oneEntry.txt;
                    break;
                }
            }
        }
        if (!(typeof texto == "string")) {
            texto = "";
        }
        const selectChoice = this.selectChoice.bind(this);
        const options = await super.getList(this.state.list, texto);
        const myOptions = [];
        for (let i = 0; i < options.length; i++) {
            const una = options[i];
            myOptions.push(
                {
                    izq: { label: una.txt },
                    value: una.key,
                    onPress: selectChoice,
                }
            );
        }
        this.setState({ myOptions: myOptions, buscable: texto });
    }
    render() {
        const autoSubmit = true;
        const onSubmit = this.onSubmit.bind(this);
        const actualizarOpcionesThis = this.actualizarOpciones.bind(this);
        super.addHighlighted(this.state.myOptions, this.state[this.state.keyName]);
        return super.myRender(this.state.myOptions, "", onSubmit, null, autoSubmit, actualizarOpcionesThis);
    }
}