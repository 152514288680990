import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from "react";
import styled from "styled-components/native";

import Constants from "../../Constants"
import * as CssConstants from "../../widgets/CssConstans";
import TopDetail0 from "./TopDetail0";


export default class TopDetail2 extends React.Component {
    render() {
        const {
            me,
        } = this.props.details;
        const dropletFeauture = Constants.getDropletFeature(me.donorType + "");
        return (
            <AutoLayoutVertical>
                <AvatarContainer>
                    <Avatar source={{ uri: me.picture }} />
                    <DropletContainer>
                        <FontAwesomeIcon icon={"droplet"} style={{ color: dropletFeauture.color }} size={28} />
                    </DropletContainer>
                </AvatarContainer>
                <AutoLayoutVertical1>
                    <HazEntradoAPartic>Haz entrado a participar en esta donación</HazEntradoAPartic>
                    <ParaGanrtelaAseg>
                        Para ganártela asegúrate de recolectar la mayor cantidad de votos en el tiempo que le queda. Empieza por votar por ti mismo y
                        comparte esta votación con tus amigos.
                    </ParaGanrtelaAseg>
                </AutoLayoutVertical1>
                <TopDetail0 details={this.props.details}></TopDetail0>
            </AutoLayoutVertical>
        );
    }
}

const AutoLayoutVertical = styled.View`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
`;

const AutoLayoutVertical1 = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 24px;
`;

const HazEntradoAPartic = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H4Bold}
    align-self: stretch;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 28px;
    margin-bottom: 8px;
`;

const ParaGanrtelaAseg = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumRegular}
    align-self: stretch;
    font-weight: 400;
    color: ${props => props.theme.__greyscale__400};
    text-align: center;
    line-height: 19px;
`;

const AvatarContainer = styled.View`
    position: relative;
    width: 120px;
    height: 120px;
    margin-bottom: 12px;
`;

const Avatar = styled.ImageBackground`
    width: 120px;
    height: 120px;
    background-size: cover;
    background-position: center;
    border-radius: 100px;
    overflow: hidden;
`;

const DropletContainer = styled.View`
    position: absolute;
    width: 28px;
    height: 28px;
    right: 0px;
    bottom: 0px;
`;
