import * as React from 'react';
import AssetsImg from '../../AssetsImg';
import styled from "styled-components/native";

const Frame10 = () => {
    return (<Frame source={AssetsImg.IMPORTED.frame_10} />);
}

const Frame = styled.Image`
    min-width: 24px;
    height: 24px;
`;

export default Frame10;