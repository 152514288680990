import React from "react";
import styled from "styled-components/native";
import {
    ValignTextMiddle,
    BodyMediumRegular,
    BodyMediumSemibold
} from "../../widgets/CssConstans";
import { Pressable } from 'react-native'

export default class AutoLayoutHorizontal
    extends React.Component {
    render() {
        const { details } = this.props;
        return (
            <AutoLayoutHorizontal1>
                <DontHaveAnAccount>{details.dontHaveAnAccount}
                </DontHaveAnAccount>
                <Pressable onPress={details.onPress}><SignUp>{details.signUp}</SignUp></Pressable>
            </AutoLayoutHorizontal1>
        );
    }
}

const AutoLayoutHorizontal1 = styled.View`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 24px 24px;
`;

const DontHaveAnAccount = styled.Text`
    ${ValignTextMiddle}
    ${BodyMediumRegular}
    min-width: 151px;
    margin-top: -1px;
    margin-right: 8px;
    font-weight: 400;
    color: ${props => props.theme.__others__white};
    text-align: right;
    line-height: 19.6px;
`;

const SignUp = styled.Text`
    ${ValignTextMiddle} 
    ${BodyMediumSemibold} 
    min-width: 51px; 
    margin-top: -1px; 
    font-weight: 600; 
    color: ${props => props.theme.__others__deep_orange};
    line-height: 19.6px; 
`;