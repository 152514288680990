import React, { Component, createContext, useContext } from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import styled from "styled-components/native";
import { ThemeProvider } from "styled-components/native";
import { navigationRef } from './RootNavigation';

import StatusBarBackground from './utils/StatusBarBackground'
import {
    ScrollView,
    StyleSheet,
    Pressable,
    View,
    ActivityIndicator,
    Platform
} from 'react-native';
import theme from "./Theme"
import OutlineArrowLeft from "./screens/svg/OutlineArrowLeft"

import MyHomeScreen from './pages/MyHomeScreen'
import RealTime from "./srv/RealTime";
import MyModal from "./widgets/MyModal";

import RegisterScreen from './pages/RegisterScreen';
import SignUpScreen from './pages/SignUpScreen';
import SignInScreen from './pages/SignInScreen';
import RecuperarClaveScreen from './pages/RecuperarClaveScreen';
import WDonationScreen1 from "./pages/WDonationScreen1";
import WDonationScreen2 from "./pages/WDonationScreen2";
import WDonationScreen3 from "./pages/WDonationScreen3";
import MyAcceptedScreen from "./pages/MyAcceptedScreen";
import OnBoardingScreen from "./pages/OnBoardingScreen";
import EditProfileScreen from "./pages/EditProfileScreen";
import WDonationDatesScreen from "./pages/WDonationDatesScreen";
import WDonationWinnersScreen from "./pages/WDonationWinnersScreen";
import WDonationCategoryScreen from "./pages/WDonationCategoryScreen";
import WDonationCityScreen from "./pages/WDonationCityScreen";
import WDonationLinkScreen from "./pages/WDonationLinkScreen";
import WDonationPlaceReceiveScreen from "./pages/WDonationPlaceReceiveScreen";
import WDonationPlaceMeetScreen from "./pages/WDonationPlaceMeetScreen";
import WDonationDetailScreen from "./pages/WDonationDetailScreen";
import VoteScreen from "./pages/VoteScreen";
import MessengerScreen from "./pages/MessengerScreen";
import PeopleScreen from "./pages/PeopleScreen";
import { MyAnalytics } from "./config/myAnalytics";
import MyLog from "./srv/MyLog";
import NotificationsScreen from "./pages/NotificationsScreen";
import WDonationPublishDateScreen from "./pages/WDonationPublishDateScreen";
import WDonationAdvancedScreen from "./pages/WDonationAdvancedScreen";
import WDonationLinkMoreScreen from "./pages/WDonationLinkMoreScreen";
import WDonationOrganicerScreen from "./pages/WDonationOrganicerScreen";
import ProfileScreen from "./pages/ProfileScreen";
import StatusScreen from "./pages/StatusPanal";
import ReportsScreen from "./pages/ReportsScreen";
import AssetsImg from "./AssetsImg";
import { DonacionesSrv } from "./srv/DonacionesSrv";
import { NotificationsSrv } from "./srv/NotificationsSrv";
import WSurveyWinnerScreen from "./pages/WSurveyWinnerScreen";
import WSurveyWinnerMissedScreen from "./pages/WSurveyWinnerMissedScreen";
import WDonationWinnersScreenNew from "./pages/WDonationWinnersScreenNew";
import WDonationPriceScreen from "./pages/WDonationPriceScreen";
import GroupScreen from "./pages/GroupScreen";
import SearchPeopleScreen from "./pages/SearchPeopleScreen";

// https://reactnative.dev/docs/navigation
// https://blog.logrocket.com/integrating-firebase-authentication-expo-mobile-app/
// https://docs.expo.dev/guides/using-firebase/

const ParentContext = React.createContext();
const Stack = createNativeStackNavigator();

export class ComponentWithActivityIndicator extends Component {
    static contextType = ParentContext;
    constructor(props) {
        super(props);
        this.promiseCount = 0;
        this.state = {
            loading: false,
            alertVisible: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    isMounted() {
        return this._isMounted;
    }
    setMounted(value) {
        this._isMounted = value;
    }
    parentRender(elementType, props, withScroll = true) {
        props.realTime = RealTime.get();
        props.loading = (promesa) => {
            this.setState({ loading: true });
            this.promiseCount++;
            promesa.finally(() => {
                this.promiseCount--;
                if (this.promiseCount == 0) {
                    this.setState({ loading: false });
                }
            });
        };
        props.popUpNotification = (notification, onlyData = false) => {
            const AHORA = new Date().getTime();
            //console.log(JSON.stringify(notification, null, 4));
            if (onlyData) {
                if (AHORA > notification.exp) {
                    if (["confirm", "won"].indexOf(notification.type) >= 0) {
                        return;
                    }
                }
            } else {
                if (AHORA > notification.exp) {
                    // Notificaciones expiradas
                    if (["confirm", "won"].indexOf(notification.type) >= 0) {
                        // La confirmación no se debe mostrar porque ya se lo perdió
                        props.alert("Esta notificación ya no está vigente", "Ups!");
                        return;
                    }
                }
            }
            const actions = [];
            const extra = {
                image: { uri: AssetsImg.BASE64.won },
                canClose: true,
                closeAction: async (finish) => {
                    const promesa = NotificationsSrv.checkPopedUp(notification.id, props.alert);
                    props.loading(promesa);
                    try {
                        await promesa;
                        finish();
                    } catch (err) {

                    }
                }
            };
            if (["recalculate", "won2"].indexOf(notification.type) >= 0) {
                // El donador
                const winners = notification.payload.winners;
                winners.forEach((winner, i) => {
                    actions.push({
                        label: `Escríbele a ${winner.name}`,
                        type: "primary",
                        onPress: async (finish) => {
                            // Send to talk with winner
                            const promesa = NotificationsSrv.turnOpened(notification.id, props.alert);
                            props.loading(promesa);
                            await promesa;
                            const autotext = `Hola ${winner.name} felicitaciones por ganarte mi donación "${notification.payload.donation.name}" ¿Cuéntame si nos podemos reunir?`;
                            const params = { autotext };
                            Object.assign(params, winner);
                            props.navigation.navigate('MessengerPage', params);
                            // finish();
                        }
                    });
                });
                if (onlyData) {
                    return {
                        title: notification.title,
                        description: notification.txt,
                        actions,
                        extra,
                    };
                } else {
                    props.alert(notification.txt, notification.title, actions, true, extra);
                }
            } else if (["confirm", "won"].indexOf(notification.type) >= 0) {
                // El ganador
                actions.push({
                    label: `Confirma con ${notification.payload.donator.name}`,
                    type: "primary",
                    onPress: async (finish) => {
                        const promesa = DonacionesSrv.confirm(notification.payload.donation.id, notification.id, props.alert);
                        props.loading(promesa);
                        try {
                            await promesa;
                            // Then send to talk with donator
                            const autotext = `Hola ${notification.payload.donator.name} estoy muy agradecido/a por la donación "${notification.payload.donation.name}". ¿Cuándo nos podemos reunir?`;
                            const params = { autotext };
                            Object.assign(params, notification.payload.donator);
                            props.navigation.navigate('MessengerPage', params);
                            // finish();
                        } catch (err) {

                        }
                    }
                });
                if (notification.type == "won") {
                    notification.txt = "Confirma tu donación contactándote con el Donador";
                }
                if (onlyData) {
                    return {
                        title: notification.title,
                        description: notification.txt,
                        actions,
                        extra,
                    };
                } else {
                    props.alert(notification.txt, notification.title, actions, true, extra);
                }
            } else if (["rank"].indexOf(notification.type) >= 0) {
                // Se le pide al ganador que rankee
                const extra2 = {
                    canClose: true,
                    closeAction: async (finish) => {
                        const promesa = NotificationsSrv.checkPopedUp(notification.id, props.alert);
                        props.loading(promesa);
                        try {
                            await promesa;
                            finish();
                        } catch (err) {

                        }
                    }
                };
                actions.push({
                    label: `Si ya la recibí`,
                    type: "primary",
                    onPress: async (finish) => {
                        // Navega a sí la recibió
                        const params = {
                            notification
                        };
                        if (typeof finish == "function") {
                            finish();
                        }
                        setTimeout(() => {
                            props.navigation.navigate('WSurveyWinnerPage', params);
                        }, 0);
                    }
                });
                actions.push({
                    label: `Aún no la he recibido`,
                    type: "secondary",
                    onPress: async (finish) => {
                        // Navega a no la recibió
                        const params = {
                            notification
                        };
                        if (typeof finish == "function") {
                            finish();
                        }
                        setTimeout(() => {
                            props.navigation.navigate('WSurveyWinnerMissedPage', params);
                        }, 0);
                    }
                });
                if (onlyData) {
                    return {
                        title: notification.title,
                        description: notification.txt,
                        actions,
                        extra: extra2,
                    };
                } else {
                    props.alert(notification.txt, notification.title, actions, true, extra2);
                }
            } else if (["askrank"].indexOf(notification.type) >= 0) {
                // Se le pide al donador que rankee
                const extra2 = {
                    canClose: true,
                    closeAction: async (finish) => {
                        const promesa = NotificationsSrv.checkPopedUp(notification.id, props.alert);
                        props.loading(promesa);
                        try {
                            await promesa;
                            finish();
                        } catch (err) {

                        }
                    }
                };
                const winners = notification.payload.winners;
                if (winners instanceof Array && winners.length > 0) {
                    winners.forEach((winner, k) => {
                        actions.push({
                            label: `Ya la entregue a ${winner.name}`,
                            type: "primary",
                            onPress: async (finish) => {
                                // Navega a sí la recibió
                                const donationId = notification.payload.donation.id;
                                const promesa = DonacionesSrv.setFinishDetail(donationId, "", true, winner, props.alert);
                                props.loading(promesa);
                                try {
                                    await promesa;
                                    finish();
                                    setTimeout(async () => {
                                        await props.alert("Gracias! sigue participando", "Listo");
                                        props.navigation.reset({
                                            index: 0,
                                            routes: [{ name: 'MyHomePage' }],
                                        });
                                    }, 0);
                                } catch (err) {

                                }
                            }
                        });
                        actions.push({
                            label: `No la he entregado a ${winner.name}`,
                            type: "secondary",
                            onPress: async (finish) => {
                                // Navega a no la recibió
                                const params = {
                                    notification,
                                    winner,
                                };
                                props.navigation.navigate('WSurveyDonatorMissedPage', params);
                            }
                        });
                    });
                } else {
                    actions.push({
                        label: `Sí ya la entregué`,
                        type: "primary",
                        onPress: async (finish) => {
                            // Navega a sí la recibió
                            const donationId = notification.payload.donation.id;
                            const winner = notification.payload.winner;
                            const promesa = DonacionesSrv.setFinishDetail(donationId, "", true, winner, props.alert);
                            props.loading(promesa);
                            try {
                                await promesa;
                                finish();
                                setTimeout(async () => {
                                    await props.alert("Gracias! sigue participando", "Listo");
                                    props.navigation.reset({
                                        index: 0,
                                        routes: [{ name: 'MyHomePage' }],
                                    });
                                }, 0);
                            } catch (err) {

                            }
                        }
                    });
                    actions.push({
                        label: `Aún no la he entregado`,
                        type: "secondary",
                        onPress: async (finish) => {
                            // Navega a no la recibió
                            const params = {
                                notification,
                                winner: notification.payload.winner,
                            };
                            props.navigation.navigate('WSurveyDonatorMissedPage', params);
                        }
                    });
                }
                if (onlyData) {
                    return {
                        title: notification.title,
                        description: notification.txt,
                        actions,
                        extra: extra2,
                    };
                } else {
                    props.alert(notification.txt, notification.title, actions, true, extra2);
                }
            }

        }
        props.alert = async (description, title = "Ups!", actions = [], onlyMyActions = false, extra) => {
            return new Promise((resolve) => {
                let myButtons = [
                    {
                        label: "Aceptar",
                        onPress: (finish) => {
                            finish();
                        }
                    }
                ];
                if (onlyMyActions) {
                    myButtons = actions;
                } else {
                    myButtons = myButtons.concat(actions);
                }
                for (let i = 0; i < myButtons.length; i++) {
                    const myButton = myButtons[i];
                    const oldFunction = myButton.onPress;
                    myButton.onPress = () => {
                        oldFunction(resolve);
                    }
                }
                this.setState({
                    alertDetails: {
                        title: title,
                        description: description,
                        buttons: myButtons,
                        extra,
                    }, alertVisible: true
                });
            });
        }
        props.alertOpen = (details) => {
            this.setState({ alertDetails: details, alertVisible: true });
        };
        props.alertClose = () => {
            this.setState({ alertVisible: false });
        };

        props.style = styles.container;
        const myElement = React.createElement(elementType, props);

        if (withScroll) {
            return (
                <ThemeProvider theme={theme}>
                    <ScrollView style={styles.navigator}>
                        {myElement}
                        {this.state.loading &&
                            <View style={styles.loading}>
                                <ActivityIndicator size='large' color={theme.__others__deep_orange} />
                            </View>
                        }
                        {this.state.alertVisible && <MyModal details={this.state.alertDetails}
                            close={() => {
                                if (this._isMounted) {
                                    this.setState({ alertVisible: false });
                                }
                            }}></MyModal>}
                    </ScrollView>
                </ThemeProvider>
            );
        } else {
            return (
                <ThemeProvider theme={theme}>
                    <View style={styles.navigator}>
                        {myElement}
                        {this.state.loading &&
                            <View style={styles.loading}>
                                <ActivityIndicator size='large' color={theme.__others__deep_orange} />
                            </View>
                        }
                        {this.state.alertVisible && <MyModal details={this.state.alertDetails}
                            close={() => {
                                if (this._isMounted) {
                                    this.setState({ alertVisible: false });
                                }
                            }}></MyModal>}
                    </View>
                </ThemeProvider>
            );
        }
    }
}

class MyHomePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(MyHomeScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}


class MessengerPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(MessengerScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class PeoplePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(PeopleScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class SearchPeoplePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(SearchPeopleScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class NotificationsPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(NotificationsScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class RegisterScreenPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(RegisterScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        });
    }
}

class SignUpPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(SignUpScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        });
    }
}

class SignInPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(SignInScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        });
    }
}

class MyAcceptedPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(MyAcceptedScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class RecuperarClavePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(RecuperarClaveScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        });
    }
}

class WDonationPage1 extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationScreen1, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationPage2 extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationScreen2, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationPage3 extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationScreen3, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class OnBoardingPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(OnBoardingScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class EditProfilePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(EditProfileScreen, {
            navigation: this.props.navigation,
            route: this.props.route,
            pageType: "edit",
        }, false);
    }
}

class EditGroupPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(GroupScreen, {
            navigation: this.props.navigation,
            route: this.props.route,
            pageType: "edit",
        }, false);
    }
}

class CreateGroupPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(GroupScreen, {
            navigation: this.props.navigation,
            route: this.props.route,
            pageType: "create",
        }, false);
    }
}

class ProfilePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(ProfileScreen, {
            navigation: this.props.navigation,
            route: this.props.route,
            pageType: "edit",
        }, false);
    }
}

class StatusPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(StatusScreen, {
            navigation: this.props.navigation,
            route: this.props.route,
            pageType: "edit",
        }, false);
    }
}

class CreateProfilePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(EditProfileScreen, {
            navigation: this.props.navigation,
            route: this.props.route,
            pageType: "create",
        }, false);
    }
}

class WDonationWinnersPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationWinnersScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationWinnersNewPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationWinnersScreenNew, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationDatesPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationDatesScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationPublishDatePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationPublishDateScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationLinkPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationLinkScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationPricePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationPriceScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationCategoryPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationCategoryScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationCityPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationCityScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationPlaceMeetPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationPlaceMeetScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationLinkMorePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationLinkMoreScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationOrganicerPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationOrganicerScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationAdvancedPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationAdvancedScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationPlaceReceivePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationPlaceReceiveScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class VotePage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(VoteScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WDonationDetailPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WDonationDetailScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class ReportsPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(ReportsScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WSurveyWinnerPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WSurveyWinnerScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class WSurveyWinnerMissedPage extends ComponentWithActivityIndicator {
    constructor(props) {
        super(props);
    }
    render() {
        return super.parentRender(WSurveyWinnerMissedScreen, {
            navigation: this.props.navigation,
            route: this.props.route
        }, false);
    }
}

class Navigator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: 0
        };
        this.headerElement = null;
    }

    getNavigatorOptions(navigation, someTitle = null, headerShown = true, isHome = false) {
        let element;
        if (this.headerElement == null) {
            this.headerElement = <Pressable
                onPress={() => {
                    const canGoBack = navigation.canGoBack();
                    if (canGoBack) {
                        navigation.goBack();
                    } else {
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'MyHomePage' }],
                        });
                    }
                }}
                style={styles.arrowButon}
                activeOpacity={0.5}>
                <OutlineArrowLeft></OutlineArrowLeft>
            </Pressable>;
        }
        if (!isHome) {
            element = this.headerElement;
        } else {
            element = null;
        }
        const respuesta = {
            headerTitleAlign: 'center',
            headerShown: headerShown,
            headerLeft: () => (element),
            headerStyle: {
                //headerBackVisible: false,
                backgroundColor: theme.__background_dark,
                elevation: 0,
                shadowOpacity: 0,
                borderBottomWidth: 0,

            },
            headerTitleStyle: {
                fontFamily: "Urbanist",
                fontSize: 22,
                color: theme.__others__white,
                fontWeight: "600",
            },
            headerTintColor: theme.__others__white,
        };
        if (typeof someTitle == "string") {
            //respuesta.headerTitle = (props) => <TituloNavBar>{someTitle}</TituloNavBar>;
            respuesta.title = someTitle;
        } else {
            respuesta.title = "";
            respuesta.headerTitle = (props) => null;
        }
        return respuesta;
    }

    render() {
        return (
            <NavigationContainer ref={navigationRef}>
                <StatusBarBackground style={styles.statusBar} />
                <Stack.Navigator
                    screenListeners={{
                        state: (e) => {
                            // Do something with the state

                            const rutas = e.data?.state?.routes;
                            if (rutas instanceof Array) {
                                const ultima = rutas[rutas.length - 1];
                                const lastName = ultima.name;
                                MyLog.log("sendEvent...", this);
                                if (Platform.OS !== "web") {
                                    MyAnalytics.sendEvent("page_view", {
                                        'origin': Platform.OS,
                                        'page_title': lastName,
                                        //'page_path': `/${lastName}`,
                                        'title': lastName,
                                        //'page_location': `${Platform.OS}/${lastName}`,
                                        //"page_referrer": "/",
                                    });
                                }
                            }
                        },
                    }}>
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false, true); }}
                        name="MyHomePage" component={MyHomePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false, false); }}
                        name="PeoplePage" component={PeoplePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false, false); }}
                        name="SearchPeoplePage" component={SearchPeoplePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Nuevo Grupo"); }}
                        name="CreateGroupPage" component={CreateGroupPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Detalle del Grupo"); }}
                        name="EditGroupPage" component={EditGroupPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Donación no entregada", true); }}
                        name="WSurveyDonatorMissedPage" component={WSurveyWinnerMissedPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Donación no recibida", true); }}
                        name="WSurveyWinnerMissedPage" component={WSurveyWinnerMissedPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Ayúdanos con esta encuesta", true); }}
                        name="WSurveyWinnerPage" component={WSurveyWinnerPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false, false); }}
                        name="NotificationsPage" component={NotificationsPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false, false); }}
                        name="MessengerPage" component={MessengerPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Crear Perfil"); }}
                        name="CreateProfilePage" component={CreateProfilePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Editar Perfil"); }}
                        name="EditProfilePage" component={EditProfilePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false, false); }}
                        name="ProfilePage" component={ProfilePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Estatus PANAL", true, false); }}
                        name="StatusPage" component={StatusPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false); }}
                        name="OnBoardingPage" component={OnBoardingPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false); }}
                        name="MyAcceptedPage" component={MyAcceptedPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation); }}
                        name="WDonationPage3" component={WDonationPage3} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation); }}
                        name="WDonationPage1" component={WDonationPage1} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation); }}
                        name="WDonationPage2" component={WDonationPage2} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation); }}
                        name="RegisterScreenPage" component={RegisterScreenPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation); }}
                        name="SignUpPage" component={SignUpPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation); }}
                        name="SignInPage" component={SignInPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation); }}
                        name="RecuperarClavePage" component={RecuperarClavePage} />

                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Fecha de videoconferencia"); }}
                        name="WDonationDatesPage" component={WDonationDatesPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Fecha y hora de publicación"); }}
                        name="WDonationPublishDatePage" component={WDonationPublishDatePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Fecha de Oportunidad"); }}
                        name="WDonationDatesPagePre" component={WDonationDatesPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Fecha de Oportunidad"); }}
                        name="WDonationDatesPageRepost" component={WDonationDatesPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Lugar de recolección"); }}
                        name="WDonationPlaceReceivePage" component={WDonationPlaceReceivePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Lugar de donación"); }}
                        name="WDonationPlaceMeetPage" component={WDonationPlaceMeetPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Lugar de donación"); }}
                        name="WDonationPlaceRepostPage" component={WDonationPlaceMeetPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Link para más información"); }}
                        name="WDonationLinkMorePage" component={WDonationLinkMorePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Organizador"); }}
                        name="WDonationOrganicerPage" component={WDonationOrganicerPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Ajustes avanzados"); }}
                        name="WDonationAdvancedPage" component={WDonationAdvancedPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Número de ganadores"); }}
                        name="WDonationWinnersPage" component={WDonationWinnersPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Concurso"); }}
                        name="WDonationWinnersNewPage" component={WDonationWinnersNewPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Link de videoconferencia"); }}
                        name="WDonationLinkPage" component={WDonationLinkPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Precio"); }}
                        name="WDonationPricePage" component={WDonationPricePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Link de oportunidad"); }}
                        name="WRepostLinkPage" component={WDonationLinkPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Categoría"); }}
                        name="WDonationCategoryPage" component={WDonationCategoryPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Programa o Institución"); }}
                        name="WDonationInstitucionPage" component={WDonationCategoryPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Ciudad"); }}
                        name="WDonationCityPage" component={WDonationCityPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Actividad Económica"); }}
                        name="WDonationCiiuPage" component={WDonationCityPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "País"); }}
                        name="WDonationCountryPage" component={WDonationCityPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Vota por el ganador", true); }}
                        name="VotePage" component={VotePage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false); }}
                        name="WDonationDetailPage" component={WDonationDetailPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false); }}
                        name="WDonationDetailWonPage" component={WDonationDetailPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, null, false); }}
                        name="WDonationDetailAcceptedPage" component={WDonationDetailPage} />
                    <Stack.Screen
                        options={({ navigation }) => { return this.getNavigatorOptions(navigation, "Administración", true); }}
                        name="ReportsPage" component={ReportsPage} />
                </Stack.Navigator>
            </NavigationContainer>
        );
    }
};

const styles = StyleSheet.create({
    arrowButon: {
        marginLeft: 10,
        marginRight: 10,
    },
    statusBar: {

    },
    navigator: {
        backgroundColor: theme.__background_dark,
        height: "100%",
    },
    container: {

    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    }
});

// white-space: nowrap; warning
const TituloNavBar = styled.Text`
    font-family: ${theme.__font_family_urbanist};
    font-size: ${theme.__font_size_xl}; 
    font-weight: 700;
    color: ${props => theme.__others__white};
`;

export default Navigator;
