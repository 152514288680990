import { Text, Linking } from 'react-native';
import React, { Component } from 'react'
import styled from "styled-components/native";

export default class AcceptTerms extends Component {
    render() {
        const enlaceToS = "https://comunidad.panal.co/docs/terminos_de_servicio.html";
        const enlacePP = "https://comunidad.panal.co/docs/politicas_de_privacidad.html";
        return (<Text>Al registrarme o iniciar sesión, declaro que he leído y acepto los <TextA onPress={() => Linking.openURL(enlaceToS)}>Términos del Servicio</TextA> y las <TextA onPress={() => Linking.openURL(enlacePP)}>Políticas de Privacidad</TextA> de Panal Comunidad. Adicionalmente acepto ser contactado por celular.</Text>);
    }
}
const TextA = styled.Text`
    color: ${props => props.theme.__others__deep_orange};
    text-decoration: underline;
`;