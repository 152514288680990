import React from "react";
import styled from "styled-components/native";
import { Pressable } from 'react-native';

import theme from "../../Theme";
import * as CssConstants from "../../widgets/CssConstans";
import Config from "../../utils/Config";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import MyLog from "../../srv/MyLog";

const SCROLL_PADDING = 10;

export default class OtherDonations extends React.Component {
    isCloseToTheEnd({ layoutMeasurement, contentOffset, contentSize }) {
        const padding = SCROLL_PADDING;
        this.xScrollOffset = contentOffset.x;
        const isClose = layoutMeasurement.width + contentOffset.x >=
            contentSize.width - padding;
        return isClose;
    };
    eventEndScroll() {
        MyLog.log("eventEndScroll", this);
        const {
            fetchNextDonations,
        } = this.props;
        if (this.fetchMoreDonations) {
            this.setState({ loading: true });
            fetchNextDonations().finally(() => {
                this.setState({ loading: false });
            });
        } else {
            return;
        }
        const pos = {
            x: this.xScrollOffset - SCROLL_PADDING - 2,
            animated: false,
        };
        this._scrollView.scrollTo(pos);
        this.fetchMoreDonations = false;
    }
    render() {
        const {
            details,
            goToDonation,
            windowSize,
        } = this.props;
        const eventEndScrollThis = this.eventEndScroll.bind(this);

        let lista = details.donations;
        if (lista === null) {
            lista = [];
        }

        const NUMBER_VISIBLE_LINES = 2;
        const LINE_HEIGTH = 18;
        const textBoxTransparentStyle = {
            overflow: "hidden",
            height: (LINE_HEIGTH * NUMBER_VISIBLE_LINES),
            lineHeight: LINE_HEIGTH,
        };

        const listItems = lista.map((elemento, index) =>
            <Pressable key={index} onPress={() => {
                goToDonation(elemento, index);
            }}>
                <Rectangle
                    source={{ uri: Config.getSuffixPath(elemento.img, "_xs") }}
                    resizeMode="cover">
                    <RectangleEmpty>
                        {elemento.rel?.desition === true && <RectangleIconAccepted>
                            <FontAwesomeIcon icon={"heart"} style={{ color: theme.__others__deep_orange, }} size={20} />
                        </RectangleIconAccepted>
                        }
                        {elemento.rel?.desition === false && <RectangleIconRejected>
                            <FontAwesomeIcon icon={"xmark"} style={{ color: theme.__rejected_cross, }} size={20} />
                        </RectangleIconRejected>
                        }
                    </RectangleEmpty>
                    <RectangleTextContainer>
                        <RectangleText style={textBoxTransparentStyle}>{elemento.title}</RectangleText>
                    </RectangleTextContainer>
                </Rectangle>
            </Pressable>
        );

        return (
            <ContainerOtherDonations>
                <AutoLayoutVertical>
                    <Title>{this.props.title ? this.props.title : "Oportunidades"}</Title>
                    <MyContainerV>
                        <HorizontalRectangles
                            windowSize={windowSize}
                            ref={view => this._scrollView = view}
                            onScrollEndDrag={eventEndScrollThis}
                            onMouseUp={eventEndScrollThis}
                            onScroll={({ nativeEvent }) => {
                                //MyLog.log("onScroll", this);
                                if (this.isCloseToTheEnd(nativeEvent)) {
                                    this.fetchMoreDonations = true;
                                }
                            }}
                            scrollEventThrottle={400}
                            horizontal={true}
                        >
                            {listItems}
                        </HorizontalRectangles>
                    </MyContainerV>
                </AutoLayoutVertical>
            </ContainerOtherDonations>
        );
    }
}

const MARGIN = 18;

const ContainerOtherDonations = styled.View`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${MARGIN}px;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 32px 0px 0px;
`;

const Title = styled.Text`
    ${CssConstants.ValignTextBottom}
    ${CssConstants.H4Bold}
    height: 31px;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    line-height: 28.8px;
    margin-bottom: 14px;
`;

const MyContainerV = styled.View`
    display: flex;
    flex-direction: row;
`;

const HorizontalRectangles = styled.ScrollView`
    display: flex;
    flex-direction: row;
    width: ${props => (props.windowSize.width - 3 * MARGIN)}px;
`;

const Rectangle = styled.ImageBackground`
    width: 150px;
    height: 206px;
    margin-right: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 7px;
    overflow: hidden;
`;

const RectangleEmpty = styled.View`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const RectangleIconAccepted = styled.View`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
`;

const RectangleIconRejected = styled.View`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    background-color: ${props => props.theme.__rejected_background};
`;

const RectangleText = styled.Text`
    width: 100%;
    ${CssConstants.BodyMediumSemibold}
    color: ${props => props.theme.__others__white};
    text-align: center;
`;

const RectangleTextContainer = styled.View`
    padding: 10px;
    width: 100%;
    background-color: ${props => props.theme.__eerie_black_mine};
`;