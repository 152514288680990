import { library } from '@fortawesome/fontawesome-svg-core';

// https://www.npmjs.com/package/@fortawesome/react-native-fontawesome#get-started
// https://fontawesome.com/icons

// import your icons
import { faMugSaucer } from '@fortawesome/free-solid-svg-icons/faMugSaucer'
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera'
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons/faCirclePlay'
import { faCompass } from '@fortawesome/free-solid-svg-icons/faCompass'
import { faTicket } from '@fortawesome/free-solid-svg-icons/faTicket'
import { faBagShopping } from '@fortawesome/free-solid-svg-icons/faBagShopping'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons/faUserGroup'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons/faCalendarDays'
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons/faEllipsisVertical'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons/faSquareCheck'
import { faArrowDownUpAcrossLine } from '@fortawesome/free-solid-svg-icons/faArrowDownUpAcrossLine'
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons/faBoxArchive'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons/faSquareXmark'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'

import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp'
import { faX } from '@fortawesome/free-solid-svg-icons/faX'
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark'
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons/faClockRotateLeft'
import { faDroplet } from '@fortawesome/free-solid-svg-icons/faDroplet'

import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faTrophy } from '@fortawesome/free-solid-svg-icons/faTrophy'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons/faShareSquare'

import { faGlassCheers } from '@fortawesome/free-solid-svg-icons/faGlassCheers'
import { faHandsClapping } from '@fortawesome/free-solid-svg-icons/faHandsClapping'

import { faTreeCity } from '@fortawesome/free-solid-svg-icons/faTreeCity'

import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl'

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation'

import { faCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass'

import { faMessage } from '@fortawesome/free-solid-svg-icons/faMessage'
import { faHeartCrack } from '@fortawesome/free-solid-svg-icons/faHeartCrack'
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons/faHeartBroken'

import { faVolumeUp } from '@fortawesome/free-solid-svg-icons/faVolumeUp'
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders'

import { faCircleDot } from '@fortawesome/free-solid-svg-icons/faCircleDot'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'

import { faGift } from '@fortawesome/free-solid-svg-icons/faGift'
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion'

import { faCartShopping } from '@fortawesome/free-solid-svg-icons/faCartShopping'
import { faDiceFour } from '@fortawesome/free-solid-svg-icons/faDiceFour'

import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons/faPeopleGroup'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip'

import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons/faEarthAmericas'
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons/faCommentsDollar'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase'
import { faStore } from '@fortawesome/free-solid-svg-icons/faStore'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons/faLightbulb'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons/faBuildingColumns'

library.add(
    faMugSaucer,
    faSquare,
    faEnvelope,
    faLock,
    faEyeSlash,
    faCheck,
    faAngleDown,
    faCamera,
    faCirclePlay,
    faCompass,
    faTicket,
    faBagShopping,
    faAngleRight,
    faUserGroup,
    faCalendarDays,
    faLink,
    faEllipsisVertical,
    faLocationDot,
    faSquareCheck,
    faArrowDownUpAcrossLine,
    faBoxArchive,
    faClock,
    faPlus,
    faSquareXmark,
    faAngleLeft,
    faStar,
    faPaperPlane,
    faAngleUp,
    faX,
    faHeart,
    faClockRotateLeft,
    faDroplet,
    faHouse,
    faSearch,
    faUser,
    faArrowLeft,
    faTrophy,
    faShareSquare,
    faGlassCheers,
    faHandsClapping,
    faTreeCity,
    faCircle,
    faXmark,
    faListUl,
    faTriangleExclamation,
    faCommentDots,
    faBell,
    faMagnifyingGlass,
    faMessage,
    faHeartCrack,
    faHeartBroken,
    faVolumeUp,
    faGlobe,
    faSliders,
    faCircleDot,
    faCircleCheck,
    faGift,
    faQuestion,
    faCartShopping,
    faDiceFour,
    faPeopleGroup,
    faPaperclip,
    faEarthAmericas,
    faCommentsDollar,
    faGraduationCap,
    faBriefcase,
    faStore,
    faLightbulb,
    faInstagram,
    faBuildingColumns,
);