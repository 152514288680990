import React from "react";
import styled from "styled-components/native";
import { StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import * as CssConstans from "./CssConstans"
import theme from "../Theme";

export default class MyTag
    extends React.Component {
    render() {
        const {
            txt,
            icon,
        } = this.props.details;
        return (
            <Tag1>
                <FontAwesomeIcon icon={icon} style={{ color: theme.__others__white }} size={15} />
                <TagMarker>{txt}</TagMarker>
            </Tag1>
        );
    }
}

const Tag1 = styled.View`
    display: flex;
    flex-direction: row;
    z-index: 1;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    background-color: ${props => props.theme.__greyscale__600};
    border-radius: 15px;
`;

const TagMarker = styled.Text`

    ${CssConstans.ValignTextMiddle}
    ${CssConstans.BodySmallSemibold}
    margin-left: 10px;
    margin-top: -1px;
    font-weight: 600;
    color: ${props => props.theme.__others__white};
`;