import styled from "styled-components/native";
import BottomBar from "../screens/home/BottomBar";
import MyLog from "../srv/MyLog";
import { MountedComponent } from "../utils/MountedComponent";
import { MyFileService } from "../utils/MyFileService";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import theme from "../Theme";
import React from 'react'
import { DonacionesSrv } from "../srv/DonacionesSrv";
import { Users } from "../srv/Users";
import CONFIG from "../Constants";
import { StyleSheet } from "react-native";
import OutlineArrowLeft from "../screens/svg/OutlineArrowLeft";
import MyShare from "../srv/MyShare";
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import Config from "../utils/Config";

const MAX_PAGE = 20;

export default class ProfileScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.epoch = new Date().getTime();
        this.lastTotalFetchedDonations = -1;
        this.pareceQueNoHayMas = false;
        this.state = Object.assign(this.state, {
            donations: [],
            status: {},
            isMe: null,
            loggedUser: false,
            detailsOpened: false,
            theUser: {
                email: "",
                picture: "",
                name: "",
                bio: ""
            }
        });
        const email = this.props.route.params?.email;
        this.initialize(email).catch((err) => {
            this.props.alert(err.message);
        });
    }
    async initialize(email) {
        if (!email) {
            setTimeout(async () => {
                await this.props.alert("No se especificó el correo");
                this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'MyHomePage' }],
                });
            });
        } else {
            this.donacionesSrv = new DonacionesSrv();
            this.donacionesSrv.resetUser(email);
            const promesas = [];
            promesas.push(this.donacionesSrv.fetchUserNext(MAX_PAGE, email, this.props.alert));
            promesas.push(this.donacionesSrv.userStatus(email, this.props.alert));
            const unaSolaPromesa = Promise.all(promesas);
            this.props.loading(unaSolaPromesa);
            const respuestas = await unaSolaPromesa;
            // Se debe asignar avgStars a cada donacion
            this.computeAvgStars(respuestas[0]);
            this.lastTotalFetchedDonations = respuestas[0].length;
            this.setState({
                donations: respuestas[0],
                status: respuestas[1],
            });
        }
    }
    goToChat() {
        if (this.state.loggedUser) {
            this.props.navigation.navigate('MessengerPage', this.state.theUser);
        } else {
            this.askUserToLogin();
        }
    }
    computeAvgStars(donations) {
        for (let i = 0; i < donations.length; i++) {
            const donation = donations[i];
            donation.totalStars = 0;
            donation.totalRating = 0;
            donation.avgStars = 0;
            const llaves = Object.keys(donation);
            const starKeys = llaves.filter((value, index) => {
                return /^starsx_/.test(value);
            });
            for (let j = 0; j < starKeys.length; j++) {
                const oneStarKey = starKeys[j];
                const oneRating = donation[oneStarKey];
                if (typeof oneRating.stars == "number") {
                    donation.totalStars += oneRating.stars;
                    donation.totalRating++;
                }
            }
            if (donation.totalRating > 0) {
                donation.avgStars = Math.ceil(donation.totalStars / donation.totalRating);
            }
        }
    }
    async onUser(payloadUser) {
        const nuevoEstado = {
            loggedUser: false,
            isMe: null,
            theUser: {}
        };
        this.epoch = new Date().getTime();

        nuevoEstado.isMe = (this.props.route.params?.email == payloadUser.user?.email);
        if (payloadUser.user !== null) {
            nuevoEstado.loggedUser = true;
        }
        if (payloadUser.user !== null && nuevoEstado.isMe) {
            nuevoEstado.theUser = {
                email: payloadUser.user?.email,
                picture: payloadUser.userBack?.picture,
                name: payloadUser.userBack?.name,
                bio: payloadUser.userBack?.bio,
                // Campos nuevos
                pitch: payloadUser.userBack?.pitch,
                profesion: payloadUser.userBack?.profesion,
                enterprise: payloadUser.userBack?.enterprise,
                instagram: payloadUser.userBack?.instagram,
                webpage: payloadUser.userBack?.webpage,
                // Listas de valores
                country: payloadUser.userBack?.country,
                city: payloadUser.userBack?.city,
                ciiu: payloadUser.userBack?.ciiu,
                institucion: payloadUser.userBack?.institucion,
            };
        } else {
            // Cargar el otro usuario
            try {
                nuevoEstado.theUser = await Users.readUser(this.props.route.params?.email, this.props.alert);
            } catch (err) {
                // Ya lo está haciendo el alert
            }
        }
        this.setState(nuevoEstado);
    }
    async onShow() {

    }
    async goToCreateDonation(payload) {
        if (!(await this.alertLogedUser())) {
            return;
        }
        const nuevaImagen = await MyFileService.pickFile();
        if (typeof nuevaImagen == "string") {
            const nuevaDonacion = {
                image: nuevaImagen,
                type: payload.donationType,
            };
            // Go to next page
            this.props.navigation.reset({ index: 0, routes: [{ name: 'WDonationPage3', params: nuevaDonacion }] });
        }
    }
    async goToEditProfile() {
        this.props.navigation.navigate('EditProfilePage');
    }
    async gotToDonation(index) {
        console.log(`go to donation ${index}`);
        const params = {
            currentUserEmail: this.state.user?.email,
            donations: this.state.donations,
            index: index,
            origin: "ProfilePage",
        };
        // Toca buscarle los ganadores junto con sus reseñas...
        this.props.loading(new Promise((resolve) => {
            setTimeout(resolve, 1000);
        }));
        setTimeout(() => {
            this.props.navigation.push('WDonationDetailPage', params);
        }, 0);
    }
    async gotToStatus() {
        if (!this.state.status || Object.keys(this.state.status).length == 0) {
            return;
        }
        const myDroplet = CONFIG.getDropletFeature(this.state.status.newStatus);
        const params = {
            donorTypeName: myDroplet.txt,
            beePoints: this.state.status.points,
            dropletColor: myDroplet.color
        };
        this.props.navigation.navigate('StatusPage', params);
    }
    computeStatistics() {
        const response = {
            nDonations: 0,
            starsRating: 0,
            starsCount: 0,
            winnerPersons: 0,
        };
        if (this.state.status && Object.keys(this.state.status).length > 0) {
            const donaciones = this.state.status.VOTES;
            if (donaciones != undefined) {
                for (let i = 0; i < donaciones.length; i++) {
                    const donacion = donaciones[i];
                    response.nDonations++;
                    response.winnerPersons += donacion.numWin;
                }
            }
            const stars = this.state.status.STARS;
            if (stars != undefined) {
                for (let i = 0; i < stars.length; i++) {
                    const star = stars[i];
                    response.starsCount++;
                    response.starsRating += star.stars;
                }
            }
            if (response.starsCount > 0) {
                response.starsRating = (response.starsRating / response.starsCount).toFixed(1);
            }
        }
        return response;
    }
    processOneDonation(donacionIterada, index) {
        const gotToDonationThis = this.gotToDonation.bind(this);
        const details = {
            url: donacionIterada.img,
            gotToDonation: gotToDonationThis,
            index,
            title: donacionIterada.title,
            stars: donacionIterada.avgStars
        };
        return <MyOneDonation key={donacionIterada.id} details={details}></MyOneDonation>;
    }
    async loadMoreDonations() {
        const email = this.props.route.params?.email;
        const promesa = this.donacionesSrv.fetchUserNext(MAX_PAGE, email, this.props.alert);
        this.props.loading(promesa);
        const donations = await promesa;
        if (this.lastTotalFetchedDonations == donations.length) {
            this.pareceQueNoHayMas = true;
        }
        this.lastTotalFetchedDonations = donations.length;
        this.setState({
            donations,
        });
    }
    toggleDetails() {
        this.setState({ detailsOpened: !this.state.detailsOpened });
    }
    procesarTexto(texto) {
        if ([null, undefined, ""].indexOf(texto) >= 0) {
            return "Sin información";
        }
        return texto;
    }
    render() {
        const goToCreateDonationThis = this.goToCreateDonation.bind(this);
        const goToEditProfileThis = this.goToEditProfile.bind(this);
        const loadMoreDonationsThis = this.loadMoreDonations.bind(this);
        const goToChatThis = this.goToChat.bind(this);
        const toggleDetailsThis = this.toggleDetails.bind(this);

        const gotToStatusThis = this.gotToStatus.bind(this);

        const details = {
            homeAction: async () => {
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyHomePage' }] });
            },
            notificationAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("notificationAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'NotificationsPage' }] });
            },
            plusAction: async (payload) => {
                await goToCreateDonationThis(payload);
            },
            chatAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("chatAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'PeoplePage' }] });
            },
            visitAcceptedDonationsAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyAcceptedPage' }] });
            },
        };

        let myDroplet = null;
        let myStatistics = null;
        if (this.state.status) {
            myDroplet = CONFIG.getDropletFeature(this.state.status.newStatus);
            myStatistics = this.computeStatistics();
        }

        let donacionesHtml = [];
        if (this.state.donations instanceof Array) {
            donacionesHtml = this.state.donations.map((donacionIterada, index) => {
                return this.processOneDonation(donacionIterada, index);
            });
        }

        let styleParent = {
            'display': 'flex',
            'flexDirection': 'column',
        };
        if (this.props.style && Object.keys(this.props.style).length > 0) {
            styleParent = Object.assign(styleParent, this.props.style);
        } else {
            styleParent = Object.assign(styleParent, {
                'height': '100%',
            });
        }
        let backAction = super.backOrGoHome.bind(this);
        if (typeof this.props.backAction == 'function') {
            backAction = this.props.backAction;
        }
        let cityName = "";
        if (this.state.theUser.country == "col") {
            cityName = Config.getCityValue(this.state.theUser.city);
        } else if (this.state.theUser.country == "usa") {
            cityName = Config.getCityUsaValue(this.state.theUser.city);
        }

        return (
            <ParentContainer style={styleParent}>
                <MyHeader>
                    <FakeIcon onPress={backAction}>
                        <OutlineArrowLeft></OutlineArrowLeft>
                    </FakeIcon>
                    <FakeText>
                        {this.state.theUser.email}
                    </FakeText>
                    <FakeIcon onPress={gotToStatusThis}>
                        <FontAwesomeIcon icon={"user"} style={{ color: theme.__others__white, outline: "none" }} size={28} />
                    </FakeIcon>
                </MyHeader>
                <MyContent>
                    <MessagesScroll ref={ref => { this.scrollView = ref }} onContentSizeChange={() => { }}>
                        <MyBody>
                            <MyProfile>
                                <MyProfileTop>
                                    <MyCircledPhotoWithDroplet>
                                        <MyCircledPhoto>
                                            <MyPhoto resizeMode="cover" source={{ uri: this.state.theUser.picture + "?t=" + this.epoch }}>
                                            </MyPhoto>
                                        </MyCircledPhoto>
                                        {myDroplet != null && <DropletClickeable onPress={gotToStatusThis}>
                                            <FontAwesomeIcon
                                                icon={"droplet"}
                                                style={{
                                                    color: myDroplet.color,
                                                    outline: "none"
                                                }}
                                                size={30} />
                                        </DropletClickeable>}
                                    </MyCircledPhotoWithDroplet>
                                    <MySimpleName>{this.state.theUser.name}</MySimpleName>
                                    {myDroplet != null && <MyStatusName>{myDroplet.txt}</MyStatusName>}
                                    {myStatistics != null && <MyStatistics>
                                        <OneStatistic>
                                            <NumberStatistic>{myStatistics.nDonations}</NumberStatistic>
                                            <DescriptionStatistic>{"Donaciones"}</DescriptionStatistic>
                                        </OneStatistic>
                                        <VerticalDivider></VerticalDivider>
                                        <OneStatistic>
                                            <NumberStatistic>{`${myStatistics.starsRating} de 5`}</NumberStatistic>
                                            <DescriptionStatistic>{"Calificación"}</DescriptionStatistic>
                                        </OneStatistic>
                                        <VerticalDivider></VerticalDivider>
                                        <OneStatistic>
                                            <NumberStatistic>{myStatistics.winnerPersons}</NumberStatistic>
                                            <DescriptionStatistic>{"Beneficiarios"}</DescriptionStatistic>
                                        </OneStatistic>
                                    </MyStatistics>}
                                </MyProfileTop>
                                <MyProfileBiography>{this.state.theUser.bio}</MyProfileBiography>

                                {this.state.isMe && <MyProfileButton onPress={goToEditProfileThis}>
                                    <MyProfileButtonText>{"Editar Perfil"}</MyProfileButtonText>
                                </MyProfileButton>}
                                {!this.state.isMe && <MyProfileButton onPress={goToChatThis}>
                                    <MyProfileButtonText>{"Enviar mensaje"}</MyProfileButtonText>
                                </MyProfileButton>}

                                {!this.state.detailsOpened && <DetailsButton onPress={toggleDetailsThis}>
                                    <MyProfileButtonText>{"Ver más detalles"}</MyProfileButtonText>
                                    <FontAwesomeIcon icon={"angle-down"} style={{ marginLeft: 10, color: theme.__others__white, outline: "none" }} size={18} />
                                </DetailsButton>}
                                {this.state.detailsOpened && <DetailsButton onPress={toggleDetailsThis}>
                                    <MyProfileButtonText>{"Ver menos detalles"}</MyProfileButtonText>
                                    <FontAwesomeIcon icon={"angle-up"} style={{ marginLeft: 10, color: theme.__others__white, outline: "none" }} size={18} />
                                </DetailsButton>}

                                {this.state.detailsOpened && <DetailsContainer>
                                    <HorizontalDivider style={{ marginTop: 15, marginBottom: 15 }}></HorizontalDivider>
                                    <MyProfileButtonText style={{ width: "100%", textAlign: "left", marginBottom: 10 }}>{"Información personal:"}</MyProfileButtonText>
                                    <DetailsField>
                                        <DetailIcon>
                                            <FontAwesomeIcon icon={"briefcase"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                                        </DetailIcon>
                                        <DetailValue>{"Profesión: " + this.procesarTexto(this.state.theUser.profesion)}</DetailValue>
                                    </DetailsField>
                                    <DetailsField>
                                        <DetailIcon>
                                            <FontAwesomeIcon icon={"building-columns"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                                        </DetailIcon>
                                        <DetailValue>{"Institución: " + this.procesarTexto(Config.getInstitucionValue(this.state.theUser.institucion))}</DetailValue>
                                    </DetailsField>
                                    <HorizontalDivider style={{ marginTop: 15, marginBottom: 15 }}></HorizontalDivider>
                                    <MyProfileButtonText style={{ width: "100%", textAlign: "left", marginBottom: 10 }}>{"Información de empresa o emprendimiento:"}</MyProfileButtonText>
                                    <DetailsField>
                                        <DetailIcon>
                                            <FontAwesomeIcon icon={"store"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                                        </DetailIcon>
                                        <DetailValue>{"Nombre: " + this.procesarTexto(this.state.theUser.enterprise)}</DetailValue>
                                    </DetailsField>
                                    <DetailsField>
                                        <DetailIcon>
                                            <FontAwesomeIcon icon={"lightbulb"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                                        </DetailIcon>
                                        <DetailValue>{"Elevator pitch: " + this.procesarTexto(this.state.theUser.pitch)}</DetailValue>
                                    </DetailsField>
                                    <DetailsField>
                                        <DetailIcon>
                                            <FontAwesomeIcon icon={"link"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                                        </DetailIcon>
                                        <DetailValue>
                                            <DetailValue style={{ marginRight: 10 }}>{"Página web:"}</DetailValue>
                                            {[null, undefined, ""].indexOf(this.state.theUser.webpage) < 0 && <MyClickeable onPress={() => {
                                                MyShare.openUrlOrMail(this.state.theUser.webpage);
                                            }}>
                                                <DetailValueClick>{this.state.theUser.webpage}</DetailValueClick>
                                            </MyClickeable>}
                                            {[null, undefined, ""].indexOf(this.state.theUser.webpage) >= 0 && <DetailValue>{"Sin información"}</DetailValue>}
                                        </DetailValue>
                                    </DetailsField>
                                    <DetailsField>
                                        <DetailIcon>
                                            <FontAwesomeIcon icon={faInstagram} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                                        </DetailIcon>
                                        <DetailValue>
                                            <DetailValue style={{ marginRight: 10 }}>{"Usuario de Instagram:"}</DetailValue>
                                            {[null, undefined, ""].indexOf(this.state.theUser.instagram) < 0 && <MyClickeable onPress={() => {
                                                const instaUser = this.state.theUser.instagram.replace(/^@/, "");
                                                MyShare.openUrlOrMail(`https://www.instagram.com/${instaUser}/`);
                                            }}>
                                                <DetailValueClick>{this.state.theUser.instagram}</DetailValueClick>
                                            </MyClickeable>}
                                            {[null, undefined, ""].indexOf(this.state.theUser.instagram) >= 0 && <DetailValue>{"Sin información"}</DetailValue>}
                                        </DetailValue>
                                    </DetailsField>
                                    <DetailsField>
                                        <DetailIcon>
                                            <FontAwesomeIcon icon={"comments-dollar"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                                        </DetailIcon>
                                        <DetailValue>{"Actividad económica: " + this.procesarTexto(Config.getCiiuValue(this.state.theUser.ciiu))}</DetailValue>
                                    </DetailsField>
                                    <DetailsField>
                                        <DetailIcon>
                                            <FontAwesomeIcon icon={"earth-americas"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                                        </DetailIcon>
                                        <DetailValue>{"País: " + this.procesarTexto(Config.getCountryValue(this.state.theUser.country))}</DetailValue>
                                    </DetailsField>
                                    {[null, undefined, ""].indexOf(cityName) < 0 && <DetailsField>
                                        <DetailIcon>
                                            <FontAwesomeIcon icon={"location-dot"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                                        </DetailIcon>
                                        <DetailValue>{"Ciudad: " + cityName}</DetailValue>
                                    </DetailsField>}
                                </DetailsContainer>}

                            </MyProfile>
                            <HorizontalDivider></HorizontalDivider>
                            <MyDonations>
                                <MyDonationTitle>{"Donaciones"}</MyDonationTitle>
                                <TheDonationsParent>
                                    {donacionesHtml.length > 0 && <TheDonationsContainer>
                                        {donacionesHtml}
                                        {donacionesHtml.length >= MAX_PAGE && this.pareceQueNoHayMas == false && <MoreDonation onPress={loadMoreDonationsThis}>
                                            <MoreDonationText>{"Ver más..."}</MoreDonationText>
                                        </MoreDonation>}
                                    </TheDonationsContainer>}
                                </TheDonationsParent>
                            </MyDonations>
                        </MyBody>
                    </MessagesScroll>
                </MyContent>
                <MyFooterBottom>
                    <AutoLayoutVerticalBotom>
                        <BottomBar
                            useDirectDonation={true}
                            details={details}
                            userRealTime={this.state.userRealTime}
                            alert={this.props.alert}
                            route={"Profile"} />
                    </AutoLayoutVerticalBotom>
                </MyFooterBottom>
            </ParentContainer >
        );
    }
}

const FakeIcon = styled.Pressable`
    margin: 10px;
`;

const FakeText = styled.Text`
    flex: 1;
    margin: 16px;
    font-family: Urbanist;
    color: white;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
`;

class MyOneDonation extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const iconStyleStarOn = { marginLeft: 2, marginRight: 2, color: theme.__others__white, outline: "none" };
        const iconStyleStarOff = { marginLeft: 2, marginRight: 2, color: "rgba(26, 27, 34, 0.5)", outline: "none" };
        const {
            index,
            title,
            stars,
            url,
            gotToDonation,
        } = this.props.details;

        const NUMBER_VISIBLE_LINES = 2;
        const LINE_HEIGTH = 18;
        const textBoxTransparentStyle = {
            overflow: "hidden",
            height: (LINE_HEIGTH * NUMBER_VISIBLE_LINES),
            lineHeight: LINE_HEIGTH,
        };

        return (<OneDonation onPress={() => {
            gotToDonation(index);
        }}>
            <OneDonationBasckground resizeMode="cover" source={{ uri: url }}>
                <OneDonationTransparency>
                    <RectangleTextContainer>
                        <OneDonationTitle style={textBoxTransparentStyle}>{title}</OneDonationTitle>
                    </RectangleTextContainer>
                    <OneDonationStars>
                        <FontAwesomeIcon icon={"star"} style={stars > 0 ? iconStyleStarOn : iconStyleStarOff} size={15} />
                        <FontAwesomeIcon icon={"star"} style={stars > 1 ? iconStyleStarOn : iconStyleStarOff} size={15} />
                        <FontAwesomeIcon icon={"star"} style={stars > 2 ? iconStyleStarOn : iconStyleStarOff} size={15} />
                        <FontAwesomeIcon icon={"star"} style={stars > 3 ? iconStyleStarOn : iconStyleStarOff} size={15} />
                        <FontAwesomeIcon icon={"star"} style={stars > 4 ? iconStyleStarOn : iconStyleStarOff} size={15} />
                    </OneDonationStars>
                </OneDonationTransparency>
            </OneDonationBasckground>
        </OneDonation>);
    }
}

const DetailsButton = styled.Pressable`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const DetailsContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

const DetailsField = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const DetailIcon = styled.View`
    height: 20px;
    padding: 0px 10px;
`;

const DetailValue = styled.Text`
    flex: 1;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    text-align: left;
`;

const DetailValueClick = styled.Text`
    flex: 1;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${props => props.theme.__others__deep_orange};
    text-align: left;
    border-color: ${props => props.theme.__others__deep_orange};
`;

const MyClickeable = styled.Pressable`

`;

const DropletClickeable = styled.Pressable`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
`;

const OneDonationStars = styled.View`
    display: flex;
    flex-direction: row;
`;

//line-height: 140%;
const OneDonationTitle = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
`;

const RectangleTextContainer = styled.View`
    width: 100%;
`;

const OneDonationTransparency = styled.View`
    background-color: rgba(26, 27, 34, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 4px 9px 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;

const OneDonation = styled.Pressable`
    margin: 4px;
    border-radius: 8px;
    height: 234px;
    width: 150px;
    overflow: hidden;
`;

const MoreDonation = styled.Pressable`
    margin: 4px;
    border-radius: 8px;
    height: 234px;
    width: 150px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
`;

const MoreDonationText = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 12px;
`;

const OneDonationBasckground = styled.ImageBackground`
    background-color: white;
    width: 100%;
    height: 100%;
    position: relative;
`;

//line-height: 120%;
const MyDonationTitle = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 12px;
`;

const VerticalDivider = styled.View`
    height: 100%;
    width: 1px;
    background-color: #2A2B39;
    margin: 0 16px;
`;

const HorizontalDivider = styled.View`
    width: 100%;
    height: 1px;
    background-color: #2A2B39;
`;

//line-height: 120%;
const NumberStatistic = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
`;

//line-height: 140%;
const DescriptionStatistic = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
`;

const OneStatistic = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MyStatistics = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

// line-height: 140%;
const MyStatusName = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 19px;
`;

//line-height: 120%;
const MySimpleName = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
`;

const MyCircledPhotoWithDroplet = styled.View`
    width: 120px;
    height: 120px;
    margin-bottom: 12px;
    position: relative;
`;

// border-radius no se puede en porcentaje para react native
const MyCircledPhoto = styled.View`
    width: 100%;
    height: 100%;
    border-radius: 120px;
    overflow: hidden;
`;

const MyPhoto = styled.ImageBackground`
    width: 100%;
    height: 100%;
`;

const DivFill = styled.View`
    flex: 1;
`;

//line-height: 120%; da error en react native!!!!
const TheName = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
`;

const MyName = styled.View`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MyProfileTop = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
`;

//line-height: 140%;
const MyProfileBiography = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #BDBDBD;
    text-align: center;
    margin-bottom: 15px;
`;

//line-height: 140%;
const MyProfileButtonText = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
`;

const MyProfileButton = styled.Pressable`
    background: #2A2B39;
    border-radius: 30px;
    margin-bottom: 15px;
    align-items: center;
    padding: 12px 16px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const MyProfile = styled.View`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const MyDonations = styled.View`
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const TheDonationsParent = styled.View`
    margin: 0 auto;
`;

const TheDonationsContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

const MyHeader = styled.View`
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MyBody = styled.View`
    display: flex;
    flex-direction: column;
`;

const ParentContainer = styled.View`
    width: 100%;
`;

const MyContent = styled.View`
    width: 100%;
    flex: 1;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0px;
`;

const MessagesScroll = styled.ScrollView`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const MyFooterBottom = styled.View`
    width: 100%;
`;

const AutoLayoutVerticalBotom = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
