import MyEditProfile from "../screens/profile/MyEditProfile";
import styled from "styled-components/native";
import { Formik } from 'formik';
import MyLog from "../srv/MyLog";
import { MountedComponent } from "../utils/MountedComponent";
import { hasMinLength } from "../utils/InputValidator";
import theme from "../Theme";
import { Users } from "../srv/Users";
import { MyFileService } from "../utils/MyFileService";
import myFirebase from "../config/firebase";
import { saveData } from "../utils/MyLocalStorage";
import Config from "../utils/Config";
import { isValidInstagram, isValidURL } from '../utils/InputValidator';

export default class EditProfileScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.pageType = this.props.pageType;
        this.state = Object.assign(this.state, {
            showTime: new Date().getTime(),
            data: {
                name: null,
                picture: null,
                email: null,
                bio: null,
            }
        });
    }
    frezzePicture(picture) {
        this.picture = picture;
    }
    pictureHasChanged() {
        return (this.picture != this.state.data.picture);
    }
    async onUser(payloadUser) {
        if (payloadUser.user !== null) {
            this.frezzePicture(payloadUser.userBack.picture);
            let params = this.props.route.params;
            if (!params) {
                params = {};
                this.props.route.params = params;
            }
            if (!params.country) {
                params.country = payloadUser.userBack.country;
            }
            if (!params.city) {
                params.city = payloadUser.userBack.city;
            }
            if (!params.ciiu) {
                params.ciiu = payloadUser.userBack.ciiu;
            }
            if (!params.institucion) {
                params.institucion = payloadUser.userBack.institucion;
            }

            // Se valida el campo de ciudad
            let cityText = null;
            if (params.country == "col") {
                cityText = Config.getCityValue(params.city);
            } else if (params.country == "usa") {
                cityText = Config.getCityUsaValue(params.city);
            }
            if (cityText == null) {
                params.city = "";
            }

            this.setState({
                data: {
                    name: payloadUser.userBack.name,
                    email: payloadUser.userBack.email,
                    picture: payloadUser.userBack.picture,
                    bio: payloadUser.userBack.bio,
                    // Campos nuevos
                    pitch: payloadUser.userBack.pitch,
                    profesion: payloadUser.userBack.profesion,
                    enterprise: payloadUser.userBack.enterprise,
                    instagram: payloadUser.userBack.instagram,
                    webpage: payloadUser.userBack.webpage,
                    // Listas de valores
                    country: params.country,
                    city: params.city,
                    ciiu: params.ciiu,
                    institucion: params.institucion,
                }
            });
        }
    }
    async saveAndSyncUser(values) {
        // Debo guardar los datos del usuario en backend
        // Debo decirle a firebase cuál es el nuevo usuario guardado
        this.setState({ data: values });
        this.frezzePicture(values.picture);
        const theFirebase = await myFirebase;
        theFirebase.mergeBackUser({
            name: values.name,
            picture: values.picture,
            bio: values.bio,
            profesion: values.profesion,
            pitch: values.pitch,
            enterprise: values.enterprise,
            instagram: values.instagram,
            webpage: values.webpage,
            country: values.country,
            city: values.city,
            ciiu: values.ciiu,
            institucion: values.institucion,
        });
    }
    async pickPhotoAction() {
        const nuevaImagen = await MyFileService.pickFile();
        if (typeof nuevaImagen == "string") {
            Object.assign(this.state.data, { picture: nuevaImagen });
            this.setState({
                data: this.state.data
            });
        }
    }
    async logoutAction() {
        // Pedir confirmar
        const options = {
            message: "¿Seguro que deseas cerrar tu sesión?",
        }
        const desicion = await super.confirm(options);
        if (desicion) {
            this.logout();
        }
    }
    async finishActions() {
        const lastRoute = this.getCurrentState();
        if (lastRoute.name == "CreateProfilePage") {
            // Lo mando al home
            await saveData("createprofile.", { done: "si" });
            super.reloadOldStateOrGoHome();
        }
    }
    async eraseUserAction() {
        // Pedir confirmar
        const options = {
            message: "Esta acción no se puede deshacer. ¿Estas seguro?",
        }
        const desicion = await super.confirm(options);
        if (desicion) {
            try {
                // En backend crear el registro de la tabla.
                const promesa = Users.startErasingUser();
                this.props.loading(promesa);
                await promesa;
                //Hacer logout
                await this.props.alert("El proceso de borrado se ha radicado correctamente.", "Listo");
                await super.logout();
            } catch (error) {
                await this.props.alert(error.message);
            }
        }
    }
    handleLocalNavigation(page, extra = {}) {
        const props = this.props;
        const state = props.navigation.getState();
        const routes = state.routes;
        const lastRoute = routes[routes.length - 1];
        let params = props.route.params;
        if (!params) {
            params = {};
        }
        // Se mezclan los datos extra
        Object.assign(params, extra);
        if (!(params.backPage instanceof Array)) {
            params.backPage = [lastRoute.name];
        } else {
            params.backPage.push(lastRoute.name);
        }
        props.navigation.navigate(page, params);
    }

    render() {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }

        const pickPhotoAction = this.pickPhotoAction.bind(this);
        const eraseUserAction = this.eraseUserAction.bind(this);
        const logoutAction = this.logoutAction.bind(this);
        const saveAndSyncUser = this.saveAndSyncUser.bind(this);
        const pictureHasChanged = this.pictureHasChanged.bind(this);
        const finishActionsThis = this.finishActions.bind(this);
        const handleLocalNavigationThis = this.handleLocalNavigation.bind(this);

        const detailsButtonPrimary = {
            label: (this.pageType == "create" ? "Continuar" : "Guardar"),
            autoWidth: false,
        };

        let detailsButtonSecondary = {
            label: "Saltar",
            onPress: async () => {
                await finishActionsThis();
            },
            autoWidth: false,
            bgColor: theme.__background_dark_button,
            myStyle: { color: "white" },
        };

        if (this.pageType == "edit") {
            detailsButtonSecondary = null;
        }

        const showLogout = (this.pageType == "edit");
        const showEraseAccount = (this.pageType == "edit");

        let cityProps = null;

        if (["col", "usa"].indexOf(params.country) >= 0) {
            cityProps = {
                label: "Ciudad:",
                icon: "location-dot",
                name: "city",
                currentValue: "col",
                goToPage: () => {
                    const options = {
                        keyName: "city",
                    };
                    if (params.country == "col") {
                        options.list = "cities";
                    } else if (params.country == "usa") {
                        options.list = "usa_cities_comunidad";
                    }
                    handleLocalNavigationThis("WDonationCityPage", options);
                }
            };
            if (params.country == "col") {
                cityProps.currentValue = Config.getCityValue(params.city);
            } else if (params.country == "usa") {
                cityProps.currentValue = Config.getCityUsaValue(params.city);
            }
        }

        return (
            <AutoLayoutVertical>
                {this.state.data.email && <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={this.state.data}
                    validate={values => {
                        const errors = {};

                        if (typeof values.enterprise == "string" && values.enterprise.length > 0) {
                            errors.enterprise = hasMinLength(values.enterprise, 3);
                            if (errors.enterprise === undefined) {
                                delete errors.enterprise;
                            } else {
                                this.props.alert("Tu nombre de empresa debe tener al menos 3 letras. " + errors.enterprise);
                                return errors;
                            }
                        }

                        if (typeof values.pitch == "string" && values.pitch.length > 0) {
                            errors.pitch = hasMinLength(values.pitch, 30);
                            if (errors.pitch === undefined) {
                                delete errors.pitch;
                            } else {
                                this.props.alert("Tu elevator pitch debe tener al menos 30 letras. " + errors.pitch);
                                return errors;
                            }
                        }

                        const hayValorInstagram = ["", null, undefined].indexOf(values["instagram"]) < 0;
                        if (hayValorInstagram) {
                            errors["instagram"] = isValidInstagram(values["instagram"], true);
                            if (errors["instagram"] === undefined) {
                                delete errors["instagram"];
                            } else {
                                this.props.alert(errors["instagram"]);
                                return errors;
                            }
                        }

                        const hayValorWebPage = ["", null, undefined].indexOf(values["webpage"]) < 0;
                        if (hayValorWebPage) {
                            errors["webpage"] = isValidURL(values["webpage"], true);
                            if (errors["webpage"] === undefined) {
                                delete errors["webpage"];
                            } else {
                                this.props.alert(errors["webpage"]);
                                return errors;
                            }
                        }

                        errors.name = hasMinLength(values.name, 5);
                        if (errors.name === undefined) {
                            delete errors.name;
                        } else {
                            this.props.alert("Verifica el nombre. " + errors.name);
                            return errors;
                        }

                        /*
                        if ([null, undefined, ""].indexOf(params.country) >= 0) {
                            errors.country = "El país es requerido";
                            this.props.alert(errors.country);
                            return errors;
                        } else {
                            delete errors.country;
                        }
                        */
                        //params.city
                        //params.ciiu
                        //params.institucion

                        return errors;
                    }}
                    onSubmit={async (values) => {
                        // Invocar a servicio de guardar datos de usuario
                        //
                        try {
                            // Se completan datos de params
                            values.country = params.country;
                            values.city = params.city;
                            values.ciiu = params.ciiu;
                            values.institucion = params.institucion;

                            // Se debe pasar a texto cada una de las listas de valores
                            let q = "";
                            const country = Config.getCountryValue(values.country);
                            if (country != null) {
                                q += country + " ";
                            }
                            const ciiu = Config.getCiiuValue(values.ciiu);
                            if (ciiu != null) {
                                q += ciiu + " ";
                            }
                            if (cityProps != null && cityProps.currentValue != null) {
                                q += cityProps.currentValue + " ";
                            }

                            const programaInstitucion = Config.getInstitucionValue(params.institucion);
                            if (typeof programaInstitucion == "string") {
                                q += programaInstitucion + " ";
                            }

                            values.q = q;

                            //console.log(JSON.stringify(values, null, 4));

                            const promesa = new Users().updateUser(values, pictureHasChanged());
                            this.props.loading(promesa);
                            const nextUser = await promesa;
                            await saveAndSyncUser(nextUser);
                            this.setState({ showTime: new Date().getTime() });
                            await this.props.alert("Tus cambios se han guardado", "¡Listo!");
                            await this.finishActions();
                        } catch (error) {
                            await this.props.alert(error.message);
                        }

                    }}
                >
                    {({ handleChange, handleBlur, submitForm, errors, values }) => (
                        <MyEditProfile
                            details={{
                                pageType: this.pageType,
                                showTime: this.state.showTime,
                                submit: submitForm,
                                pickPhotoAction,
                                logoutAction,
                                eraseUserAction,
                                nameProps: {
                                    secret: false,
                                    label: "Nombres",
                                    onChangeText: handleChange('name'),
                                    onBlur: handleBlur('name'),
                                    errors: errors.name,
                                    value: values.name,
                                },
                                enterpriseName: {
                                    secret: false,
                                    label: "Nombre",
                                    onChangeText: handleChange('enterprise'),
                                    onBlur: handleBlur('enterprise'),
                                    errors: errors.enterprise,
                                    value: values.enterprise,
                                },
                                webPage: {
                                    secret: false,
                                    label: "Página web",
                                    onChangeText: handleChange('webpage'),
                                    onBlur: handleBlur('webpage'),
                                    errors: errors.webpage,
                                    value: values.webpage,
                                },
                                instagramProps: {
                                    secret: false,
                                    label: "Usuario de Instagram:",
                                    onChangeText: handleChange('instagram'),
                                    onBlur: handleBlur('instagram'),
                                    errors: errors.instagram,
                                    value: values.instagram,
                                },
                                profesionName: {
                                    secret: false,
                                    label: "Profesión",
                                    onChangeText: handleChange('profesion'),
                                    onBlur: handleBlur('profesion'),
                                    errors: errors.profesion,
                                    value: values.profesion,
                                },
                                photoProps: {
                                    url: (this.state.data.picture ? this.state.data.picture : values.picture),
                                },
                                emailProps: {
                                    editable: false,
                                    secret: false,
                                    label: "Email",
                                    onChangeText: handleChange('email'),
                                    onBlur: handleBlur('email'),
                                    errors: errors.email,
                                    value: values.email,
                                },
                                bioProps: {
                                    multiline: true,
                                    numberOfLines: 3,
                                    secret: false,
                                    label: "Biografía",
                                    onChangeText: handleChange('bio'),
                                    onBlur: handleBlur('bio'),
                                    errors: errors.bio,
                                    value: values.bio,
                                },
                                pitchProps: {
                                    multiline: true,
                                    numberOfLines: 3,
                                    secret: false,
                                    label: "Elevator pitch",
                                    onChangeText: handleChange('pitch'),
                                    onBlur: handleBlur('pitch'),
                                    errors: errors.pitch,
                                    value: values.pitch,
                                },
                                countryProps: {
                                    label: "País:",
                                    icon: "earth-americas",
                                    name: "country",
                                    currentValue: Config.getCountryValue(params.country),
                                    errors: errors.country,
                                    goToPage: () => {
                                        const options = {
                                            list: "countries_comunidad",
                                            keyName: "country",
                                        };
                                        handleLocalNavigationThis("WDonationCountryPage", options);
                                    }
                                },
                                cityProps: cityProps,
                                ciiuProps: {
                                    label: "Actividad económica:",
                                    icon: "comments-dollar",
                                    name: "ciiu",
                                    currentValue: Config.getCiiuValue(params.ciiu),
                                    goToPage: () => {
                                        const options = {
                                            list: "ciiu_comunidad",
                                            keyName: "ciiu",
                                        };
                                        handleLocalNavigationThis("WDonationCiiuPage", options);
                                    }
                                },
                                institucionProps: {
                                    label: "Programa o Institución:",
                                    icon: "building-columns",
                                    name: "institucion",
                                    currentValue: Config.getInstitucionValue(params.institucion),
                                    goToPage: () => {
                                        const options = {
                                            list: "instituciones",
                                            keyName: "institucion",
                                        };
                                        handleLocalNavigationThis("WDonationInstitucionPage", options);
                                    }
                                },
                                detailsButtonPrimary: Object.assign(detailsButtonPrimary, {
                                    onPress: submitForm,
                                }),
                                detailsButtonSecondary: detailsButtonSecondary,
                                showLogout: showLogout,
                                showEraseAccount: showEraseAccount,
                            }}
                        />
                    )}
                </Formik>}
            </AutoLayoutVertical>
        );
    }
}

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;