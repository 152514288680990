import styled from "styled-components/native";
import { Formik } from 'formik';
import MyLog from "../srv/MyLog";
import { MountedComponent } from "../utils/MountedComponent";
import { hasMinLength } from "../utils/InputValidator";
import theme from "../Theme";
import { MyFileService } from "../utils/MyFileService";
import AssetsImg from "../AssetsImg";
import Config from "../utils/Config";
import MyInput from "../widgets/MyInput";
import * as CssConstants from "../widgets/CssConstans";
import Button from "../screens/signup/Button";
import { GroupsSrv } from "../srv/GroupsSrv";
import MemberRow from "../widgets/MemberRow";
import MyRichText from "../widgets/MyRichText";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

const MAX_PAGE_MEMBERS = 20;

export default class GroupScreen extends MountedComponent {
    constructor(props) {
        super(props);
        // Este parámetro se asinga en Navigator.js
        this.pageType = this.props.pageType;
        this.membersLoadedFirstTime = false;
        this.originalUserIds = [];
        let group = this.props.route.params.group;
        let selectedList = this.props.route.params.members;
        let imOwnerGroup = false;
        //console.log(JSON.stringify(selectedList, null, 4));
        if (!(selectedList instanceof Array)) {
            selectedList = [];
        }
        if (!group) {
            group = {
                description: null,
                name: null,
                picture: null,
            };
        }
        this.frezzePicture(group.picture);
        this.state = Object.assign(this.state, {
            showTime: new Date().getTime(),
            data: group,
            selectedList,
            imOwnerGroup,
            isEditDescription: false,
            someChange: false,// TODO la idea es detectar cuándo se debe guardar y cuando no.
        });
    }
    freezeModel() {
        const freeze = {
            name: "",
            description: "",
            participantes: [],
        };
    }
    frezzePicture(picture) {
        this.picture = picture;
    }
    pictureHasChanged() {
        return (this.picture != this.state.data.picture);
    }
    popUpUserMenu(user) {
        const opciones = [];
        opciones.push({
            label: `Ver perfil de ${user.name}`,
            type: "secondary",
            onPress: async () => {
                //console.log(`Ver perfil de ${user.email}`);
                this.goToProfile(user);
            }
        });
        opciones.push({
            label: `Chat con ${user.name}`,
            type: "primary",
            onPress: async () => {
                //console.log(`Chat con ${user.email}`);
                this.goToPersonSpace(user);
            }
        });
        if (this.state.imOwnerGroup) {
            if (user.is_admin === false) {
                opciones.push({
                    label: `Asignar como administrador`,
                    type: "secondary",
                    onPress: async () => {
                        user.is_admin = true;
                        user.changed = true;
                    }
                });
            }
            if (user.is_admin === true) {
                opciones.push({
                    label: `Dar de baja de administrador`,
                    type: "secondary",
                    onPress: async () => {
                        user.is_admin = false;
                        user.changed = true;
                    }
                });
            }
        }
        opciones.push({
            label: "Cancelar",
            type: "secondary",
            onPress: async () => {
                // Do nothing
            }
        });
        this.props.alertOpen({
            title: `Opciones de ${user.name}`,
            description: "Selecciona una opción",
            buttons: opciones
        });
    }
    async goToPeopleScreen() {
        this.props.navigation.reset({ index: 0, routes: [{ name: 'PeoplePage' }] });
    };
    freezeSelectedUsers() {
        this.originalUserIds = [];
        const actualList = this.state.selectedList;
        for (let i = 0; i < actualList.length; i++) {
            const selectedUser = actualList[i];
            this.originalUserIds.push(selectedUser.email);
        }
    }
    setSelectedListUnchanged() {
        const actualList = this.state.selectedList;
        for (let i = 0; i < actualList.length; i++) {
            const selectedUser = actualList[i];
            selectedUser.changed = false;
        }
    }
    computeMemberChanges() {
        const originalList = this.originalUserIds;
        const actualList = this.state.selectedList;
        const changedIndexed = [];
        const mapUsers = {};
        // Indexo la lista actual
        const actualIndexList = [];
        for (let i = 0; i < actualList.length; i++) {
            const selectedUser = actualList[i];
            actualIndexList.push(selectedUser.email);
            mapUsers[selectedUser.email] = selectedUser;
            if (selectedUser.changed == true) {
                changedIndexed.push(selectedUser.email);
            }
        }
        // Miro cuáles se han de borrar
        const eraseMemberList = [];
        for (let i = 0; i < originalList.length; i++) {
            const originalMember = originalList[i];
            if (actualIndexList.indexOf(originalMember) < 0) {
                // No aparece en la lista actual, se debe borrar
                eraseMemberList.push({ email: originalMember });
            }
        }
        // Miro cuáles se han de agregar
        const addMemberList = [];
        for (let i = 0; i < actualIndexList.length; i++) {
            const actuallMember = actualIndexList[i];
            const realUser = mapUsers[actuallMember];
            if (originalList.indexOf(actuallMember) < 0 || changedIndexed.indexOf(actuallMember) >= 0) {
                // No aparece en la lista actual, se debe agregar
                addMemberList.push({
                    email: realUser.email,
                    is_admin: realUser.is_admin
                });
            }
        }
        return {
            eraseMemberList,
            addMemberList,
        };
    }
    async pageAllMembers() {
        if (!this.membersLoadedFirstTime) {
            if (this.pageType == "edit") {
                const groupId = this.state.data.id;
                const { selectedListLoaded, imOwnerGroup, originalUserIds } = await GroupsSrv.pageAllMembers(groupId, this.state.user.email, MAX_PAGE_MEMBERS, this.props.alert, this.props.loading);
                this.originalUserIds = originalUserIds;
                this.setState({
                    selectedList: selectedListLoaded,
                    imOwnerGroup
                });
                this.membersLoadedFirstTime = true;
            }
        }
    }
    async onUser(payloadUser) {
        if (payloadUser.user === null) {
            // Redirect to home
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        } else {
            this.pageAllMembers();
            if (this.props.route.params.reloadGroup) {
                const readedGroup = await GroupsSrv.readById(this.state.data.id, this.props.alert);
                if (readedGroup.group) {
                    this.frezzePicture(readedGroup.group.picture);
                    this.setState({
                        data: readedGroup.group,
                    });
                }
            }
        }
    }
    async saveAndSyncUser(values) {
        // Debo guardar los datos del usuario en backend
        // Debo decirle a firebase cuál es el nuevo usuario guardado
        this.setState({ data: values });
        this.frezzePicture(values.picture);
    }
    async pickPhotoAction() {
        const nuevaImagen = await MyFileService.pickFile();
        if (typeof nuevaImagen == "string") {
            Object.assign(this.state.data, { picture: nuevaImagen });
            this.setState({
                data: this.state.data
            });
        } else {
            console.log(`Error ${typeof nuevaImagen}`);
        }
    }
    async quiteGroup() {
        // Borrar este grupo!
        // Pedir confirmar
        const options = {
            message: `¿Seguro que deseas salir del grupo "${this.state.data.name}"?`,
        }
        const desicion = await super.confirm(options);
        if (desicion) {
            //Borrar
            try {
                const promesa = GroupsSrv.removeMe(this.state.data.id);
                this.props.loading(promesa);
                await promesa;
                await this.props.alert("Has salido del grupo", "Listo!");
                // Navega de regreso
                this.backOrGoHome();
            } catch (err) {
                this.props.alert(err.message);
            }
        }
    }
    async eraseGroup() {
        // Borrar este grupo!
        // Pedir confirmar
        const options = {
            message: `¿Seguro que deseas borrar el grupo "${this.state.data.name}"?`,
        }
        const desicion = await super.confirm(options);
        if (desicion) {
            //Borrar
            try {
                const promesa = GroupsSrv.deleteGroup(this.state.data.id);
                this.props.loading(promesa);
                await promesa;
                await this.props.alert("El grupo ha sido borrado", "Listo!");
                // Navega de regreso
                this.backOrGoHome();
            } catch (err) {
                this.props.alert(err.message);
            }
        }
    }
    renderPerson(acc, ele, ix, prefix, isSelected = false, popUpUserMenuThis) {
        const nuevo = <MemberRow
            key={`${prefix}-${ele.email}`}
            details={{
                selfInstance: ele,
                showTime: this.state.showTime,
                goToPersonSpace: () => {
                    if (this.pageType == "edit") {
                        popUpUserMenuThis(ele);

                    }
                },
                goToProfile: () => {
                    if (this.pageType == "edit") {
                        popUpUserMenuThis(ele);
                    }
                },
                picture: ele.picture,
                name: ele.name,
                txt: (ele.is_admin === true ? "Administrador" : ""),
                countUnread: ele.countUnread,
                d: ele.d,
                viewed: ele.viewed,
                selectedEditable: (this.pageType == "create" || (this.state.imOwnerGroup && ele.is_admin !== true)),
                selected: isSelected,
                selectedChanged: async (event, element) => {
                    const value = event.target.value;
                    const setValue = event.setValue;
                    if (typeof value == "boolean") {
                        if (!value) {
                            // Pedir confirmar y volarlo de acá
                            const desicion = await super.confirm({ message: `¿Vas a quitar a "${ele.name}"?` });
                            if (!desicion) {
                                // Se reasigna en true
                                setValue(true);
                                return;
                            }
                            // Se debe quitar de la lista de seleccionados
                            const indice = this.state.selectedList.indexOf(element);
                            if (indice >= 0) {
                                this.state.selectedList.splice(indice, 1);
                            }
                        }
                        this.setState({ selectedList: this.state.selectedList });
                    }
                },
            }}></MemberRow>;
        acc.push(nuevo);
        return acc;
    }

    async addMemberList(group, members) {
        for (let i = 0; i < members.length; i++) {
            const member = members[i];
            // Le agrego los miembros
            let promesaMiembro = GroupsSrv.addMember(group.id, member.email, member.is_admin);
            this.props.loading(promesaMiembro);
            await promesaMiembro;
        }
    }
    async removeMemberList(group, members) {
        for (let i = 0; i < members.length; i++) {
            const member = members[i];
            // Le agrego los miembros
            let promesaMiembro = GroupsSrv.removeMember(group.id, member.email);
            this.props.loading(promesaMiembro);
            await promesaMiembro;
        }
    }

    async goToAddMembers() {
        // Se le debe mandar a la página de selección de miembros la lista actual
        const params = {
            members: this.state.selectedList,
            pageType: "edit",
        };
        this.props.navigation.navigate('SearchPeoplePage', params);
    }

    async goToPersonSpace(payload) {
        const soyYoMismo = (this.state?.user?.email == payload.email);
        if (!soyYoMismo) {
            if (payload.viewed === false) {
                // Marcar la como vista...
                const promesa = PeopleScreen.checkViewed(payload.id,);
                this.props.loading(promesa);
                await promesa;
                payload.viewed = true;
            }
            const params = payload;
            this.props.navigation.navigate('MessengerPage', params);
        }
    }
    goToProfile(payload) {
        this.props.navigation.push('ProfilePage', payload);
    }

    toggleEditDescription() {
        this.setState({
            isEditDescription: !this.state.isEditDescription,
        });
    }

    async goToGroupChat() {
        const params = {
            name: this.state.data.name,
            email: this.state.data.id,
        };
        this.props.navigation.navigate('MessengerPage', params);
    }

    render() {
        const pickPhotoActionThis = this.pickPhotoAction.bind(this);
        const eraseGroupThis = this.eraseGroup.bind(this);
        const quiteGroupThis = this.quiteGroup.bind(this);
        const goToAddMembersThis = this.goToAddMembers.bind(this);
        const popUpUserMenuThis = this.popUpUserMenu.bind(this);
        const toggleEditDescriptionThis = this.toggleEditDescription.bind(this);
        const goToGroupChatThis = this.goToGroupChat.bind(this);

        const detailsButtonPrimary = {
            label: (this.pageType == "create" ? "Crear" : "Guardar"),
            autoWidth: false,
        };

        let sourceImage = AssetsImg.IMG.DEFGROUP;
        if (this.state.data.picture) {
            sourceImage = { uri: Config.getNewPath(this.state.data.picture, "", this.state.showTime) };
        }

        if (this.pageType != "create") {
            // Se organizan primero los administradores
            this.state.selectedList.sort((a, b) => {
                if (a.is_admin === true && b.is_admin !== true) {
                    return -1;
                } else if (a.is_admin === b.is_admin) {
                    return 0;
                } else {
                    return 1;
                }
            });
        }

        const selectedListElements = this.state.selectedList.reduce((acc, el, ix) => {
            return this.renderPerson(acc, el, ix, "selected", true, popUpUserMenuThis);
        }, []);

        return (
            <AutoLayoutVertical>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={this.state.data}
                    validate={values => {
                        const errors = {};
                        errors.name = hasMinLength(values.name, 5);
                        if (errors.name === undefined) { delete errors.name; } else { this.props.alert("El nombre debe tener al menos 5 caracteres."); return errors; }
                        return errors;
                    }}
                    onSubmit={async (values) => {
                        if (this.state.selectedList.length == 0) {
                            await this.props.alert("Debes seleccionar al menos un participante", "Ups!");
                            return;
                        }
                        // Invocar a servicio de crear o guardar
                        try {
                            const nuevoGroup = {
                                id: this.state.data.id,
                                name: values.name,
                                description: values.description,
                                created: this.state.data.created
                            };
                            let promesa = null;
                            const changeImage = this.pictureHasChanged();
                            if (changeImage) {
                                nuevoGroup.picture = this.state.data.picture;
                            }
                            if (this.pageType == "create") {
                                promesa = GroupsSrv.createGroup(nuevoGroup, changeImage);
                            } else {
                                promesa = GroupsSrv.updateGroup(nuevoGroup, changeImage);
                            }
                            this.props.loading(promesa);
                            const response = await promesa;

                            const group = response.group;

                            const promesas = [];
                            if (this.pageType == "create") {
                                // Se deben agregar los miembros al grupo recien creado
                                const members = this.state.selectedList;
                                promesas.push(this.addMemberList(group, members));
                            } else {
                                // Se debe saber de antemano qué acciones se hicieron con los miembros
                                const {
                                    eraseMemberList,
                                    addMemberList,
                                } = this.computeMemberChanges();

                                promesas.push(this.addMemberList(group, addMemberList));
                                promesas.push(this.removeMemberList(group, eraseMemberList));
                            }

                            await Promise.all(promesas);

                            await this.props.alert("Se ha guardado correctamente", "Listo!");
                            this.goToPeopleScreen();
                        } catch (error) {
                            await this.props.alert(error.message);
                        }
                    }}
                >
                    {({ handleChange, handleBlur, submitForm, errors, values }) => (
                        <ParentContainer>
                            <MyContent>
                                <AutoLayoutVertical>
                                    {(this.pageType == "edit") && <MyCircledPhotoWithDroplet>
                                        <MyCircledPhoto>
                                            <MyPhoto resizeMode="cover" source={sourceImage}>
                                            </MyPhoto>
                                        </MyCircledPhoto>
                                        <DropletClickeable onPress={goToGroupChatThis}>
                                            <FontAwesomeIcon
                                                icon={"message"}
                                                style={{
                                                    outline: "none",
                                                    color: "#AAAAAA",
                                                }}
                                                size={30} />
                                        </DropletClickeable>
                                    </MyCircledPhotoWithDroplet>}
                                    {(this.pageType == "create") && <Avatar source={sourceImage}></Avatar>}
                                    {(this.pageType == "create" || this.state.imOwnerGroup == true) && <TextoCambiarFotoButton onPress={pickPhotoActionThis}>
                                        <TextoCambiarFoto>Cambiar foto de perfil</TextoCambiarFoto>
                                    </TextoCambiarFotoButton>}
                                    <ContenedorInput style={{ paddingBottom: 0, paddingTop: (this.state.imOwnerGroup == false ? 24 : 0), }}>
                                        <MyInput details={{
                                            editable: (this.state.imOwnerGroup == true || this.pageType == "create"),
                                            secret: false,
                                            label: "Nombre de grupo",
                                            onChangeText: handleChange('name'),
                                            onBlur: handleBlur('name'),
                                            errors: errors.name,
                                            value: values.name,
                                        }}></MyInput>
                                    </ContenedorInput>
                                    {(this.pageType == "edit" && this.state.imOwnerGroup === true) && <TextoCambiarFotoButton onPress={eraseGroupThis}>
                                        <TextoCambiarFoto>Borrar grupo para siempre</TextoCambiarFoto>
                                    </TextoCambiarFotoButton>}
                                    {(this.pageType == "edit" && this.state.imOwnerGroup === false) && <TextoCambiarFotoButton onPress={quiteGroupThis}>
                                        <TextoCambiarFoto>Salirme del grupo</TextoCambiarFoto>
                                    </TextoCambiarFotoButton>}
                                    <TituloSeccion>
                                        <TextoSeccion>Descripción</TextoSeccion>
                                        {(this.state.imOwnerGroup == true || this.pageType == "create") && <TextoSeccionPequeButton onPress={toggleEditDescriptionThis}>
                                            {this.state.isEditDescription === false && <TextoSeccionPeque>Editar</TextoSeccionPeque>}
                                            {this.state.isEditDescription === true && <TextoSeccionPeque>Cerrar edición</TextoSeccionPeque>}
                                        </TextoSeccionPequeButton>}
                                    </TituloSeccion>
                                    {this.state.isEditDescription === true && <MyInput details={{
                                        secret: false,
                                        label: "Descripción del grupo",
                                        onChangeText: (argumento) => {
                                            this.state.data.description = argumento;
                                            handleChange('description')(argumento);
                                        },
                                        onBlur: handleBlur('description'),
                                        errors: errors.description,
                                        value: values.description,
                                        multiline: true,
                                        numberOfLines: 10,
                                    }}></MyInput>}
                                    {this.state.isEditDescription === false && <MyRichText alert={this.props.alert} description={this.state.data.description}></MyRichText>}
                                    <TituloSeccion>
                                        <TextoSeccion>Participantes</TextoSeccion>
                                        {this.state.imOwnerGroup == true && <TextoSeccionPequeButton onPress={goToAddMembersThis}>
                                            <TextoSeccionPeque>Agregar</TextoSeccionPeque>
                                        </TextoSeccionPequeButton>}
                                    </TituloSeccion>
                                    {selectedListElements}
                                </AutoLayoutVertical>
                            </MyContent>
                            {(this.pageType == "create" || this.state.imOwnerGroup == true) && <MyFooter>
                                <ContenedorBotones>
                                    <Button details={Object.assign(detailsButtonPrimary, {
                                        onPress: submitForm,
                                    })}></Button>
                                </ContenedorBotones>
                            </MyFooter>}
                        </ParentContainer>
                    )}
                </Formik>
            </AutoLayoutVertical>
        );
    }
}

const MyCircledPhotoWithDroplet = styled.View`
    width: 140px;
    height: 140px;
    margin-bottom: 12px;
    position: relative;
`;

const MyCircledPhoto = styled.View`
    width: 100%;
    height: 100%;
    border-radius: 120px;
    overflow: hidden;
`;

const MyPhoto = styled.ImageBackground`
    width: 100%;
    height: 100%;
`;

const DropletClickeable = styled.Pressable`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
`;

const TextoSeccionPequeButton = styled.Pressable`
`;

const TextoSeccionPeque = styled.Text`
    ${CssConstants.H6Bold}
    color: ${props => props.theme.__link_blue};
`;

const TituloSeccion = styled.View`
    padding-top: 10px;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const TextoSeccion = styled.Text`
    ${CssConstants.H6Bold}
    color: ${props => props.theme.__others__white};
    flex: 1;
`;

const MessagesScroll = styled.ScrollView`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const ContenedorBotones = styled.View`
    width: 100%;
`;

const MyFooter = styled.View`
    width: 100%;
    padding: 24px;
`;

const ContenedorInput = styled.View`
    width: 100%;

`;

const TextoCambiarFoto = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumBold}
    width: 100%;
    text-align: center;
    color: ${props => props.theme.__alerts__status__info};
`;

const TextoCambiarFotoButton = styled.Pressable`
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const Avatar = styled.ImageBackground`
    width: 140px;
    height: 140px;
    border-radius: 100px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    margin-top: 24px;
`;

const MyContent = styled.ScrollView`
    width: 100%;
    flex: 1;
    padding-left: 24px;
    padding-right: 24px;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;