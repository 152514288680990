import React from "react";
import styled from "styled-components/native";

import theme from "../../Theme";
import Button from "../signup/Button";
import IconlyLightArrowRight2 from "./IconlyLightArrowRight2";


class XPageWithMenu extends React.Component {
    render() {
        const {
            selectedImage,
            submit
        } = this.props.details;
        return (
            <ParentContainer1
                source={{ uri: selectedImage }}
                resizeMode="cover"
                blurRadius={10}
            >
                <ParentContainer
                    source={{ uri: selectedImage }}
                    resizeMode="contain">
                    <MyContent></MyContent>
                    <MyFooter>
                        <AutoLayoutVertical>
                            <AutoLayoutHorizontal>
                                <StyledButton>
                                    <Button details={{
                                        label: "Tipo de Donación",
                                        onPress: submit,
                                        bgColor: theme.__background_dark
                                    }}></Button>
                                </StyledButton>
                                <Frame onPress={submit}>
                                    <IconlyLightArrowRight2 top={"20px"} left={"22px"} />
                                </Frame>
                            </AutoLayoutHorizontal>
                        </AutoLayoutVertical>
                    </MyFooter>
                </ParentContainer>
            </ParentContainer1>
        );
    }
}

export default XPageWithMenu;

const ParentContainer1 = styled.ImageBackground`
width: 100%;
height: 100%;
`;

const AutoLayoutHorizontal = styled.View`
display: flex;
flex-direction: row;
width: 100%;
`;

const StyledButton = styled.View`
flex: 1;
margin-right: 20px;
`;

const Frame = styled.Pressable`
width: 58px;
height: 58px;
background-color: ${props => props.theme.__others__deep_orange};
border-radius: 100px;
`;

const ParentContainer = styled.ImageBackground`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
`;

const MyContent = styled.View`
width: 100%;
flex: 1;
`;

// justify-content: 'flex-end'; crash
const MyFooter = styled.View`
width: 100%;
`;

// gap: 24px;
// width: 428px;
// position: fixed;
// top: 0;
// left: 0;
const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 8px 24px 48px;
    overflow: hidden;
`;
