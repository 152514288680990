import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { Component } from 'react'
import styled from "styled-components/native";
import CONFIG from "../Constants";
import BottomBar from "../screens/home/BottomBar";
import OutlineArrowLeft from "../screens/svg/OutlineArrowLeft";
import MyLog from "../srv/MyLog";
import { NotificationsSrv } from "../srv/NotificationsSrv";
import theme from "../Theme";
import Config from "../utils/Config";
import { MountedComponent } from "../utils/MountedComponent";
import { MyDates } from "../utils/MyDates";
import { MyFileService } from "../utils/MyFileService";
import * as CssConstants from "../widgets/CssConstans";


class NotificationsScreen extends MountedComponent {
    constructor(props) {
        super(props);
        MyLog.log(`Constructor`, this);
        this.state = Object.assign(this.state, {
            notifications: [],
            isBottomMenuOpened: false,
        });
        this.notificationsSrv = new NotificationsSrv(this.props.navigation);
    }
    setBottomMenu(value) {
        this.setState({ isBottomMenuOpened: value });
    }
    setBottomMenuClosed() {
        this.setBottomMenu(false);
    }
    async onUser(payloadUser) {
        if (payloadUser.user === null) {
            // Redirect to home
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        } else {
            await this.registerPush(true);
        }
    }
    async onShow() {
        MyLog.log("onShow", this);
        const lista = await this.notificationsSrv.getNotifications(this.props.alert, this.props.loading);
        this.setState({ notifications: lista });
    }
    backAction() {
        this.backOrGoHome();
    }
    async forwardNotification(notification) {
        // Es mejor no borrar las notificaciones si no es realmente intencional
        //await this.forgetNotification(notification);
        // Set notification as viewed
        const promesa = NotificationsSrv.checkViewed(notification.id, this.props.alert);
        this.props.loading(promesa);
        await promesa;
        this.notificationsSrv.forwardNotification(notification, this.props.alert, this.props.popUpNotification);
    }
    async forgetNotification(notification) {
        await this.notificationsSrv.forgetNotification(notification, this.props.alert, this.props.loading);
        const nuevo = this.state.notifications.filter((value, index) => {
            return (value.id !== notification.id);
        });
        this.setState({ notifications: nuevo });
    }
    renderNotification(acc, ele, ix, forwardNotificationThis, forgetNotificationThis) {
        acc.push(<ElementsAccountListCont
            key={ix}
            details={{
                forwardNotification: () => {
                    forwardNotificationThis(ele);
                },
                forgetNotification: () => {
                    forgetNotificationThis(ele);
                },
                type: ele.type,
                txt: ele.txt,
                title: ele.title,
                d: ele.d,
                viewed: ele.viewed
            }}></ElementsAccountListCont>);
        return acc;
    }
    async goToCreateDonation(payload) {
        if (!(await this.alertLogedUser())) {
            return;
        }
        const nuevaImagen = await MyFileService.pickFile();
        if (typeof nuevaImagen == "string") {
            const nuevaDonacion = {
                image: nuevaImagen,
                type: payload.donationType,
            };
            this.props.navigation.reset({ index: 0, routes: [{ name: 'WDonationPage3', params: nuevaDonacion }] });
        }
    }
    render() {
        const backActionThis = this.backAction.bind(this);
        const forwardNotificationThis = this.forwardNotification.bind(this);
        const forgetNotificationThis = this.forgetNotification.bind(this);
        const goToCreateDonationThis = this.goToCreateDonation.bind(this);
        const setBottomMenuThis = this.setBottomMenu.bind(this);
        const setBottomMenuClosedThis = this.setBottomMenuClosed.bind(this);

        const details = {
            homeAction: async () => {
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyHomePage' }] });
            },
            notificationAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("notificationAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'NotificationsPage' }] });
            },
            plusAction: async (payload) => {
                await goToCreateDonationThis(payload);
            },
            chatAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("chatAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'PeoplePage' }] });
            },
            visitAcceptedDonationsAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyAcceptedPage' }] });
            },
        };

        const notificationsElements = this.state.notifications.reduce((acc, el, ix) => {
            return this.renderNotification(acc, el, ix, forwardNotificationThis, forgetNotificationThis);
        }, []);

        return (
            <ParentContainer>
                <MyContent>
                    <Header>
                        <ContenedorFlecha onPress={backActionThis}>
                            <OutlineArrowLeft></OutlineArrowLeft>
                        </ContenedorFlecha>
                        <ContenedorTitulo style={{ textAlign: 'center', flex: 1 }}>
                            {"Notificaciones"}
                        </ContenedorTitulo>
                    </Header>
                    <MessagesScroll ref={ref => { this.scrollView = ref }}
                        onContentSizeChange={() => { }}>
                        <TituloSeccion>
                            <TextoSeccion>Recientes</TextoSeccion>
                        </TituloSeccion>
                        {notificationsElements}
                    </MessagesScroll>
                </MyContent>
                {this.state.isBottomMenuOpened && <BigBackground onPress={setBottomMenuClosedThis}></BigBackground>}
                <MyFooterBottom>
                    <AutoLayoutVerticalBotom>
                        <BottomBar
                            useDirectDonation={true}
                            popupState={this.state.isBottomMenuOpened}
                            setBottomMenu={setBottomMenuThis}
                            details={details}
                            userRealTime={this.state.userRealTime}
                            alert={this.props.alert}
                            route={"Notifications"} />
                    </AutoLayoutVerticalBotom>
                </MyFooterBottom>
            </ParentContainer >
        );
    }
}

export default NotificationsScreen;

const BigBackground = styled.Pressable`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
`;

const MyFooterBottom = styled.View`
    width: 100%;
`;

const AutoLayoutVerticalBotom = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

class ElementsAccountListCont extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            forwardNotification,
            forgetNotification,
            type,
            txt,
            title,
            d,
            viewed,
        } = this.props.details;

        let textoFecha = null;
        if (typeof d == "number") {
            const isToday = MyDates.isToday(d);
            const fecha = new Date(d);
            if (isToday) {
                textoFecha = MyDates.formatTime(fecha);
            } else {
                textoFecha = MyDates.formatDateSimple(fecha);
            }
        }

        const MAPA_ICONOS = CONFIG.PUSH.MAPA_ICONOS;
        const MAPA_COLORES = CONFIG.PUSH.MAPA_COLORES;

        let elIcono = MAPA_ICONOS[type];
        let elColor = MAPA_COLORES[type];
        if (!elIcono) {
            elIcono = "question";
        }
        if (!elColor) {
            elColor = "rgba(255, 255, 255, 1)";
        }

        const dotStyle = {
            position: "absolute",
            top: 0,
            right: -9,
            color: theme.__others__deep_orange,
        };

        return (
            <ElementsAccountList onPress={forwardNotification}>
                <AvatarNameContainer>
                    <AvatarContainerParent>
                        <AvatarContainer colorDonorType={"#FFFFFF"}>
                            <FontAwesomeIcon icon={elIcono} style={{ marginLeft: "auto", marginRight: "auto", flex: 1, color: elColor, outline: "none" }} size={28} />

                        </AvatarContainer>
                        {viewed === false && <FontAwesomeIcon icon={"circle"} style={dotStyle} size={13} />}
                    </AvatarContainerParent>
                    <NameLastMessageContainer>
                        <LastMessageContainerTitle>
                            {title}
                        </LastMessageContainerTitle>
                        <LastMessageContainer>
                            {txt}
                        </LastMessageContainer>
                        <DateContainer>
                            {textoFecha}
                        </DateContainer>
                    </NameLastMessageContainer>
                    <CloseContainer onPress={forgetNotification}>
                        <FontAwesomeIcon icon={"x"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                    </CloseContainer>
                </AvatarNameContainer>
            </ElementsAccountList>
        );
    }
}

const CloseContainer = styled.Pressable`
    padding: 5px 10px;
`;

const DateContainer = styled.Text`
    ${CssConstants.BodyMediumMedium}
    font-weight: 500;
    color: ${props => props.theme.__greyscale__400};
    text-align: left;
    line-height: 19.6px;
`;

const IndicatorContainer = styled.View`
    display: flex;
    flex-direction: column;
`;

const LastMessageContainerTitle = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumMedium}
    font-weight: 700;
    color: ${props => props.theme.__greyscale__400};
`;

const LastMessageContainer = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumMedium}
    font-weight: 500;
    color: ${props => props.theme.__greyscale__400};
    
    flex: 1;
`;

const NameLastMessageContainer = styled.View`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 4px;
`;

const AvatarNameContainer = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const ElementsAccountList = styled.Pressable`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 24px;
    padding-right: 5px;
`;

const AvatarContainerParent = styled.View`
    position:relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    
    width: 60px;
    height: 60px;
    margin-right: 20px;
`;

const AvatarContainer = styled.View`
    display: flex;
    align-items: center;
    flex-direction: row;
    
    width: 60px;
    height: 60px;
    border: 3px solid;
    border-color: ${props => props.colorDonorType};
    border-radius: 100px;
    overflow: hidden;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyContent = styled.View`
    width: 100%;
    flex: 1;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
`;

const Header = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
`;

const ContenedorFlecha = styled.Pressable`
    width: 28px;
    height: 28px;
    border: 0;
`;

const ContenedorTitulo = styled.Text`
    ${CssConstants.H4Bold}
    color: ${props => props.theme.__others__white};
    margin-left: 16px;
`;

const MessagesScroll = styled.ScrollView`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const TituloSeccion = styled.View`
    padding-top: 10px;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const TextoSeccion = styled.Text`
    ${CssConstants.H6Bold}
    color: ${props => props.theme.__others__white};
    flex: 1;
`;
