import Constants from '../Constants';
import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import { Buffer } from "buffer";
import md5 from 'md5';

import {
    getAuth,
    onAuthStateChanged
} from 'firebase/auth';
import MyLog from './MyLog';
import Config from '../utils/Config';

let auth = null;
let currentUser = null;

export function connectFirebase(userOutside = null) {
    MyLog.log("connectFirebase");
    if (userOutside != null) {
        currentUser = userOutside;
    }
    if (auth != null) {
        return;
    }
    auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            currentUser = user;
        } else {
            currentUser = null;
        }
    });
}

export async function getOauthToken() {
    MyLog.log("getOauthToken");
    let token = null;
    if (currentUser) {
        //token = currentUser.stsTokenManager.accessToken;
        token = await currentUser.getIdToken();
    }
    return token;
}

export function serverUrl(myPath) {
    return `${Constants.API.rootUrl}${myPath}`;
}

// 1 Hour of cache
const MAX_CACHE_TIME = Constants.API.CACHE_TIME;
const CACHE = {};

function creatBodyCacheKey(body) {
    return Buffer.from(JSON.stringify(body)).toString('base64');
};

function getVerifyKey(body, sometime) {
    return md5(Buffer.from(md5(body) + sometime).toString("base64"));
}

export async function fetchApiInternal(subUrl, payload, alert, defaultMethod = 'POST') {
    return new Promise(async (resolve, reject) => {
        // const handlerFirebase = await myFirebase;
        // const accessToken = handlerFirebase.getOauthToken();
        const accessToken = await getOauthToken();
        const options = {
            method: defaultMethod,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-mytime': '' + (new Date().getTime()),
            },
        };
        if (defaultMethod == 'POST') {
            options.body = JSON.stringify(payload);
            options.headers["x-constant"] = getVerifyKey(options.body, options.headers['x-mytime']);
        }
        if (accessToken) {
            options.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        fetch(`${Constants.API.url}${subUrl}`, options)
            .then(async (response) => {
                let status = response.status;
                if (status == 428) {
                    reject(428);
                }
                if (status >= 400 && status < 600) {
                    try {
                        const myJson = await response.json();
                        if (typeof alert == "function") {
                            alert(myJson.message);
                        }
                        reject(myJson);
                    } catch (e) {
                        reject(`Error interno ${Constants.API.url + subUrl} ${e.message}`);
                    }
                    return;
                } else if (status == 204) {
                    return null;
                } else {
                    return response.json()
                }
            })
            .then((json) => {
                resolve(json);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function fetchApiInternalRecursiveRetry(subUrl, payload, alert, pageSize = 20, maxRetries = 3) {
    let tries = 0;
    let fail = false;
    let response = null;
    do {
        try {
            response = await fetchApiInternalRecursive(subUrl, payload, alert, pageSize);
        } catch (err) {
            fail = true;
            tries++;
            if (tries > maxRetries) {
                throw err;
            }
        }
    } while (fail);
    return response;
}

export async function fetchApiInternalRecursive(subUrl, payload, alert, pageSize = 20) {
    let respuesta = [];
    let actual = [];
    let offset = 0;
    do {
        payload.max = pageSize;
        payload.offset = offset;
        actual = await fetchApiInternal(subUrl, payload, alert)
        respuesta = respuesta.concat(actual);
        offset += actual.length;
    } while (actual.length >= pageSize);
    return respuesta;
}

export async function fetchApi(subUrl, payload, useCache = false, alert) {
    if ([true, "reload"].indexOf(useCache) >= 0) {
        const ahora = new Date().getTime();
        if (!(subUrl in CACHE)) {
            CACHE[subUrl] = {};
        }
        const CACHE_SRV = CACHE[subUrl];
        const name = creatBodyCacheKey(payload);
        if (useCache != "reload" && name in CACHE_SRV) {
            const actual = CACHE_SRV[name];
            if (ahora > actual.expTime) {
                // expiró
                const nuevo = await fetchApiInternal(subUrl, payload, alert);
                if (nuevo != null) {
                    // Si no es vacío, lo guardo
                    CACHE_SRV[name] = {
                        expTime: ahora + MAX_CACHE_TIME,
                        value: nuevo,
                    }
                }
                return nuevo;
            } else {
                // Es vigente en caché
                return actual.value;
            }
        } else {
            // No está en caché o se forza a recargarlo
            const nuevo = await fetchApiInternal(subUrl, payload, alert);
            if (nuevo != null) {
                CACHE_SRV[name] = {
                    expTime: ahora + MAX_CACHE_TIME,
                    value: nuevo,
                }
            }
            return nuevo;
        }
    } else {
        return await fetchApiInternal(subUrl, payload, alert);
    }
}

async function b64toBlob(b64Data) {
    const base64Response = await fetch(b64Data);
    const blob = await base64Response.blob();
    return blob;
}

function checkMaxFileSize(myBlob, MAX_MB) {
    if (myBlob.size > 1024 * 1024 * MAX_MB) {
        throw new Error(`La imagen es muy grande, se espera menor a ${MAX_MB}MB`);
    }
}

const MAPEO_MIME_TIMES = {
    "image/bmp": "bmp",
    "image/gif": "gif",
    "image/jpeg": "jpeg",
    "image/tiff": "tiff",
    "image/png": "png",
};

const EXTENSION_FALLBACK = "jpg";


// Hacer en serie el http upload y la creación de la donación
// Definir la carpeta por parámetro
// mandar un json con el cuerpo de la donación

export async function uploadFile(
    myFile,//1
    subUrl,//2
    extra = null,//3
    folder = null,//4
    fileName = null,//5
    sizeBig = null,//6
    sizeSmall = null,//7
    folderType = null,//8
    fileType = "image",//9
) {
    if (typeof myFile != "string") {
        throw new Error("La imagen no se pudo leer");
    }
    const CONFIG = await Config.getValue("config", false);
    const UPLOAD_URL = `${Constants.API.url}${subUrl}`;
    //const handlerFirebase = await myFirebase;
    //const accessToken = handlerFirebase.getOauthToken();
    const accessToken = await getOauthToken();
    let response = null;
    const extraText = Buffer.from(JSON.stringify(extra)).toString('base64')
    if (Platform.OS === 'web') {
        let extension;
        const mimeParts = /data:([^;]+)/ig.exec(myFile);
        if (mimeParts != null) {
            extension = MAPEO_MIME_TIMES[mimeParts[1]];
        }
        if (!extension) {
            extension = EXTENSION_FALLBACK;
        }
        const blob = await b64toBlob(myFile);
        checkMaxFileSize(blob, CONFIG.MAX_UPLOAD_MB);

        response = await new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open("POST", UPLOAD_URL, true);
            req.setRequestHeader('Authorization', `Bearer ${accessToken}`);
            if (typeof fileName == "string") {
                req.setRequestHeader('filename', fileName);
            } else {
                req.setRequestHeader('filename', `miarchivo.${extension}`);
            }
            if (typeof folder == "string") {
                req.setRequestHeader('folder', folder);
            }
            if (sizeBig != null) {
                req.setRequestHeader('size_big', sizeBig);
            }
            if (sizeSmall != null) {
                req.setRequestHeader('size_small', sizeSmall);
            }
            if (fileType != null) {
                req.setRequestHeader('file_type', fileType);
            }
            if (folderType != null) {
                req.setRequestHeader('folder_type', folderType);
            }
            req.setRequestHeader('extra', extraText);
            req.onload = (event) => {
                const jsonResponse = JSON.parse(req.responseText);
                let status = null;
                if (event.currentTarget && status === null) {
                    status = event.currentTarget.status;
                }
                if (event.target && status === null) {
                    status = event.target.status;
                }
                if ([428, 424].indexOf(status) >= 0) {
                    reject(status);
                } else {
                    if (status >= 400 && status <= 599) {
                        if (typeof jsonResponse.message == "string") {
                            reject(jsonResponse.message);
                        } else {
                            reject(`${jsonResponse}`);
                        }
                    } else {
                        try {
                            resolve(JSON.stringify(jsonResponse));
                        } catch (err) {
                            resolve(`${jsonResponse}`);
                        }
                    }
                }
            };
            req.onerror = () => {
                reject("Error uploading file");
            }
            req.send(blob);
        });
    } else {
        // Se asume una uri de dispositivo
        const info = await FileSystem.getInfoAsync(myFile);
        const partesNombre = /([^/]+$)/ig.exec(info.uri);
        // Se puede subir al servicio de guardado o creación inicial...
        const myQuery = {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'filename': (partesNombre != null ? partesNombre[1] : `archivo.${EXTENSION_FALLBACK}`),
                'extra': extraText,
            },
            fieldName: "file",
            httpMethod: "POST",
            uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
        };
        if (typeof fileName == "string") {
            myQuery.headers.filename = fileName;
        }
        if (typeof folder == "string") {
            myQuery.headers.folder = folder;
        }
        if (sizeBig != null) {
            myQuery.headers.size_big = sizeBig;
        }
        if (sizeSmall != null) {
            myQuery.headers.size_small = sizeSmall;
        }
        if (folderType != null) {
            myQuery.headers.folder_type = folderType;
        }
        response = await FileSystem.uploadAsync(
            UPLOAD_URL,
            myFile,
            myQuery,
        )
    }
    return response;
}