import Config from '../utils/Config';
import { hasMinLength, isValidAddress } from '../utils/InputValidator';
import { MyDates } from '../utils/MyDates';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_1 = "pickAddress";
const FIELD_KEY_NAME_2 = "pickTime";
const FIELD_KEY_NAME_3 = "city";

export default class WDonationPlaceReceiveScreen extends SelectScreen {
    constructor(props) {
        super(props);
        this.minDate = this.props.route.params.minDate;
        this.maxDate = this.props.route.params.maxDate;
        this.initValue(FIELD_KEY_NAME_1);
        this.initValue(FIELD_KEY_NAME_2);
    }
    onSubmit(values) {
        super.redirectWithParams([
            FIELD_KEY_NAME_1,
            FIELD_KEY_NAME_2,
        ], values);
    }
    validate(values) {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        const errors = {};
        errors[FIELD_KEY_NAME_1] = isValidAddress(values[FIELD_KEY_NAME_1]);
        if (errors[FIELD_KEY_NAME_1] === undefined) { delete errors[FIELD_KEY_NAME_1]; }
        const fecha2 = parseInt(values[FIELD_KEY_NAME_2]);
        if (isNaN(fecha2)) {
            errors[FIELD_KEY_NAME_2] = "Para continuar debes ingresar una Fecha y Hora de Recolección";
        }
        if (!params[FIELD_KEY_NAME_3]) {
            errors[FIELD_KEY_NAME_3] = "Debes seleccionar una Ciudad";
        }
        const llaves = Object.keys(errors);
        if (llaves.length > 0) {
            this.props.alert(errors[llaves[0]]);
        }
        return errors;
    }
    render() {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        const handleLocalNavigationThis = super.handleLocalNavigation.bind(this);
        const listaOpciones = [
            {
                izq: {},
                der: {
                    type: "page",
                    details: {
                        label: "Ciudad", icon: "tree-city",
                        name: FIELD_KEY_NAME_3,
                        currentValue: Config.getCityValue(params.city),
                        goToPage: () => {
                            handleLocalNavigationThis("WDonationCityPage");
                        }
                    },
                }
            },
            {
                izq: { label: "Dirección" },
                der: {
                    type: "input-text",
                    details: {
                        name: FIELD_KEY_NAME_1,
                    }
                }
            },
            {
                izq: { label: "Fecha y Hora Tentativa de Recolección" },
                der: {
                    type: "datetime",
                    details: {
                        name: FIELD_KEY_NAME_2,
                    }
                }
            },
        ];
        if (this.minDate) {
            listaOpciones[2].der.details.minDate = new Date(this.minDate);
        }
        if (this.maxDate) {
            listaOpciones[2].der.details.maxDate = new Date(this.maxDate);
        }
        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = "Solamente el ganador/es de tu donación va a obtener esta dirección para recolectar la donación.";
        return super.myRender(listaOpciones, instructions, onSubmit, validate);
    }
}