import * as React from 'react';
import AssetsImg from '../../AssetsImg'
import styled from "styled-components/native";

const Logo = (props) => {
    let size = 35;
    if (typeof props.size == "number") {
        size = props.size;
    }
    return (<Frame size={size} source={AssetsImg.IMPORTED.logo} />);
}

const Frame = styled.Image`
    min-width: ${props => props.size}px;
    height: ${props => props.size}px;
`;

export default Logo;