import MyLog from '../srv/MyLog';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_1 = "isfree"; // Se valida acá
const FIELD_KEY_NAME_2 = "price"; // Se valida acá

export default class WDonationPriceScreen extends SelectScreen {
    static organicername = "";
    constructor(props) {
        super(props);
        this.state[FIELD_KEY_NAME_2] = this.props.route.params[FIELD_KEY_NAME_2];
        this.state.data[FIELD_KEY_NAME_2] = this.state[FIELD_KEY_NAME_2];
        this.state[FIELD_KEY_NAME_1] = this.props.route.params[FIELD_KEY_NAME_1];
        this.state.data[FIELD_KEY_NAME_1] = this.state[FIELD_KEY_NAME_1];
    }
    onSubmit(values) {
        this.props.route.params[FIELD_KEY_NAME_2] = values[FIELD_KEY_NAME_2];
        this.props.route.params[FIELD_KEY_NAME_1] = values[FIELD_KEY_NAME_1];
        super.myLocalGoBack(this.props.route.params);
    }
    validate(values) {
        MyLog.log("validate", this);
        const errors = {};
        // Si usevotes, entonces validar que haya selecionado numWin
        const price = values[FIELD_KEY_NAME_2];
        const isfree = this.state.data[FIELD_KEY_NAME_1];
        if (!isfree) {
            if (typeof price == "string") {
                const partes = /^[\d]+$/.exec(price);
                if (partes == null) {
                    errors[FIELD_KEY_NAME_2] = "El precio debe ser un valor de solo números";
                }
            }
        }
        const llaves = Object.keys(errors);
        if (llaves.length > 0) {
            this.props.alert(errors[llaves[0]]);
        }
        return errors;
    }
    render() {
        let params = this.props.route.params;
        const handleLocalNavigationThis = super.handleLocalNavigation.bind(this);
        if (!params) {
            params = {};
        }
        const listaOpciones = [
            {
                izq: {},
                der: {
                    type: "radio",
                    details: {
                        type: "switch",
                        label: "Gratis",
                        value: this.state[FIELD_KEY_NAME_1],
                        disabled: false,
                        onChange: (payload) => {
                            const nuevo = { data: this.state.data };
                            nuevo.data[FIELD_KEY_NAME_1] = payload.target.value;
                            nuevo[FIELD_KEY_NAME_1] = payload.target.value;
                            this.setState(nuevo);

                        },
                        name: FIELD_KEY_NAME_1,
                    }
                }
            }
        ];
        if (!this.state.data[FIELD_KEY_NAME_1]) {
            listaOpciones.push({
                izq: { label: "Costo de entrada" },
                der: {
                    type: "input-text",
                    details: {
                        name: FIELD_KEY_NAME_2,
                        editable: true,
                    }
                }
            });
        }
        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = "Si no cuentas con esta información deja el campo vacío";
        return super.myRender(listaOpciones, instructions, onSubmit, validate);
    }
}