import Constants from '../Constants';
import fetchRetry from "fetch-retry";

const fetchRetryFun = fetchRetry(fetch, {});

export class Listas {
    static async getList(nombre) {
        const subUrl = `/config/${nombre}`;
        return new Promise((resolve, reject) => {
            const url = `${Constants.API.url}${subUrl}`;
            fetchRetryFun(url, {
                retries: 3,
                retryDelay: 1000
            })
                .then((response) => response.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}