export class Hand {
    static tryCatch(fun, args = []) {
        return async () => {
            try {
                await fun(...args);
            } catch (err) {
                console.log(err);
            }
        }
    }
}