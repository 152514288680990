import React from "react";
import styled from "styled-components/native";
import { Dimensions } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import theme from "../../Theme";
import * as CssConstants from "../../widgets/CssConstans";
import { MyDates } from "../../utils/MyDates";
import Config from "../../utils/Config";
import CONFIG from "../../Constants";

export default class ElementsCommentsList2
    extends React.Component {
    getExpirationTimeText(end) {
        return MyDates.formatDatetime(end);
    }
    getCityName(code) {
        return Config.getCityValue(code);
    }
    getServiceTypeFeature(type) {
        return CONFIG.LISTS.DONATION.MAPA[type];
    }
    render() {
        const {
            description,
            type,
            virtual,
            end,
            theTime,
            address,
            theCity,
            linkMeet,
            numWin,
            isAdmin,
            setPromo,
            forceExpire,
            verReporte,
            donationMenu,
            setAdvertising,
        } = this.props.details;
        const { width, height } = Dimensions.get('window');
        //console.log(JSON.stringify(this.props.details));
        return (
            <TypeCaptionsComponentCommentsList>
                <ContenedorTitulo>
                    <AutoLayoutHorizontal1>
                        <Username>{"Descripción"}</Username>
                    </AutoLayoutHorizontal1>

                </ContenedorTitulo>
                <ContenedorTxtDescripcion>{description}</ContenedorTxtDescripcion>
                {type == CONFIG.LISTS.DONATION.TYPE.DONACION && <X6HoursAgo>{"Fecha: " + this.getExpirationTimeText(theTime)}</X6HoursAgo>}
                {type == CONFIG.LISTS.DONATION.TYPE.REPOST && <X6HoursAgo>{"Compartido por: " + this.props.details.author}</X6HoursAgo>}
                {type == CONFIG.LISTS.DONATION.TYPE.DONACION && ["", null, undefined].indexOf(theCity) < 0 && <X6HoursAgo>{"Lugar: " + this.getCityName(theCity) + (typeof address == "string" ? ", " + address : "")}</X6HoursAgo>}
                {type == CONFIG.LISTS.DONATION.TYPE.REPOST && ["", null, undefined].indexOf(linkMeet) < 0 && <X6HoursAgo>{"Link: " + linkMeet}</X6HoursAgo>}
                <AutoLayoutHorizontal2>
                    <AutoLayoutHorizontal3>
                        <IconStarTouchable onPress={donationMenu}>
                            <FontAwesomeIcon icon={"triangle-exclamation"} style={{ color: theme.__others__white, }} size={24} />
                            <Text>{"Reportar"}</Text>
                        </IconStarTouchable>
                    </AutoLayoutHorizontal3>
                </AutoLayoutHorizontal2>
                {isAdmin === true && <AutoLayoutHorizontal2>
                    <ContenedorAccion onPress={() => { setPromo(101); }}>
                        <ContenedorTexto>Promocionar</ContenedorTexto>
                    </ContenedorAccion>
                    <ContenedorAccion onPress={() => { setPromo(0); }}>
                        <ContenedorTexto>Normalizar</ContenedorTexto>
                    </ContenedorAccion>
                    <ContenedorAccion onPress={() => { forceExpire(); }}>
                        <ContenedorTexto>Expirar</ContenedorTexto>
                    </ContenedorAccion>
                    <ContenedorAccion onPress={() => { forceExpire(true); }}>
                        <ContenedorTexto>Borrar</ContenedorTexto>
                    </ContenedorAccion>
                    <ContenedorAccion onPress={() => { verReporte(); }}>
                        <ContenedorTexto>Reporte</ContenedorTexto>
                    </ContenedorAccion>
                    <ContenedorAccion onPress={() => { setAdvertising(); }}>
                        <ContenedorTexto>Publicidad</ContenedorTexto>
                    </ContenedorAccion>
                </AutoLayoutHorizontal2>}
            </TypeCaptionsComponentCommentsList>
        );
    }
}

const ContenedorAccion = styled.Pressable`
    margin-top: 24px;
    margin-right: 24px;
`;

const ContenedorTexto = styled.Text`
    ${CssConstants.BodyMediumRegular}
    color: white;
`;

const TypeCaptionsComponentCommentsList = styled.View`
display: flex;
flex-direction: column;
align-items: flex-start;

align-self: stretch;
border-radius: 12px;

`;

const ContenedorTitulo = styled.View`
display: flex;
flex-direction: row;
align-items: center;

position: relative;
align-self: stretch;

`;

const AutoLayoutHorizontal1 = styled.View`
display: flex;
flex-direction: row;
width: 100%;
align-items: center;


`;

const Username = styled.Text`
${CssConstants.ValignTextMiddle}
${CssConstants.BodyLargeBold}
align-self: stretch;
margin-top: -1px;
font-weight: 700;
color: ${props => props.theme.__others__white};
line-height: 22.4px;
margin-right: 16px;
`;

const ContenedorTxtDescripcion = styled.Text`
${CssConstants.ValignTextMiddle}
${CssConstants.BodyMediumRegular}
align-self: stretch;
font-weight: 400;
color: ${props => props.theme.__others__white};
line-height: 19.6px;
width: 100%;
`;

const X6HoursAgo = styled.Text`
${CssConstants.ValignTextMiddle}
${CssConstants.BodySmallSemibold}
align-self: stretch;
font-weight: 500;
color: ${props => props.theme.__others__white};
margin-top: 12px;
`;

const AutoLayoutHorizontal2 = styled.View`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    margin-top: 12px;
`;

const AutoLayoutHorizontal3 = styled.View`
display: flex;
flex-direction: row;
align-items: center;

position: relative;
margin-right: 24px;
margin-bottom: 5px;
`;

const Text = styled.Text`
${CssConstants.BodyMediumSemibold}

font-weight: 600;
color: ${props => props.theme.__others__white};
line-height: 19.6px;
margin-left: 8px;
`;

const IconStar = styled.View`
min-width: 24px;
height: 24px;
`;

const IconStarTouchable = styled.Pressable`
    min-width: 24px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
`;
