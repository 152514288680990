import React from "react";
import styled from "styled-components/native";

import * as CssConstants from "../../widgets/CssConstans";
import Config from "../../utils/Config";

export default class FooterDescription
    extends React.Component {
    getCityName(code) {
        return Config.getCityValue(code);
    }
    render() {
        const {
            showTime,
            avatarImage,
            title,
            author,
            authorEmail,
            goToPersonSpace,
            type,
            theCity,
            address,
        } = this.props.details;

        let tapAction = () => { };
        if (typeof this.props.openAction == 'function') {
            tapAction = this.props.openAction;
        } else if (typeof this.props.closeAction == 'function') {
            tapAction = this.props.closeAction;
        }

        let ciudadTxt = "";
        if (theCity) {
            ciudadTxt = "Ciudad: " + this.getCityName(theCity);
            if (typeof address == "string") {
                ciudadTxt += ", " + address;
            }
        }

        return (
            <AutoLayoutHorizontal1>
                {type !== "repost" && <AvatarTouchable onPress={() => { goToPersonSpace({ email: authorEmail }) }}>
                    <Avatar source={{ uri: Config.getNewPath(avatarImage, "_xs", showTime) }} />
                </AvatarTouchable>}
                <AutoLayoutVertical style={{ marginLeft: (type === "repost" ? 0 : 20) }} onPress={tapAction}>
                    <DonationTitle>{title}</DonationTitle>
                    {type !== "repost" && <UserLogin>{author}</UserLogin>}
                    {type === "repost" && <UserLogin>{ciudadTxt}</UserLogin>}
                </AutoLayoutVertical>
            </AutoLayoutHorizontal1>
        );
    }
}

const AvatarTouchable = styled.Pressable`
    width: 60px;
    height: 60px;
`;

const Avatar = styled.ImageBackground`
    min-width: 60px;
    height: 60px;
    border-radius: 100px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
`;

const AutoLayoutHorizontal1 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    position: relative;
    flex: 1;
    
    margin-right: 12px;
`;

const AutoLayoutVertical = styled.Pressable`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1;
`;

//display: inline; not supported
const DonationTitle = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H6Bold}
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap;
    margin-top: -1px;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    line-height: 21.6px;
    max-height: 65px;
    overflow: hidden;
`;

const UserLogin = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumMedium}
    align-self: stretch;
    font-weight: 500;
    color: ${props => props.theme.__others__white};
    line-height: 19.6px;
`;
