import CONFIG from "../Constants";

export default class MyLog {
    static log(texto, instancia = null) {
        if ([
            "dev",
            "stg",
            "pro",
        ].indexOf(CONFIG.ENV) >= 0) {
            if (instancia?.constructor?.name) {
                console.log("[" + instancia.constructor.name + "] : " + texto);
            } else {
                console.log(texto);
            }
        }
    }
}
