import React from "react";
import styled from "styled-components/native";
import CONFIG from "../../Constants";

import ComponenteTop from "./ComponenteTop";
import OptionReject from "./OptionReject";
import OptionsAccept from "./OptionsAccept";
import Stars from "./Stars";

export default class SecondMenuBottom
    extends React.Component {
    render() {
        const {
            middle,
            button1,
            donation,
        } = this.props.details;
        const {
            openAction,
        } = this.props;
        return (
            <AutoLayoutVertical style={{ minHeight: 105 }}>
                <ComponenteTop details={middle} openAction={openAction} />
                {middle.won !== true && middle.canParticipate && middle.type != CONFIG.LISTS.DONATION.TYPE.REPOST && <AutoLayoutHorizontal>
                    <OptionReject details={{ rejectAction: button1.rejectAction, donation }} />
                    <OptionsAccept details={{ acceptAction: button1.acceptAction, disabled: (middle.desition == true) }} />
                </AutoLayoutHorizontal>
                }
            </AutoLayoutVertical>
        );
    }
}

const AutoLayoutVertical = styled.View`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    
    top: 18px;
    padding: 15px 24px 18px;
    background-color: ${props => props.theme.__eerie_black_mine};
    
    width: 100%;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    height: 93px;
    align-items: center;
    justify-content: center;
    
    padding: 14px 24px 20px;
    position: relative;
    align-self: stretch;
    margin-bottom: -2px;
    margin-top: 20px;
    
`;