import styled from "styled-components/native";
import { fetchApiInternal } from "../srv/Http";
import Config from "../utils/Config";
import { CsvFormatter } from "../utils/CsvFormatter";
import { CsvFormatterFilters } from "../utils/CsvFormatterFilters";
import { Hand } from "../utils/Hand";
import { MountedComponent } from "../utils/MountedComponent";
import { MyDates } from "../utils/MyDates";
import MyDateTimePicker from "../widgets/MyDateTimePicker";

export default class ReportsScreen extends MountedComponent {
    constructor(props) {
        super(props);
        const ahora = new Date();
        const start = new Date(ahora.getTime());
        start.setMonth(ahora.getMonth() - 1);
        this.state = Object.assign(this.state, {
            fixCreateCount: 0,
            updateStatusCount: 0,
            reportUsersCount: 0,
            reportDonationsCount: 0,
            ahora: ahora.getTime(),
            start: start.getTime(),
            end: ahora.getTime()
        });
    }
    async expirar() {
        const desicion = await super.confirm({ message: "¿Seguro?" });
        if (!desicion) {
            return;
        }
        await this.genericCall("/donations/expired?max=10");
    }
    async perdedores() {
        const desicion = await super.confirm({ message: "¿Seguro?" });
        if (!desicion) {
            return;
        }
        await this.genericCall("/donations/cancelloosers?max=20");
    }
    async notificar() {
        const desicion = await super.confirm({ message: "¿Seguro?" });
        if (!desicion) {
            return;
        }
        await this.genericCall("/donations/notify?max=10");
    }
    async iniciar() {
        const desicion = await super.confirm({ message: "¿Seguro?" });
        if (!desicion) {
            return;
        }
        await this.genericCall("/donations/startdonations?max=20");
    }
    async notificaciones() {
        const desicion = await super.confirm({ message: "¿Seguro?" });
        if (!desicion) {
            return;
        }
        await this.genericCall("/push/future?max=3");
    }
    async barrer() {
        const desicion = await super.confirm({ message: "¿Seguro?" });
        if (!desicion) {
            return;
        }
        await this.genericCall("/users/clean?max=2");
    }
    static mapKeyTxt(lista) {
        const mapa = {};
        for (let i = 0; i < lista.length; i++) {
            const actual = lista[i];
            mapa[actual.key] = actual.txt;
        }
        return mapa;
    }
    async reportDonationsDownload(all, countExport) {
        const MAPEO_TIPOS = { "pro": "producto", "srv-pre": "servicio presencial", "srv-virt": "servicio virtual" };

        const formatter = new CsvFormatter();
        formatter.registerFunction("toAAAAMMDDHHmmss", MyDates.toAAAAMMDDHHmmss);
        formatter.registerFunction("typeFilter", (valor) => {
            return MAPEO_TIPOS[valor];
        });
        formatter.registerFunction("cityFilter", (valor) => {
            return Config.getCityValue(valor);
        });
        formatter.registerFunction("categoryFilter", (valor) => {
            return Config.getCategoryValue(valor);
        });
        formatter.registerFunction("donationDate", (donacion) => {
            if ([null, 0, undefined].indexOf(donacion.pickTime) < 0) {
                return MyDates.toAAAAMMDDHHmmss(donacion.pickTime);
            } else if ([null, 0, undefined].indexOf(donacion.dateStartMeet) < 0) {
                return MyDates.toAAAAMMDDHHmmss(donacion.dateStartMeet);
            }
            return "";
        });
        formatter.registerFunction("escaparSeparadorEnter", (valor) => {
            if (typeof valor == "string") {
                return valor.replace(/;/g, "").replace(/(?:\r\n|\r|\n)/g, "");
            }
            return "";
        });

        const csvHeader = 'id; \
        created|toAAAAMMDDHHmmss;\
        end|toAAAAMMDDHHmmss;\
        owner;\
        url;\
        title|escaparSeparadorEnter;\
        description|escaparSeparadorEnter;\
        type|typeFilter;\
        category|categoryFilter;\
        users;\
        votes;\
        numWin;\
        city|cityFilter;\
        date|donationDate*;\
        winners.0.email;\
        winners.1.email;\
        winners.2.email;\
        ';
        const addHeader = true;
        const text = formatter.parse(all, csvHeader, addHeader);
        const payload = {
            text,
            mime: "text/csv",
            filename: `donations_${MyDates.toAAAAMMDD(new Date().getTime())}_${countExport}.csv`
        };
        ReportsScreen.downloadTextData(payload);
    }
    async reportDonations() {
        const end = this.state.end;
        const start = this.state.start;
        const desicion = await super.confirm({ message: `¿Seguro que quieres el reporte de donaciones de ${MyDates.toAAAAMMDDHHmmss(start)} a ${MyDates.toAAAAMMDDHHmmss(end)}?` });
        if (!desicion) {
            return;
        }
        const MAX_PER_FILE = 1000;
        const PAGE_REPORT_DONATIONS = 20;
        let response = null;
        let lastValue = "";
        let all = [];
        let count = 0;
        let countExport = 1;
        this.setState({ reportDonationsCount: 0 });
        do {
            const url = `/donations/walk?max=${PAGE_REPORT_DONATIONS}&inline=0&format=json&start=${start}&end=${end}&offset=${count}`;
            response = await this.genericReponse(url);
            for (let j = 0; j < response.length; j++) {
                const actual = response[j];
                all.push(actual);
                count++;
            }
            if (response.length > 0) {
                lastValue = response[response.length - 1].email;
                this.setState({ reportDonationsCount: this.state.reportDonationsCount + response.length });
            }
            if (all.length >= MAX_PER_FILE) {
                await this.reportDonationsDownload(all.splice(0, MAX_PER_FILE), countExport);
                countExport++;
            }
        } while (response.length > 0);
        if (all.length > 0) {
            await this.reportDonationsDownload(all, countExport);
            countExport++;
        }
        this.props.alert("Listo", "Ok");
    }
    async reportUsersDownload(all, countExport) {
        const myParser = new CsvFormatter();
        const STATUS = {
            "-1": "Miembro",
            "0": "Donador",
            "1": "Donador Silver",
            "2": "Donador Gold",
            "3": "Donador Black",
        };
        myParser.registerFunction("status", CsvFormatterFilters.map(STATUS));
        myParser.registerFunction("date", MyDates.toAAAAMMDDHHmmss);
        const text = myParser.parse(all, "email;\
        name;\
        donorType|status;\
        created|date\
        ", true);

        const payload = {
            text,
            mime: "text/csv",
            filename: `users_${MyDates.toAAAAMMDD(new Date().getTime())}_${countExport}.csv`
        };
        ReportsScreen.downloadTextData(payload);
    }
    async reportUsers() {
        const end = this.state.end;
        const start = this.state.start;
        const desicion = await super.confirm({ message: `¿Seguro que quieres el reporte de usuarios de ${MyDates.toAAAAMMDDHHmmss(start)} a ${MyDates.toAAAAMMDDHHmmss(end)}?` });
        if (!desicion) {
            return;
        }
        const MAX_PER_FILE = 1000;
        const PAGE_REPORT_USERS_STATUS = 20;
        let response = null;
        let count = 0;
        const all = [];
        let countExport = 1;
        this.setState({ reportUsersCount: 0 });
        do {
            response = await this.genericReponse(`/users/report?max=${PAGE_REPORT_USERS_STATUS}&start=${start}&end=${end}&offset=${count}`);
            for (let j = 0; j < response.length; j++) {
                const actual = response[j];
                all.push(actual);
                count++;
            }
            if (response.length > 0) {
                this.setState({ reportUsersCount: this.state.reportUsersCount + response.length });
            }
            if (all.length >= MAX_PER_FILE) {
                await this.reportUsersDownload(all.splice(0, MAX_PER_FILE), countExport);
                countExport++;
            }
        } while (response.length > 0);
        if (all.length > 0) {
            await this.reportUsersDownload(all, countExport);
            countExport++;
        }

        this.props.alert("Listo", "Ok");
    }
    async updateStatusDownload(all, countExport) {
        const text = ReportsScreen.formatStatusResponse(all);
        const payload = {
            text,
            mime: "text/csv",
            filename: `status_${MyDates.toAAAAMMDD(new Date().getTime())}_${countExport}.csv`
        };
        ReportsScreen.downloadTextData(payload);
    }
    async updateStatus() {
        const desicion = await super.confirm({ message: "¿Seguro que deseas actualizar el status de donador de los usuarios?" });
        if (!desicion) {
            return;
        }
        const MAX_PER_FILE = 1000;
        const PAGE_UPDATE_STATUS = 10;
        let response = null;
        const all = [];
        this.setState({ updateStatusCount: 0 });
        let countExport = 1;
        do {
            response = await this.genericReponse(`/users/iterate?field=versionstatus&max=${PAGE_UPDATE_STATUS}&today=1`);
            for (let j = 0; j < response.length; j++) {
                all.push(response[j]);
            }
            this.setState({ updateStatusCount: this.state.updateStatusCount + response.length });
            if (all.length >= MAX_PER_FILE) {
                await this.updateStatusDownload(all.splice(0, MAX_PER_FILE), countExport);
                countExport++;
            }
        } while (response.length > 0);
        if (all.length > 0) {
            await this.updateStatusDownload(all, countExport);
            countExport++;
        }

        this.props.alert("Listo", "Ok");
    }
    static formatStatusResponse(db) {
        for (let i = 0; i < db.length; i++) {
            const actual = db[i];
            let stars_detail = {
                count: 0,
                points: 0,
            };
            let votes_detail = {
                count: 0,
                points: 0,
            };
            const starts = actual.REPORTE.STARS;
            for (let j = 0; j < starts.length; j++) {
                const detalle = starts[j];
                stars_detail.count += detalle.stars;
                stars_detail.points += detalle.points;
            }
            const votes = actual.REPORTE.VOTES;
            for (let j = 0; j < votes.length; j++) {
                const detalle = votes[j];
                votes_detail.count += detalle.votes;
                votes_detail.points += detalle.points;
            }
            actual.starts = stars_detail;
            actual.votes = votes_detail;
            actual.share = {
                count: actual.REPORTE.SHARE.events,
                points: actual.REPORTE.SHARE.points,
            };
            delete actual.REPORTE.STARS;
            delete actual.REPORTE.VOTES;
            delete actual.REPORTE.SHARE;
        }

        const myParser = new CsvFormatter();
        const STATUS = {
            "-1": "Miembro",
            "0": "Donador",
            "1": "Donador Silver",
            "2": "Donador Gold",
            "3": "Donador Black",
        };
        myParser.registerFunction("status", CsvFormatterFilters.map(STATUS));
        const response = myParser.parse(db, "email;\
        REPORTE.newStatus|status;\
        REPORTE.points;\
        REPORTE.REPOSTS.count;\
        REPORTE.REPOSTS.points;\
        REPORTE.DONATIONS.count;\
        REPORTE.DONATIONS.points;\
        starts.count;\
        starts.points;\
        votes.count;\
        votes.points;\
        share.count;\
        share.points;\
        ", true);
        return response;
    }
    static downloadTextData({ text = "", mime = "text/plain", filename = "file.txt" }) {
        function downloadBlob(blob, name) {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = name;
            document.body.appendChild(link);
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                })
            );
            document.body.removeChild(link);
        }
        function text2Blob(bstr, mime) {
            return new Blob([bstr], { type: mime });
        }
        const blob2 = text2Blob(text, mime);
        downloadBlob(blob2, filename);
    }
    async fixCreatedDate() {
        const MAX_ITERATED = 5;
        let response = null;
        this.setState({ fixCreateCount: 0 });
        do {
            response = await this.genericReponse(`/users/iterate?field=version&value=12&max=${MAX_ITERATED}`);
            this.setState({ fixCreateCount: this.state.fixCreateCount + response.length });
        } while (response.length > 0);
        this.props.alert("Listo", "Ok");
    }
    async genericReponse(subUrl) {
        let response = null;
        try {
            const promesa = fetchApiInternal(subUrl, {}, this.props.alert, "GET");
            this.props.loading(promesa);
            response = await promesa;
            return response;
        } catch (err) {
            this.props.alert(err.message);
        }
    }
    async genericCall(subUrl) {
        let response = null;
        try {
            const promesa = fetchApiInternal(subUrl, {}, this.props.alert, "GET");
            this.props.loading(promesa);
            response = await promesa;
            this.setState({ detalle: JSON.stringify(response, null, 4) });
            this.props.alert("Listo");
        } catch (err) { }
    }
    render() {
        const expirarThis = this.expirar.bind(this);
        const perdedoresThis = this.perdedores.bind(this);
        const notificarThis = this.notificar.bind(this);
        const iniciarThis = this.iniciar.bind(this);
        const notificacionesThis = this.notificaciones.bind(this);
        const barrerThis = this.barrer.bind(this);
        const fixCreatedDateThis = this.fixCreatedDate.bind(this);
        const updateStatusThis = this.updateStatus.bind(this);
        const reportUsersThis = this.reportUsers.bind(this);
        const reportDonationsThis = this.reportDonations.bind(this);
        const startDateDetail = {
            value: this.state.start,
            minDate: new Date(2022, 6, 1),
            maxDate: new Date(),
            errors: [],
            onBlur: () => {

            },
            onChangeText: (dato) => {
                this.setState({ start: parseInt(dato) });
            }
        };
        const endDateDetail = {
            value: this.state.end,
            minDate: new Date(2022, 6, 1),
            maxDate: new Date(),
            errors: [],
            onBlur: () => {

            },
            onChangeText: (dato) => {
                this.setState({ end: parseInt(dato) });
            }
        };
        const windowSize = {
            width: this.state.width,
            height: this.state.height,
        };
        return (<ParentView>
            <MessagesScroll>
                <Label>{`Fecha inicial:`}</Label>
                <MyDateTimePicker windowSize={windowSize} details={startDateDetail}></MyDateTimePicker>
                <Label>{`Fecha final:`}</Label>
                <MyDateTimePicker windowSize={windowSize} details={endDateDetail}></MyDateTimePicker>
                <ActionButton onPress={Hand.tryCatch(reportDonationsThis)}>
                    <Label>{`+ Reporte de donaciones x ${this.state.reportDonationsCount}`}</Label>
                </ActionButton>
                <ActionButton onPress={Hand.tryCatch(reportUsersThis)}>
                    <Label>{`+ Reporte de usuarios x ${this.state.reportUsersCount}`}</Label>
                </ActionButton>
                <ActionButton onPress={Hand.tryCatch(updateStatusThis)}>
                    <Label>{`+ Actualizar status x ${this.state.updateStatusCount}`}</Label>
                </ActionButton>
                <ActionButton onPress={Hand.tryCatch(expirarThis)}>
                    <Label>+ Decidir ganadores/perdedores de Donación</Label>
                    <Explanation>Busca las donaciones/repost que a la fecha deben estar expirados, ajusta:
                        <br />- donation: promo: -1, done: true.
                        <br />- donation_usr: won: true/false, done: true, tie: true/false, almostwon: true/false
                        <br />- Crea el testigo donation_won.
                    </Explanation>
                </ActionButton>
                <ActionButton onPress={Hand.tryCatch(perdedoresThis)}>
                    <Label>+ Ajustar estado de los perdedores</Label>
                    <Explanation>
                        Busca las relaciones donation_usr donde done == false y end sea en el pasado, ajusta:
                        <br />- donation_usr: won: false, done: true
                    </Explanation>
                </ActionButton>
                <ActionButton onPress={Hand.tryCatch(notificarThis)}>
                    <Label>+ Notificar perdedores y ganadores</Label>
                    <Explanation>
                        Busca las relaciones donation_usr donde done == false, end sea en el pasado y notified sea cero, ajusta:
                        <br />- donation_usr: notified: ahora
                        <br />- Manda los correos de ganar o perder
                        <br />- Manda las notificaciones de ganar o perder
                        <br />- Crea las notificaciones push del futuro:
                        <br />*   ¿Cómo te fue en la donación?
                        <br />*   Te quedan X donaciones por redimir
                        <br />*   No te han rankeado
                    </Explanation>
                </ActionButton>
                <ActionButton onPress={Hand.tryCatch(iniciarThis)}>
                    <Label>+ Iniciar Donaciones/Repost programadas en el futuro</Label>
                    <Explanation>
                        Buscar las donation que tengan start en el pasado y started == false, actualiza:
                        <br />- donation: started: true
                    </Explanation>
                </ActionButton>
                <ActionButton onPress={Hand.tryCatch(notificacionesThis)}>
                    <Label>+ Procesar Notificaciones programadas en el futuro</Label>
                    <Explanation>
                        Busca las alert_future que tengan target en el pasado y procesa los casos:
                        <br />- askrank:
                        <br />- rank:
                        <br />- remaining:
                    </Explanation>
                </ActionButton>
                <ActionButton onPress={Hand.tryCatch(barrerThis)}>
                    <Label>+ Borrar usuarios que se han dado de baja</Label>
                    <Explanation>
                        Busca los usuarios y los borra.
                    </Explanation>
                </ActionButton>
                <pre style={{ color: "white" }}>{this.state.detalle}</pre>
            </MessagesScroll>
        </ParentView>)
    }
}

const MessagesScroll = styled.ScrollView`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const ActionButton = styled.Pressable`
    margin-top: 5px;
    margin-bottom: 5px;
`;

const Label = styled.Text`
    font-size: 20px;
    color: white;
`;

const Explanation = styled.Text`
    font-size: 15px;
    color: white;
`;

const ParentView = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 25px;
`;
