import theme from "../Theme";

export const BodyXlargeSemibold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_xl2}; 
    letter-spacing: 0.2px;
    font-weight: 600;
    font-style: normal;
`;

export const BodyXsmallSemibold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_10px}; 
    letter-spacing: 0.2px;
    font-weight: 600;
    font-style: normal;
`;

export const BodyXsmallMedium = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_10px}; 
    letter-spacing: 0.2px;
    font-weight: 500;
    font-style: normal;
`;

export const ValignTextMiddle = `
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const BodyXsmallRegular = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_10px}; 
    letter-spacing: 0.2px;
    font-weight: 400;
    font-style: normal;
`;

export const BodyMediumRegular = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_m2}; 
    letter-spacing: 0.2px;
    font-weight: 400;
    font-style: normal;
`;

export const BodyMediumBold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_m2}; 
    letter-spacing: 0.2px;
    font-weight: 700;
    font-style: normal;
`;

export const H6Bold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_xl2}; 
    letter-spacing: 0;
    font-weight: 700;
    font-style: normal;
`;

export const H4Bold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_xxl4}; 
    letter-spacing: 0;
    font-weight: 700;
    font-style: normal;
`;

export const H3Bold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_xxl3}; 
    letter-spacing: 0;
    font-weight: 700;
    font-style: normal;
`;

export const H2Bold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_xxl2}; 
    letter-spacing: 0;
    font-weight: 700;
    font-style: normal;
`;

export const BodyMediumMedium = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_m2}; 
    letter-spacing: 0.2px;
    font-weight: 500;
    font-style: normal;
`;

export const BodyLargeBold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_l2}; 
    letter-spacing: 0.2px;
    font-weight: 700;
    font-style: normal;
`;

export const BodyMediumSemibold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_m2}; 
    letter-spacing: 0.2px;
    font-weight: 600;
    font-style: normal;
`;

export const BodySmallSemibold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_12px}; 
    letter-spacing: 0.2px;
    font-weight: 500;
    font-style: normal;
`;

export const Border1pxDarkDark3 = `
    border: 1px solid ${theme.__dark__dark_3};
`;

export const Border3pxOthersDeepOrange = `
    border: 3px solid ${theme.__others__deep_orange};
`;

export const BodyLargeSemibold = `
    font-family: ${theme.__font_family_urbanist};
    font-size: ${theme.__font_size_l2};
    letter-spacing: 0.2px;
    font-weight: 600;
    font-style: normal;
`;

export const ValignTextBottom = `
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const Border1pxGreyscale200 = `
    border: 1px solid ${theme.__border_dark_buttons}; 
`;

export const BodySmallBold = `
    font-family: ${theme.__font_family_urbanist};
    font-size: ${theme.__font_size_12px}; 
    letter-spacing: 0.2px;
    font-weight: 700;
    font-style: normal;
`;

export const H5Bold = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_xl}; 
    letter-spacing: 0;
    font-weight: 700;
    font-style: normal;
`;

export const BodyLargeRegular = `
    font-family: ${theme.__font_family_urbanist}; 
    font-size: ${theme.__font_size_l2}; 
    letter-spacing: 0.2px;
    font-weight: 400;
    font-style: normal;
`;