import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { Component } from 'react'
import styled from "styled-components/native";
import AssetsImg from "../AssetsImg";
import BottomBar from "../screens/home/BottomBar";
import OutlineArrowLeft from "../screens/svg/OutlineArrowLeft";
import { fetchApi } from "../srv/Http";
import MyLog from "../srv/MyLog";
import theme from "../Theme";
import Config from "../utils/Config";
import { MountedComponent } from "../utils/MountedComponent";
import { MyDates } from "../utils/MyDates";
import { MyFileService } from "../utils/MyFileService";
import * as CssConstants from "../widgets/CssConstans";
import MyInput from "../widgets/MyInput";

const MAX_PAGE_LAST_CHAT = 50;
const MAX_PAGE_GROUP = 20;

class PeopleScreen extends MountedComponent {
    constructor(props) {
        super(props);
        MyLog.log(`Constructor`, this);
        this.state = Object.assign(this.state, {
            showTime: new Date().getTime(),
            searchedList: [],
            lastChatsList: [],
            hasMoreChatList: false,
            groupList: [],
            hasMoreGroupList: false,
            isBottomMenuOpened: false,
        });
    }
    setBottomMenu(value) {
        this.setState({ isBottomMenuOpened: value });
    }
    setBottomMenuClosed() {
        this.setBottomMenu(false);
    }
    async onUser(payloadUser) {
        if (payloadUser.user === null) {
            // Redirect to home
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        } else {
            await this.registerPush();
        }
    }
    async onShowAndUser() {
        MyLog.log("onShow People Screen", this);
        const currentEmail = this.state.user?.email;
        if (!currentEmail) {
            return;
        }

        const promesas = [];
        promesas.push(this.loadPageLast());
        promesas.push(this.loadGroups(true));
        await Promise.all(promesas);
    }
    static async checkViewed(id, alert, isGroup = false) {
        const subUrl = "/msg/checkviewed";
        const payload = {
            id,
            isGroup,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    async loadGroups(firstTime = false) {
        let offset = this.state.groupList.length;
        if (firstTime) {
            offset = 0;
        }
        const subUrl = `/groups/list?offset=${offset}&max=${MAX_PAGE_GROUP}`;
        const promesa = fetchApi(subUrl, {}, false, this.props.alert);
        this.props.loading(promesa);
        try {
            const response = await promesa;
            const listaNueva = response.groups;
            // Se debe hacer el merge de lo nuevo con lo viejo ordenandolo por d y verificando que no hayan repetidos
            if (!firstTime) {
                const oldList = this.state.groupList;
                const oldIds = [];
                for (let i = 0; i < oldList.length; i++) {
                    const actual = oldList[i];
                    oldIds.push(actual.id);
                }

                const filtered = listaNueva.filter((value, index) => {
                    return oldIds.indexOf(value.id) < 0;
                });

                const merged = oldList.concat(filtered);

                merged.sort((a, b) => {
                    return b.updated - a.updated;
                });
                this.setState({
                    groupList: merged,
                    hasMoreGroupList: (listaNueva.length == MAX_PAGE_GROUP),
                });
            } else {
                this.setState({
                    groupList: listaNueva,
                    hasMoreGroupList: (listaNueva.length == MAX_PAGE_GROUP),
                });
            }
        } catch (err) {
            this.setState({
                groupList: [],
                hasMoreGroupList: true,
            });
        }
    }
    async loadPageLast() {
        console.log("loadPageLast");
        const offset = this.state.lastChatsList.length;
        const subUrl = `/msg/last?offset=${offset}&max=${MAX_PAGE_LAST_CHAT}`;
        const promesa = fetchApi(subUrl, {}, false, this.props.alert);
        this.props.loading(promesa);
        try {
            const response = await promesa;
            const listaNueva = response.response;
            // Se debe hacer el merge de lo nuevo con lo viejo ordenandolo por d y verificando que no hayan repetidos

            const oldList = this.state.lastChatsList;
            const oldIds = [];
            for (let i = 0; i < oldList.length; i++) {
                const actual = oldList[i];
                oldIds.push(actual.id);
            }

            const filtered = listaNueva.filter((value, index) => {
                return oldIds.indexOf(value.id) < 0;
            });

            const merged = oldList.concat(filtered);

            merged.sort((a, b) => {
                return b.d - a.d;
            });

            this.setState({
                lastChatsList: merged,
                hasMoreChatList: (listaNueva.length == MAX_PAGE_LAST_CHAT),
            });
        } catch (err) {
            console.log(err);
            this.setState({
                lastChatsList: [],
                hasMoreChatList: true,
            });
        }
    }
    backAction() {
        this.backOrGoHome();
    }
    async searchAction(texto) {
        MyLog.log(`Search ${texto}`);
        if (!(typeof texto == "string") || texto.trim().length < 3) {
            this.props.alert("Debes escribir el nombre de la persona que deseas buscar");
            return;
        }
        const payload = {
            q: texto,
        };
        const subUrl = "/users/search";
        const promesa = fetchApi(subUrl, payload, false, this.props.alert);
        this.props.loading(promesa);
        const response = await promesa;
        this.setState({ searchedList: response.response });
    }
    async createGroup() {
        const params = {};
        this.props.navigation.navigate('SearchPeoplePage', params);
    }
    async editGroup(group) {
        const params = {
            group,
            pageType: "edit",
        };
        this.props.navigation.navigate('EditGroupPage', params);
    }
    async chatGroup(group) {
        const params = {
            name: group.name,
            email: group.id,
        };
        if (group.viewed !== true) {
            // Marcar la como vista...
            const promesa = PeopleScreen.checkViewed(group.id, null, true);
            this.props.loading(promesa);
            await promesa;
            group.viewed = true;
        }
        this.props.navigation.navigate('MessengerPage', params);
    }
    async goToPersonSpace(payload) {
        const soyYoMismo = (this.state?.user?.email == payload.email);
        if (!soyYoMismo) {
            if (payload.viewed !== true) {
                // Marcar la como vista...
                const promesa = PeopleScreen.checkViewed(payload.id,);
                this.props.loading(promesa);
                await promesa;
                payload.viewed = true;
            }
            const params = payload;
            this.props.navigation.navigate('MessengerPage', params);
        }
    }
    goToProfile(payload) {
        this.props.navigation.push('ProfilePage', payload);
    }
    renderGroup(acc, ele, ix, prefix, editGroupThis, chatGroupThis) {
        const nuevo = <ElementsAccountListCont
            key={`${prefix}-${ele.id}`}
            details={{
                showTime: this.state.showTime,
                goToPersonSpace: () => {
                    chatGroupThis(ele);
                },
                goToProfile: () => {
                    editGroupThis(ele);
                },
                picture: ele.picture ? ele.picture : AssetsImg.IMG.DEFGROUP,
                name: ele.name,
                txt: typeof ele.txt == "string" ? ele.txt : "",
                countUnread: null,
                d: ele.d,
                viewed: typeof ele.viewed == "boolean" ? ele.viewed : true,
            }}></ElementsAccountListCont>;
        acc.push(nuevo);
        return acc;
    }
    renderPerson(acc, ele, ix, prefix, goToPersonSpaceThis, goToProfileThis) {
        const nuevo = <ElementsAccountListCont
            key={`${prefix}-${ele.email}`}
            details={{
                showTime: this.showTime,
                goToPersonSpace: () => {
                    goToPersonSpaceThis(ele);
                },
                goToProfile: () => {
                    goToProfileThis(ele);
                },
                picture: ele.picture,
                name: ele.name,
                txt: ele.txt,
                countUnread: ele.countUnread,
                d: ele.d,
                viewed: ele.viewed,
            }}></ElementsAccountListCont>;
        acc.push(nuevo);
        return acc;
    }
    async goToCreateDonation(payload) {
        if (!(await this.alertLogedUser())) {
            return;
        }
        const nuevaImagen = await MyFileService.pickFile();
        if (typeof nuevaImagen == "string") {
            const nuevaDonacion = {
                image: nuevaImagen,
                type: payload.donationType,
            };
            // Go to next page
            this.props.navigation.reset({ index: 0, routes: [{ name: 'WDonationPage3', params: nuevaDonacion }] });
        }
    }
    async loadAnyPage() {
        const promesas = [];
        if (this.state.hasMoreChatList) {
            promesas.push(loadPageLastThis());
        }
        if (this.state.hasMoreGroupList) {
            promesas.push(loadGroupsThis());
        }
        return await Promise.all(promesas);
    }
    render() {
        const backActionThis = this.backAction.bind(this);
        const searchActionThis = this.searchAction.bind(this);
        const goToPersonSpaceThis = this.goToPersonSpace.bind(this);
        const goToProfileThis = this.goToProfile.bind(this);
        const goToCreateDonationThis = this.goToCreateDonation.bind(this);
        const setBottomMenuThis = this.setBottomMenu.bind(this);
        const setBottomMenuClosedThis = this.setBottomMenuClosed.bind(this);
        const editGroupThis = this.editGroup.bind(this);
        const chatGroupThis = this.chatGroup.bind(this);
        const createGroupThis = this.createGroup.bind(this);
        const loadAnyPageThis = this.loadAnyPage.bind(this);

        const details = {
            homeAction: async () => {
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyHomePage' }] });
            },
            notificationAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("notificationAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'NotificationsPage' }] });
            },
            plusAction: async (payload) => {
                await goToCreateDonationThis(payload);
            },
            chatAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("chatAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'PeoplePage' }] });
            },
            visitAcceptedDonationsAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyAcceptedPage' }] });
            },
        };

        const searchedListElements = this.state.searchedList.reduce((acc, el, ix) => {
            return this.renderPerson(acc, el, ix, "searched", goToPersonSpaceThis, goToProfileThis);
        }, []);

        // Parto en dos los últimos mensajes que son de persona a persona y los que son grupo a persona
        const lastPersonToPerson = [];
        const lastGroupToPerson = [];
        for (let k = 0; k < this.state.lastChatsList.length; k++) {
            const oneLast = this.state.lastChatsList[k];
            if (oneLast.group !== undefined) {
                lastGroupToPerson.push(oneLast);
                //console.log("Group: " + JSON.stringify(oneLast, null, 4));
            } else {
                lastPersonToPerson.push(oneLast);
                //console.log("Person: " + JSON.stringify(oneLast, null, 4));
            }
        }

        // Mapeo los grupos
        const mapeoGrupos = {};
        //const gruposExistentes = JSON.parse(JSON.stringify(this.state.groupList));
        const gruposExistentes = this.state.groupList;
        for (let k = 0; k < gruposExistentes.length; k++) {
            const grupo = gruposExistentes[k];
            grupo.d = grupo.updated;
            mapeoGrupos[grupo.id] = grupo;
        }

        // Agrego los grupos que no existían
        const llavesGrupos = Object.keys(mapeoGrupos);
        for (let k = 0; k < lastGroupToPerson.length; k++) {
            const oneGroupChat = lastGroupToPerson[k];
            let grupo = oneGroupChat.group;
            if (llavesGrupos.indexOf(grupo.id) < 0) {
                // No existe el grupo y lo debo agregar
                gruposExistentes.push(grupo);
            } else {
                grupo = mapeoGrupos[grupo.id];
            }
            // Le debo agregar lo necesario para que se vea como un chat...
            const sender = oneGroupChat.detail[0];
            if (sender) {
                grupo.txt = `${sender.name}: ${oneGroupChat.txt}`;
            } else {
                grupo.txt = oneGroupChat.txt;
            }
            grupo.d = oneGroupChat.d;
            //console.log(`grupo.viewed = ${grupo.viewed}`);
            //console.log(`oneGroupChat.viewed = ${oneGroupChat.viewed}`);
            if (grupo.viewed === true) {
                oneGroupChat.viewed = true;
            }
            grupo.viewed = oneGroupChat.viewed;
            const llaves = Object.keys(oneGroupChat).filter((llave) => { return /viewed_/.test(llave) });
            for (let m = 0; m < llaves.length; m++) {
                const llave = llaves[m];
                grupo[llave] = oneGroupChat[llave];
            }
        }

        // Se marcan los grupos por separado
        const unidos = [];
        for (let k = 0; k < gruposExistentes.length; k++) {
            const grupo = gruposExistentes[k];
            grupo.isGroup = true;
            // Se unen personas y grupos
            unidos.push(grupo);
        }

        for (let k = 0; k < lastPersonToPerson.length; k++) {
            const person = lastPersonToPerson[k];
            unidos.push(person);
        }

        // Se ordena por fecha
        unidos.sort((a, b) => {
            return b.d - a.d;
        });

        const lastChatsListElements = unidos.reduce((acc, el, ix) => {
            if (el.isGroup === true) {
                return this.renderGroup(acc, el, ix, "list", editGroupThis, chatGroupThis);
            } else {
                return this.renderPerson(acc, el, ix, "last_person", goToPersonSpaceThis, goToProfileThis);
            }
        }, []);

        return (
            <ParentContainer>
                <MyContent>
                    <Header>
                        <ContenedorFlecha onPress={backActionThis}>
                            <OutlineArrowLeft></OutlineArrowLeft>
                        </ContenedorFlecha>
                        <ContenedorTitulo style={{ textAlign: 'center', flex: 1 }}>
                            {"Chats"}
                        </ContenedorTitulo>
                    </Header>
                    <MyFooter>
                        <BottomWidget details={{ searchAction: searchActionThis }}></BottomWidget>
                    </MyFooter>
                    <MessagesScroll ref={ref => { this.scrollView = ref }}
                        onContentSizeChange={() => { }}>
                        {searchedListElements.length > 0 && <TituloSeccion>
                            <TextoSeccion>Resultados de búsqueda</TextoSeccion>
                        </TituloSeccion>}
                        {searchedListElements}
                        <TituloSeccion>
                            <TextoSeccion>Recientes</TextoSeccion>
                            <TextoSeccionPequeButton onPress={createGroupThis}>
                                <TextoSeccionPeque>Nuevo grupo</TextoSeccionPeque>
                            </TextoSeccionPequeButton>
                        </TituloSeccion>
                        {lastChatsListElements}
                        {(this.state.hasMoreGroupList || this.state.hasMoreChatList) && <TituloSeccionClick onPress={loadAnyPageThis}>
                            <TextoSeccionClick>Ver más</TextoSeccionClick>
                        </TituloSeccionClick>}
                    </MessagesScroll>
                </MyContent>
                {this.state.isBottomMenuOpened && <BigBackground onPress={setBottomMenuClosedThis}></BigBackground>}
                <MyFooterBottom>
                    <AutoLayoutVerticalBotom>
                        <BottomBar
                            useDirectDonation={true}
                            popupState={this.state.isBottomMenuOpened}
                            setBottomMenu={setBottomMenuThis}
                            details={details}
                            userRealTime={this.state.userRealTime}
                            alert={this.props.alert}
                            route={"People"} />
                    </AutoLayoutVerticalBotom>
                </MyFooterBottom>
            </ParentContainer >
        );
    }
}

export default PeopleScreen;

const BigBackground = styled.Pressable`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
`;

const MyFooterBottom = styled.View`
    width: 100%;
`;

const AutoLayoutVerticalBotom = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

class ElementsAccountListCont extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            goToPersonSpace,
            goToProfile,
            showTime,
            picture,
            name,
            donorType,//Use it later
            txt,
            countUnread,
            d,
            viewed,
        } = this.props.details;

        let textoFecha = null;
        if (typeof d == "number") {
            const isToday = MyDates.isToday(d);
            const fecha = new Date(d);
            if (isToday) {
                textoFecha = MyDates.formatTime(fecha);
            } else {
                textoFecha = MyDates.formatDateSimple(fecha);
            }
        }

        const dotStyle = {
            position: "absolute",
            top: 0,
            right: -9,
            color: theme.__others__deep_orange,
        };

        let avatarIcon = null;
        if (typeof picture == "string") {
            avatarIcon = <Avatar source={{ uri: Config.getNewPath(picture, "_xs", showTime) }}></Avatar>;
        } else {
            avatarIcon = <Avatar source={picture}></Avatar>;
        }

        return (
            <ElementsAccountList>
                <AvatarNameContainer>
                    <AvatarContainerParent>
                        <AvatarContainer colorDonorType={"#FFFFFF"} onPress={goToProfile}>
                            {avatarIcon}
                        </AvatarContainer>
                        {viewed === false && <FontAwesomeIcon icon={"circle"} style={dotStyle} size={13} />}
                    </AvatarContainerParent>
                    <NameLastMessageContainer onPress={goToPersonSpace}>
                        <OnlyNameContainer>
                            {name}
                        </OnlyNameContainer>
                        {typeof txt == "string" && <LastMessageContainer>
                            {txt}
                        </LastMessageContainer>}
                    </NameLastMessageContainer>
                    <IndicatorContainer onPress={goToPersonSpace}>
                        {typeof countUnread == "number" && <CountContainer>
                            <CountContainerText>{countUnread}</CountContainerText>
                        </CountContainer>}
                        {textoFecha != null && <DateContainer>
                            {textoFecha}
                        </DateContainer>}
                    </IndicatorContainer>
                </AvatarNameContainer>
            </ElementsAccountList>
        );
    }
}

const DateContainer = styled.Text`
    ${CssConstants.BodyMediumMedium}
    font-weight: 500;
    color: ${props => props.theme.__greyscale__400};
    text-align: right;
    line-height: 19.6px;
`;

const CountContainer = styled.View`
    display: flex;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: ${props => props.theme.__greyscale__800};
    border-radius: 100px;
    margin-bottom: 4px;
`;

const CountContainerText = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXsmallRegular}
    font-weight: 400;
    color: ${props => props.theme.__others__white};
    line-height: normal;
`;

const IndicatorContainer = styled.Pressable`
    display: flex;
    flex-direction: column;
`;

const OnlyNameContainer = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H6Bold}
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    line-height: 21.6px;
    margin-bottom: 4px;
`;

const LastMessageContainer = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumMedium}
    font-weight: 500;
    color: ${props => props.theme.__greyscale__400};
    line-height: 19.6px;
    flex: 1;
`;

const NameLastMessageContainer = styled.Pressable`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 4px;
`;

const AvatarNameContainer = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const ElementsAccountList = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 24px;
    padding-right: 5px;
`;

const AvatarContainerParent = styled.View`
    position:relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    
    width: 60px;
    height: 60px;
    margin-right: 20px;
`;

const AvatarContainer = styled.Pressable`
    width: 60px;
    height: 60px;
    border: 3px solid;
    border-color: ${props => props.colorDonorType};
    border-radius: 100px;
    overflow: hidden;
`;

const Avatar = styled.ImageBackground`
    width: 54px;
    height: 54px;
    background-size: contain;
    border-radius: 100px;
    overflow: hidden;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyContent = styled.View`
    width: 100%;
    flex: 1;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
`;

const Header = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
`;

const ContenedorFlecha = styled.Pressable`
    width: 28px;
    height: 28px;
    border: 0;
`;

const ContenedorTitulo = styled.Text`
    ${CssConstants.H4Bold}
    color: ${props => props.theme.__others__white};
    margin-left: 16px;
`;

const MessagesScroll = styled.ScrollView`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const MyFooter = styled.View`
    padding-top: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

const TituloSeccion = styled.View`
    padding-top: 10px;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const TituloSeccionClick = styled.Pressable`
    padding-top: 10px;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TextoSeccion = styled.Text`
    ${CssConstants.H6Bold}
    color: ${props => props.theme.__others__white};
    flex: 1;
`;

const TextoSeccionPequeButton = styled.Pressable`
`;

const TextoSeccionPeque = styled.Text`
    ${CssConstants.H6Bold}
    color: ${props => props.theme.__link_blue};
`;

const TextoSeccionClick = styled.Text`
    ${CssConstants.BodyXlargeSemibold}
    color: ${props => props.theme.__others__white};
    flex: 1;
    text-align: center;
    width: 100%;
`;

class BottomWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
        }
        this.child = React.createRef();
    }
    render() {
        const {
            searchAction
        } = this.props.details;

        const localSearch = async () => {
            MyLog.log(`Buscar #{this.state.text}`);
            if (typeof searchAction == "function") {
                searchAction(this.state.text);
            }
        };

        const messageProps = {
            secret: false,
            label: "Busca personas aquí ...",
            onChangeText: (texto) => {
                this.setState({
                    text: texto,
                });
            },
            onBlur: () => {

            },
            onSubmit: localSearch,
            value: "",
        }

        return (
            <AutoLayoutHorizontal>
                <InputTextContainer>
                    <MyInput ref={this.child} details={messageProps}></MyInput>
                </InputTextContainer>
                <SendButton onPress={localSearch}>
                    <Group
                        imageStyle={{ borderRadius: 100 }}
                        resizeMode="cover"
                        source={AssetsImg.IMG.YELLOW_BUTTON}>
                        <IconlyBoldStar>
                            <Discovery>
                                <FontAwesomeIcon icon={"magnifying-glass"} style={{ color: theme.__others__white, outline: "none" }} size={16} />
                            </Discovery>
                        </IconlyBoldStar>
                    </Group>
                </SendButton>
            </AutoLayoutHorizontal>
        );
    }
}

const SendButton = styled.Pressable`
    width: 56px;
    height: 56px;
`;

const InputTextContainer = styled.View`
    display: flex;
    flex: 1;
    padding-right: 12px;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const Group = styled.ImageBackground`
    width: 56px;
    height: 56px;
    border-radius: 100px;
`;

const IconlyBoldStar = styled.View`
    width: 24px;
    height: 24px;
    top: 18px;
    left: 18px;
    display: flex;
    padding: 3px 1px;
    align-items: flex-end;
`;

const Discovery = styled.View`
    width: 20px;
    height: 20px;
`;
