import React from 'react'
import X2RegistroEmail from '../screens/signup/X2RegistroEmail';
import { validInputEmail, validInputPassword } from '../utils/InputValidator';
import { Formik } from 'formik';
import { saveData, readData } from '../utils/MyLocalStorage';
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword
} from 'firebase/auth';
import { MountedComponent } from "../utils/MountedComponent";
import Constants from '../Constants';
import { isOldUser } from "../utils/OldUsers"
import AcceptTerms from "../widgets/AcceptTerms"
import { View } from 'react-native';

const auth = getAuth();

export default class SignInScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign(this.state, {
            loading: true,
            config: {},
            aceptacion: false,
            data: { email: '', password: '', rememberme: false, accept: false }
        });
        const promesas = [];
        promesas.push(readData("signin.", this.state.data).then((data) => {
            this.state.data = data;
            this.setState({ data: data, aceptacion: data.accept });
        }));
        this.props.loading(Promise.all(promesas).finally(() => {
            this.setState({ loading: false });
        }));
    }
    async onUser(payloadUser) {
        await this.onUserDefaultGoHome(payloadUser);
    }
    async signInIfOldUser(email, password) {
        const shouldAutocreate = isOldUser(email);
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            if (error.code == "auth/user-not-found" && shouldAutocreate) {
                await createUserWithEmailAndPassword(auth, email, password);
            } else {
                throw error;
            }
        }
    }
    render() {
        const mySubmit = async (values) => {
            if (values.accept !== true) {
                console.log("Es SignInScreen");
                this.props.alert(Constants.TXT.ALERT.primero_acepta);
                return;
            }
            values.email = values.email.toLowerCase().trim();
            if (values.rememberme === true) {
                await saveData("signin.", values);
            } else {
                await saveData("signin.", { email: '', password: '', rememberme: false });
            }

            const promesaLogin = new Promise(async (resolve) => {
                try {
                    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
                    await this.signInIfOldUser(values.email, values.password);
                } catch (error) {
                    if (error.code == "auth/wrong-password") {
                        this.props.alert("Correo o clave no coinciden");
                    } else if (error.code == "auth/invalid-email") {
                        this.props.alert("Correo inválido");
                    } else if (error.code == "auth/user-disabled") {
                        this.props.alert("Correo inhabilitado");
                    } else if (error.code == "auth/user-not-found") {
                        this.props.alert("Correo no encontrado, prueba registrarte primero");
                    } else if (error.code == "auth/too-many-requests") {
                        this.props.alert("Has superado el máximo número de intentos, espera un momento");
                    } else if (error.code == "auth/email-already-in-use") {
                        this.props.alert("Intenta ingresar por medio de los botones de redes sociales"); 7
                    } else if (error.code == "auth/operation-not-allowed") {
                        this.props.alert("Operación no permitida");
                    } else if (error.code == "auth/weak-password") {
                        this.props.alert("Reintenta ingresar con una clave más segura");
                    } else {
                        this.props.alert(error.code);
                    }
                }
                resolve();
            });

            this.props.loading(promesaLogin);

            return promesaLogin;
        }
        return (
            <View>
                {!this.state.loading &&
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={this.state.data}
                        validate={values => {
                            const errors = {};
                            errors.email = validInputEmail(values.email);
                            if (errors.email === undefined) { delete errors.email; }
                            errors.password = validInputPassword(values.password);
                            if (errors.password === undefined) { delete errors.password; }
                            return errors;
                        }}
                        onSubmit={mySubmit}
                    >
                        {({ handleChange, handleBlur, submitForm, errors, values, setFieldValue }) => (
                            <X2RegistroEmail
                                alert={this.props.alert}
                                creaTuCuenta={
                                    <React.Fragment>Inicia{"\n"}sesión</React.Fragment>
                                }
                                input5Props2={{
                                    label: "Email",
                                    leftIcon: "envelope",
                                    onChangeText: handleChange('email'),
                                    onBlur: handleBlur('email'),
                                    errors: errors.email,
                                    value: values.email,
                                }}
                                input22Props={{
                                    label: "Contraseña",
                                    leftIcon: "lock",
                                    rightIcon: "eye-slash",
                                    secret: true,
                                    onChangeText: handleChange('password'),
                                    onBlur: handleBlur('password'),
                                    errors: errors.password,
                                    value: values.password,
                                }}
                                checkboxProps={{
                                    label: "Recuérdame",
                                    onChange: handleChange('rememberme'),
                                    value: values.rememberme
                                }}
                                aceptaCondiciones={{
                                    label: (<AcceptTerms></AcceptTerms>),
                                    onChange: (event) => {
                                        this.setState({ aceptacion: event.target.value });
                                        handleChange('accept')(event);
                                    },
                                    value: values.accept
                                }}
                                buttonProps={{
                                    label: "Inicia Sesión",
                                    onPress: async () => {
                                        //si se usa expo start, debe colocar false
                                        if (true) {
                                            submitForm();
                                            //handleSubmit();
                                        } else {
                                            await mySubmit(values);
                                        }
                                    },
                                }}
                                oContinaCon="O continúa con"
                                yaEresMiembro="¿No tienes cuenta?"
                                iniciaTuSesin={{
                                    label: "Regístrate",
                                    onPress: () => {
                                        this.props.navigation.navigate('SignUpPage', { name: 'Jane' });
                                    }
                                }}
                                button2Google={{
                                    className: "google",
                                    webClientId: Constants.LOGIN.googleClientId,
                                    acceptTerms: this.state.aceptacion,
                                }}
                                button2Apple={{
                                    className: "apple",
                                    webClientId: null,
                                    acceptTerms: this.state.aceptacion,
                                }}
                                button2Facebook={{
                                    className: "facebook",
                                    webClientId: Constants.LOGIN.facebookClientId,
                                    acceptTerms: this.state.aceptacion,
                                }}
                                recuperaClave={{
                                    label: "¿Olvidaste tu contraseña?",
                                    onPress: () => {
                                        this.props.navigation.navigate('RecuperarClavePage', { name: 'Jane' });
                                    }
                                }}
                            />
                        )}
                    </Formik>
                }
            </View>
        )
    }
}
