import { Linking, Share } from 'react-native';
import CONFIG from '../Constants';
import { Platform } from 'react-native';
import { MyDates } from "../utils/MyDates"
import { MyAnalytics } from '../config/myAnalytics';
import MyClipBoard from './MyClipBoard';

export default class MyShare {
    static sharedIds = [];
    static copyToClipboard(text) {
        MyClipBoard.copyToClipboard(text);

    }
    static async openUrlOrMail(url, alert) {
        const regExp3 = /^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/;
        const regExp2 = /^\s*@[a-zA-Z0-9._]+\s*$/ig;
        const isEmail = regExp3.test(url);
        const isProfile = regExp2.test(url);
        let mailUrl = url;
        if (isEmail) {
            mailUrl = `mailto:${url}`;
        } else if (isProfile) {
            //
        } else {
            if (!(/^\s*http/ig.test(mailUrl))) {
                mailUrl = "http://" + mailUrl;
            }
        }
        if (Platform.OS === 'web') {
            if (isEmail) {
                MyShare.copyToClipboard(url);
                alert(`Has copiado el correo de contacto ${url}`, "Listo!");
            } else if (isProfile) {
                MyShare.copyToClipboard(url);
                alert(`Has copiado el identificador de perfil ${url}`, "Listo!");
            } else {
                window.open(mailUrl, "_blank");
            }
        } else {
            const canOpen = await Linking.canOpenURL(mailUrl);
            if (!canOpen) {
                if (isEmail) {
                    MyShare.copyToClipboard(url);
                    alert(`Has copiado el correo de contacto ${url}`, "Listo!");
                } else if (isProfile) {
                    MyShare.copyToClipboard(url);
                    alert(`Has copiado el identificador de perfil ${url}`, "Listo!");
                } else {
                    MyShare.copyToClipboard(url);
                    alert(`Has copiado la url ${url}`, "Listo!");
                }
            } else {
                Linking.openURL(mailUrl);
            }
        }
        MyAnalytics.sendEvent("open_detail", { url: mailUrl });
    }
    async mailTo(email, alert, donation) {
        if (Platform.OS === 'web') {
            MyShare.copyToClipboard(email);
            alert(`Has copiado el correo ${email}`, "Listo!")
        } else {
            const url = `mailto:${email}?subject=Te escribo desde Panal Comunidad&body=Hola, yo soy ganador de la donación "${donation.title}" desde el ${MyDates.formatDatetime(donation.end)}. Me puedes responder a este correo.`;
            const canOpen = await Linking.canOpenURL(url);
            if (!canOpen) {
                MyShare.copyToClipboard(email);
                alert(`Has copiado el correo ${email}`, "Listo!")
            } else {
                Linking.openURL(url);
            }
        }
    }
    static async share(donationId, alert) {
        if (MyShare.sharedIds.indexOf(donationId) < 0) {
            MyShare.sharedIds.push(donationId);
            MyAnalytics.sendEvent("com_share", { donationId: donationId });
        }
        // Se prefieren underscores para evitar que se parta la url en whatsapp web
        donationId = donationId.replace(/-(\d{8})-(\d{6})-(\d{3})$/ig, "_$1_$2_$3");

        const url = `${CONFIG.API.rootUrl}/srv/donations/share?id=${donationId}`;

        if (Platform.OS === 'web') {
            MyShare.copyToClipboard(url);
            alert("Has copiado el enlace para que lo compartas", "Listo!")
        } else {
            try {
                const result = await Share.share({
                    message: url,
                    url: url,
                });
                if (result.action === Share.sharedAction) {
                    if (result.activityType) {
                        // shared with activity type of result.activityType
                        console.log(`shared with activity type of $(result.activityType)`);
                    } else {
                        console.log("Shared");
                    }
                } else if (result.action === Share.dismissedAction) {
                    console.log("Dismised");
                }
            } catch (error) {
                console.log(error.message);
            }
        }
    }
}
