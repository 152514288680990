import React from "react";
import styled from "styled-components/native";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import theme from "../../Theme";

export default class Stars extends React.Component {
    render() {
        const {
            setStars,
        } = this.props.details;
        let {
            stars,
        } = this.props.details;
        if (typeof stars !== "number") {
            stars = 0;
        }
        const styleNoSelected = {
            color: theme.__background_dark_button,
        };
        const styleSelected = {
            color: theme.__others__white,
        };
        const styleBack = {
            color: theme.__others__white,
            position: "absolute",
            zIndex: -1,
            left: -3,
            top: -3,
        };
        return (
            <AutoLayoutHorizontal>
                <ImageContainer onPress={() => { setStars(1) }}>
                    <FontAwesomeIcon icon={"star"} style={styleBack} size={49} />
                    <FontAwesomeIcon icon={"star"} style={stars >= 1 ? styleSelected : styleNoSelected} size={43} />
                </ImageContainer>
                <ImageContainer onPress={() => { setStars(2) }}>
                    <FontAwesomeIcon icon={"star"} style={styleBack} size={49} />
                    <FontAwesomeIcon icon={"star"} style={stars >= 2 ? styleSelected : styleNoSelected} size={43} />
                </ImageContainer>
                <ImageContainer onPress={() => { setStars(3) }}>
                    <FontAwesomeIcon icon={"star"} style={styleBack} size={49} />
                    <FontAwesomeIcon icon={"star"} style={stars >= 3 ? styleSelected : styleNoSelected} size={43} />
                </ImageContainer>
                <ImageContainer onPress={() => { setStars(4) }}>
                    <FontAwesomeIcon icon={"star"} style={styleBack} size={49} />
                    <FontAwesomeIcon icon={"star"} style={stars >= 4 ? styleSelected : styleNoSelected} size={43} />
                </ImageContainer>
                <ImageContainer onPress={() => { setStars(5) }}>
                    <FontAwesomeIcon icon={"star"} style={styleBack} size={49} />
                    <FontAwesomeIcon icon={"star"} style={stars >= 5 ? styleSelected : styleNoSelected} size={43} />
                </ImageContainer>
            </AutoLayoutHorizontal>
        );
    }
}

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
    position: relative;
`;

const ImageContainer = styled.Pressable`
    width: 43px;
    height: 43px;
    margin-left: 9px;
    margin-right: 9px;
`;
