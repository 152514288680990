import MyLog from "../srv/MyLog";

export function validInputEmail(email) {
    const mensajes = [];
    var re = /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
    if (!re.test(email)) {
        mensajes.push("No es un email válido");
    }
    if (mensajes.length > 0) {
        return mensajes[0];
    }
    return undefined;
};

export function validInputPassword(pass) {
    const mensajes = [];
    if (!/^([^\s]{5,})/.test(pass)) {
        mensajes.push("Mínimo debe tener 5 caracteres");
    }
    if (mensajes.length > 0) {
        return mensajes[0];
    }
    return undefined;
};

export function isValidInstagram(text) {
    const mensajes = [];
    const regExp = /^@(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/ig;
    if (!regExp.test(text)) {
        mensajes.push(`El usuario de instagram no es válido, recuerda que debe comenzar con @`);
    }
    if (mensajes.length > 0) {
        return mensajes[0];
    }
    return undefined;
}

export function isValidURL(text) {
    const mensajes = [];
    const regExp = /\s*[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)\s*/ig;
    if (!regExp.test(text)) {
        mensajes.push(`No es una url válida`);
    }
    if (mensajes.length > 0) {
        return mensajes[0];
    }
    return undefined;
}

export function isValidURLWithInstagramUser(text, mail = false) {
    const mensajes = [];
    const regExp1 = /\s*[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)\s*/ig;
    const regExp2 = /^\s*@[a-zA-Z0-9._]+\s*$/ig;
    const regExp3 = /^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/;
    const cumple1 = regExp1.test(text);
    const cumple2 = regExp2.test(text);
    if (!mail) {
        if (!(cumple1 || cumple2)) {
            mensajes.push(`No es una url válida. También puedes usar tu perfil así @usuario`);
        }
    } else {
        const cumple3 = regExp3.test(text);
        if (!(cumple1 || cumple2 || cumple3)) {
            mensajes.push(`No es una url válida. También puedes usar el perfil (@usuario) o correo.`);
        }
    }
    if (mensajes.length > 0) {
        return mensajes[0];
    }
    return undefined;
}

export function isValidAddress(text) {
    return undefined;
}

export function hasMinLength(text, minLength = 5) {
    const mensaje = `Mínimo debe tener ${minLength} caracteres`;
    const mensajes = [];
    if (typeof text !== "string") {
        mensajes.push(mensaje);
    } else {
        const trimed = text.trim();
        if (trimed.length < minLength) {
            mensajes.push(mensaje);
        }
    }
    if (mensajes.length > 0) {
        return mensajes[0];
    }
    return undefined;
};

export function hasMinMaxLength(text, minLength = 5, maxLength = 60) {

    const mensajes = [];
    if (typeof text !== "string") {
        mensajes.push("Debe tener mínimo ${minLength} caracteres.");
    } else {
        const trimed = text.trim();
        if (trimed.length < minLength || trimed.length > maxLength) {
            const mensaje = `Debe tener entre ${minLength} y ${maxLength} caracteres. Actualmente hay ${trimed.length}.`;
            mensajes.push(mensaje);
        }
    }
    if (mensajes.length > 0) {
        return mensajes[0];
    }
    return undefined;
};
