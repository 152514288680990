import * as React from 'react';
import * as WebBrowser from 'expo-web-browser';
import styled from "styled-components/native";
import { Pressable, Linking } from 'react-native'
import Constants from '../../Constants';
import CONFIG from '../../Constants';
import { defineAppleNonce } from '../../config/firebase';
import { Platform } from 'react-native'

WebBrowser.maybeCompleteAuthSession();

/*
Este sí es!
https://comunidad.panal.co/srv/users/apple
https://dev-dot-panal-comunidad-app.uc.r.appspot.com/srv/users/apple

http://localhost:8080/srv/users/apple
http://localhost:8080/srv/users/handle_redirect

https://firebase.google.com/docs/auth/web/apple

https://rnfirebase.io/auth/social-auth#apple
JWT Token
https://www.mongodb.com/docs/atlas/app-services/authentication/apple/#:~:text=Configure%20Apple%20ID%20Authentication,-1&text=Navigate%20to%20the%20Certificates%2C%20Identifiers,IDs%20and%20then%20click%20Continue.

noreply@panal-comunidad-app.firebaseapp.com
*/

if (typeof document === 'undefined') {
    global.document = {
        
    };
}

function popupCenter({ url, title, w, h }) {
    if (Platform.OS === "web") {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title,
            `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
        )
        if (window.focus) newWindow.focus();
    } else {
        Linking.openURL(url);
    }
}

function SocialApple(props) {
    const rootUrl = CONFIG.API.rootUrl;

    function promptAsync() {
        // Add message listener
        let popUpUrl = `${rootUrl}/srv/users/apple`;
        const nonceFrontEnd = (Platform.OS !== "web");
        //const nonceFrontEnd = true;
        if (nonceFrontEnd) {
            const nonce = defineAppleNonce();
            popUpUrl += `?h=${encodeURIComponent(nonce.HASHED_NONCE)}&u=${encodeURIComponent(nonce.unhashedNonce)}`;
        }

        popupCenter({ url: popUpUrl, title: "Apple Login", w: 516, h: 736 });
    }

    return (
        <Pressable
            onPress={() => {
                if (!props.acceptTerms) {
                    console.log("Es SocialApple");
                    props.alert(Constants.TXT.ALERT.primero_acepta);
                    return;
                }
                promptAsync();
            }}
            style={props.mystyle}
        >
            {props.children}
        </Pressable>
    );
}

const TypeTouchableLocal = styled.Pressable`
    
`;

export { SocialApple };