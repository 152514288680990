// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import {
    initializeAuth,
    getReactNativePersistence,
} from 'firebase/auth/react-native';
import { getAuth, onAuthStateChanged, signOut, sendEmailVerification, OAuthProvider, signInWithCredential } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'
import { getAnalytics, logEvent, setUserProperties, setUserId } from "firebase/analytics";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Users } from '../srv/Users';
import { connectFirebase, fetchApiInternalRecursiveRetry } from '../srv/Http';
import MyLog from '../srv/MyLog';
import { Buffer } from "buffer";
import { Platform } from 'react-native'
import CONFIG from '../Constants';
import { StringDecoder } from "string_decoder";
import * as CryptoJS from "crypto-js";
import Config from '../utils/Config';
import * as SecureStore from 'expo-secure-store';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { MyAnalytics } from './myAnalytics';

// https://firebase.google.com/docs/web/setup?hl=es-419
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCeWij11DshAlW-YhWmkCnhYRwTpej1HsU",
    authDomain: "panal-comunidad-app.firebaseapp.com",
    projectId: "panal-comunidad-app",
    storageBucket: "panal-comunidad-app.appspot.com",
    messagingSenderId: "928458581389",
    appId: "1:928458581389:web:e33e80e7d496f7794ac9c4",
    measurementId: "G-Z7F5Y9QYT0"
};

let app = null;
let analyticsResolve = null;
let analyticsReject = null;
let analyticsPromise = new Promise((resolve, reject) => {
    analyticsResolve = resolve;
    analyticsReject = reject;
});
MyAnalytics.setAnalytics(analyticsPromise);

function myLog(message) {
    MyLog.log(message, { constructor: { name: "firebase.js" } });
}

function getRandomValues(abv) {
    var l = abv.length
    while (l--) {
        abv[l] = Math.floor(Math.random() * 256)
    }
    return abv
}

function randomBytes(size, cb) {
    var MAX_BYTES = 65536

    // Node supports requesting up to this number of bytes
    // https://github.com/nodejs/node/blob/master/lib/internal/crypto/random.js#L48
    var MAX_UINT32 = 4294967295

    // phantomjs needs to throw
    if (size > MAX_UINT32) throw new RangeError('requested too many random bytes')

    var bytes = Buffer.allocUnsafe(size)

    if (size > 0) {  // getRandomValues fails on IE if size == 0
        if (size > MAX_BYTES) { // this is the max bytes crypto.getRandomValues
            // can do at once see https://developer.mozilla.org/en-US/docs/Web/API/window.crypto.getRandomValues
            for (var generated = 0; generated < size; generated += MAX_BYTES) {
                // buffer.slice automatically checks if the end is past the end of
                // the buffer so we don't have to here
                getRandomValues(bytes.subarray(generated, generated + MAX_BYTES));
            }
        } else {
            getRandomValues(bytes);
        }
    }

    if (typeof cb === 'function') {
        cb(null, bytes);
    }

    return bytes
}

function randomFillSync(buffer, offset, size) {
    if (offset === undefined) offset = 0
    if (size === undefined) size = buffer.length - offset
    if (typeof offset !== 'number') throw new TypeError('The "offset" argument must be of type number. Received type ' + typeof offset)
    if (typeof size !== 'number') throw new TypeError('The "size" argument must be of type number. Received type ' + typeof size)
    if (size + offset > buffer.length) throw new RangeError('The value of "size + offset" is out of range. It must be <= ' + buffer.length + '. Received ' + (size + offset))

    randomBytes(size).copy(buffer, offset, 0, size)
}

const generateNonce = function (length) {
    const decoder = new StringDecoder("ascii");
    const buf = Buffer.alloc(length);
    let nonce = "";
    while (nonce.length < length) {
        randomFillSync(buf);
        nonce = decoder.write(buf);
    }
    return nonce.slice(0, length);
};

let unhashedAppleNonce = null;

export const createNonce = function () {
    const hashedNonce = generateNonce(10);
    // SHA256-hashed nonce in hex

    const hashedNonceHex = CryptoJS.SHA256(hashedNonce).toString(CryptoJS.enc.Hex);
    //const hashedNonceHex = createHash('sha256').update(hashedNonce).digest().toString('hex');
    const unhashedNonce = Buffer.from(hashedNonce).toString('base64');
    const resultado = {
        hashedNonce: hashedNonce,
        HASHED_NONCE: hashedNonceHex,
        unhashedNonce: unhashedNonce,
    };
    return resultado;
};

export const defineAppleNonce = function () {
    const nonce = createNonce();
    unhashedAppleNonce = Buffer.from(nonce.unhashedNonce, 'base64').toString('ascii');
    return nonce;
};

export async function setAppInstanceValue(llave, valor) {
    await SecureStore.setItemAsync(llave, valor);
}

export async function getAppInstanceValue(llave, defaultValue = null) {
    let returnValue = defaultValue;
    let oldValue = await SecureStore.getItemAsync(llave);
    if ([null, "", undefined].indexOf(oldValue) < 0) {
        returnValue = oldValue
    }
    return returnValue;
}

export async function getAppInstanceId() {
    const LLAVE_DEVICE_ID = 'secure_deviceid';
    const oldValue = await getAppInstanceValue(LLAVE_DEVICE_ID, null);
    if (oldValue == null) {
        const uuid = uuidv4();
        await setAppInstanceValue(LLAVE_DEVICE_ID, uuid);
        return uuid;
    }
    return oldValue;
}

const myFirebase = new Promise((resolve, reject) => {
    myLog("Creating global promise...");
    const apps = getApps();
    if (!apps.length) {
        app = initializeApp(firebaseConfig);
        initializeAuth(app, {
            persistence: getReactNativePersistence(AsyncStorage)
        });
        if (Platform.OS == "web") {
            myLog("Analytics - supported");
            analyticsResolve(getAnalytics(app));
        } else {
            // Initialize using 
            const promesaConfig = Config.getValue("config");
            promesaConfig.then((MY_CONFIG) => {
                // Debo resolver los parámetros de la invocación a analytics
                const params = {
                    custom: true,
                    firebase_app_id: null,
                    api_secret: null,
                };
                if (Platform.OS == "android") {
                    params.firebase_app_id = MY_CONFIG.ANAN;
                    if (typeof MY_CONFIG.ANANS == "string") {
                        params.api_secret = Buffer.from(MY_CONFIG.ANANS, 'base64').toString('ascii');
                    }
                } else if (Platform.OS == "ios") {
                    params.firebase_app_id = MY_CONFIG.ANIO;
                    if (typeof MY_CONFIG.ANIOS == "string") {
                        params.api_secret = Buffer.from(MY_CONFIG.ANIOS, 'base64').toString('ascii');
                    }
                }
                getAppInstanceId().then((app_instance_id) => {
                    app_instance_id = app_instance_id.replace(/-/g, "");
                    myLog(`app_instance_id=${app_instance_id}`);
                    params.app_instance_id = app_instance_id;
                    analyticsResolve(params);
                });
            });
        }
    } else {
        app = apps[0];
    }
    const auth = getAuth();
    const makeAppleLogin = (id_token) => {
        // Build Firebase credential with the Apple ID token.
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        const authCredential = provider.credential({
            idToken: id_token,
            rawNonce: unhashedAppleNonce,//this is global here
        });

        // Firebase: Duplicate credential received. Please try again with a new credential. (auth/missing-or-invalid-nonce).
        // Sign in with credential form the Apple user.
        signInWithCredential(auth, authCredential)
            .then((result) => {
                myLog("Signed In");
            })
            .catch((error) => {
                // An error occurred. If error.code == 'auth/missing-or-invalid-nonce',
                // make sure you're sending the SHA256-hashed nonce as a hex string
                // with your request to Apple.
                alert(error);
                myLog(error);
            });
    };
    const appleAuthListener = (event) => {
        const rootUrl = CONFIG.API.rootUrl;
        if (event.origin !== rootUrl) {
            return;
        }
        try {
            if (typeof event.data != "string" || !event.data.startsWith("{")) {
                return;
            }
            myLog(`Starting event listening with ${event.data}`);
            const parsed = JSON.parse(event.data);

            const tipado = (typeof parsed.unhashedNonce);
            if (tipado == "string") {
                unhashedAppleNonce = Buffer.from(parsed.unhashedNonce, 'base64').toString('ascii');
                myLog(`First event OK, unhashedAppleNonce=${unhashedAppleNonce}, waiting Second event...`);
                return;
            }

            myLog(`Processing Second event with unhashedAppleNonce=${unhashedAppleNonce}...`);
            makeAppleLogin(parsed.id_token);

        } catch (err) {
            myLog(err);
        }
    };
    if (Platform.OS == "web") {
        window.addEventListener("message", appleAuthListener, false);
    }
    const db = getFirestore(app);
    const payload = {
        user: null,
        userBack: null,
        emailVerified: null,
        displayName: null,
        photoURL: null,
        email: null,
        promesaBloquedUsers: null,
        addedBloquedUsers: [],
    };
    const listeners = [];

    let unsubscribeStateChanged = null;
    function fireListeners(payload, someListener = null) {
        if (payload.user != null) {
            payload.emailVerified = payload.user.emailVerified;
            payload.displayName = payload.user.displayName;
            payload.photoURL = payload.user.photoURL;
            payload.email = payload.user.email;
        }
        const copy = JSON.parse(JSON.stringify(payload));
        if (typeof someListener == "function") {
            someListener(copy);
        } else {
            for (let i = 0; i < listeners.length; i++) {
                listeners[i](copy);
            }
        }
    };

    async function segmentUser(payload) {
        try {
            const myAnalytics = await analyticsPromise;
            if (myAnalytics.custom !== true) {
                if (payload.user != null) {
                    myLog(`Analytics - setUserId ${payload.user.email}`);
                    setUserId(myAnalytics, payload.user.email);
                    if (payload.userBack != null) {
                        const properties = { donor_type: payload.userBack.donorType };
                        setUserProperties(myAnalytics, properties);
                    }
                } else {
                    myLog("Analytics - setUserId anonymous");
                    setUserId(myAnalytics, "anonymous@panal.co");
                }
            } else {
                if (payload.user != null) {
                    myAnalytics.user_id = payload.user.email;
                    myAnalytics.user_properties = {
                        donor_type: {
                            value: payload.userBack.donorType
                        }
                    };
                } else {
                    myAnalytics.user_id = "anonymous@panal.co";
                    myAnalytics.user_properties = {};
                }
                analyticsPromise = Promise.resolve(myAnalytics);
                MyAnalytics.setAnalytics(analyticsPromise);
            }
        } catch (err) {
            // what to do?
        }
    }

    function reloadUser() {
        myLog("Calling reloadUser() to generate a promise...");
        if (typeof unsubscribeStateChanged == "function") {
            unsubscribeStateChanged();
        }
        return new Promise((resolveUser, rejectUser) => {
            unsubscribeStateChanged = onAuthStateChanged(auth, async (user) => {
                myLog("onAuthStateChanged fired!");
                if (user) {
                    myLog("We Got User");
                    connectFirebase(user);
                    payload.user = user;
                    payload.userBack = await Users.getCurrentUser(user);
                    payload.emailVerified = user.emailVerified;
                    payload.displayName = user.displayName;
                    payload.photoURL = user.photoURL;
                    payload.email = user.email;
                    // Intencionalmente no se espera acá los usuarios bloqueados
                    payload.promesaBloquedUsers = fetchApiInternalRecursiveRetry("/users/listblocked", {}, null, 50);
                    payload.addedBloquedUsers = [];
                } else {
                    myLog("No user...");
                    // User is signed out
                    payload.user = null;
                    payload.userBack = null;
                    payload.emailVerified = null;
                    payload.displayName = null;
                    payload.photoURL = null;
                    payload.email = null;
                    payload.promesaBloquedUsers = null;
                    payload.addedBloquedUsers = [];
                }
                segmentUser(payload);
                fireListeners(payload);
                // unsubscribe();
                resolveUser(payload);
            });
        });
    };

    const promesaUsuario = reloadUser();

    function getOauthToken() {
        let token = null;
        if (payload.user) {
            token = payload.user.stsTokenManager.accessToken;
            // Se pide refrescar el token
            payload.user.getIdToken();
        }
        return token;
    }

    async function forceUpdateIdToken() {
        const response = await payload.user.getIdToken(true);
        await payload.user.reload();
        fireListeners(payload);
    }

    async function logout() {
        await signOut(auth);
    }

    async function emailVerification() {
        return new Promise((resolve) => {
            if (payload.user == null) {
                resolve(false);
                return;
            }
            sendEmailVerification(payload.user).then(() => {
                resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    }

    function addListener(funcion, callme = false) {
        const index = listeners.indexOf(funcion);
        if (index < 0) {
            listeners.push(funcion);
        }
        if (callme) {
            fireListeners(payload, funcion);
        }
    }

    function removeListener(funcion) {
        const index = listeners.indexOf(funcion);
        if (index >= 0) {
            listeners.splice(index, 1);
        }
    }

    function mergeBackUser(datos) {
        if ([null, undefined].indexOf(payload.userBack) >= 0) {
            payload.userBack = {};
        }
        Object.assign(payload.userBack, datos);
    }

    function addBloquedUser(email) {
        payload.addedBloquedUsers.push(email);
    }

    async function getBloquedUsers() {
        let respuesta = [];
        if (payload.promesaBloquedUsers != null) {
            const lista = await payload.promesaBloquedUsers;
            for (let i = 0; i < lista.length; i++) {
                const dato = lista[i];
                respuesta.push(dato.email);
            }
            respuesta = respuesta.concat(payload.addedBloquedUsers);
        }
        return respuesta;
    }

    myLog("General resolve");

    resolve({
        db: db,
        auth: auth,
        app: app,
        promesaUsuario: promesaUsuario,
        getOauthToken: getOauthToken,
        logout: logout,
        emailVerification: emailVerification,
        addListener: addListener,
        removeListener: removeListener,
        forceUpdateIdToken: forceUpdateIdToken,
        mergeBackUser: mergeBackUser,
        getBloquedUsers: getBloquedUsers,
        addBloquedUser: addBloquedUser,
        makeAppleLogin: makeAppleLogin,
    });
});

export default myFirebase;
