import React from "react";
import styled from "styled-components/native";
import MyShare from "../srv/MyShare";
import * as CssConstants from "./CssConstans";
import theme from "../Theme";
import {
    StyleSheet,
} from 'react-native';

class MyRichText
    extends React.Component {
    render() {
        const {
            alert,
            description
        } = this.props;

        let richTextRows = [<RichTextP key={"no_desc"}>Sin descripción</RichTextP>];
        const tam = 20;
        if (typeof description == "string" && !/^\s+$/.test(description)) {
            let rows = description.split(/(\n\r|\n|\r)/);
            rows = rows.filter((value) => { return !(/(\n)/.test(value)); });
            richTextRows = rows.reduce((acc1, el1, ix1) => {
                // Busco las columnas
                const palabras = el1.split(/\s+/);
                const columnas = palabras.reduce((acc2, el2, ix2) => {
                    let columna;
                    const columnKey = `col-${ix1}-${ix2}`;
                    if (/^https?:\/\/[^\s]+$/.test(el2)) {
                        const originalLink = el2;
                        const partes = [];
                        let i = 0;
                        do {
                            const pedazo = el2.substring(0, tam);
                            partes.push(<RichTextA key={`ped_${i}`} style={styles.underline}>{`${pedazo}...`}</RichTextA>);
                            el2 = el2.substring(tam);
                            i++;
                            break;
                        } while (el2.length > 0);

                        columna = <RichTextAClick key={columnKey} onPress={() => {
                            MyShare.openUrlOrMail(originalLink, alert);
                        }}>
                            {partes}
                        </RichTextAClick>;
                    } else {
                        columna = <RichTextP key={columnKey}>{el2 + " "}</RichTextP>;
                    }
                    acc2.push(columna);
                    return acc2;
                }, []);
                const fila = <RichTextP key={`desc-${ix1}`}>{columnas}</RichTextP>;
                acc1.push(fila);
                return acc1;
            }, []);
        }

        return <RichTextRenderer>{richTextRows}</RichTextRenderer>
    }
}

export default MyRichText;

const styles = StyleSheet.create({
    ///
    underline: {
        textDecorationLine: 'underline',
        textDecorationColor: theme.__link_blue,
    },
});

const RichTextRenderer = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 15px;
`;

const RichTextP = styled.Text`
    ${CssConstants.BodyLargeRegular}
    color: white;
`;

//    flex-wrap: wrap;
const RichTextAClick = styled.Pressable`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
`;

const RichTextA = styled.Text`
    ${CssConstants.BodyLargeSemibold}
    color: ${props => props.theme.__link_blue};
`;