import MyLog from '../srv/MyLog';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_1 = "usevotes"; // Ganadores ilimitados
const FIELD_KEY_NAME_2 = "numWin"; // Se valida acá

export default class WDonationWinnersScreenNew extends SelectScreen {
    static organicername = "";
    constructor(props) {
        super(props);
        this.state[FIELD_KEY_NAME_2] = this.props.route.params[FIELD_KEY_NAME_2];
        this.state.data[FIELD_KEY_NAME_2] = this.state[FIELD_KEY_NAME_2];
        this.state[FIELD_KEY_NAME_1] = this.props.route.params[FIELD_KEY_NAME_1];
        this.state.data[FIELD_KEY_NAME_1] = this.state[FIELD_KEY_NAME_1];
    }
    onSubmit(values) {
        //this.props.route.params[FIELD_KEY_NAME_2] = values[FIELD_KEY_NAME_2];
        this.props.route.params[FIELD_KEY_NAME_1] = values[FIELD_KEY_NAME_1];
        super.myLocalGoBack(this.props.route.params);
    }
    validate(values) {
        MyLog.log("validate", this);
        const errors = {};
        // Si usevotes, entonces validar que haya selecionado numWin
        if (!this.state.data[FIELD_KEY_NAME_1]) {
            const numWin = this.props.route.params[FIELD_KEY_NAME_2];
            if (typeof numWin != "number") {
                errors[FIELD_KEY_NAME_2] = "Debes seleccionar la cantidad de ganadores";
            }
        }
        const llaves = Object.keys(errors);
        if (llaves.length > 0) {
            this.props.alert(errors[llaves[0]]);
        }
        return errors;
    }
    render() {
        let params = this.props.route.params;
        const handleLocalNavigationThis = super.handleLocalNavigation.bind(this);
        if (!params) {
            params = {};
        }
        const listaOpciones = [
            {
                izq: {},
                der: {
                    type: "radio",
                    details: {
                        type: "switch",
                        label: "Ganadores ilimitados",
                        value: this.state[FIELD_KEY_NAME_1],
                        disabled: false,
                        onChange: (payload) => {
                            const nuevo = { data: this.state.data };
                            nuevo.data[FIELD_KEY_NAME_1] = payload.target.value;
                            nuevo[FIELD_KEY_NAME_1] = payload.target.value;
                            this.setState(nuevo);

                        },
                        name: FIELD_KEY_NAME_1,
                    }
                }
            }
        ];
        if (!this.state.data[FIELD_KEY_NAME_1]) {
            listaOpciones.push({
                izq: {},
                der: {
                    type: "page",
                    details: {
                        label: "Número de ganadores", 
                        //icon: "trophy",
                        name: FIELD_KEY_NAME_2,
                        currentValue: params[FIELD_KEY_NAME_2],
                        goToPage: () => {
                            handleLocalNavigationThis("WDonationWinnersPage");
                        }
                    },
                }
            });
        }
        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = "Los concursos te permiten seleccionar hasta 3 ganadores a través de una competencia de votos por 48 hrs. Sólo de estos ganadores puntuales puedes recibir reseñas para aumentar tu credibilidad.";
        return super.myRender(listaOpciones, instructions, onSubmit, validate);
    }
}