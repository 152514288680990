import { logEvent } from "firebase/analytics";
import { fetchApi } from "../srv/Http";
import MyLog from "../srv/MyLog";

export class MyAnalytics {
    static analyticsPromise = null;
    static setAnalytics(analyticsPromise) {
        MyAnalytics.analyticsPromise = analyticsPromise;
    }
    static async sendEvent(eventName, eventParams) {
        try {
            const promesas = [];
            MyLog.log("Sent logEvent... " + typeof MyAnalytics.analyticsPromise, this);
            const analytics = await MyAnalytics.analyticsPromise;
            if (analytics.custom !== true) {
                MyLog.log("Using traditional logEvent", this);
                // https://developers.google.com/gtagjs/reference/ga4-events#sign_up
                promesas.push(logEvent(analytics, eventName, eventParams));
                promesas.push(fetchApi(`/status/trace?&action=${eventName}`, eventParams, false));
            } else {
                MyLog.log("Using custom logEvent", this);
                const firebase_app_id = analytics.firebase_app_id;
                const api_secret = analytics.api_secret;
                const app_instance_id = analytics.app_instance_id;
                const user_id = analytics.user_id;

                try {
                    const session_id = new Number(`0x${app_instance_id}`).toExponential(18).replace(/e\+\d+$/, "").replace(/[.]/g, "");
                    eventParams.session_id = session_id;
                    eventParams.engagement_time_msec = "10000000";
                } catch (e) { }

                let url = `https://www.google-analytics.com/mp/collect?firebase_app_id=${firebase_app_id}&api_secret=${api_secret}`;
                //MyLog.log("Event:" + eventName + ":" + url + "...[" + app_instance_id + "]..." + JSON.stringify(eventParams), this);
                promesas.push(fetch(url, {
                    method: "POST",
                    body: JSON.stringify({
                        app_instance_id: app_instance_id,
                        user_id: user_id,
                        events: [{
                            name: eventName,
                            params: eventParams,
                        }]
                    })
                }));
                promesas.push(fetchApi(`/status/trace?&action=${eventName}`, eventParams, false));
                //    .then((data) => MyLog.log("Hey" + JSON.stringify(data), this));
            }
            await Promise.all(promesas);
        } catch (err) {
            MyLog.log(err.message, this);
        }
    }
}