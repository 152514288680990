import React from "react";
import styled from "styled-components/native";
import AssetsImg from "../../AssetsImg";

import Constants from "../../Constants"
import * as CssConstants from "../../widgets/CssConstans";
import TopDetail0 from "./TopDetail0";


export default class TopDetail1 extends React.Component {
    render() {
        const {
            donation,
        } = this.props.details;
        return (
            <AutoLayoutVertical>
                <AutoLayoutVertical1>
                    <AutoLayoutVertical2>
                        <AsesoraEnFotografaParaCampaas>{donation.title}</AsesoraEnFotografaParaCampaas>
                        <AsegrateDeVotarP>
                            Asegúrate de votar por el emprendedor que deseas que se gane esta donación.
                        </AsegrateDeVotarP>
                    </AutoLayoutVertical2>
                </AutoLayoutVertical1>
                <TopDetail0 details={this.props.details}></TopDetail0>
            </AutoLayoutVertical>
        );
    }
}

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
`;

const AutoLayoutVertical1 = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-bottom: 25px;
`;

const AutoLayoutVertical2 = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`;

const AsesoraEnFotografaParaCampaas = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H6Bold}
    align-self: stretch;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 21.6px;
    margin-bottom: 8px;
`;

const AsegrateDeVotarP = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumRegular}
    align-self: stretch;
    font-weight: 400;
    color: ${props => props.theme.__greyscale__400};
    text-align: center;
    line-height: 19.6px;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
`;

const ContainerDetail = styled.View`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
`;

const MainNumber = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H4Bold}
    align-self: stretch;
    margin-top: -1px;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 28.8px;
    margin-bottom: 4px;
`;

const DescriptionNumber = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumRegular}
    align-self: stretch;
    font-weight: 400;
    color: ${props => props.theme.__greyscale__400};
    text-align: center;
    line-height: 19.6px;
`;

const Divider = styled.View`
    align-self: stretch;
    width: 1px;
    height: 100%;
    background-color: ${props => props.theme.__dark__dark_3};
`;