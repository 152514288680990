import React, { Component } from 'react'
import { MountedComponent } from "../utils/MountedComponent";
import XPageDetail1 from '../screens/donationdetail/XPageDetail1';
import styled from "styled-components/native";
import Config from "../utils/Config"
import { Users } from '../srv/Users';
import { DonacionesSrv } from '../srv/DonacionesSrv';
import MyShare from '../srv/MyShare';
import MyLog from '../srv/MyLog';
import TheClose from '../screens/svg/TheClose';
import myFirebase from '../config/firebase';
import RealTime from '../srv/RealTime';
import Constants from 'expo-constants';
import { Keyboard, KeyboardAvoidingView, Platform } from 'react-native';
import CONFIG from '../Constants';
import md5 from 'md5';

const OFFSET_VIEW_Y = -20;

export default class WDonationDetailScreen extends MountedComponent {
    constructor(props) {
        super(props);
        MyLog.log(`Constructor`, this);
        const params = this.props.route.params;
        const index = params?.index;
        this.state = Object.assign(this.state, {
            user: null,
            userBack: null,
            currentIndex: index,
            isTypingMessage: false,
        });
    }
    async onShowAndUser() {
        MyLog.log("onShow", this);
        const params = this.props.route.params;
        this.siNoHayVisiblesRegresar();
        const self = this;
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
            const keyBoardHeight = e.endCoordinates.height;
            self.setScrollEnabled(false);
            self.posicionarScroll(self.scrollReference, null, keyBoardHeight + OFFSET_VIEW_Y);
        });
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
            self.setScrollEnabled(true);
            self.posicionarScroll(self.scrollReference, null, -1);
        });

    }
    async onLeave() {
        MyLog.log("onLeave", this);
        Keyboard.removeAllListeners("keyboardDidShow");
        Keyboard.removeAllListeners("keyboardDidHide");
    }
    async onUser(payloadUser) {
        this.setState({ user: payloadUser.user, userBack: payloadUser.userBack });
    }
    backAction() {
        this.backOrGoHome();
    }
    setScrollEnabled(value) {
        this.setState({ isScrollEnable: value });
        if (value === false) {
            // snap to grid fixme
            this.posicionadoOk = false;
            this.posicionarScroll(this.scrollReference, this.state.halfIndex);
        }
    }
    getScrollReference() {
        return this.scrollReference;
    }
    goToPersonSpace(payload) {
        const params = payload;
        this.props.navigation.push('ProfilePage', params);
    }
    async rejectAction(donation, lastAction, beforeAction) {
        // TODO borrar
        // Ya no se van a rechazar las donaciones
        /*
        if (!(await this.alertLogedUser())) {
            return;
        }
        let information = {
            donation: donation,
        };
        const promesa = DonacionesSrv.apply(information.donation.id, false, this.props.alert);
        this.props.loading(promesa);
        const respuesta = await promesa;
        if (respuesta == null) {
            return;
        }
        if (typeof beforeAction == "function") {
            await beforeAction();
        }
        donation.rel = respuesta.relacion;
        if (typeof lastAction == "function") {
            await lastAction();
        }
        this.desaparecerDonacion();
        */
    }
    async acceptAction(donation, lastAction, beforeAction) {
        if (!(await this.alertLogedUser(false, "Para participar o votar debes registrarte"))) {
            return;
        }
        const promesaOtherUser = this.promesasOtherUser[donation.id];
        if ([undefined, null].indexOf(promesaOtherUser) >= 0) {
            const params = this.props.route.params;
            const donations = params.donations;
            return;
        }
        if (typeof beforeAction == "function") {
            const promesa1 = beforeAction();
            this.props.loading(promesa1);
            await promesa1;
        }
        this.props.loading(promesaOtherUser);
        await promesaOtherUser;
        let information = {
            otherUser: donation.otherUser,
            donation: donation,
            mode: 1,// 1: I appear in the top
        };
        const promesa = DonacionesSrv.apply(information.donation.id, true, this.props.alert);
        this.props.loading(promesa);
        const respuesta = await promesa;
        if (respuesta == null) {
            return;
        }
        donation.rel = respuesta.relacion;
        if (typeof lastAction == "function") {
            const promesa2 = lastAction();
            this.props.loading(promesa2);
            await promesa2;
        }
    }
    siNoHayVisiblesRegresar() {
        /*
        const params = this.props.route.params;
        const vieneDeDetalle = ([
            "MyAcceptedScreen",
            "NotificationsScreen",
            "ProfilePage"
        ].indexOf(params.origin) >= 0);
        let donacionesVisibles = 0;

        if (vieneDeDetalle) {
            donacionesVisibles = params.donations.length;
        } else {
            for (let i = 0; i < params.donations.length; i++) {
                const donacion = params.donations[i];
                if (!(donacion.rel?.donation_id)) {
                    donacionesVisibles++;
                }
            }
        }

        if (donacionesVisibles == 0) {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
            return true;
        }
        */
        return false;
    }
    desaparecerDonacion(autoHome = true) {
        if (autoHome) {
            const regreso = this.siNoHayVisiblesRegresar();
            if (!regreso) {
                this.setState({ fecha: new Date().getTime() });
            }
        }
    }
    async seeVotes(donation) {
        await this.promesasOtherUser[donation.id];
        let information = {
            otherUser: donation.otherUser,
            donation: donation,
            mode: 0, // 0: No user preffered
        };
        information = JSON.parse(JSON.stringify(information));
        this.props.navigation.navigate("VotePage", information);
    }
    async setStars(donation, myRate) {
        // Not used any more here...
    }
    async openDonationMenu(donation) {
        if (!(await this.alertLogedUser())) {
            return;
        }
        const props = this.props;
        const confirm = super.confirm.bind(this);
        return new Promise((resolve) => {
            let name = (donation.otherUser?.name);
            if (!(typeof name == "string")) {
                name = "el usuario";
            } else {
                name = "a " + name;
            }
            function confirmarHacer(mensaje, miFuncion) {
                setTimeout(async () => {
                    const desicion = await confirm({ message: mensaje });
                    if (desicion) {
                        MyLog.log("Execute!");
                        if (typeof miFuncion == "function") {
                            miFuncion();
                        }
                    }
                }, 0);
            }
            props.alertOpen({
                title: "Opciones de esta publicación",
                description: "Selecciona una opción",
                buttons: [
                    {
                        label: "Reportar publicación como inapropiada",
                        type: "secondary",
                        onPress: async () => {
                            // Mandar correo a info@panal.co con el detalle de:
                            // - correo del denunciante
                            // - enlace de la publicación
                            resolve(false);
                            confirmarHacer(`¿Confirma que deseas reportar la publicación como inapropiada para que nuestros moderadores la evaluen?`, async () => {
                                const promesa = DonacionesSrv.reportInapropiate(donation.id, "donacion", props.alert);
                                props.loading(promesa);
                                const response = await promesa;
                                if (response != null) {
                                    props.alert("Gracias por tu reporte", "Listo!");
                                }
                            });
                        }
                    },
                    {
                        label: `Reportar ${name} como inapropiado`,
                        type: "secondary",
                        onPress: async () => {
                            // Mandar correo a info@panal.co con el detalle de:
                            // - correo del denunciante
                            // - enlace de la publicación
                            resolve(false);
                            confirmarHacer(`¿Confirma que deseas reportar ${name} como inapropiado para que nuestros moderadores lo evaluen?`, async () => {
                                const promesa = DonacionesSrv.reportInapropiate(donation.id, "usuario", props.alert);
                                props.loading(promesa);
                                const response = await promesa;
                                if (response != null) {
                                    props.alert("Gracias por tu reporte", "Listo!");
                                }
                            });
                        }
                    },
                    {
                        label: "Bloquear y rechazar la publicación",
                        type: "secondary",
                        onPress: async () => {
                            // Es lo mismo que rechazar la publicación, pero además le puede agregar una bandera: block: true
                            resolve(false);
                            confirmarHacer(`¿Confirma que deseas bloquear y rechazar la publicación?`, async () => {
                                const promesa = DonacionesSrv.blockDonation(donation.id, props.alert);
                                props.loading(promesa);
                                const response = await promesa;
                                donation.rel = response.relacion;
                                this.desaparecerDonacion();
                                if (response != null) {
                                    props.alert("Se ha bloqueado la publicación", "Listo!");
                                }
                            });
                        }
                    },
                    {
                        label: `Bloquear ${name}`,
                        type: "secondary",
                        onPress: async () => {
                            // Debo tener una lista de usuarios bloqueados para filtrar las donaciones de dicho usuario
                            resolve(false);
                            confirmarHacer(`¿Confirma que deseas bloquear ${name}?`, async () => {
                                try {
                                    const email = donation.id.replace(/[-_]\d{8}[-_]\d{6}[-_]\d{3}$/ig, "");
                                    const promesa = Users.blockUser(email, props.alert);
                                    props.loading(promesa);
                                    await promesa;
                                    const firebaseRef = await myFirebase;
                                    firebaseRef.addBloquedUser(email);
                                    RealTime.get().refreshDonations();
                                    props.alert(`Has bloqueado a ${email}`, "Listo!");

                                } catch (e) {
                                    // No hacer nada, ya se hizo el alert.
                                }
                            });
                        }
                    },
                    {
                        label: "Cancelar",
                        type: "secondary",
                        onPress: async () => {
                            resolve(false);
                        }
                    },
                ]
            });
        });
    }
    computeResenias(donation) {
        const response = [];
        const llaves = Object.keys(donation);
        const starKeys = llaves.filter((value, index) => {
            return /^starsx_/.test(value);
        });
        for (let j = 0; j < starKeys.length; j++) {
            const oneStarKey = starKeys[j];
            const oneRating = donation[oneStarKey];
            response.push(oneRating);
        }
        return response;
    }
    processOneDonation(donation, index, realIndex) {
        //console.log(JSON.stringify(donation, null, 4));
        const params = this.props.route.params;
        let emailActual = this.state.user?.email;
        if (!emailActual && params.currentUserEmail) {
            // En el caso que no se haya cargado el usuario, pero venga por parámetro
            // Se tomará el usuario por parámetro
            emailActual = params.currentUserEmail;
        }
        const backAction = this.backAction.bind(this);
        const setScrollEnabled = this.setScrollEnabled.bind(this);
        const getScrollReferenceThis = this.getScrollReference.bind(this);
        const posicionarScrollThis = this.posicionarScroll.bind(this);
        const seeVotes = this.seeVotes.bind(this);
        const setStars = this.setStars.bind(this);
        const openDonationMenuThis = this.openDonationMenu.bind(this);
        const goToPersonSpaceThis = this.goToPersonSpace.bind(this);
        const acceptActionThis = this.acceptAction.bind(this);

        // Esto permite que una donación no sea visible
        if (["MyAcceptedScreen", "MyHomeScreen"].indexOf(params.origin) >= 0) {
            // Viene desde las aceptadas
            if (donation.rel?.donation_id) {
                // Ha tomado una decisión de rechazo
                if (donation.rel.desition === false) {
                    return;
                }
            }
        }

        const details = {
            setScrollEnabled: setScrollEnabled,
            getScrollReference: getScrollReferenceThis,
            posicionarScroll: posicionarScrollThis,
            top: {
                right: {
                    //icon: "square-xmark",
                    svgIcon: <TheClose></TheClose>,
                    action: backAction,
                },
                text: Config.getCategoryValue(donation.category),
            },
            backImage: donation.img,
            middle: {
                goToPersonSpace: () => {
                    goToPersonSpaceThis(donation.otherUser);
                },
                showTime: this.showTime,
                canParticipate: (donation.owner != emailActual),
                title: donation.title,
                description: donation.description,
                end: donation.end,
                numWin: donation.numWin,
                isfree: donation.isfree,
                price: donation.price,
                type: donation.type,
                virtual: donation.virtual,
                authorEmail: donation.owner,
                author: donation.otherUser?.name,
                avatarImage: donation.otherUser?.picture,
                donorType: donation.otherUser?.donorType,
                profile: donation.otherUser?.bio,
                done: donation.done,
                won: donation.rel?.won,
                desition: donation.rel?.desition,
                organicername: donation.organicername,
                organicerme: donation.organicerme,
                resenias: this.computeResenias(donation),
                isAdmin: this.isAdmin(),
                donationMenu: async () => {
                    openDonationMenuThis(donation);
                },
                setPromo: async (number) => {
                    const promesa = DonacionesSrv.setPromo(donation.id, number, this.props.alert);
                    this.props.loading(promesa);
                    try {
                        await promesa;
                        this.props.alert("Ok!", "Listo!");
                    } catch (err) {
                        this.props.alert(err.message);
                    }
                },
                verReporte: async () => {
                    let text = "eleccion;votante;\n";
                    const promesa = DonacionesSrv.seeVotes(donation.id, this.props.alert);
                    this.props.loading(promesa);
                    const data = await promesa;
                    for (let i = 0; i < data.length; i++) {
                        const actual = data[i];
                        text += `${actual.eleccion};${actual.votante}\n`;
                    }
                    let url = 'data:application/csv;charset=utf-8,' + encodeURIComponent(text);
                    window.open(url, '_blank');

                    // Acá se saca el reporte agrupado
                    const filas = text.split("\n");
                    const mapa = {};
                    for (let i = 1; i < filas.length; i++) {
                        const actual = filas[i];
                        const partes = actual.split(";");
                        if (partes.length >= 2) {
                            const elegido = partes[0].toLocaleLowerCase();
                            if (!(elegido in mapa)) {
                                mapa[elegido] = 0;
                            }
                            mapa[elegido] += 1;
                        }
                    }

                    const top = [];
                    for (let email in mapa) {
                        const votos = mapa[email];
                        top.push({ email: email, votos: votos });
                    }
                    top.sort((a, b) => {
                        return b.votos - a.votos;
                    });
                    let texto = "email;votos;\n";
                    for (let i = 0; i < top.length; i++) {
                        const actual = top[i];
                        texto += `${actual.email};${actual.votos}\n`;
                    }
                    url = 'data:application/csv;charset=utf-8,' + encodeURIComponent(texto);
                    window.open(url, '_blank');
                },
                forceExpire: async (block = false) => {
                    const desicion = await this.confirm({ message: "Por favor confirmar, no se puede deshacer" });
                    if (desicion) {
                        MyLog.log(`Expire with block=${block}`);
                        const promesa = DonacionesSrv.forceExpire(donation.id, block, this.props.alert);
                        this.props.loading(promesa);
                        try {
                            await promesa;
                            let fixed = [];
                            do {
                                let promesaFix = DonacionesSrv.fixForceExpire(donation.id, this.props.alert);
                                this.props.loading(promesaFix);
                                fixed = await promesaFix;
                            } while (fixed.length > 0);
                            this.props.alert("Ok!", "Listo!");
                        } catch (err) {
                            this.props.alert(err.message);
                        }
                    }
                },
                setAdvertising: async () => {
                    const promesa = DonacionesSrv.setAdvertising(donation.id, this.props.alert);
                    this.props.loading(promesa);
                    try {
                        const response = await promesa;
                        this.props.alert(JSON.stringify(response), "Listo!");
                    } catch (err) {

                    }
                }
            },
            button1: {
                rejectAction: async (lastAction, beforeAction) => {
                    // No se usa ...
                    // Esta opción es a la izquierda
                },
                acceptAction: async (lastAction, beforeAction) => {
                    // Esta opción es a la derecha
                    await acceptActionThis(donation, lastAction, beforeAction);

                },
                setStars: async (myRate) => {
                    if (!(await this.alertLogedUser())) {
                        return;
                    }
                    const userMd5 = md5(this.state.user.email);
                    const keyRating = `starsx_${userMd5}`;
                    if ([null, undefined].indexOf(donation[keyRating]) < 0) {
                        // Ya puso su rating!
                    } else {
                        setStars(donation, myRate);
                    }
                },
                won: donation.rel?.won,
                stars: donation.rel?.stars,
            },
            button2: {
                type: donation.type,
                linkMore: donation.linkMore,
                donation: donation,
                email: donation.otherUser?.email,
                won: donation.rel?.won,
                onFocusMessage: () => {
                    this.setState({ isTypingMessage: true });
                },
                onBlurMessage: () => {
                    this.setState({ isTypingMessage: false });
                },
                starAction: async () => {
                    await seeVotes(donation);
                },
                shareAction: async () => {
                    await MyShare.share(donation.id, this.props.alert);
                },
                donationMenu: async () => {
                    openDonationMenuThis(donation);
                }
            },
        };

        if (donation.type == CONFIG.LISTS.DONATION.TYPE.SRV_VIRT) {
            details.middle.theTime = donation.dateStartMeet;
        } else if (donation.type == CONFIG.LISTS.DONATION.TYPE.SRV_PRE) {
            details.middle.theTime = donation.dateStartMeet;
            details.middle.theCity = donation.city;
            details.middle.address = donation.address;
        } else if (donation.type == CONFIG.LISTS.DONATION.TYPE.PRODUCTO) {
            details.middle.theTime = donation.pickTime;
            details.middle.theCity = donation.city;
            details.middle.address = donation.address;
        } else if ([CONFIG.LISTS.DONATION.TYPE.DONACION, CONFIG.LISTS.DONATION.TYPE.REPOST].indexOf(donation.type) >= 0) {
            details.middle.theTime = donation.dateStartMeet;
            if (!donation.virtual) {
                details.middle.theCity = donation.city;
                details.middle.address = donation.address;
            } else {
                details.middle.linkMeet = donation.linkMeet;
            }
        }

        // Debo mirar para la persona logeada, qué notificación se le debe mostrar encima
        let notification = null;
        let modalInFront = null;
        if (this.state.user && donation.modal && this.state.user.email in donation.modal) {
            notification = donation.modal[this.state.user.email];
        }

        if (notification) {
            modalInFront = this.props.popUpNotification(notification, true);
        }
        return <XPageDetail1
            mountedComponent={this}
            outherProps={this.props}
            key={index}
            myIndex={realIndex}
            currentIndex={this.state.currentIndex}
            windowSize={{
                width: this.state.width,
                height: this.getRealHeight(),
            }}
            navigation={this.props.navigation}
            loading={this.props.loading}
            alert={this.props.alert}
            details={details}
            isAdmin={this.isAdmin()}
            currentUser={this.state.user}
            origin={params.origin}
            goToPersonSpace={goToPersonSpaceThis}
            modalInFront={modalInFront}
        />;
    }
    getRealHeight() {
        if (Platform.OS == "ios") {
            return this.state.height - Constants.statusBarHeight;
        } else {
            return this.state.height;
        }
    }
    posicionarScroll(scrollReference, outerIndex = null, extraOffsetY = 0) {
        const params = this.props.route.params;
        let index = params.index;
        if (typeof outerIndex == 'number') {
            index = outerIndex;
        }
        this.completarUsuario(index);
        if (!this.posicionadoOk || extraOffsetY != 0) {
            this.posicionadoOk = true;
            this.setState({ currentIndex: index, halfIndex: index });
            const offsetY = index * this.getRealHeight();
            //MyLog.log(`index ${index} this.getRealHeight() ${this.getRealHeight()} offsetY ${offsetY}`);
            setTimeout(() => {
                try {
                    scrollReference.scrollTo({
                        x: 0,
                        y: offsetY + extraOffsetY,
                        animated: false,
                    });
                } catch (err) { }
            });
        }
    }
    donationHasDesition(donation) {
        if (donation.rel) {
            if ([true, false].indexOf(donation.rel.desition) >= 0) {
                return true;
            }
        }
        return false;
    }
    donationHasRejectedDesition(donation) {
        if (donation.rel) {
            if ([false].indexOf(donation.rel.desition) >= 0) {
                return true;
            }
        }
        return false;
    }
    getFixedIndex(indice) {
        const rutaActual = super.getCurrentState();
        if (["WDonationDetailPage"].indexOf(rutaActual.name) < 0) {
            return indice;
        }
        let counterNoDesition = 0;
        let counterWithDesition = 0;
        const params = this.props.route.params;
        const donations = params.donations;
        for (let i = 0; i < donations.length; i++) {
            const currentDonation = donations[i];
            const hasDesition = this.donationHasRejectedDesition(currentDonation);
            if (hasDesition) {
                counterWithDesition++;
            } else {
                if (counterNoDesition == indice) {
                    return i;
                }
                counterNoDesition++;
            }
        }
        return -1;
    }
    handleScroll(event) {
        const realHeight = this.getRealHeight();
        const scrollY = event.nativeEvent.contentOffset.y;
        const indice = Math.floor(scrollY / realHeight);
        const halfIndex = Math.floor((scrollY + 0.5 * realHeight) / realHeight);
        this.setState({ currentIndex: indice, halfIndex });
        this.completarUsuario(indice);
        this.completarUsuario(indice - 1);
        this.completarUsuario(indice + 1);
    }
    async cargarUsuario(donation) {
        return new Promise((resolve, reject) => {
            Users.readUser(donation.owner, this.props.alert).then((someUser) => {
                donation.otherUser = { ...donation.otherUser, ...someUser };
                this.setState({ fecha: new Date().getTime() });
                resolve();
            }).catch((error) => {
                console.log(error);
            });
        });
    }
    async completarUsuario(indiceInput) {
        const indice = this.getFixedIndex(indiceInput);
        //MyLog.log(`completarUsuario(${indiceInput}) fixedIndex: ${indice}`, this);
        const params = this.props.route.params;
        const donations = params.donations;
        if (!this.promesasOtherUser) {
            this.promesasOtherUser = {};
        }
        if (indice < 0 || indice >= donations.length) {
            return;
        }
        const donacion = donations[indice];
        const llave = donacion.id;
        if (!(llave in this.promesasOtherUser)) {
            this.promesasOtherUser[llave] = this.cargarUsuario(donacion);
        }
    }
    render() {
        const params = this.props.route.params;
        const donations = params.donations;
        const handleScroll = this.handleScroll.bind(this);

        let donacionesHtml = null;
        if (donations instanceof Array) {
            let realIndex = 0;
            donacionesHtml = donations.map((donacionIterada, index) => {
                const currentElement = this.processOneDonation(donacionIterada, index, realIndex);
                if (currentElement !== undefined) {
                    realIndex++;
                }
                return currentElement;
            });
        }
        const isTypingMessage = this.state.isTypingMessage;
        const messageStyleHelp = {
            position: "relative",
        };
        if (isTypingMessage && Platform.OS !== 'web') {
            // El problema de que no se ve el campo de texto
            // solo ocurre en movil y por eso no aplica para web
            messageStyleHelp.top = OFFSET_VIEW_Y;
        }
        return (
            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                style={{ position: "relative", flex: 1 }}
            >
                <ScrollContainer
                    style={messageStyleHelp}
                    ref={(scrollReference) => {
                        this.scrollReference = scrollReference;
                        this.posicionarScroll(scrollReference);
                    }}
                    scrollEventThrottle={500}
                    onScroll={handleScroll}
                    decelerationRate={0}
                    scrollEnabled={this.state.isScrollEnable}
                    snapToInterval={this.getRealHeight()} //your element width
                    snapToAlignment={"center"}
                >
                    {donacionesHtml != null && donacionesHtml}
                </ScrollContainer>
            </KeyboardAvoidingView>
        )
    }
}

const ScrollContainer = styled.ScrollView`
    display: flex;
    width: 100%;
    height: 100%;
`;
