import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { Component } from 'react'
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native';
import styled from "styled-components/native";
import AssetsImg from '../AssetsImg';
import { fetchApi } from '../srv/Http';
import MyLog from '../srv/MyLog';
import RealTime from '../srv/RealTime';
import theme from '../Theme';
import { MountedComponent } from '../utils/MountedComponent';
import { MyDates } from '../utils/MyDates';
import { BodyLargeRegular, BodyMediumRegular, H4Bold, ValignTextBottom, ValignTextMiddle } from '../widgets/CssConstans';
import MyInput from '../widgets/MyInput';
import * as CssConstants from "../widgets/CssConstans";
import { MyThrottle } from '../utils/MyThrottle';
import OutlineArrowLeft from '../screens/svg/OutlineArrowLeft';
import MyRichText from '../widgets/MyRichText';
import Config from '../utils/Config';
import { MyFileService } from '../utils/MyFileService';
import * as ImagePicker from 'expo-image-picker';
import { GroupsSrv } from '../srv/GroupsSrv';
import MyShare from '../srv/MyShare';

const MAX_MESSAGES_READ = 100;
const MAX_CHARS = 500;

/*
PUSH: recibe {email, name} de la otra persona con la quiere chatear.
*/

class MessengerScreen extends MountedComponent {
    constructor(props) {
        super(props);
        MyLog.log(`Constructor`, this);
        this.scrollMessagemap = {};
        this.showTime = new Date().getTime();
        const email = this.props.route.params.email;
        const name = this.props.route.params.name;
        if (!email) {
            setTimeout(() => {
                this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'MyHomePage' }],
                });
            }, 0);
        }
        this.isGroup = /group:\d{14}_/.test(email);
        this.state = Object.assign(this.state, {
            otherUser: {
                name: name,
                email: email,
            },
            messages: [],
            adjuntarRespuesta: null,
        });
        if (this.props.route.params.automessage) {
            setTimeout(() => {
                this.writeMessage(this.props.route.params.automessage);
            }, 0);
        }
    }
    async onUser(payloadUser) {
        if (payloadUser.user === null) {
            // Redirect to home
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        }
    }
    async onShowAndUser() {
        MyLog.log("onShow", this);
        const currentEmail = this.state.user?.email;
        const otherEmail = this.state.otherUser?.email;
        if (!currentEmail || !otherEmail) {
            return;
        }
        const reloadNewMessagesThis = this.reloadNewMessages.bind(this);
        if (this.isGroup) {
            //this.unsubscribreListener = await RealTime.get().listenGroupMessengerConversation(currentEmail, otherEmail, reloadNewMessagesThis);
            reloadNewMessagesThis();
        } else {
            this.unsubscribreListener = await RealTime.get().listenPersonMessengerConversation(currentEmail, otherEmail, reloadNewMessagesThis);
        }
    }
    async writeMessage(text) {
        const currentEmail = this.state.user?.email;
        const otherEmail = this.state.otherUser?.email;
        if (!currentEmail || !otherEmail) {
            return false;
        }

        if (!(typeof text == "string") || text.trim().length == 0) {
            this.props.alert("Escribe el mensaje que deseas enviar");
            return false;
        }
        if (text.length > MAX_CHARS) {
            this.props.alert(`Máximo puedes enviar ${MAX_CHARS} caracteres, actualmente tienes ${text.length}.`);
            return false;
        }
        const payload = {
            to: this.state.otherUser.email,
            msg: {
                txt: text,
            }
        };

        if (this.state.adjuntarRespuesta != null) {
            payload.msg.reply = this.getInformationFromSubMessage(this.state.adjuntarRespuesta);
        }

        const subUrl = "/msg/w";
        const promesa = fetchApi(subUrl, payload, false, this.props.alert);
        this.props.loading(promesa);
        await promesa;
        if (this.state.adjuntarRespuesta != null) {
            this.setState({
                adjuntarRespuesta: null,
            });
        }
        if (this.isGroup) {
            await this.reloadNewMessages();
        }
        return true;
    }
    async reloadOlderMessages() {
        try {
            // Se debe buscar la mínima fecha
            const messages = this.state.messages;
            const minDate = messages.reduce((acc, elem, index) => {
                if (acc == null || elem.date < acc) {
                    acc = elem.date;
                }
                return acc;
            }, null);
            const subUrl = "/msg/r";
            const payload = {
                to: this.state.otherUser.email,
                max: MAX_MESSAGES_READ,
            };
            payload.offset = minDate;
            const promesa = fetchApi(subUrl, payload, false, this.props.alert);
            this.props.loading(promesa);
            const response = await promesa;
            const lista = response?.compacted?.msg;
            if (lista instanceof Array) {
                this.agregarMensajes(lista);
            }
        } catch (err) {
            MyLog.log(err);
        }
    }
    async reloadNewMessages() {
        if (this.intervalRefresh !== undefined) {
            clearTimeout(this.intervalRefresh);
        }
        try {
            // Se debe buscar la máxima fecha
            const messages = this.state.messages;
            const maxDate = messages.reduce((acc, elem, index) => {
                if (acc == null || elem.date > acc) {
                    acc = elem.date;
                }
                return acc;
            }, null);

            const subUrl = "/msg/r";
            const payload = {
                to: this.state.otherUser.email,
                max: MAX_MESSAGES_READ,
            };
            let response = null;
            let promesa = null;
            if (maxDate == null) {
                // No hay mensajes, se debe leer todo
                promesa = fetchApi(subUrl, payload, false, this.props.alert);
                if (!this.isGroup) {
                    this.props.loading(promesa);
                }
                response = await promesa;
            } else {
                // Luego invocar el servicio a partir de la máxima fecha
                payload.offset = maxDate;
                payload.isnews = true;
                promesa = fetchApi(subUrl, payload, false, this.props.alert);
                if (!this.isGroup) {
                    this.props.loading(promesa);
                }
                response = await promesa;
            }
            const lista = response?.compacted?.msg;
            if (lista instanceof Array) {
                this.agregarMensajes(lista);
                if (this.scrollView) {
                    setTimeout(() => {
                        this.scrollView.scrollToEnd({ animated: false });
                    });
                }
            }

        } catch (err) {
            MyLog.log(err);
        }
        if (this.isGroup) {
            // El refresco periodico solo se activa para grupos
            const reloadNewMessagesThis = this.reloadNewMessages.bind(this);
            this.intervalRefresh = setTimeout(reloadNewMessagesThis, 10 * 1000);
        }
    }
    async onLeave() {
        MyLog.log("onLeave", this);
        if (typeof this.unsubscribreListener == "function") {
            this.unsubscribreListener();
        }
        if (this.intervalRefresh !== undefined) {
            clearTimeout(this.intervalRefresh);
        }
    }
    agregarMensajes(lista) {
        const messages = this.state.messages;
        const soloIds = messages.reduce((acc, elem, index) => {
            acc.push(elem.id);
            return acc;
        }, []);
        for (let i = 0; i < lista.length; i++) {
            const actual = lista[i];
            if (soloIds.indexOf(actual.id) < 0) {
                messages.push(
                    {
                        who: actual.who,
                        whoName: actual.whoName,
                        whoPicture: actual.whoPicture,
                        txt: actual.txt,
                        att: actual.att,
                        reply: actual.reply,
                        date: actual.d,
                        id: actual.id,
                    }
                );
            }
        }
        this.setState({ messages: messages });
    }
    backAction() {
        this.backOrGoHome();
    }
    goToProfile(payload) {
        this.props.navigation.push('ProfilePage', payload);
    }
    async goToPersonSpace(params) {
        this.props.navigation.push('MessengerPage', params);
    }
    popUpUserMenu(payload) {
        const user = {
            email: payload.who,
            name: payload.whoName,
            picture: payload.whoPicture,
        };
        this.props.alertOpen({
            title: "Opciones de usuario",
            description: "Selecciona una opción",
            buttons: [
                {
                    label: `Ver perfil de ${user.name}`,
                    type: "secondary",
                    onPress: async () => {
                        //console.log(`Ver perfil de ${user.email}`);
                        this.goToProfile(user);
                    }
                },
                {
                    label: `Chat con ${user.name}`,
                    type: "primary",
                    onPress: async () => {
                        //console.log(`Chat con ${user.email}`);
                        this.goToPersonSpace(user);
                    }
                },
                {
                    label: "Cancelar",
                    type: "secondary",
                    onPress: async () => {
                        // Do nothing
                    }
                },
            ]
        });
    }
    definirAdjuntoRespuesta(respuesta) {
        this.setState({
            adjuntarRespuesta: respuesta,
        });
    }
    getInformationFromSubMessage(message) {
        const corta = {
            name: message.whoName,
            txt: "",
            id: message.id
        };
        if (message.who == "me") {
            corta.who = this.state.user.email;
        } else {
            corta.who = message.who;
        }
        if (typeof message.txt == "string") {
            corta.txt = message.txt.substring(0, 60);
        }
        return corta;
    }
    scrollToMessage(message) {
        //console.log(JSON.stringify(message, null, 4));
        const messageLayout = this.scrollMessagemap[message.id];
        //console.log(JSON.stringify(messageLayout, null, 4));
        if (messageLayout) {
            this.scrollView.scrollTo({
                x: 0,
                y: messageLayout.y,
                animated: true,
            });
        }
    }
    renderSubMessage(message, definirAdjuntoRespuestaThis) {
        const corta = this.getInformationFromSubMessage(message);
        return <MeMessageContainerSub
            style={{ marginBottom: 10 }}
            background={theme.__dark__dark_2}
            radiusLeft={"12px"}
            radiusRight={"12px"}
        >
            <ColumnDetailView>
                <TextContainerFullContainer>
                    <TextContainerFull>{`${corta.name}:`}</TextContainerFull>
                </TextContainerFullContainer>
                <CloseContainer onPress={() => {
                    definirAdjuntoRespuestaThis(null);
                }}>
                    <FontAwesomeIcon icon={"xmark"} style={{ color: theme.__others__white, outline: "none" }} size={15} />
                </CloseContainer>
            </ColumnDetailView>
            <RichTextP>{corta.txt}</RichTextP>
        </MeMessageContainerSub>;
    }
    async pickAttachmentFile() {
        const nuevaImagen = await MyFileService.pickFile(ImagePicker.MediaTypeOptions.All, true);
        const imageRef = nuevaImagen.uri;
        try {
            // Try to paint it in a canvas, even if it 
            const email1 = this.state.otherUser.email;
            const email2 = this.state.user.email;
            const chatId = GroupsSrv.getChatId(email1, email2).replace(/[:]/g, "_");
            const promesa1 = GroupsSrv.sendAttachment(chatId, imageRef, email1);
            this.props.loading(promesa1);
            await promesa1;
            // Pedir recargar el último mensaje
            await this.reloadNewMessages();
        } catch (err) {
            this.props.alert(err.message);
        }
    }
    render() {
        const model = this.state.messages;
        const otherUser = this.state.otherUser;
        const writeMessageThis = this.writeMessage.bind(this);
        const reloadOlderMessagesThis = this.reloadOlderMessages.bind(this);
        const backActionThis = this.backAction.bind(this);
        const popUpUserMenuThis = this.popUpUserMenu.bind(this);
        const definirAdjuntoRespuestaThis = this.definirAdjuntoRespuesta.bind(this);
        const scrollToMessageThis = this.scrollToMessage.bind(this);
        const pickAttachmentFileThis = this.pickAttachmentFile.bind(this);

        const scrollMessagemap = this.scrollMessagemap;
        // ordenarlo
        model.sort((a, b) => {
            return a.date - b.date;
        });

        let indicadorFecha = null;
        // renderizarlo
        const listItems = model.reduce((acc, elemento, index) => {
            if (index == 0) {
                acc.push(<DateChangeIndicatorContainer key={"g" + index}>
                    <ChangeDateContainerTouchable onPress={reloadOlderMessagesThis}>
                        <DateLabel>{"Ver más mensajes"}</DateLabel>
                    </ChangeDateContainerTouchable>
                </DateChangeIndicatorContainer>);

            }
            const diaActual = MyDates.getDayAsContinuosNumber(new Date(elemento.date));
            if (diaActual != indicadorFecha) {
                indicadorFecha = diaActual;
                acc.push(<DateChangeIndicatorContainer key={"d" + index}>
                    <DateChangeIndicator date={elemento.date}></DateChangeIndicator>
                </DateChangeIndicatorContainer>);
            }
            acc.push(<MessageOneContainer
                onLayout={(event) => {
                    const layout = event.nativeEvent.layout;
                    const messageLayout = {
                        y: layout.y,
                        height: layout.height,
                    };
                    //console.log(JSON.stringify(elemento));
                    scrollMessagemap[elemento.id] = messageLayout;
                    //console.log(`${elemento.id} = ${JSON.stringify(messageLayout)}`);
                }}
                showTime={this.showTime}
                alert={this.props.alert}
                showExtraName={this.isGroup}
                side={elemento.who}
                sideName={elemento.whoName}
                sidePicture={elemento.whoPicture}
                popUpUserMenu={() => {
                    popUpUserMenuThis(elemento);
                }}
                handlerClick={null}
                handlerLongClick={() => {
                    definirAdjuntoRespuestaThis(elemento);
                }}
                scrollToMessage={(message) => {
                    scrollToMessageThis(message);
                }}
                txt={elemento.txt}
                att={elemento.att}
                reply={elemento.reply}
                date={elemento.date}
                key={"m" + index}
            />);
            return acc;
        }, []);

        return (
            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                style={styles.container}
            >
                <ParentContainer>
                    <MyContent>
                        <Header>
                            <ContenedorFlecha onPress={backActionThis}>
                                <OutlineArrowLeft></OutlineArrowLeft>
                            </ContenedorFlecha>
                            <ContenedorTitulo style={{ textAlign: 'center', flex: 1 }}>
                                {otherUser.name}
                            </ContenedorTitulo>
                        </Header>
                        <MessagesScroll ref={ref => { this.scrollView = ref }}
                            onContentSizeChange={() => { }}>
                            {listItems}
                        </MessagesScroll>
                    </MyContent>
                    <MyFooter>
                        {this.state.adjuntarRespuesta != null && this.renderSubMessage(this.state.adjuntarRespuesta, definirAdjuntoRespuestaThis)}
                        <BottomWidget
                            pickAttachmentFile={pickAttachmentFileThis}
                            autotext={this.props.route.params.autotext}
                            details={{ writeMessage: writeMessageThis }}></BottomWidget>
                    </MyFooter>
                </ParentContainer>
            </KeyboardAvoidingView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingBottom: 5,
    }
});

const ColumnDetailView = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const CloseContainer = styled.Pressable`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const TextContainerFullContainer = styled.View`
    flex:1;
`

const RichTextP = styled.Text`
    width: 100%;
    max-width: 100%;
    ${CssConstants.BodyLargeRegular}
    color: white;
`;

const DateChangeIndicatorContainer = styled.View`
    display: flex;
    flex-direcion: column;
    align-items: center;
`;

const ContenedorFlecha = styled.Pressable`
    width: 28px;
    height: 28px;
    border: 0;
`;

const ContenedorTitulo = styled.Text`
    ${H4Bold}
    color: ${props => props.theme.__others__white};
    margin-left: 16px;
`;

const Header = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
`;

const MessagesScroll = styled.ScrollView`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const MyContent = styled.View`
    width: 100%;
    flex: 1;
    flex-direction: column;
`;

const MyFooter = styled.View`
    padding-top: 10px;
    width: 100%;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px;
`;



class BottomWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: (typeof props.autotext == "string" ? props.autotext : ""),
        }
        this.child = React.createRef();
        this.myTrhottle = new MyThrottle(1000, true);
    }
    async sendMessage(writeMessage) {
        this.myTrhottle.throttle(async () => {
            const response = await writeMessage(this.state.text);
            if (response) {
                this.setState({ text: "" });
                this.child.current.doEmpty();
            }
        });
    }
    render() {
        const {
            writeMessage
        } = this.props.details;
        const {
            pickAttachmentFile
        } = this.props;
        const messageProps = {
            secret: false,
            multiline: true,
            numberOfLines: 2,
            label: "Escribe tu mensaje...",
            onChangeText: (texto) => {
                this.setState({
                    text: texto,
                });
            },
            onSubmit: (texto) => {
                this.setState({
                    text: texto,
                });
                this.sendMessage(writeMessage);
            },
            onBlur: () => {

            },
            value: this.state.text,
        };

        return (
            <AutoLayoutHorizontal>
                <InputTextContainer>
                    <MyInput style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0
                    }} ref={this.child} details={messageProps}></MyInput>
                    <AttachedFileClick onPress={() => {
                        pickAttachmentFile();
                    }}>
                        <FontAwesomeIcon icon={"paperclip"} style={{ color: theme.__others__white, outline: "none" }} size={20} />
                    </AttachedFileClick>
                </InputTextContainer>
                <IconStar onPress={() => {
                    this.sendMessage(writeMessage);
                }}>
                    <LabelText>Enviar</LabelText>
                </IconStar>
            </AutoLayoutHorizontal>
        );
    }
}

const AttachedFileClick = styled.Pressable`
    position: absolute;
    right: 10px;
    bottom: 10px;
`;

const IconStar = styled.Pressable`
    display: flex;
    flex-direction: row;
    min-width: 28px;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
`;

const LabelText = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXlargeSemibold}
    font-weight: 500;
    color: ${props => props.theme.__greyscale__500};
    text-align: center;
`;

const InputTextContainer = styled.View`
    position: relative;
    flex: 1;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

class MessageOneContainer
    extends React.Component {

    renderReply(reply, scrollToMessage) {
        return <MeMessageContainerSubClick
            onPress={() => {
                scrollToMessage(reply)
            }}>
            <MeMessageContainerSub
                style={{ margin: 10 }}
                background={theme.__background_dark}
                radiusLeft={"12px"}
                radiusRight={"12px"}
            >
                <ColumnDetailView>
                    <TextContainerFull>{`${reply.name}:`}</TextContainerFull>
                </ColumnDetailView>
                <RichTextP>{reply.txt}</RichTextP>
            </MeMessageContainerSub>
        </MeMessageContainerSubClick>;
    }

    renderAttachment(att) {
        let thumbnail = AssetsImg.IMG.THUMBNAIL_VIDEO;
        if (att.type == "image") {
            const xsImage = Config.getNewPath(att.url, "_xs", false)
            thumbnail = { uri: xsImage };
        }
        // Se crea el elemento
        return <AttachmentClick onPress={() => {
            MyShare.openUrlOrMail(att.url, alert);
        }}>
            <Attachment source={thumbnail}></Attachment>
        </AttachmentClick>;
    }

    render() {
        const {
            txt,
            att,
            reply,
            date,
            side,
            sideName,
            sidePicture,
            showExtraName,
            showTime,
            popUpUserMenu,
            handlerClick,
            handlerLongClick,
            scrollToMessage,
        } = this.props;

        const fecha = new Date(date);

        let alignment = "flex-start";
        let background = theme.__dark__dark_2;
        let radiusLeft = "0px";
        let radiusRight = "12px";

        let extraName = null;
        if (side == "me") {
            alignment = "flex-end";
            background = theme.__greyscale__800;
            radiusLeft = "12px";
            radiusRight = "0px";
        } else {
            if (typeof sideName == "string") {
                extraName = sideName + ":";
            } else {
                extraName = side + ":";
            }
        }

        let sourceImage = AssetsImg.IMG.DEFAVATAR;
        if (sidePicture) {
            sourceImage = { uri: Config.getNewPath(sidePicture, "", showTime) };
        }

        return (
            <TypeMeComponentChatDetailsList
                onLayout={this.props.onLayout}
                onLongPress={handlerLongClick}
                onPress={handlerClick}
                side={alignment}>
                <AutoLayoutVertical side={alignment}>
                    {showExtraName && side !== "me" && <AvatarClick onPress={popUpUserMenu}>
                        <Avatar source={sourceImage}></Avatar>
                    </AvatarClick>}
                    <MeMessageContainer
                        background={background}
                        radiusLeft={radiusLeft}
                        radiusRight={radiusRight}
                    >
                        {showExtraName === true && <TextContainerFull>{extraName}</TextContainerFull>}
                        {!!att && this.renderAttachment(att)}
                        {!!reply && this.renderReply(reply, scrollToMessage)}
                        <MyRichText alert={this.props.alert} description={txt}></MyRichText>
                        <DateContainer>{MyDates.formatTime(fecha)}</DateContainer>
                    </MeMessageContainer>
                </AutoLayoutVertical>
            </TypeMeComponentChatDetailsList>
        );
    }
}

const AttachmentClick = styled.Pressable`
    width: 150px;
    height: 150px;
`

const Attachment = styled.ImageBackground`
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
`;

const AvatarClick = styled.Pressable`
    width: 35px;
    height: 35px;
    margin-right: 5px;
`

const Avatar = styled.ImageBackground`
    width: 100%;
    height: 100%;
    border-radius: 100px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
`;

const TypeMeComponentChatDetailsList = styled.Pressable`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.side};
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.side};
    margin-right: 5px;
    width: 100%;
`;

const MeMessageContainer = styled.View`
    display: flex;
    align-items: flex-end;
    padding: 12px 20px;
    background-color: ${props => props.background};
    borderTopLeftRadius: ${props => props.radiusLeft};
    borderTopRightRadius: ${props => props.radiusRight};
    borderBottomLeftRadius: 12px;
    borderBottomRightRadius: 12px;
    max-width: 80%;
`;

const MeMessageContainerSubClick = styled.Pressable`
`;

const MeMessageContainerSub = styled.View`
    display: flex;
    align-items: flex-end;
    padding: 12px 20px;
    background-color: ${props => props.background};
    borderTopLeftRadius: ${props => props.radiusLeft};
    borderTopRightRadius: ${props => props.radiusRight};
    borderBottomLeftRadius: 12px;
    borderBottomRightRadius: 12px;
`;

const TextContainerFull = styled.Text`
    ${ValignTextBottom}
    ${CssConstants.BodyLargeSemibold}
    font-weight: 400;
    color: ${props => props.theme.__others__white};
    line-height: 22.4px;
    text-align: justify;
    width: 100%;
`;

const DateContainer = styled.Text`
    ${ValignTextBottom}
    ${BodyMediumRegular}
    font-weight: 400;
    color: ${props => props.theme.__others__white};
    text-align: right;
    line-height: 19.6px;
`;


class DateChangeIndicator extends React.Component {
    render() {
        const { date } = this.props;
        const fecha = new Date(date);
        const ahora = new Date();
        const anio0 = ahora.getFullYear();
        const mes0 = ahora.getMonth();
        const dia0 = ahora.getDate();

        const isToday = (fecha.getFullYear() == anio0 && fecha.getMonth() == mes0 && fecha.getDate() == dia0);

        let texto = "Hoy";
        if (!isToday) {
            texto = MyDates.formatDateSimple(fecha);
        }

        return (
            <ChangeDateContainer>
                <DateLabel>{texto}</DateLabel>
            </ChangeDateContainer>
        );
    }
}

const ChangeDateContainer = styled.View`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    background-color: ${props => props.theme.__dark__dark_3};
    border-radius: 8px;
`;

const ChangeDateContainerTouchable = styled.Pressable`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    background-color: ${props => props.theme.__dark__dark_3};
    border-radius: 8px;
    margin-bottom: 5px;
`;

const DateLabel = styled.Text`
    font-family: ${props => props.theme.__font_family_inter};
    font-weight: 400;
    color: ${props => props.theme.__greyscale__400};
    font-size: ${props => props.theme.__font_size_l2};
    letter-spacing: 0;
    line-height: 24px;
`;

export default MessengerScreen;
