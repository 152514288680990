import React from "react";
import styled from "styled-components/native";
import Frame9 from "../svg/Frame9"
import Frame8 from "../svg/Frame8"
import Frame10 from "../svg/Frame10"
import { SocialGoogle } from "../../utils/hooks/SocialGoogle"
import { SocialFacebook } from "../../utils/hooks/SocialFacebook"

import {
  Border1pxDarkDark3,
} from "../../widgets/CssConstans";
import { SocialApple } from "../../utils/hooks/SocialApple";

export default class Button2
  extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
    };
  }

  render() {
    const { className, onPress, webClientId, acceptTerms } = this.props.details;
    const submitAction = async () => {
      try {
        this.setState({ submitting: true });
        await onPress();
      } catch (e) {

      }
      this.setState({ submitting: false });
    }

    //TypeLoginType2SocialType3FacebookSt
    //onPress={submitAction}
    //disabled={this.state.submitting}
    if (className == "google") {
      return (
        <SocialGoogle alert={this.props.alert} webClientId={webClientId} acceptTerms={acceptTerms} >
          <TypeLoginType2SocialType3FacebookSt>
            <AutoLayoutHorizontal>
              <Frame9></Frame9>
            </AutoLayoutHorizontal>
          </TypeLoginType2SocialType3FacebookSt>
        </SocialGoogle>
      );
    } else if (className == "facebook") {
      return (
        <SocialFacebook alert={this.props.alert} webClientId={webClientId} acceptTerms={acceptTerms} >
          <TypeLoginType2SocialType3FacebookSt>
            <AutoLayoutHorizontal>
              <Frame8></Frame8>
            </AutoLayoutHorizontal>
          </TypeLoginType2SocialType3FacebookSt>
        </SocialFacebook>
      );
    } else if (className == "apple") {
      return (
        <SocialApple alert={this.props.alert} webClientId={webClientId} acceptTerms={acceptTerms} >
          <TypeLoginType2SocialType3FacebookSt>
            <AutoLayoutHorizontal>
              <Frame10></Frame10>
            </AutoLayoutHorizontal>
          </TypeLoginType2SocialType3FacebookSt>
        </SocialApple>
      );
    }
  }
}

const TypeLoginType2SocialType3FacebookSt = styled.View`
  ${Border1pxDarkDark3}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 18px 32px;
  background-color: ${props => props.theme.__dark__dark_2};
  border-radius: 16px;
    width: 87px;
`;

const AutoLayoutHorizontal = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
`;

const Frame = styled.Image`
  min-width: 24px;
  height: 24px;
`;

const AutoLayoutHorizontal1 = styled.View`
    margin-left: 0px;
    margin-right: 0px;
`;

const Frame1 = styled.Image`
    min-width: 23px;
    margin-left: 0px;
    margin-right: 0px;
`;