import React from "react";
import styled from "styled-components/native";
import { StyleSheet } from 'react-native';
import theme from "../../Theme";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

class IconlyLightArrowRight2
    extends React.Component {
    render() {
        const {
            top,
            left,
        } = this.props;
        return (
            <IconlyLightArrowRight21 top={top} left={left}>
                <FontAwesomeIcon icon={"angle-right"} style={[styles.icon]} size={16} />
            </IconlyLightArrowRight21>
        );
    }
}

export default IconlyLightArrowRight2;

const IconlyLightArrowRight21 = styled.View`
position: relative;
width: 24px;
height: 24px;
top: ${props => (props.top ? props.top : "0px")};
left: ${props => (props.left ? props.left : "0px")};
`;

const styles = StyleSheet.create({
    icon: {
        color: theme.__others__white,
    }
});