import { Linking, Platform } from "react-native";
import CONFIG from "../Constants";
import { fetchApi } from "../srv/Http";
import Config from "../utils/Config";
import { DonacionesSrv } from "./DonacionesSrv";
import MyLog from "./MyLog";

export class NotificationsSrv {
    constructor(navigation) {
        this.navigation = navigation;
    }
    async has() {
        const subUrl = "/push/has";
        const promesa = fetchApi(subUrl, {}, false);
        try {
            const response = await promesa;
            return response;
        } catch (err) {
            return { has: false };
        }
    }
    static async turnOpened(notification_id, alert) {
        const subUrl = "/push/opened";
        const payload = {
            notification_id,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    static async checkViewed(notification_id, alert) {
        const subUrl = "/push/checkviewed";
        const payload = {
            ids: [notification_id],
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    static async checkPopedUp(id, alert) {
        const subUrl = "/push/checkpopedup";
        const payload = {
            id,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    async nextPopUp(alert, loading) {
        const subUrl = "/push/nextpopup";
        const promesa = fetchApi(subUrl, {}, false, alert);
        loading(promesa);
        try {
            const response = await promesa;
            return response.data;
        } catch (err) {
            return [];
        }
    }
    async forgetNotification(notification, alert, loading) {
        const subUrl = "/push/forget";
        const promesa = fetchApi(subUrl, { id: notification.id }, false, alert);
        loading(promesa);
        try {
            const response = await promesa;
            return response.data;
        } catch (err) {
            return [];
        }
    }
    async getNotifications(alert, loading) {
        const subUrl = "/push/last";
        const promesa = fetchApi(subUrl, {}, false, alert);
        loading(promesa);
        try {
            const response = await promesa;
            return response.data;
        } catch (err) {
            return [];
        }
    }
    async goToDonation(payload, alert) {
        // Se debe cargar la donación:
        const miDonacion = await DonacionesSrv.byId(payload.donation.id, alert, true);
        if (!miDonacion) {
            return;
        }
        // Se definen los parámetros
        const params = {
            donations: [miDonacion],
            index: 0,
            origin: "NotificationsScreen",
        };
        // Se hace la redirección
        this.navigation.navigate('WDonationDetailAcceptedPage', params);
    }
    async goToVotes(payload, alert) {
        // Se debe cargar la donación:
        const miDonacion = await DonacionesSrv.byId(payload.donation.id, alert, true);
        if (!miDonacion) {
            return;
        }
        // Se definen los parámetros
        const params = {
            donation: miDonacion,
            mode: 0,
            origin: "NotificationsScreen",
        };
        // Se hace la redirección
        this.navigation.navigate('VotePage', params);
    }
    async goToRecieverProfile(payload, alert) {
        this.donacionesSrv = new DonacionesSrv();
        const status = await this.donacionesSrv.myStatus(alert);
        const myDroplet = CONFIG.getDropletFeature(status.newStatus);
        const params = {
            email: payload.receiver.email,
            donorTypeName: myDroplet.txt,
            beePoints: status.points,
            dropletColor: myDroplet.color
        };
        this.navigation.navigate('StatusPage', params);
    }
    goToPersonSpaceToReciever(payload) {
        const messengerPayload = {
            email: payload.receiver.email,
            name: payload.receiver.name,
        };
        this.navigation.navigate('MessengerPage', messengerPayload);
    }
    goToPersonSpaceToDonator(payload) {
        const messengerPayload = {
            email: payload.donator.email,
            name: payload.donator.name,
        };
        this.navigation.navigate('MessengerPage', messengerPayload);
    }
    goToPersonSpace(payload) {
        this.navigation.navigate('MessengerPage', payload);
    }
    goToGroupSpace(payload) {
        payload.author = {
            name: payload.name,
            email: payload.email,
        };
        payload.name = payload.group.name;
        payload.email = payload.group.id;
        //console.log(JSON.stringify(payload, null, 4));
        this.navigation.navigate('MessengerPage', payload);
    }
    goToGroupSpace2(payload) {
        payload.name = payload.group.name;
        payload.email = payload.group.id;
        //console.log(JSON.stringify(payload, null, 4));
        this.navigation.navigate('MessengerPage', payload);
    }
    goToGroupSpaceProfile(payload) {
        const params = {
            group: payload.group,
            reloadGroup: true,
        };
        //console.log(JSON.stringify(payload, null, 4));
        this.navigation.navigate('EditGroupPage', params);
    }
    goToHome() {
        this.navigation.navigate('MyHomePage');
    }
    popUpNotification(notification, alert, popUpNotificationFun) {
        if (typeof popUpNotificationFun == "function") {
            popUpNotificationFun(notification);
        }
    }
    forwardNotification(notification, alert, popUpNotificationFun) {
        switch (notification.type) {
            case "askrank":
                //Chatear con quien recibe la donación
                //this.goToPersonSpaceToReciever(notification.payload, alert);
                this.popUpNotification(notification, alert, popUpNotificationFun);
                break;
            case "rank":
                // Mandar a la página de la donación
                //this.goToDonation(notification.payload, alert);
                this.popUpNotification(notification, alert, popUpNotificationFun);
                break;
            case "remaining":
                // Mandar al tacómetro de quien ha recibido la donación
                this.goToRecieverProfile(notification.payload, alert);
                break;
            case "chat":
                this.goToPersonSpace(notification.payload, alert);
                break;
            case "chat_group":
                this.goToGroupSpace(notification.payload, alert);
                break;
            case "won":
                //this.goToDonation(notification.payload, alert);
                //this.goToPersonSpaceToDonator(notification.payload, alert);
                // OK
                this.popUpNotification(notification, alert, popUpNotificationFun);
                break;
            case "won2":
                //this.goToDonation(notification.payload, alert);
                // OK
                this.popUpNotification(notification, alert, popUpNotificationFun);
                break;
            case "loose":
                // OK
                this.goToDonation(notification.payload, alert);
                //this.goToPersonSpaceToDonator(notification.payload, alert);
                break;
            case "almostwon":
                // Ok
                this.goToVotes(notification.payload, alert);
                break;
            case "confirm":
                //this.goToPersonSpaceToDonator(notification.payload, alert);
                // OK
                this.popUpNotification(notification, alert, popUpNotificationFun);
                break;
            case "losted":
                // Lamentamos OK
                this.goToDonation(notification.payload, alert);
                break;
            case "recalculate":
                this.popUpNotification(notification, alert, popUpNotificationFun);
                break;
            case "forgetit":
                this.goToHome();
                break;
            case "void":
                this.goToDonation(notification.payload, alert);
                break;
            case "newdon":
                this.goToDonation(notification.payload, alert);
                break;
            case "newrepost":
                this.goToDonation(notification.payload, alert);
                break;
            case "group_added":
                this.goToGroupSpaceProfile(notification.payload, alert);
                break;
            case "group_isadmin":
                this.goToGroupSpaceProfile(notification.payload, alert);
                break;
            default:
                // Pedir actualizar la app
                (async () => {
                    if (Platform.OS == "web") {
                        const CONFIG = await Config.getValue("config", false);
                        const UPDATE = CONFIG.UPDATE;
                        let url = UPDATE.android;
                        let appStoreName = "Play Store";
                        if (Platform.OS == 'ios') {
                            // Open ios
                            url = UPDATE.UPDATE.ios;
                            appStoreName = "Apple Store"
                        }
                        let canOpen = await Linking.canOpenURL(url);
                        if (!canOpen) {
                            // Alertar!
                            alert(`Por favor ve al ${appStoreName} y busca 'PANAL COMUNIDAD'.`);
                        } else {
                            Linking.openURL(url);
                        }
                    } else {
                        alert("Notificación desconocida");
                    }
                })();


        }
    }
}
