import styled from "styled-components/native";
import React from "react";
import theme from "../Theme";
import Logo from "../screens/svg/Logo";
import AssetsImg from "../AssetsImg";
import { Animated, Easing } from "react-native";


export default class LoadingPage extends React.Component {
    constructor(props) {
        super(props);
        this.spinValue = new Animated.Value(0);
        this.spinValue.addListener(({ value }) => {
            if (value == 1) {
                this.spinValue.setValue(0);
                this.runAnimation();
            }
        });
        this.runAnimation();
    }
    componentWillUnmount() {
        this.compositeAnimation.stop();
    }
    runAnimation() {
        this.compositeAnimation = Animated.loop(
            Animated.timing(
                this.spinValue,
                {
                    toValue: 1,
                    duration: 3000,
                    easing: Easing.linear,
                    useNativeDriver: true
                }
            )
        );
        this.compositeAnimation.start();
    }
    render() {
        const spin = this.spinValue.interpolate({
            inputRange: [0, 1],
            outputRange: ['0deg', '360deg']
        })
        return (
            <ContainerLoading>
                <ContainerLogo>
                    <Logo size={100}></Logo>
                </ContainerLogo>
                <ContainerIndicator>
                    <Animated.View
                        style={{ transform: [{ rotate: spin }] }}
                    >
                        <Frame source={AssetsImg.IMG.LOADING}></Frame>
                    </Animated.View>
                </ContainerIndicator>
            </ContainerLoading>
        );
    }
}

const ContainerLoading = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.__dark__dark_1};
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const ContainerLogo = styled.View`
    flex: 1;
    width: 110px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const ContainerIndicator = styled.View`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
`;

const Frame = styled.ImageBackground`
  width: 60px;
  height: 60px;
  margin: 80px;
`;

