import React from "react";
import { Platform } from 'react-native';
import styled from "styled-components/native";
import {
    Easing,
    Animated,
} from 'react-native';
import FirstMenuBottom from "./FirstMenuBottom";
import SecondMenuBottom from "./SecondMenuBottom";
import Second2MenuBottom from "./Second2MenuBottom";
import ElementsNavbar from "../../widgets/ElementsNavbar";
import AssetsImg from "../../AssetsImg";
import * as CssConstants from "../../widgets/CssConstans";
import CONFIG from "../../Constants";
import MyModal from "../../widgets/MyModal";
import ProfileScreen from "../../pages/ProfileScreen";
import VoteScreen from "../../pages/VoteScreen";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import OutlineArrowLeft from "../svg/OutlineArrowLeft";
import OutlineArrowRight from "../svg/OutlineArrowRight";
import { MyThrottle } from "../../utils/MyThrottle";

const WINDOW_LOADED = 1;
const UMBRAL_SOME_ACTION_DONE = 5;
const UMBRAL_INTENTION_DONE = 0.5;
let AUTO_SWIPE_PROPORTION = 1.0;
let AUTO_SWIPE_TIME = 1000;
//let EASING_MODE = Easing.out(Easing.exp);
let EASING_MODE = Easing.linear;

if (Platform.OS === 'web') {
    AUTO_SWIPE_TIME = 1000;
    EASING_MODE = Easing.linear;
    //AUTO_SWIPE_PROPORTION = 0.5;
}

class XPageDetail1 extends React.Component {
    constructor(props) {
        super(props);
        this.intention = null;
        this.state = {
            opened: false,
            offsetX: 0,
            initialX: 0,
            offsetY: 0,
            initialY: 0,
            autoDragging: false,
            dragAction: null,
            isSwiped: 0,
            _animationX: 0,
            isShowingPopUp: true,
            localDesition: false,
        };
        this.lastPress = 0;
        this.animationX = new Animated.Value(0);
        this.throttle = new MyThrottle(50, false, true);
        const self = this;
        this.animationX.addListener(({ value }) => {
            self.throttle.throttle(async (argumentos) => {
                self.setState({
                    _animationX: argumentos.value,
                });
            }, { value });
        });
        this.myRootNode = null;
        this.profilePageEnv = {};
        this.profilePageEnv.handleNewUserThis = this.props.mountedComponent.handleNewUser.bind(this.props.mountedComponent);
        this.profilePageEnv.handleNavigationThis = this.props.mountedComponent.handleNavigation.bind(this.props.mountedComponent);
        this.votePageEnv = {};
        this.votePageEnv.handleNewUserThis = this.props.mountedComponent.handleNewUser.bind(this.props.mountedComponent);
        this.votePageEnv.handleNavigationThis = this.props.mountedComponent.handleNavigation.bind(this.props.mountedComponent);
    }
    async animateAccept() {
        this.setState({
            dragAction: "accept",
        });
        const promesa = this.animate(AUTO_SWIPE_PROPORTION * this.props.windowSize.width);
        this.props.outherProps.loading(promesa);
        return promesa
    }
    async animateReject() {
        this.setState({
            dragAction: "reject",
        });
        const promesa = this.animate(-1 * AUTO_SWIPE_PROPORTION * this.props.windowSize.width);
        this.props.outherProps.loading(promesa);
        return promesa;
    }
    async animate(multiplicator = 1, multiplicatorTime = 1) {
        this.setState({
            autoDragging: true,
        });
        return new Promise((resolve) => {
            Animated.timing(
                this.animationX,
                {
                    toValue: multiplicator,
                    duration: AUTO_SWIPE_TIME * multiplicatorTime,
                    easing: EASING_MODE,
                    useNativeDriver: true,
                }
            ).start(() => {
                resolve();
            });
        });
    }
    openAction() {
        this.setState({ opened: true });
    }
    closeAction() {
        this.setState({ opened: false });
    }
    printEvent(name) {
        //console.log(`printEvent:${name}`);
    }
    moveToSideScreen() {
        const windowSize = this.props.windowSize;
        // Se permite hacer scroll en Y otra vez...
        this.intention = null;
        if (this.state.isSwiped > 0) {
            // Va a una de las dons pantallas de los lados
            this.props.details.setScrollEnabled(false);
            let nextState = {
                initialX: 0,
                offsetX: 0,
                initialY: 0,
                offsetY: 0,
                // dragAction: null,
                // isSwiped: 0,
            };
            if (this.state.dragAction == "accept") {
                nextState = Object.assign(nextState, {
                    offsetX: 1 * windowSize.width,
                });
            } else if (this.state.dragAction == "reject") {
                nextState = Object.assign(nextState, {
                    offsetX: - 1 * windowSize.width,
                });
            }
            // switch X variables
            nextState = Object.assign(nextState, {
                offsetX: 0,
                autoDragging: false,
                _animationX: nextState.offsetX,
            });
            this.animationX.setValue(nextState._animationX);
            this.setState(nextState);
        } else {
            // Regresa al centro...
            this.props.details.setScrollEnabled(true);
            this.setState({
                initialX: 0,
                offsetX: 0,
                initialY: 0,
                offsetY: 0,
                dragAction: null,
                isSwiped: 0,
            });
        }
    }
    closeModalFun() {
        this.setState({ isShowingPopUp: false });
    }
    async animateBackToCenter() {
        // Esto resultó extremadamente lento
        //const promesa = this.animate(0, 0.3);
        //this.props.outherProps.loading(promesa);
        //await promesa;
        this.setState({
            dragAction: null,
            autoDragging: false,
            offsetX: 0,
            _animationX: 0,
        });
        this.props.details.setScrollEnabled(true);
        //return promesa;
    }
    onDoublePress() {
        const time = new Date().getTime();
        const delta = time - this.lastPress;

        const DOUBLE_PRESS_DELAY = 400;
        if (delta < DOUBLE_PRESS_DELAY) {
            // Success double press
            this.openAction();
        }
        this.lastPress = time;
    }
    render() {
        const {
            top,
            backImage,
            middle,
            button1,
            button2,
            setScrollEnabled,
        } = this.props.details;

        const {
            goToPersonSpace
        } = this.props;

        const windowSize = this.props.windowSize;
        const openAction = this.openAction.bind(this);
        const closeAction = this.closeAction.bind(this);

        const animateAccept = this.animateAccept.bind(this);
        const animateReject = this.animateReject.bind(this);

        const animateBackToCenterThis = this.animateBackToCenter.bind(this);

        const mapedActions = {
            acceptAction: async () => {
                if (middle.desition || this.state.localDesition === true) {
                    // Ignore it
                    return;
                }
                const NEEDED_GAP_TIME = 1000;
                const startTime = new Date().getTime();
                await button1.acceptAction(async () => {
                    // Lo último por hacer
                    return new Promise((resolve) => {
                        const currentTime = new Date().getTime();
                        let diff = NEEDED_GAP_TIME - (currentTime - startTime);
                        if (diff < 0) {
                            diff = 0;
                        }
                        setTimeout(async () => {
                            await animateAccept();
                            resolve();
                        }, diff);
                    });
                }, async () => {
                    // Lo primero en hacer...
                    this.props.details.setScrollEnabled(false);
                    // Muestro el fondo naranja
                    this.setState({ localDesition: true });
                });
            },
            rejectAction: async () => {
                this.props.details.setScrollEnabled(false);
                await animateAccept();
            },
            setStars: button1.setStars,
            stars: button1.stars,
        };
        // Se valida si toca o no mostrar el contenido.
        const myIndex = this.props.myIndex;
        const currentIndex = this.props.currentIndex;

        let showSelf = false;
        if (typeof myIndex == "number" && typeof currentIndex == "number") {
            const minBound = currentIndex - WINDOW_LOADED;
            const maxBound = currentIndex + WINDOW_LOADED;
            if (myIndex >= minBound && myIndex <= maxBound) {
                showSelf = true;
            }
        }

        const modalInFront = this.props.modalInFront;
        const closeModalFunThis = this.closeModalFun.bind(this);
        if ([undefined, null].indexOf(modalInFront) < 0 && modalInFront.actions instanceof Array) {
            const myButtons = modalInFront.actions;
            for (let k = 0; k < myButtons.length; k++) {
                const myButton = myButtons[k];
                const oldFunction = myButton.onPress;
                myButton.onPress = () => {
                    oldFunction(closeModalFunThis);
                }
            }
        }

        const profilePageEnv = this.profilePageEnv;
        const votePageEnv = this.votePageEnv;

        const sideScreensStyle = {
            position: 'absolute',
            top: 64,
            left: 0,
            right: 0,
            bottom: 0,
        };

        const sideScreensStyleNoTop = {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };

        let myDetectorStyle = {};
        if (Platform.OS === 'web') {
            myDetectorStyle = {
                'WebkitUserSelect': 'none',
                'msUserSelect': 'none',
                'MozUserSelect': 'none',
                'userSelect': 'none',
            };
        }
        this.myRootNode = (
            <ParentContainer1
                windowSize={windowSize}
                source={{ uri: backImage }}
                resizeMode="cover"
                blurRadius={5}
                dragOffsetX={(this.state.offsetX + this.state._animationX) + "px"}
                dragOffsetY={this.state.offsetY + "px"}
            >
                {showSelf && <ParentContainer
                    source={{ uri: backImage }}
                    resizeMode="contain">
                    <MyColor
                        isAccepted={middle.desition || this.state.localDesition === true}>
                        <MyContent>
                            <ElementsNavbar details={top}></ElementsNavbar>
                            <MyDetector
                                style={myDetectorStyle}
                                onStartShouldSetResponder={(evt) => {
                                    return true;
                                }}
                                onMoveShouldSetResponder={(evt) => {
                                    return true;
                                }}
                                onResponderTerminationRequest={(evt) => {
                                    this.printEvent("force call");
                                    return false;
                                }}
                                onResponderTerminate={(evt) => {
                                    this.printEvent("force response");
                                    this.moveToSideScreen();
                                }}
                                onResponderGrant={(evt) => {
                                    this.printEvent("grant");
                                    this.onDoublePress();
                                    setScrollEnabled(false);
                                    this.initialX = evt.nativeEvent.pageX;
                                    this.initialY = evt.nativeEvent.pageY;
                                }}
                                onResponderReject={(evt) => {
                                    this.printEvent("reject");
                                }}
                                onResponderMove={(evt) => {
                                    if (this.state.autoDragging) {
                                        return;
                                    }
                                    let diffX = evt.nativeEvent.pageX - this.initialX;
                                    let diffY = evt.nativeEvent.pageY - this.initialY;
                                    if (middle.type === CONFIG.LISTS.DONATION.TYPE.REPOST) {
                                        if (diffX > 0) {
                                            return;
                                        }
                                    }
                                    const windowWidth = this.props.windowSize.width;
                                    const significativo = (Math.abs(diffX) > UMBRAL_SOME_ACTION_DONE || Math.abs(diffY) > UMBRAL_SOME_ACTION_DONE);
                                    if (Math.abs(diffX) > Math.abs(diffY)) {
                                        diffY = 0;
                                        if (significativo && this.intention == null) {
                                            this.intention = "X";
                                        }
                                    } else {
                                        diffX = 0;
                                        if (significativo && this.intention == null) {
                                            this.intention = "Y";
                                        }
                                    }
                                    const proportionDone = Math.abs(diffX / windowWidth);
                                    let isSwiped = 0;
                                    if (proportionDone > UMBRAL_INTENTION_DONE) {
                                        isSwiped = new Date().getTime();
                                    }
                                    let dragAction = null;
                                    if (significativo) {
                                        if (diffX > 0) {
                                            dragAction = "accept";
                                        } else if (diffX < 0) {
                                            dragAction = "reject";
                                        }
                                    }

                                    setScrollEnabled(this.intention == "Y");
                                    this.setState({
                                        isSwiped,
                                        offsetX: diffX,
                                        offsetY: diffY,
                                        dragAction,
                                    });
                                }}
                                onResponderRelease={(evt) => {
                                    this.printEvent("release");
                                    this.moveToSideScreen();
                                }}
                                onScrollEndDrag={(evt) => {
                                    this.printEvent("end drag");
                                    this.moveToSideScreen();
                                }}
                            >
                                {(middle.desition === true || this.state.localDesition === true) && <MyDesition
                                    source={{ uri: AssetsImg.BASE64.participating }}
                                    resizeMode="contain"
                                />}
                            </MyDetector>
                        </MyContent>

                        <MyFooter>
                            <AutoLayoutVertical>
                                {this.state.opened === false && <SecondMenuBottom details={{ middle, button1: mapedActions, donation: button2.donation }} openAction={openAction}></SecondMenuBottom>}
                                {this.state.opened === true && <Second2MenuBottom goToPersonSpace={goToPersonSpace} origin={this.props.origin} details={{ middle, button1: mapedActions, donation: button2.donation }} closeAction={closeAction}></Second2MenuBottom>}
                                <FirstMenuBottom
                                    currentUser={this.props.currentUser}
                                    navigation={this.props.navigation}
                                    details={button2}
                                    scroll={{
                                        setScrollEnabled: this.props.details.setScrollEnabled,
                                        getScrollReference: this.props.details.getScrollReference,
                                        posicionarScroll: this.props.details.posicionarScroll,
                                    }}
                                    alert={this.props.alert}></FirstMenuBottom>
                            </AutoLayoutVertical>
                        </MyFooter>
                    </MyColor>
                    {this.state.isShowingPopUp === true && [null, undefined].indexOf(modalInFront) < 0 && <Backdrop>
                        <MyModal
                            avoidAutoClose={true}
                            close={closeModalFunThis}
                            details={{
                                title: modalInFront.title,
                                description: modalInFront.description,
                                useFixedPage: true,
                                buttons: modalInFront.actions,
                                extra: modalInFront.extra,
                            }}></MyModal>
                    </Backdrop>}
                </ParentContainer>}
                {showSelf > 0 && (this.state.offsetX > 0 || this.state._animationX > 0) && <LeftContainer
                    windowSize={windowSize}>
                    <FakeTopHeader>
                        <FakeText>Votación</FakeText>
                        <FakeIcon onPress={() => { animateBackToCenterThis(true); }}>
                            <OutlineArrowRight></OutlineArrowRight>
                        </FakeIcon>
                    </FakeTopHeader>
                    <VoteScreen
                        style={sideScreensStyle}
                        onMount={(self) => {
                            self.myNavigatorFocusActions(votePageEnv.handleNewUserThis, votePageEnv.handleNavigationThis);
                        }}
                        onUnMount={(self) => {
                            self.myNavigatorBlurActions(votePageEnv.handleNewUserThis);
                        }}
                        alert={this.props.outherProps.alert}
                        alertOpen={this.props.outherProps.alertOpen}
                        alertClose={this.props.outherProps.alertClose}
                        loading={this.props.outherProps.loading}
                        navigation={this.props.outherProps.navigation}
                        popUpNotification={this.props.outherProps.popUpNotification}
                        route={{
                            params: {
                                mode: (this.state.localDesition === true ? 1 : 0),
                                donation: button2.donation,
                                backToHome: false,
                            }
                        }}></VoteScreen>
                </LeftContainer>}
                {showSelf && (this.state.offsetX < 0 || this.state._animationX < 0) && <RightContainer
                    windowSize={windowSize}>
                    <ProfileScreen
                        style={sideScreensStyleNoTop}
                        onMount={(self) => {
                            self.myNavigatorFocusActions(profilePageEnv.handleNewUserThis, profilePageEnv.handleNavigationThis);
                        }}
                        onUnMount={(self) => {
                            self.myNavigatorBlurActions(profilePageEnv.handleNewUserThis);
                        }}
                        backAction={() => { animateBackToCenterThis(true); }}
                        alert={this.props.outherProps.alert}
                        alertOpen={this.props.outherProps.alertOpen}
                        alertClose={this.props.outherProps.alertClose}
                        loading={this.props.outherProps.loading}
                        navigation={this.props.outherProps.navigation}
                        popUpNotification={this.props.outherProps.popUpNotification}
                        route={{
                            params: {
                                email: button2.donation.owner
                            }
                        }}></ProfileScreen>
                </RightContainer>}
            </ParentContainer1>
        );
        return this.myRootNode;
    }
}

export default XPageDetail1;

const FakeIcon = styled.Pressable`
    margin: 10px;
`;

const FakeText = styled.Text`
    flex: 1;
    margin: 16px;
    font-family: Urbanist;
    color: white;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
`;

const FakeTopHeader = styled.View`
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ParentContainer1 = styled.ImageBackground`
    width: 100%;
    height: ${props => props.windowSize.height}px;
    position: relative;
    left: ${props => props.dragOffsetX};
`;

//left: 0px;
//left: ${props => (-1 * props.windowSize.width)}px;
const LeftContainer = styled.View`
    width: ${props => props.windowSize.width}px;
    height: ${props => props.windowSize.height}px;
    position: absolute;
    left: ${props => (-1 * props.windowSize.width)}px;
`;

const RightContainer = styled.View`
    width: ${props => props.windowSize.width}px;
    height: ${props => props.windowSize.height}px;
    position: absolute;
    left: ${props => (props.windowSize.width)}px;
`;

const Backdrop = styled.View`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const MyColor = styled.View`
    flex: 1;
    ${props => (props.isAccepted ? "background-color: rgba(255, 87, 38, 0.6);" : "")}
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

//pointerEvents: none;
const MyDesition = styled.ImageBackground`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
`;

const ParentContainer = styled.ImageBackground`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyContent = styled.View`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const MyDetector = styled.View`
    width: 100%;
    flex: 1;
    background-color: rgba(52, 52, 52, 0);
    margin: 0 auto;
`;

// justify-content: 'flex-end'; crash
const MyFooter = styled.View`
    width: 100%;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    
    overflow: hidden;
`;
