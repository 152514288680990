import * as React from 'react';
import AssetsImg from '../../AssetsImg';
import styled from "styled-components/native";

const OutlineArrowLeft = () => {
    return (<Frame source={AssetsImg.IMPORTED.arrow_left} />);
}

const Frame = styled.Image`
    min-width: 28px;
    height: 28px;
    color: white;
`;

export default OutlineArrowLeft;