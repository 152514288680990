import Config from '../utils/Config';
import { isValidAddress } from '../utils/InputValidator';
import { MyDates } from '../utils/MyDates';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_1 = "start";
const FIELD_KEY_NAME_2 = "organicerme";
const FIELD_KEY_NAME_3 = "organicername";
const FIELD_KEY_NAME_4 = "linkMore";

export default class WDonationAdvancedScreen extends SelectScreen {
    constructor(props) {
        super(props);
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        this.initValue(FIELD_KEY_NAME_1);
        this.initValue(FIELD_KEY_NAME_2);
        this.initValue(FIELD_KEY_NAME_3);
        this.initValue(FIELD_KEY_NAME_4);
    }
    onSubmit(values) {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        super.myLocalGoBack(params);
    }
    validate(values) {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        const errors = {};
        return errors;
    }
    render() {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        const handleLocalNavigationThis = super.handleLocalNavigation.bind(this);

        const listaOpciones = [];

        listaOpciones.push({
            izq: {},
            der: {
                type: "page",
                details: {
                    label: "Organizador",
                    //icon: "ticket",
                    currentValue: params.organicername,
                    goToPage: async () => {
                        handleLocalNavigationThis("WDonationOrganicerPage")
                    }
                }
            }
        });

        listaOpciones.push({
            izq: {},
            der: {
                type: "page",
                details: {
                    label: "Link para más información",
                    //icon: "ticket",
                    currentValue: params.linkMore,
                    goToPage: async () => {
                        handleLocalNavigationThis("WDonationLinkMorePage")
                    }
                }
            }
        });

        listaOpciones.push({
            izq: {},
            der: {
                type: "page",
                details: {
                    label: "Programar publicación",
                    //icon: "calendar-days",
                    currentValue: MyDates.formatDatetime(params.start),
                    goToPage: async () => {
                        const MY_CONFIG = await Config.getValue("config");
                        this.props.route.params.config = MY_CONFIG;
                        handleLocalNavigationThis("WDonationPublishDatePage");
                    }
                }
            }
        });

        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = "";
        return super.myRender(listaOpciones, instructions, onSubmit, validate);
    }
}