import React from "react";
import styled from "styled-components/native";
import { 
    ValignTextMiddle, 
    BodyLargeBold 
} from "../../widgets/CssConstans";

export default class Button
    extends React.Component {
    render() {
        const { details } = this.props;
        return (
            <TypeButtonType2PrimaryType3RoundedS onPress={details.onPress}>
                <Button1>{details.label}</Button1>
            </TypeButtonType2PrimaryType3RoundedS>
        );
    }
}

const TypeButtonType2PrimaryType3RoundedS = styled.Pressable`
    display: flex;
    width: 100%;
    height: 55px;
    align-items: center;
    justify-content: center;

    padding: 18px 16px;
    background-color: ${props => props.theme.__others__deep_orange};
    border-radius: 100px;
`;

const Button1 = styled.Text`
    ${ValignTextMiddle}
    ${BodyLargeBold}
    flex: 1;
    align-self: stretch;
    margin-top: -2.5px;
    margin-bottom: -0.5px;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 22.4px;
`;
