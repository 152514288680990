import React from "react";
import styled from "styled-components/native";
import { StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import theme from "../../Theme";

export default class OptionReject
    extends React.Component {
    render() {
        const {
            rejectAction,
            donation,
        } = this.props.details;
        return (
            <AutoLayoutVertical>
                <Group onPress={rejectAction}>
                    <IconsDeleteclose>
                        <IconClose>
                            <FontAwesomeIcon icon={"trophy"} style={[styles.icon]} size={40} />
                        </IconClose>
                    </IconsDeleteclose>
                </Group>
                {!donation.done && <LabelBoton>Votar</LabelBoton>}
                {donation.done && <LabelBoton>Ver</LabelBoton>}
            </AutoLayoutVertical>
        );
    }
}

const styles = StyleSheet.create({
    icon: {
        color: theme.__others__white,
    }
});

const LabelBoton = styled.Text`
    font-family: ${theme.__font_family_urbanist};
    font-size: ${theme.__font_size_m2}; 
    font-weight: 700;
    color: ${props => theme.__others__white};
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-top: -0.5px;
    margin-bottom: -0.5px;
    
    margin-right: 40px;
`;

const Group = styled.Pressable`
    position: relative;
    min-width: 60px;
    height: 60px;
    background-color: ${props => props.theme.__dark__dark_2};
    border-radius: 100px;
`;

const IconsDeleteclose = styled.View`
    position: relative;
    width: 43px;
    height: 43px;
    top: 9px;
    left: 8px;
    display: flex;
    padding: 1px;
    align-items: flex-end;
    
`;

const IconClose = styled.View`
    width: 40px;
    height: 40px;
    margin-left: 1px;
`;