import React from "react";
import styled from "styled-components/native";
import Constants from "../../Constants"
import BottomBar from "./BottomBar";
import ElementsNavbar from "../../widgets/ElementsNavbar";
import OtherDonations from "./OtherDonations";
import Logo from "../svg/Logo";
import Config from "../../utils/Config";

const DIMENSIONS = {
    width: Constants.SIZE.VIEWS.width,
    maxWidth: Constants.SIZE.VIEWS.maxWidth,
    height: "735px",
    margin: Constants.SIZE.VIEWS.margin,
};

export default class MyAccepted extends React.Component {
    constructor() {
        super();
        this.state = {
            isBottomMenuOpened: false,
        };
    }
    setBottomMenu(value) {
        this.setState({ isBottomMenuOpened: value });
    }
    setBottomMenuClosed() {
        this.setBottomMenu(false);
    }
    render() {
        const {
            showTime,
            picture,
            homeAction,
            notificationAction,
            plusAction,
            chatAction,
            profileAction,
            acceptedDonations,
            wonDonations,
            goToDonationAccepted,
            goToDonationWon,
            fetchNextAcceptedDonations,
            fetchNextWonDonations,
            visitAcceptedDonationsAction,
            userRealTime,
        } = this.props.details;
        const details = {
            homeAction,
            notificationAction,
            plusAction,
            chatAction,
            profileAction,
            visitAcceptedDonationsAction,
        };
        const top = {
            left: {
                svgIcon: <Logo></Logo>,
                action: () => {

                }
            },
            right: {
                action: profileAction,
            },
        };
        if (picture) {
            top.right.svgIcon = <Avatar source={{ uri: Config.getNewPath(picture, "_xs", showTime) }}></Avatar>;
        } else {
            top.right.icon = "user";
        }
        const setBottomMenuThis = this.setBottomMenu.bind(this);
        const setBottomMenuClosedThis = this.setBottomMenuClosed.bind(this);
        return (
            <ParentContainer>
                <MyContent>
                    <OtherDonations
                        title={"Donaciones Seleccionadas"}
                        windowSize={this.props.windowSize}
                        details={{ donations: acceptedDonations }}
                        goToDonation={(donation, index) => {
                            goToDonationAccepted(donation, index);
                        }}
                        fetchNextDonations={fetchNextAcceptedDonations}>
                    </OtherDonations>
                    <OtherDonations
                        title={"Donaciones Ganadas"}
                        windowSize={this.props.windowSize}
                        details={{ donations: wonDonations }}
                        goToDonation={(donation, index) => {
                            goToDonationWon(donation, index);
                        }}
                        fetchNextDonations={fetchNextWonDonations}>
                    </OtherDonations>
                </MyContent>
                <MyFixedHeader>
                    <ElementsNavbar details={top}></ElementsNavbar>
                </MyFixedHeader>
                {this.state.isBottomMenuOpened && <BigBackground onPress={setBottomMenuClosedThis}></BigBackground>}
                <MyFooter>
                    <AutoLayoutVertical>
                        <BottomBar
                            useDirectDonation={true}
                            popupState={this.state.isBottomMenuOpened}
                            setBottomMenu={setBottomMenuThis}
                            details={details}
                            userRealTime={userRealTime}
                            alert={this.props.alert}
                            route={"MyAccepted"} />
                    </AutoLayoutVertical>
                </MyFooter>
            </ParentContainer>
        );
    }
};

const BigBackground = styled.Pressable`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
`;

const Avatar = styled.ImageBackground`
    width: 35px;
    height: 35px;
    border-radius: 100px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
`;

const DonationBigContainer = styled.View`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.__background_dark};
`;

const MyContent = styled.ScrollView`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 70px;
`;

const MyFixedHeader = styled.View`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    padding-right: 0px;
    padding-left: 0px;
`;

// justify-content: 'flex-end'; crash
const MyFooter = styled.View`
    width: 100%;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
