const OldUsers = {};

export function isOldUser(correo) {
    if (typeof correo == "string") {
        return (correo.toLowerCase() in OldUsers);
    } else {
        return false;
    }
}

export function getOldUserDetail(correo) {
    const response = OldUsers[correo.toLowerCase()];
    if (!response) {
        return null;
    } else {
        return response;
    }
}

function getUsersWithStatus() {
    const llaves = Object.keys(OldUsers);
    const conservar = {};
    for (let i = 0; i < llaves.length; i++) {
        const llave = llaves[i];
        const datos = OldUsers[llave];
        if (datos.status != "-1") {
            conservar[llave] = datos;
        }
    }
    //console.log(JSON.stringify(conservar));
}