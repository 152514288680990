import React from "react";
import styled from "styled-components/native";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import Button from "../signup/Button";

import Constants from "../../Constants"
import * as CssConstants from "../../widgets/CssConstans";
import Divider from "../../widgets/Divider";
import Participant from "./Participant";
import TopDetail1 from "./TopDetail1";
import TopDetail2 from "./TopDetail2";
import theme from "../../Theme";
import RealTime from "../../srv/RealTime";


export default class MainVote extends React.Component {
    render() {
        const {
            showTime,
            mode,//0, 1
            donation,
            submit,
            labelAction,
            pageParticipants,
            selectVote,
            goToPersonSpace,
            me,
            participants,
            shareAction,
        } = this.props.details;
        const canVote = (donation.done !== true);
        const transformFunction = (elemento, index) => {
            return <Participant
                showTime={showTime}
                key={index}
                goToPersonSpace={() => { goToPersonSpace(elemento) }}
                selectVote={selectVote}
                canVote={canVote}
                details={elemento}>
            </Participant>
        };
        const podium = donation.podium;
        const mapaPodium = {};
        if ([null, undefined].indexOf(podium) < 0) {
            const llavesLocales = Object.keys(podium);
            for (let j = 0; j < llavesLocales.length; j++) {
                const llaveLocal = llavesLocales[j];
                const thePerson = podium[llaveLocal];
                if (thePerson.won) {
                    mapaPodium[thePerson.email] = thePerson;
                }
            }
        }
        const theWinners = [];
        const theLoosers = [];
        participants.forEach((elem, index) => {
            if (elem.email in mapaPodium) {
                const inThePodium = mapaPodium[elem.email];
                elem.canceled = inThePodium.canceled;
                elem.won = inThePodium.won;
                theWinners.push(elem);
            } else {
                theLoosers.push(elem);
            }
        });
        const theWinnersRender = theWinners.map(transformFunction);
        const theLoosersRender = theLoosers.map(transformFunction);

        let showViewMore = false;
        if ([RealTime.MAX_PARTICIPANTS_VIEW, RealTime.MAX_PARTICIPANTS_VIEW + 1].indexOf(participants.length) >= 0) {
            showViewMore = true;
        }

        let styleParent = {
            'display': 'flex',
            'flexDirection': 'column',
        };

        if (this.props.style && Object.keys(this.props.style).length > 0) {
            styleParent = Object.assign(styleParent, this.props.style);
        } else {
            styleParent = Object.assign(styleParent, {
                'width': '100%',
                'height': '100%',
            });
        }

        return (
            <ParentContainer style={styleParent}>
                <MyContent>
                    <AutoLayoutVertical>
                        {mode == 0 && <TopDetail1 details={{ donation }}></TopDetail1>}
                        {mode == 1 && <AutoLayoutVertical2>
                            <TopDetail2 details={{ donation, me }}></TopDetail2>
                            <Participant canVote={canVote} showTime={showTime} selectVote={selectVote} details={me}></Participant>
                            <Divider marginBottom={"24px"}></Divider>
                        </AutoLayoutVertical2>}
                        {!canVote && <Participantes>Ganadores</Participantes>}
                        {!canVote && <MyParticipants>
                            {theWinnersRender}
                        </MyParticipants>}
                        <Participantes>Participantes</Participantes>
                        <MyParticipants>
                            {theLoosersRender}
                        </MyParticipants>
                        {showViewMore && <CargarMasContainer onPress={pageParticipants}>
                            <CargarMas>Ver más</CargarMas>
                        </CargarMasContainer>}
                    </AutoLayoutVertical>
                </MyContent>
                <MyFooter>
                    <AutoLayoutVerticalButton>
                        <AutoLayoutHorizontalButton>
                            <IconStar onPress={shareAction}>
                                <FontAwesomeIcon icon={"share-square"} style={{ color: theme.__others__white, }} size={26} />
                            </IconStar>
                        </AutoLayoutHorizontalButton>
                    </AutoLayoutVerticalButton>
                </MyFooter>
            </ParentContainer>
        );
    }
}

const IconStar = styled.Pressable`
    width: 28px;
    height: 28px;
    margin: 10px;
    margin: 16px;
`;

const ParentContainer = styled.View`

`;

const MyContent = styled.ScrollView`
    width: 100%;
    flex: 1;
`;

const MyFooter = styled.View`
    width: 100%;
    padding: 15px 24px;
    ${CssConstants.Border1pxDarkDark3}
    borderTopLeftRadius: 40px;
    borderTopRightRadius: 40px;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px;
`;

const AutoLayoutVertical2 = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const AutoLayoutVerticalButton = styled.View`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
`;

const AutoLayoutHorizontalButton = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
`;

const MyScroll = styled.ScrollView`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin-bottom: 24px;
    padding-top: 5px;
`;

const MyParticipants = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;

`;

const Participantes = styled.Text`
    ${CssConstants.H6Bold}
    width: 100%;
    min-height: 22px;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    line-height: 21.6px;
    margin-bottom: 28px;
`;

const CargarMasContainer = styled.Pressable`
    padding-bottom: 24px;
`;

const CargarMas = styled.Text`
    ${CssConstants.BodyLargeBold}
    width: 100%;
    min-height: 22px;
    font-weight: 700;
    color: ${props => props.theme.__alerts__status__info};
    text-align: right;
    line-height: 22.4px;
`;
