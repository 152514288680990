import React from 'react'
import X2RegistroEmail from '../screens/signup/X2RegistroEmail';
import { validInputEmail, validInputPassword } from '../utils/InputValidator'
import { Formik } from 'formik';
import { saveData, readData } from '../utils/MyLocalStorage';
import {
    createUserWithEmailAndPassword
} from 'firebase/auth';
import { MountedComponent } from "../utils/MountedComponent";
import Constants from '../Constants';
import AcceptTerms from "../widgets/AcceptTerms"
import { View } from 'react-native';
import myFirebase from '../config/firebase';
import { Users } from '../srv/Users';

export default class SignUpScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign(this.state, {
            loading: true,
            config: {},
            data: { email: '', password: '', rememberme: false, accept: false }
        });
        const promesas = [];
        promesas.push(readData("signin.", this.state.data).then((data) => {
            this.setState({ data: data, aceptacion: data.accept });
        }));
        this.props.loading(Promise.all(promesas).finally(() => {
            this.setState({ loading: false });
        }));
    }
    async onUser(payloadUser) {
        const promesa = this.onUserDefaultGoHome(payloadUser);
        this.props.loading(promesa);
        await promesa;
    }
    render() {
        return (
            <View>
                {!this.state.loading &&
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={this.state.data}
                        validate={values => {
                            const errors = {};
                            errors.email = validInputEmail(values.email);
                            if (errors.email === undefined) { delete errors.email; }
                            errors.password = validInputPassword(values.password);
                            if (errors.password === undefined) { delete errors.password; }
                            return errors;
                        }}
                        onSubmit={async (values) => {
                            if (values.accept !== true) {
                                this.props.alert(Constants.TXT.ALERT.primero_acepta);
                                return;
                            }
                            values.email = values.email.toLowerCase().trim();
                            if (values.rememberme === true) {
                                await saveData("signin.", values);
                            } else {
                                await saveData("signin.", { email: '', password: '', rememberme: false });
                            }

                            const promesaRegistro = new Promise(async (resolve) => {
                                try {
                                    const firebase = await myFirebase;
                                    const existeResponse = await Users.validateExistsUser(values.email, this.props.alert);
                                    if (existeResponse.exists) {
                                        const error = new Error(`El usuario ${values.email} ya aparece registrado`);
                                        error.code = error.message;
                                        throw error;
                                    }
                                    await createUserWithEmailAndPassword(firebase.auth, values.email, values.password);
                                } catch (error) {
                                    if (error.code == "auth/email-already-in-use") {
                                        this.props.alert("No se puede crear la cuenta porque ya existe una con el mismo correo");
                                    } else if (error.code == "auth/invalid-email") {
                                        this.props.alert("El correo no es válido");
                                    } else if (error.code == "auth/operation-not-allowed") {
                                        this.props.alert("Operación no permitida");
                                    } else if (error.code == "auth/weak-password") {
                                        this.props.alert("La clave es demasiado débil");
                                    } else if (error.code == "auth/too-many-requests") {
                                        this.props.alert("Has superado el máximo número de intentos, espera un momento");
                                    } else {
                                        this.props.alert(error.code);
                                    }
                                }
                                resolve();
                            });
                            this.props.loading(promesaRegistro);

                            const espera = new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                }, 10000);
                                promesaRegistro.then(() => {
                                    resolve();
                                });
                            });
                            this.props.loading(espera);

                            return promesaRegistro;
                        }}
                    >
                        {({ handleChange, handleBlur, submitForm, errors, values }) => (
                            <X2RegistroEmail
                                alert={this.props.alert}
                                creaTuCuenta={
                                    <React.Fragment>Crea{"\n"}tu cuenta</React.Fragment>
                                }
                                input5Props2={{
                                    label: "Email",
                                    leftIcon: "envelope",
                                    onChangeText: handleChange('email'),
                                    onBlur: handleBlur('email'),
                                    errors: errors.email,
                                    value: values.email,
                                }}
                                input22Props={{
                                    label: "Contraseña",
                                    leftIcon: "lock",
                                    rightIcon: "eye-slash",
                                    secret: true,
                                    onChangeText: handleChange('password'),
                                    onBlur: handleBlur('password'),
                                    errors: errors.password,
                                    value: values.password,
                                }}
                                checkboxProps={{
                                    label: "Recuérdame",
                                    onChange: handleChange('rememberme'),
                                    value: values.rememberme
                                }}
                                aceptaCondiciones={{
                                    label: (<AcceptTerms></AcceptTerms>),
                                    onChange: (event) => {
                                        this.setState({ aceptacion: event.target.value });
                                        handleChange('accept')(event);
                                    },
                                    value: values.accept
                                }}
                                buttonProps={{
                                    label: "Regístrate",
                                    onPress: submitForm
                                }}
                                oContinaCon="O continúa con"
                                yaEresMiembro="¿Ya eres miembro?"
                                iniciaTuSesin={{
                                    label: "Inicia tu sesión",
                                    onPress: () => {
                                        this.props.navigation.navigate('SignInPage', { name: 'Jane' });
                                    }
                                }}
                                button2Google={{
                                    className: "google",
                                    webClientId: Constants.LOGIN.googleClientId,
                                    acceptTerms: this.state.aceptacion,
                                }}
                                button2Apple={{
                                    className: "apple",
                                    webClientId: null,
                                    acceptTerms: this.state.aceptacion,
                                }}
                                button2Facebook={{
                                    className: "facebook",
                                    webClientId: Constants.LOGIN.facebookClientId,
                                    acceptTerms: this.state.aceptacion,
                                }}
                                recuperaClave={{
                                    label: "¿Olvidaste tu contraseña?",
                                    onPress: () => {
                                        this.props.navigation.navigate('RecuperarClavePage', { name: 'Jane' });
                                    }
                                }}
                            />
                        )}
                    </Formik>
                }
            </View>
        )
    }
}