import React, { Component } from 'react'
import { MountedComponent } from "../utils/MountedComponent";
import XPageWithMenu from '../screens/wdonation2/XPageWithMenu';

export default class WDonationScreen2 extends MountedComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign(this.state, {
            user: null,
            userBack: null,
            donationNew: {},
            donationType: null,
        });
    }
    async onUser(payloadUser) {
        if (payloadUser.user == null) {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        }
    }
    async submit() {
        if (this.state.donationType == null) {
            this.props.alert("Para continuar selecciona un tipo de donación");
            return;
        }
        this.props.route.params.type = this.state.donationType;
        this.props.navigation.navigate('WDonationPage3', this.props.route.params);
    }
    render() {

        const submit = this.submit.bind(this);
        const setState = this.setState.bind(this);
        const details = {
            selectedImage: this.props.route.params ? this.props.route.params.image : null,
            submit: submit,
            donationType: this.state.donationType,
            radioToggle: (myNewValue) => {
                setState({ donationType: myNewValue });
            }
        };
        return (
            <XPageWithMenu
                details={details}
            />
        )
    }
}