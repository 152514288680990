import { Platform } from 'react-native';


const esLocal = false;// Esto debe estar en false al desplegarlo
const CONFIG = {
    projectNameForProxy: '@ejfdelgado/ComunidadPanal',
    //projectNameForProxy: '@ejfdelgado/comunidadpanal',// Lowercase
    VERSION: "1.9.1",
    INTENT_DOMAIN: "com.panalcomunidad",
    PUSH: {
        MAPA_ICONOS: {
            // components/comunidad/IconLibrary.js
            "askrank": "message",
            "rank": "star",
            "remaining": "check",
            "chat": "message",
            "chat_group": "message",
            "won": "trophy",
            "won2": "check",
            "loose": "heart-broken",
            "almostwon": "heart-broken",
            "confirm": "bell",
            "losted": "heart-broken",
            "recalculate": "bell",
            "forgetit": "heart-broken",
            "void": "heart-broken",
            "newdon": "gift",
            "newrepost": "gift",
            "group_added": "people-group",
            "group_isadmin": "people-group",
        },
        MAPA_COLORES: {
            "askrank": "rgba(255, 255, 255, 1)",
            "rank": "rgba(255, 191, 0, 1)",
            "remaining": "rgba(255, 255, 255, 1)",
            "chat": "rgba(255, 255, 255, 1)",
            "chat_group": "rgba(255, 255, 255, 1)",
            "won": "rgba(255, 191, 0, 1)",
            "won2": "rgba(255, 191, 0, 1)",
            "loose": "rgba(255, 0, 0, 1)",
            "almostwon": "rgba(255, 255, 255, 1)",
            "confirm": "rgba(255, 255, 255, 1)",
            "losted": "rgba(255, 255, 255, 1)",
            "recalculate": "rgba(255, 255, 255, 1)",
            "forgetit": "rgba(255, 255, 255, 1)",
            "void": "rgba(255, 255, 255, 1)",
            "newdon": "rgba(255, 255, 255, 1)",
            "newrepost": "rgba(255, 255, 255, 1)",
            "group_added": "rgba(255, 255, 255, 1)",
            "group_isadmin": "rgba(255, 255, 255, 1)",
        }
    },
    LISTS: {
        DONATION: {
            TYPE: {
                PRODUCTO: "pro",
                SRV_VIRT: "srv-virt",
                SRV_PRE: "srv-pre",
                DONACION: "don",
                REPOST: "repost",
            },
            MAPA: {
                "pro": {
                    icon: "shopping-bag",
                    txt: "Producto",
                    placeInstruction: "",
                    linkInstruction: "",
                },
                "srv-virt": {
                    icon: "compass",
                    txt: "Servicio Virtual",
                    placeInstruction: "",
                    linkInstruction: "",
                },
                "srv-pre": {
                    icon: "ticket",
                    txt: "Servicio Presencial",
                    placeInstruction: "",
                    linkInstruction: "",
                },
                "don": {
                    icon: "ticket",
                    txt: "Donación",
                    placeInstruction: "Solamente el ganador/es de tu donación va a obtener esta dirección para llegar a tu encuentro.",
                    linkInstruction: "Solamente el ganador/es de tu donación va a obtener este enlace para poder acceder a la videoconferencia contigo.",
                    createdSuccessfully: "Tu donación se ha creado correctamente!",
                    createdError: "No se logró crear la donación, reintenta más tarde por favor.",
                },
                "repost": {
                    icon: "ticket",
                    txt: "Aviso",
                    placeInstruction: "La ciudad y dirección dará claridad a los interesados.",
                    linkInstruction: "El enlace será de ayuda a los interesados.",
                    createdSuccessfully: "Tu publicación se ha creado correctamente!",
                    createdError: "No se logró crear la publicación, reintenta más tarde por favor.",
                },
            },
            VIRTUAL: {
                "true": {
                    icon: "globe",
                    txt: "Virtual",
                },
                "false": {
                    icon: "compass",
                    txt: "Presencial",
                }
            }
        }
    },
    ENV: "pro",// Solo aplica para móvil, más adelante se reasigna para web.
    API: {
        CACHE_TIME: 1000 * 60 * 60 * 1, //1 Hour
        CACHE_TIME_LISTS: 1000 * 60 * 60 * 24, //24 Hours
        rootUrl: null,
        url: null,
    },
    LOGIN: {
        googleClientId: null,
        facebookClientId: null,
    },
    COLOR: {
        BLUE1: '#F2F4F8',
        BLUE2: '#C3D1F6',
        BLUE3: '#0647EE',
    },
    SIZE: {
        VIEWS: {
            width: (Platform.OS === 'web' ? "100%" : "100%"),
            maxWidth: (Platform.OS === 'web' ? "max-width: none;" : ""),
            margin: (Platform.OS === 'web' ? "0 auto" : "0"),
        },
        BOTTOM_MENU: {
            HEIGHT: 64,
        },
        BOTTOM_ICON: {
            SMALL: 30,
            BIG: 50,
        }
    },
    TXT: {
        ALERT: {
            primero_acepta: "Para continuar primero debes aceptar los términos y condiciones.",
        }
    },
    getDropletFeature(donorType) {
        // https://www.rapidtables.com/web/color/RGB_Color.html
        const MAPA = {
            "-1": {
                color: "rgba(255, 255, 255, 0.0)",// Miembro - transparente
                txt: "Miembro",
            },
            "0": {
                color: "#e93224",//Donador - roja
                txt: "Donador",
            },
            "1": {
                color: "#A19A9A",// Donador silver - Gris
                txt: "Donador Silver",
            },
            "2": {
                color: "#FFDE54",// Donador gold - Dorada
                txt: "Donador Gold"
            },
            "3": {
                color: "#5488FF",// Donador black - Blue?
                txt: "Donador Black",
            },
        };
        if ([undefined, null, ""].indexOf(donorType) >= 0) {
            return MAPA["-1"];
        }
        if (donorType in MAPA) {
            return MAPA[donorType];
        } else {
            return MAPA["-1"];
        }
    }
};

if (Platform.OS === 'web') {
    // Web
    if (esLocal) {
        CONFIG.API.rootUrl = "http://localhost:8080";
    } else {
        CONFIG.API.rootUrl = window.location.origin;
    }

    const host = window.location.host;
    if ([
        "comunidad.panal.co",
        "www.comunidad.panal.co",
        "pro-dot-panal-comunidad-app.uc.r.appspot.com",
        "v3-dot-panal-comunidad-app.uc.r.appspot.com",
    ].indexOf(host) >= 0) {
        CONFIG.ENV = "pro";
    } else if ([
        "stg-dot-panal-comunidad-app.uc.r.appspot.com",
        "v2-dot-stg-dot-panal-comunidad-app.uc.r.appspot.com",
    ].indexOf(host) >= 0) {
        CONFIG.ENV = "stg";
    } else {
        CONFIG.ENV = "dev";
    }

    /*
    CONFIG.API.rootUrl = "http://localhost:8080";
    //CONFIG.API.rootUrl = "https://comunidad.panal.co";
    CONFIG.ENV = "stg";
    */

    CONFIG.LOGIN.googleClientId = "928458581389-3tujsl8sghgc46vhov95epj2gsp3i3e2.apps.googleusercontent.com";
    CONFIG.LOGIN.facebookClientId = '789014145657047';
} else {
    // Android or iOS
    if (esLocal) {
        //CONFIG.API.rootUrl = `http://192.168.127.227:8080`;
        CONFIG.API.rootUrl = `https://${CONFIG.ENV}-dot-panal-comunidad-app.uc.r.appspot.com`;
    } else {
        if (CONFIG.ENV == 'pro') {
            //CONFIG.API.rootUrl = `https://panal-comunidad-app.uc.r.appspot.com`;
            CONFIG.API.rootUrl = `https://comunidad.panal.co`;
        } else {
            CONFIG.API.rootUrl = `https://${CONFIG.ENV}-dot-panal-comunidad-app.uc.r.appspot.com`;
        }
    }

    CONFIG.LOGIN.googleClientId = "928458581389-da5gl4iv9it5aea6vr966j00pdounect.apps.googleusercontent.com";
    // npx uri-scheme add fb789014145657047 --android
    CONFIG.LOGIN.facebookClientId = '789014145657047'; // TODO configure it and changeit
}

CONFIG.API.url = CONFIG.API.rootUrl + "/srv";

export default CONFIG;

// Validar que se tomó la decisión antes y no permitir cambiar
// En backend lanzar excepción si antes ya la tomó.

/*
- https://stackoverflow.com/questions/40145301/preventing-hardware-back-button-android-for-react-native
- Poder ver bien la parte de abajo en registrate. tal vez ya quedó...
- Usar login con apple.

Domains:
comunidad.panal.co,auth.expo.io,panal-comunidad-app.firebaseapp.com,www.comunidad.panal.co,dev-dot-panal-comunidad-app.uc.r.appspot.com,stg-dot-panal-comunidad-app.uc.r.appspot.com,panal-comunidad-app.uc.r.appspot.com,pro-dot-panal-comunidad-app.uc.r.appspot.com
Redirect URIs
https://panal-comunidad-app.firebaseapp.com/__/auth/handler,http://localhost:19006,https://auth.expo.io/@ejfdelgado/PanalComunidad,https://comunidad-358511.uc.r.appspot.com/,https://comunidad.panal.co,http://localhost:8080/,https://dev-dot-panal-comunidad-app.uc.r.appspot.com/,https://stg-dot-panal-comunidad-app.uc.r.appspot.com/,https://panal-comunidad-app.uc.r.appspot.com/,https://pro-dot-panal-comunidad-app.uc.r.appspot.com/,https://dev-dot-panal-comunidad-app.uc.r.appspot.com,https://stg-dot-panal-comunidad-app.uc.r.appspot.com,https://pro-dot-panal-comunidad-app.uc.r.appspot.com,https://comunidad.panal.co/
*/

// Sábado
// No están guardando las decisiones de acepto o rechazo en navegador de mobil.
// Hacer retries de las invocaciones http y manejo en give up con alert

// No hacer:
// Mandar al home el icono de panal o quitar el clickeable.
// Intentar configurar dominio para sendgrid.
// Verificar el enlace del correo de verificación en iphone.
// Agregar swipe a intro

// Viernes
// Hacer el deep link de share para que se abra la app o seguir en la página
// Detectar cuando es en web y solicitar instalar la app

// Pre producción:
// Pedir la lista de correos creados previamente.

// Estimado Nuevo:
// Que exista la manera de borrar una cuenta. desarrollo. backend. borrar o expirar todas las donaciones de la persona. marcar o borrar a la persona.
// Que agreguemos en el login entrar con iOS. Investigar cómo es el logeo con ios.
// Que les facilitemos un usuario y clave para que vean contenido ok.
// Pantalla de ver mi perfil + 8h (incluye mis donaciones ofrecidas) + 2h contador de mis donaciones
// Ajuste de crear donación (el popup con el icono) + 2h
// Replantear visibilidad de donaciones con decisión tomada.
// Verificar que se pueden ver las donaciones después de haber tomado una decisión.
// Cancelar donación.
// Editar donación.
// Las donaciones rechazadas se debe ver con un icono de x arriba a la derecha y opacas.
