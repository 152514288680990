import { fetchApi, serverUrl, uploadFile } from "./Http"
import {
    sendEmailVerification
} from 'firebase/auth';
import MyLog from "./MyLog";
import { getOldUserDetail } from "../utils/OldUsers";
import CONFIG from "../Constants";
import { MyAnalytics } from "../config/myAnalytics";

export class Users {
    static refererMail = null;
    static refererUrl = null;
    static setRefererMail(refererDonationId) {
        Users.refererUrl = `${CONFIG.API.rootUrl}/?donation_id=${refererDonationId}`;
        const partes = /^(.*)[-_]\d{8}[-_]\d{6}[-_]\d{3}$/ig.exec(refererDonationId);
        if (partes != null) {
            Users.refererMail = partes[1];
        }
    }
    static getDefaultUser() {
        return {
            name: "Nombre",
            picture: serverUrl("/assets/img/defavatar.jpg"),
            email: "Email",
            donorType: "-1",
            profile: "Información del perfil...",
        }
    }
    static async blockUser(email, alert) {
        const payload = {
            email: email,
        };
        const response = await fetchApi("/users/block", payload, false, alert);
        return response;
    }
    // https://storage.googleapis.com/panal-comunidad-dev-public/profile/edelgado@panal.co/me
    // https://storage.googleapis.com/panal-comunidad-dev-public/profile/edelgado%40panal.co/me
    async updateUser(payload, imageChanged, alert) {
        const filtered = {
            name: payload.name,
            bio: payload.bio,
            pushkey: payload.pushkey,
            //
            profesion: payload.profesion,
            pitch: payload.pitch,
            enterprise: payload.enterprise,
            instagram: payload.instagram,
            webpage: payload.webpage,
            //
            country: payload.country,
            city: payload.city,
            ciiu: payload.ciiu,
            institucion: payload.institucion,
            q: payload.q,
        };

        if (imageChanged) {
            MyLog.log("Image changed");
            const subUrl = "/users/updatewithimage";
            const image = payload.picture;
            await uploadFile(image, subUrl, filtered, "profile", "/me", "512", "128", "NONE");
        } else {
            MyLog.log("Image stay");
            await fetchApi("/users/update", filtered, false, alert);
        }

        const userBack = await fetchApi("/users/byid", { email: payload.email }, "reload", alert);
        return userBack;
    }
    static async readUser(email, alert) {
        const payload = {
            email: email,
        };
        const useCache = true;
        const userBack = await fetchApi("/users/byid", payload, useCache, alert);
        return userBack;
    }
    static async startErasingUser(alert) {
        const payload = {};
        const useCache = false;
        const userBack = await fetchApi("/users/eraseme", payload, useCache, alert);
        return userBack;
    }
    static async validateExistsUser(email, alert) {
        const payload = {
            email: email,
        };
        const useCache = false;
        const response = await fetchApi("/users/exists", payload, useCache, alert);
        return response;
    }
    // { method: "email" }
    static getOldUserInfo(email) {
        const details = {
            pan: false,
            com: false,
            referer: "nadie@panal.co",
        };
        const encontrado = getOldUserDetail(email);
        if (encontrado) {
            details.pan = (encontrado.pan == 1 || encontrado.com == 1);
            details.com = (encontrado.com == 1);
        }
        if (Users.refererMail != null) {
            details.referer = Users.refererMail;
        }
        return details;
    }
    static async getCurrentUser(userFront, alert) {
        const payload = Users.getOldUserInfo(userFront.email);
        const userBack = await fetchApi("/users/current", payload, false, alert);
        if (userBack.firstTime) {
            MyAnalytics.sendEvent("sign_up", payload);
            // Se acabó de crear en la base de datos...
            if (userBack.email_verified === false) {
                // No está verificado...
                await sendEmailVerification(userFront);
                if (typeof alert == "function") {
                    alert("Te acabamos de enviar un correo de verificación, ábrelo y sigue las instrucciones.", "¡Hola!");
                }
            }
        }
        return userBack;
    }
}
