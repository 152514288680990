import React from "react";
import styled from "styled-components/native";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import theme from "../../Theme";
import * as CssConstants from "../../widgets/CssConstans";
import Button from "../signup/Button";
import MyShare from "../../srv/MyShare";
import CONFIG from "../../Constants";
import MyInput from "../../widgets/MyInput";

export default class FirstMenuBottom
    extends React.Component {
    constructor(props) {
        super(props);
        this.messageRef = React.createRef();
        this.state = {
            isWriting: false,
        };
    }
    mailTo(email, donation) {
        new MyShare().mailTo(email, this.props.alert, donation);
    }
    render() {
        const {
            won,
            email,
            starAction,
            shareAction,
            donation,
            donationMenu,
            onFocusMessage,
            onBlurMessage,
            type,
            linkMore,
        } = this.props.details;
        const mailTo = this.mailTo.bind(this);
        const isSameUser = this.props.currentUser?.email == donation.owner;
        const existUserLoged = [null, undefined, ""].indexOf(this.props.currentUser?.email) < 0;
        const sendMessageThis = (() => {
            if (!this.state || !(typeof this.state.message == "string") || this.state.message.trim().length == 0) {
                this.props.alert("Debes escribir un mensaje para poder enviarlo");
                return;
            }
            const messengerPayload = {
                email: donation.owner,//email?
                name: donation.otherUser.name,
                automessage: this.state.message,
            };
            this.props.navigation.navigate('MessengerPage', messengerPayload);
            this.messageRef.current.doEmpty();
        }).bind(this);
        const messageProps = {
            secret: false,
            label: "Escribe tu mensaje...",
            onChangeText: (texto) => {
                this.setState({
                    message: texto,
                });
            },
            onSubmit: (texto) => {
                if (typeof onBlurMessage == "function") {
                    onBlurMessage();
                }
                this.setState({
                    message: texto,
                });
                sendMessageThis();
            },
            onBlur: () => {
                if (typeof onBlurMessage == "function") {
                    onBlurMessage();
                }
                setTimeout(() => {
                    this.setState({ isWriting: false });
                }, 1000);
            },
            onFocus: () => {
                if (typeof onFocusMessage == "function") {
                    onFocusMessage();
                }
                this.setState({ isWriting: true });
            },
            value: "",
        };
        const hasLinkMore = (typeof linkMore == "string" && linkMore.length > 0);
        if (type == "repost") {
            return (<AutoLayoutVertical1>
                <AutoLayoutHorizontal>
                    <AutoLayoutHorizontal2>
                        <AutoLayoutHorizontal2 style={{ flex: 1 }}>
                            {hasLinkMore && <MyProfileButton onPress={() => {
                                MyShare.openUrlOrMail(linkMore);
                            }}>
                                <MyProfileButtonText>{"Link"}</MyProfileButtonText>
                            </MyProfileButton>}
                        </AutoLayoutHorizontal2>
                        {!this.state.isWriting && <IconStar onPress={shareAction}>
                            <FontAwesomeIcon icon={"paper-plane"} style={{ color: theme.__others__white, }} size={28} />
                            <SubText>Compartir</SubText>
                        </IconStar>}
                    </AutoLayoutHorizontal2>
                </AutoLayoutHorizontal>
            </AutoLayoutVertical1>);
        } else {
            return (
                <AutoLayoutVertical1>
                    <AutoLayoutHorizontal>
                        <AutoLayoutHorizontal2>
                            {(!isSameUser && existUserLoged) && <AutoLayoutHorizontal2 style={{ flex: 1 }}>
                                <InputTextContainer>
                                    <MyInput ref={this.messageRef} details={messageProps}></MyInput>
                                </InputTextContainer>
                                {this.state.isWriting && <IconStar onPress={sendMessageThis}>
                                    <LabelText>Enviar</LabelText>
                                </IconStar>}
                            </AutoLayoutHorizontal2>}
                            {!(!isSameUser && existUserLoged) && <AutoLayoutHorizontal2 style={{ flex: 1 }}></AutoLayoutHorizontal2>}
                            {hasLinkMore && !this.state.isWriting && <IconStar onPress={() => {
                                MyShare.openUrlOrMail(linkMore);
                            }}>
                                <FontAwesomeIcon icon={"link"} style={{ color: theme.__others__white, }} size={28} />
                                <SubText>Link</SubText>
                            </IconStar>}
                            {!this.state.isWriting && <IconStar onPress={shareAction}>
                                <FontAwesomeIcon icon={"paper-plane"} style={{ color: theme.__others__white, }} size={28} />
                                <SubText>Compartir</SubText>
                            </IconStar>}
                        </AutoLayoutHorizontal2>
                    </AutoLayoutHorizontal>
                </AutoLayoutVertical1>
            );
        }
    }
}

const MyProfileButton = styled.Pressable`
    background: #2A2B39;
    border-radius: 30px;
    align-items: center;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

const MyProfileButtonText = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
`;

const InputTextContainer = styled.View`
    display: flex;
    flex: 1;
`;

const AutoLayoutVertical1 = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 15px 24px;
    background-color: ${props => props.theme.__dark__dark_1};
    borderTopLeftRadius: 24px;
    borderTopRightRadius: 24px;
    border: 1px solid;
    border-color: ${props => props.theme.__dark__dark_3};
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    height: 56px;
    align-items: center;
    position: relative;
    align-self: stretch;

`;

const AutoLayoutHorizontal1 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    
    margin: 0px;
`;

const AutoLayoutHorizontal2 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const IconStar = styled.Pressable`
    min-width: 28px;
    height: 28px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
`;

// Este se supone que era el input
// background-color: ${props => props.theme.__dark__dark_2};
const StatusDefaultTypeDefaultStateDefaul = styled.View`
    display: flex;
    height: 56px;
    align-items: center;

    padding: 0px 20px;
    flex: 1;

    border-radius: 12px;

`;

const SubText = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXsmallMedium}
    align-self: stretch;
    font-weight: 500;
    color: ${props => props.theme.__greyscale__500};
    text-align: center;
`;

const LabelText = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXlargeSemibold}
    align-self: stretch;
    font-weight: 500;
    color: ${props => props.theme.__greyscale__500};
    text-align: center;
`;
