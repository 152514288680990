import SelectScreen from './SelectScreen';

export default class WDonationWinnersScreen extends SelectScreen {
    constructor(props) {
        super(props);
        this.state.numWin = this.props.route.params.numWin;
    }
    selectChoice(elemento) {
        this.props.route.params.numWin = elemento.value;
        this.setState({ numWin: elemento.value });
    }
    onSubmit(values) {
        if ([null, undefined].indexOf(this.state.numWin) >= 0) {
            this.props.alert("Para continuar debes seleccionar al menos una opción");
            return;
        }
        super.myLocalGoBack(this.props.route.params);
    }
    render() {
        const selectChoice = this.selectChoice.bind(this);
        const onSubmit = this.onSubmit.bind(this);
        const listaOpciones = [
            {
                izq: { label: "1" },
                value: 1,
                onPress: selectChoice,
            },
            {
                izq: { label: "2" },
                value: 2,
                onPress: selectChoice,
            },
            {
                izq: { label: "3" },
                value: 3,
                onPress: selectChoice,
            }
        ];
        super.addHighlighted(listaOpciones, this.state.numWin);
        // Se revisa cuál debe estar 
        const autoSubmit = true;
        const instructions = "Esta es la cantidad de personas que van a recibir tu donación simultáneamente.";
        return super.myRender(listaOpciones, instructions, onSubmit, null, autoSubmit);
    }
}