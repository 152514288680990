import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { NotificationsSrv } from '../srv/NotificationsSrv';
import { Users } from '../srv/Users';
import myFirebase, { getAppInstanceId, getAppInstanceValue, setAppInstanceValue } from './firebase';

const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK';
const PUSH_CONFIRMATION = "PUSH_CONFIRMATION";

// 1. go to firebase and pick Server key
// https://console.firebase.google.com/project/panal-comunidad-app/settings/cloudmessaging/android:com.panalcomunidad
// 2. Paste Server Key https://expo.dev/accounts/ejfdelgado/projects/PanalComunidad/credentials/android/com.panalcomunidad
// 3. then run command 
// expo push:android:upload --api-key <Server key>

if (Platform.OS !== 'web') {
    Notifications.setNotificationHandler({
        handleNotification: async () => ({
            shouldShowAlert: true,
            shouldPlaySound: true,
            shouldSetBadge: false,
        }),
    });
}
/*
console.log("MyPush:running static");

TaskManager.defineTask(BACKGROUND_NOTIFICATION_TASK, ({ data, error, executionInfo }) => {
    console.log("MyPush:backgroundHandler");
    console.log(JSON.stringify(data));
});
Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK);
console.log("MyPush:running static OK");
*/

export class MyPush {
    static isRegistered = false;
    static myAlert = null;
    static navigation = null;
    static myEvents = null;
    /**
     * Fired if app is on top
     */
    notificationHandler(notification) {

    }
    /**
     * Fired after tap into it
     */
    responseHandler(response) {
        console.log("MyPush:responseHandler");
        if (MyPush.myEvents == null || MyPush.navigation == null) {
            return;
        }
        MyPush.myEvents.emit("pushArrived", "");
        const notification = response.notification;
        const fecha = notification.date;
        const content = notification.request.content;
        const title = content.title;
        const myJson = content.data;
        const srv = new NotificationsSrv(MyPush.navigation);
        srv.forwardNotification(myJson, MyPush.alert, MyPush.popUpNotification);
    }
    backgroundHandler({ data, error, executionInfo }) {

    }
    async registerNewToken(myAlert, myConfirm, myLoading, askAgain = false) {
        console.log("MyPush:registerNewToken");
        MyPush.myAlert = myAlert;
        if (Platform.OS === 'web') {
            return true;
        }

        // Se espera que el usuario esté logeado
        let usuario = null;
        let theFirebase = null;
        try {
            theFirebase = await myFirebase;
            usuario = await theFirebase.promesaUsuario;
        } catch (err) {
            myAlert(err.message);
            return false;
        }

        if (usuario.user == null || usuario.userBack == null) {
            //No user, so just go out
            return false;
        }
        // Se debe buscar primero en sus datos de perfil
        let creado = false;
        const appInstanceId = await getAppInstanceId();
        if (!("pushkey" in usuario.userBack) || typeof usuario.userBack.pushkey != "object" || !(appInstanceId in usuario.userBack.pushkey)) {
            const confimacionPrevia = await getAppInstanceValue(PUSH_CONFIRMATION);
            if (confimacionPrevia == "NO") {
                if (askAgain == false) {
                    return true;
                }
            }
            const confirmo = await myConfirm({
                message: "¿Deseas recibir notificaciones de alerta cuando te ganes una donación? si aceptas, esto puede tardar un minuto.",
                acceptLabel: "Sí",
                cancelLabel: "No",
            });
            try {
                if (!confirmo) {
                    // Guardar que no quiere
                    await setAppInstanceValue(PUSH_CONFIRMATION, "NO");
                    return true;
                } else {
                    await setAppInstanceValue(PUSH_CONFIRMATION, "SÍ");
                }
                if (typeof usuario.userBack.pushkey != "object") {
                    usuario.userBack.pushkey = {};
                }
                // De lo contrario se crea el token
                let token;
                const promesa1 = Notifications.getPermissionsAsync();
                myLoading(promesa1);
                const { status: existingStatus } = await promesa1;
                let finalStatus = existingStatus;
                if (existingStatus !== 'granted') {
                    const promesa2 = Notifications.requestPermissionsAsync();
                    myLoading(promesa2);
                    const { status } = await promesa2;
                    finalStatus = status;
                }
                if (finalStatus !== 'granted') {
                    myAlert("No se lograron configurar las notificaciones.");
                    return false;
                }
                const promesa3 = Notifications.getExpoPushTokenAsync();
                myLoading(promesa3);
                token = (await promesa3).data;
                // Se guarda el token
                usuario.userBack.pushkey[appInstanceId] = token;
                const updatePayload = {
                    pushkey: usuario.userBack.pushkey,
                };
                const promesa4 = new Users().updateUser(updatePayload, false);
                myLoading(promesa4);
                await promesa4;
                theFirebase.mergeBackUser(updatePayload);
                creado = true;
            } catch (err) {
                myAlert(err.message);
                return false;
            }
        }

        //myAlert(`${creado} ${CONFIG.ENV} ${usuario.email} ${appInstanceId} ${usuario.userBack.pushkey[appInstanceId]}`, "Información");

        if (Platform.OS === 'android') {
            await Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }
        if (creado) {
            myAlert("Listo! recibirás notificaciones", "Información");
        }

        if (!MyPush.isRegistered) {
            console.log("MyPush:registering");
            MyPush.isRegistered = true;
            const instance = new MyPush();
            Notifications.addNotificationReceivedListener(instance.notificationHandler);
            Notifications.addNotificationResponseReceivedListener(instance.responseHandler);
            //myAlert("Registering events...");//Siempre está entrando acá
        }
        return true;
    }
}
