import React from "react";
import styled from "styled-components/native";
import * as CssConstants from "./CssConstans";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import theme from '../Theme';

const ICON_SIZE = 20;
const ICON_STYLE = {
    color: theme.__others__deep_orange,
    marginLeft: 1,
    marginTop: 1,
};

export default class MyRadio
    extends React.Component {
    constructor(props) {
        super(props);
        const { value, options, disabled } = props.details;
        this.state = {
            current: value,
            options
        };
    }

    setValue(nuevoValor) {
        this.setState({ current: nuevoValor });
        if (typeof this.props.details?.onChange == 'function') {
            const evento = {
                type: "",
                name: "",
                id: "",
                value: nuevoValor,
                checked: nuevoValor,
                outerHTML: "",
                options: {},
                multiple: false
            };
            this.props.details.onChange({ target: evento });
        }
    }

    render() {
        const {
            errors,
        } = this.props.details;
        const setValueThis = this.setValue.bind(this);
        const listaElem = this.state.options.map((el, i) => {
            return <MyRadioIcon details={el} setValue={setValueThis} selected={this.state.current} key={i}></MyRadioIcon>
        });
        return (<Parent1>
            {listaElem}
            {errors !== undefined && <ContainerError>{errors}</ContainerError>}
        </Parent1>)
    }
}

class MyRadioIcon extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            txt,
            val
        } = this.props.details;
        const valor = this.props.value;
        return <MyButton onPress={() => {
            this.props.setValue(val);
        }}>
            <Label>{txt}</Label>
            {val !== this.props.selected && <RadioOn></RadioOn>}
            {val == this.props.selected && <RadioOff><RadioOn2></RadioOn2></RadioOff>}
        </MyButton>
    }
}

const RadioOn = styled.View`
    width: 22px;
    height: 22px;
    border: 3px solid;
    border-color: ${props => props.theme.__others__deep_orange};
    border-radius: 100%;
`;
const RadioOn2 = styled.View`
    width: 100%;
    height: 100%;
    border: 3px solid;
    border-color: #000000;
    background-color: ${props => props.theme.__others__deep_orange};
    border-radius: 100%;
`;

const RadioOff = styled.View`
    width: 22px;
    height: 22px;
    border: 3px solid;
    border-color: ${props => props.theme.__others__deep_orange};
    background-color: ${props => props.theme.__others__deep_orange};
    border-radius: 100%;
`;

const ContainerError = styled.Text`
  width: 100%;
  color: ${props => props.theme.__others__deep_orange};
  font-size: ${props => props.theme.__font_size_xl2};
  margin-top: 10px;
`;

const MyButton = styled.Pressable`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 24px;
`;

const Parent1 = styled.View`
    display: flex;
    flex-direction: column;
`;

const Label = styled.Text`
  ${CssConstants.ValignTextMiddle}
  ${CssConstants.BodyLargeRegular}
  color: ${props => props.theme.__others__white};
  line-height: 19.6px;
  flex: 1;
  padding-left: 10px;
`;

