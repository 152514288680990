import * as React from 'react';
import styled from "styled-components/native";
import theme from '../Theme';
import { MyDates } from '../utils/MyDates';
//import RNPickerSelect from 'react-native-picker-select';
import "./css/select.css";
import MyLog from '../srv/MyLog';
import RNPickerSelect from './RNPickerSelect/RNPickerSelect';

// https://www.npmjs.com/package/react-native-picker-select
class MyDateTimePicker extends React.Component {
  constructor(props) {
    super(props);
    const { value } = props.details;
    let myEpoch = parseInt(value);
    this.state = {
      fecha: "",
      hora: "",
      minuto: "",
      ampm: "",
      fechaOk: true,
      horaOk: true,
      minutoOk: true,
      ampmOk: true,
    };
    if (!isNaN(myEpoch)) {
      const theDate = new Date(myEpoch);
      this.state.fecha = MyDates.toAAAAMMDD(theDate);
      this.state.hora = "" + (theDate.getHours() * 60 + (parseInt(theDate.getMinutes() / 15) * 15));
      MyLog.log(`this.state.hora = ${this.state.hora}`);
    }

    this.lastMinDate = null;
    this.lastMaxDate = null;
    if (this.props.details.ref) {
      this.props.details.ref.setExternalDate = this.setExternalDate.bind(this);
    }
  }
  setExternalDate(epoch) {
    const theDate = new Date(epoch);
    const state = {};
    state.fecha = MyDates.toAAAAMMDD(theDate);
    state.hora = "" + (theDate.getHours() * 60 + (parseInt(theDate.getMinutes() / 15) * 15));
    this.setState(state);
  }
  computeErrors() {
    let fecha = parseInt(this.state.fecha);
    let hora = parseInt(this.state.hora);

    const borderErrors = {
      fechaOk: !isNaN(fecha),
      horaOk: !isNaN(hora),
    };
    return borderErrors;
  }
  handleChange() {
    let fecha = parseInt(this.state.fecha);
    let hora = parseInt(this.state.hora);

    const borderErrors = this.computeErrors();

    if (
      !borderErrors.fechaOk ||
      !borderErrors.horaOk
    ) {
      if (typeof this.props.details.onChangeText == "function") {
        this.props.details.onChangeText("");
      }
      if (typeof this.props.details.onChange == "function") {
        this.props.details.onChange(null);
      }
    } else {

      const dia = fecha % 100;
      fecha = parseInt(fecha / 100);
      const mes = fecha % 100;
      fecha = parseInt(fecha / 100);
      const anio = fecha;

      const minuto = hora % 60;
      const horaReal = parseInt(hora / 60);

      const nuevaFecha = new Date(anio, mes, dia, horaReal, minuto);
      if (typeof this.props.details.onChangeText == "function") {
        this.props.details.onChangeText("" + nuevaFecha.getTime());
      }
      if (typeof this.props.details.onChange == "function") {
        this.props.details.onChange(nuevaFecha.getTime());
      }
    }
  }
  render() {
    const {
      minDate,
      maxDate,
      onChangeText,
      errors,
      onBlur,
      editable,
    } = this.props.details;
    if (this.props.details.ref) {
      this.props.details.ref.setExternalDate = this.setExternalDate.bind(this);
    }
    if (this.lastMinDate !== minDate || this.lastMaxDate !== maxDate) {
      this.opcionesFechas = MyDates.generateDates(minDate, maxDate);
      this.lastMinDate = minDate;
      this.lastMaxDate = maxDate;
    }
    this.opcionesHoras = MyDates.getHoursLimited(this.state.fecha, minDate, maxDate);

    const setState = this.setState.bind(this);
    const handleChange = this.handleChange.bind(this);

    // border: 0px;
    const inputAndroid = {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      height: 58,
      backgroundColor: "rgba(35, 37, 47, 1)",
      borderRadius: 12,
      color: "rgba(158, 158, 158, 1)",
      fontFamily: "Urbanist",
    };

    const inputIOS = {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      height: 58,
      backgroundColor: "rgba(35, 37, 47, 1)",
      borderRadius: 12,
      color: "rgba(158, 158, 158, 1)",
      fontFamily: "Urbanist",
    };

    const showErrors = (errors !== undefined);

    const borderErrors = this.computeErrors();
    return (
      <ContainerVertical>
        <ContainerHorizontal>
          <SubContainer>
            <MyLocalSelect alertError={showErrors && !borderErrors.fechaOk}>
              <RNPickerSelect
                disabled={editable === false}
                useNativeAndroidPickerStyle={false}
                fixAndroidTouchableBug={true}
                style={{ inputAndroid: inputAndroid, inputIOS: inputIOS }}
                placeholder={{ label: "Fecha", value: "" }}
                value={this.state.fecha}
                onValueChange={(value) => {
                  setState({ fecha: value });
                  handleChange();
                }}
                items={this.opcionesFechas}
              />
            </MyLocalSelect>
            <MyLocalSelect alertError={showErrors && !borderErrors.horaOk}>
              <RNPickerSelect
                disabled={editable === false}
                useNativeAndroidPickerStyle={false}
                fixAndroidTouchableBug={true}
                style={{ inputAndroid: inputAndroid, inputIOS: inputIOS }}
                placeholder={{ label: "Hora", value: "" }}
                value={this.state.hora}
                onValueChange={(value) => {
                  setState({ hora: value });
                  handleChange();
                }}
                items={this.opcionesHoras}
              />
            </MyLocalSelect>
          </SubContainer>
        </ContainerHorizontal>
        {errors !== undefined && <ContainterError>{errors}</ContainterError>}
      </ContainerVertical>
    );
  }
}

const MyLocalSelect = styled.View`
  margin-right: 10px;
  border-radius: 12px;
  border: ${props => props.alertError ? "1px" : "0px"};
  border-color: ${props => props.theme.__others__deep_orange};
`;

const ContainterError = styled.Text`
  width: 100%;
  color: ${props => props.theme.__others__deep_orange};
  font-size: ${props => props.theme.__font_size_xl2};
  margin-top: 10px;
`;

const ContainerVertical = styled.View`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

const ContainerHorizontal = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;

const SubContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
`;

export default MyDateTimePicker;