import * as React from 'react';
import AssetsImg from '../../AssetsImg';
import styled from "styled-components/native";

const TheClose = (props) => {
   let size = 26;
   if (typeof props.size == "number") {
      size = props.size;
   }
   return (<Frame size={size} source={AssetsImg.IMPORTED.theclose_blur} />);
}

const Frame = styled.Image`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
`;

export default TheClose;