import React from "react";
import styled from "styled-components/native";
import { 
    Border1pxGreyscale200, 
    BodyLargeSemibold 
} from "../../widgets/CssConstans";
import Frame8 from "../svg/Frame8"
import { SocialFacebook } from "../../utils/hooks/SocialFacebook"

export default class Button4 extends React.Component {
    render() {
        const { label, webClientId, acceptTerms } = this.props.details;
        return (
            <SocialFacebook alert={this.props.alert} webClientId={webClientId} acceptTerms={acceptTerms} mystyle={{ width: "100%" }}>
                <TypeLoginType2SocialType3FacebookSt>
                    <AutoLayoutHorizontal>
                        <Frame8></Frame8>
                        <ContinueWithFacebook>{label}
                        </ContinueWithFacebook>
                    </AutoLayoutHorizontal>

                </TypeLoginType2SocialType3FacebookSt>
            </SocialFacebook>
        );
    }
}

const TypeLoginType2SocialType3FacebookSt = styled.View`
    ${Border1pxGreyscale200}
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    justify-content: center;
    
    padding: 18px 32px;
    background-color: ${props => props.theme.__background_dark_button};
    
    border-radius: 16px;
    margin: 8px 8px 8px 0px;
`;


const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-flow: row;

    align-items: center;
    justify-content: center;
    
`;

const ContinueWithFacebook = styled.Text`
    ${BodyLargeSemibold}
    min-width: 179px;
    font-weight: 600;
    color: ${props => props.theme.__others__white};
    line-height: 22.4px;
    margin-left: 12px;
`;