import MyLog from '../srv/MyLog';
import CONFIG from '../Constants';
import { isValidURLWithInstagramUser } from '../utils/InputValidator';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME = "linkMeet";

export default class WDonationLinkScreen extends SelectScreen {
    constructor(props) {
        super(props);
        this.state[FIELD_KEY_NAME] = this.props.route.params[FIELD_KEY_NAME];
        this.state.data[FIELD_KEY_NAME] = this.state[FIELD_KEY_NAME];
    }
    onSubmit(values) {
        this.props.route.params[FIELD_KEY_NAME] = values[FIELD_KEY_NAME];
        super.myLocalGoBack(this.props.route.params);
        // this.props.navigation.navigate('WDonationPlaceMeetPage', this.props.route.params);
    }
    validate(values) {
        MyLog.log("validate", this);
        const errors = {};
        const hayValor = ["", null, undefined].indexOf(values[FIELD_KEY_NAME]) < 0;
        if (hayValor) {
            errors[FIELD_KEY_NAME] = isValidURLWithInstagramUser(values[FIELD_KEY_NAME]);
            if (errors[FIELD_KEY_NAME] === undefined) { delete errors[FIELD_KEY_NAME]; }
        }
        return errors;
    }
    render() {
        const listaOpciones = [
            {
                izq: { label: "URL" },
                der: {
                    type: "input-text",
                    details: {
                        name: FIELD_KEY_NAME,
                    }
                }
            }
        ];
        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = CONFIG.LISTS.DONATION.MAPA[this.props.route.params.type].linkInstruction;
        return super.myRender(listaOpciones, instructions, onSubmit, validate);
    }
}