import React from "react";
import styled from "styled-components/native";
import Constants from "../../Constants"
import BottomBar from "./BottomBar";
import DonationBig from "./DonationBig";
import ElementsNavbar from "../../widgets/ElementsNavbar";
import OtherDonations from "./OtherDonations";
import Logo from "../svg/Logo";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import theme from "../../Theme";
import * as CssConstants from "../../widgets/CssConstans";
import MyLog from "../../srv/MyLog";
import Config from "../../utils/Config";
import AssetsImg from "../../AssetsImg";

const DIMENSIONS = {
    width: Constants.SIZE.VIEWS.width,
    maxWidth: Constants.SIZE.VIEWS.maxWidth,
    height: "735px",
    margin: Constants.SIZE.VIEWS.margin,
};

export default class MyHome extends React.Component {
    constructor() {
        super();
        this.state = {
            isBottomMenuOpened: false,
        };
    }
    setBottomMenu(value) {
        this.setState({ isBottomMenuOpened: value });
    }
    setBottomMenuClosed() {
        this.setBottomMenu(false);
    }
    render() {
        const {
            showTime,
            picture,
            homeAction,
            notificationAction,
            plusAction,
            chatAction,
            profileAction,
            mainDonation,
            donations,
            goToDonation,
            fetchNextDonations,
            visitAcceptedDonationsAction,
            showAbout,
            userRealTime,
        } = this.props.details;
        const details = {
            homeAction,
            notificationAction,
            plusAction,
            chatAction,
            profileAction,
            visitAcceptedDonationsAction,
        };
        const top = {
            left: {
                svgIcon: <Logo></Logo>,
                action: showAbout,
            },
            right: {
                action: profileAction,
            },
        };
        if (picture) {
            top.right.svgIcon = <Avatar source={{ uri: Config.getNewPath(picture, "_xs", showTime) }}></Avatar>;
        } else {
            top.right.svgIcon = <Avatar source={AssetsImg.IMG.DEFAVATAR}></Avatar>;
        }

        let estaVacio = (donations.length == 0 && ([null, undefined].indexOf(mainDonation) >= 0) || Object.keys(mainDonation).length == 0);
        const myHeight = parseInt((7 / 12) * this.props.windowSize.height);
        const setBottomMenuThis = this.setBottomMenu.bind(this);
        const setBottomMenuClosedThis = this.setBottomMenuClosed.bind(this);
        return (
            <ParentContainer>
                <MyContent>
                    {estaVacio && <ContenedorVacio myHeight={myHeight}>
                        <ContenedorIcono>
                            <FontAwesomeIcon icon={"hands-clapping"} style={{ color: theme.__others__white }} size={29} />
                        </ContenedorIcono>
                        <MiTexto>¡No hay más donaciones que revisar!</MiTexto>
                    </ContenedorVacio>}
                    {!estaVacio && <DonationBig
                        windowSize={this.props.windowSize}
                        details={mainDonation}
                        goToDonation={goToDonation}></DonationBig>}
                    <OtherDonations
                        windowSize={this.props.windowSize}
                        details={{ donations: donations }}
                        goToDonation={goToDonation}
                        fetchNextDonations={fetchNextDonations}>
                    </OtherDonations>
                </MyContent>
                <MyFixedHeader>
                    <ElementsNavbar details={top}></ElementsNavbar>
                </MyFixedHeader>
                {this.state.isBottomMenuOpened && <BigBackground onPress={setBottomMenuClosedThis}></BigBackground>}
                <MyFooter>
                    <AutoLayoutVertical>
                        <BottomBar
                            useDirectDonation={true}
                            popupState={this.state.isBottomMenuOpened}
                            setBottomMenu={setBottomMenuThis}
                            details={details}
                            userRealTime={userRealTime}
                            alert={this.props.alert}
                            route={"MyHome"} />
                    </AutoLayoutVertical>
                </MyFooter>
            </ParentContainer>
        );
    }
};

const BigBackground = styled.Pressable`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
`;

const Avatar = styled.ImageBackground`
    width: 35px;
    height: 35px;
    border-radius: 100px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
`;

const MiTexto = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H6Bold}
    align-self: stretch;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
`

const ContenedorVacio = styled.View`
    width: 100%;
    height: ${props => props.myHeight}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ContenedorIcono = styled.View`
    width: 40px;
    height: 40px;
`;

const DonationBigContainer = styled.View`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.__background_dark};
`;

const MyContent = styled.ScrollView`
    width: 100%;

    flex: 1;
`;

const MyFixedHeader = styled.View`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    padding-right: 0px;
    padding-left: 0px;
`;

// justify-content: 'flex-end'; crash
const MyFooter = styled.View`
    width: 100%;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
