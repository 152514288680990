import { DonacionesSrv } from '../srv/DonacionesSrv';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_1 = "reschedulle";
const FIELD_KEY_NAME_2 = "new_date";
const FIELD_KEY_NAME_3 = "details";

export default class WSurveyWinnerMissedScreen extends SelectScreen {
    constructor(props) {
        super(props);
        if (this.props.route.params === undefined) {
            this.props.route.params = {};
        }
        super.initValue(FIELD_KEY_NAME_1, null);
        super.initValue(FIELD_KEY_NAME_2, null);
        super.initValue(FIELD_KEY_NAME_3, null);
    }
    sayGoodBye() {
        const actions = [
            {
                label: "Aceptar",
                onPress: (finish) => {
                    // Go home
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'MyHomePage' }],
                    });
                }
            }
        ];
        const onlyMyActions = true;
        this.props.alert("Gracias por tu participación", "Listo!", actions, onlyMyActions);
    }
    cancelSurvey() {
        console.log("Cancel survey");
        this.sayGoodBye();
    }
    async onSubmit(values) {
        const pageType = this.getPageType();
        const nuevaFecha = values[FIELD_KEY_NAME_2];
        const reagendo = (this.state.data[FIELD_KEY_NAME_1] === true);
        const comments = values[FIELD_KEY_NAME_3];
        const notification = this.props.route.params?.notification;
        if (!notification) {
            this.props.alert("La notificación está incompleta");
        } else {
            const notificationId = notification.id;
            const donationId = notification.payload.donation.id;
            const winner = this.props.route.params?.winner;

            if (reagendo) {
                const dateStartMeet = parseInt(nuevaFecha);
                try {
                    const promesa = DonacionesSrv.reSchedule(notificationId, donationId, dateStartMeet, winner, this.props.alert);
                    this.props.loading(promesa);
                    await promesa;
                    this.sayGoodBye();
                } catch (err) {

                }
            } else {
                try {
                    const promesa = DonacionesSrv.setFinishDetail(donationId, comments, false, winner, this.props.alert);
                    this.props.loading(promesa);
                    await promesa;
                    this.sayGoodBye();
                } catch (err) {

                }
            }

        }
    }
    validate(values) {
        const reagendo = this.state.data[FIELD_KEY_NAME_1];
        const nuevaFecha = values[FIELD_KEY_NAME_2];
        const comments = values[FIELD_KEY_NAME_3];
        const errors = {};
        let errorCount = 0;

        if (reagendo) {
            if (isNaN(parseInt(nuevaFecha))) {
                errors[FIELD_KEY_NAME_2] = "Falta definir la fecha y hora";
                errorCount++;
            }
        } else {
            delete errors[FIELD_KEY_NAME_2];
            if (typeof comments != "string" || comments.trim().length == 0) {
                errors[FIELD_KEY_NAME_3] = "Por favor deja tu comentario";
                errorCount++;
            }
        }

        if (errorCount > 0) {
            this.props.alert("Verifica los campos");
        }

        return errors;
    }
    assignValue(key, event) {
        const nuevo = { data: this.state.data };
        nuevo.data[key] = event.target.value;
        this.setState(nuevo);
    }
    getPageType() {
        let pageType = null;
        const currentPage = this.getCurrentState().name;
        if (currentPage == "WSurveyWinnerMissedPage") {
            pageType = "winner";
        } else if (currentPage == "WSurveyDonatorMissedPage") {
            pageType = "donator";
        }
        return pageType;
    }
    render() {
        const pageType = this.getPageType();

        const assignValueThis = this.assignValue.bind(this);
        const listaOpciones = [
            {
                izq: {},
                der: {
                    type: "radio",
                    details: {
                        type: "switch",
                        label: "¿Reagendaron?",
                        name: FIELD_KEY_NAME_1,
                        onChange: (event) => {
                            assignValueThis(FIELD_KEY_NAME_1, event);
                        }
                    }
                }
            }
        ];

        if (this.state.data[FIELD_KEY_NAME_1]) {
            const ahora = new Date();
            const oneMonthLater = new Date();
            oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
            listaOpciones.push({
                izq: { label: "Nueva Fecha de Donación" },
                der: {
                    type: "datetime",
                    details: {
                        minDate: ahora,
                        maxDate: oneMonthLater,
                        name: FIELD_KEY_NAME_2,
                    }
                }
            });
            listaOpciones.push({
                izq: { labelLight: "La donación se debe de recibir dentro del próximo mes para ser considerada como redimida." }
            });
        } else {
            if (pageType == "winner") {
                listaOpciones.push({
                    izq: { labelLight: "Lamentamos lo sucedido compártenos los detalles de la situación para poder reestablecerte la donación y/o votos ganados." }
                });
            } else if (pageType == "donator") {
                listaOpciones.push({
                    izq: { labelLight: "Lamentamos lo sucedido, compártenos los detalles de la situación para abonarte los puntos de esta donación como entregada." }
                });
            }
            listaOpciones.push({
                izq: { label: "Cuéntanos qué paso" },
                der: {
                    type: "input-text",
                    details: {
                        name: FIELD_KEY_NAME_3,
                        multiline: true,
                        numberOfLines: 4,
                    }
                }
            });
        }
        const cancelSurveyThis = this.cancelSurvey.bind(this);
        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = "";
        const autoSubmit = false;
        const searchFunction = null;
        const actionDefinition = {
            primary: {
                label: "Enviar",
            },
            /*secondary: {
                label: "Saltar",
                onPress: cancelSurveyThis,
            }*/
        };
        return super.myRender(listaOpciones, instructions, onSubmit, validate, autoSubmit, searchFunction, actionDefinition);
    }
}