import React from "react";
import styled from "styled-components/native";
import Modal from "react-native-modal";
import { Platform } from 'react-native';
import { Dimensions } from 'react-native';
import theme from "../Theme";
import * as CssConstans from "./CssConstans"
import Button from "../screens/signup/Button";
import MyLog from "../srv/MyLog";
import * as CssConstants from "./CssConstans";
import MyInput from "./MyInput";
import TheClose from "../screens/svg/TheClose";

/*
this.props.alertOpen({
    title: "¡Felicitaciones!",
    description: "Esto es una prueba",
    buttons: [
        {
            label: "Cancelar",
            type: "secondary",
            onPress: async () => {

            }
        },
        {
            label: "Aceptar",
            type: "primary",
            onPress: async () => {

            }
        }
    ]
});
*/
export default class MyModal extends React.Component {
    render() {
        const {
            title,
            description,
            component,
            buttons,
            myWidth,
            myStyles,
            prompt,
            extra,
            useFixedPage,
        } = this.props.details;

        //Mapeo los botones
        const autoWidth = !(buttons.length == 1);
        const botones = buttons.map((elemento, index) => {
            const COLORES = {
                "primary": theme.__others__deep_orange,
                "secondary": theme.__background_dark
            };
            const COLOR_LETRA = {
                "primary": theme.__others__white,
                "secondary": theme.__others__white
            };
            const bgColor = COLORES[elemento.type];
            const charColor = COLOR_LETRA[elemento.type];
            return <Button
                key={index}
                details={{
                    label: elemento.label,
                    bgColor: bgColor,
                    charColor: charColor,
                    autoWidth: false,
                    onPress: async () => {
                        if (typeof elemento.onPress == "function") {
                            await elemento.onPress();
                        }
                        if (this.props.avoidAutoClose !== true && typeof this.props.close == "function") {
                            this.props.close();
                        }
                    }
                }}
            ></Button>;
        }
        );
        const myWindow = Dimensions.get('window');
        let width = myWidth;
        if ([null, undefined, ""].indexOf(width) >= 0) {
            if (Platform.OS === 'web') {
                if (myWindow.width < 600) {
                    width = "85%";
                } else if (myWindow.width < 900) {
                    width = "65%";
                } else {
                    width = "40%";
                }
            } else {
                width = "85%";
            }
        }
        return (
            <Modal
                coverScreen={useFixedPage !== true}
                isVisible={true}
                style={myStyles}>
                <ModalContainer>
                    <ModalOverflow />
                    <ModalSubContainer width={width}>
                        {(extra?.canClose === true || extra?.image) && <ImageAndClose>
                            {extra?.canClose === true && <CloseButton onPress={() => {
                                if (typeof extra?.closeAction == "function") {
                                    extra?.closeAction(this.props.close);
                                } else {
                                    this.props.close();
                                }
                            }}>
                                <TheClose></TheClose>
                            </CloseButton>}
                            {extra?.image && <MyImage source={extra?.image}></MyImage>}
                        </ImageAndClose>}
                        <TituloContainer>
                            <MyTextTitle>{title}</MyTextTitle>
                        </TituloContainer>
                        <DescriptionContainer>
                            {component === undefined && <MyTextDescription>{description}</MyTextDescription>}
                            {component !== undefined && component}
                        </DescriptionContainer>
                        {prompt && <PromptContainer><MyInput details={prompt}></MyInput></PromptContainer>}
                        <ButtonContainer>
                            {botones}
                        </ButtonContainer>
                    </ModalSubContainer>
                    <ModalOverflow />
                </ModalContainer>
            </Modal>
        );
    }
}

const MyImage = styled.ImageBackground`
    width: 150px;
    height: 150px;
    margin: 0 auto;
    background-size: contain;
    margin-top: 15px;
    margin-bottom: 15px;
`;

const CloseButton = styled.Pressable`
    position: absolute;
    right: 0;
    top: 0;
    width: 26px;
    height: 26px;
`;

const ImageAndClose = styled.View`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 26px;
`;

const PromptContainer = styled.View`
    margin-bottom: 10px;
    width: 100%;
`;

const MyTextTitle = styled.Text`
    ${CssConstans.H4Bold}
    text-align: center;
    color: ${theme.__others__deep_orange};


    margin-bottom: 16px;
`;

const MyTextDescription = styled.Text`
    font-family: Urbanist;
    color: ${theme.__others__white};
    font-size: 18px;
    margin-bottom: 16px;
    text-align: center;
`;

const TituloContainer = styled.View`
    width: 100%;
`;

const DescriptionContainer = styled.View`
    width: 100%;
`;

const ButtonContainer = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

const ModalOverflow = styled.View`
    flex: 1;
`;

const ModalSubContainer = styled.View`
    background-color: ${theme.__dark__dark_2};
    border-radius: 24px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};;
    margin: 0 auto;
`;


const ModalContainer = styled.View`
    flex: 1;
    display: flex;
    flex-direction: column;
`;