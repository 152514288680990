import { fetchApiInternal, uploadFile } from "./Http";
import md5 from 'md5';
import * as FileSystem from 'expo-file-system';
import { MyDates } from "../utils/MyDates";

const MAX_PAGE_MEMBERS = 20;

export class GroupsSrv {
    // await GroupsSrv.loadCompleteGroup(groupId, currenUserEmail, max, alert, loading);
    static async loadCompleteGroup(groupId, currenUserEmail, max, alert, loading) {
        const promesas = [];
        promesas.push(GroupsSrv.pageAllMembers(groupId, currenUserEmail, max, alert, loading));
        promesas.push(GroupsSrv.readById(groupId, alert));
        const responses = await Promise.all(promesas);
        return {
            group: responses[1].group,
            members: responses[0].selectedListLoaded,
        };
    }
    static async pageAllMembers(groupId, currenUserEmail, max, alert, loading) {
        let offset = 0;
        let page = {
            members: [],
        };
        const selectedListLoaded = [];
        const originalUserIds = [];
        let imOwnerGroup = false;
        do {
            const promesa = GroupsSrv.pageMembers(groupId, max, offset, alert);
            loading(promesa);
            page = await promesa;
            const localList = page.members;
            for (let i = 0; i < localList.length; i++) {
                const member = localList[i];
                if (originalUserIds.indexOf(member.email) < 0) {
                    selectedListLoaded.push(member);
                    originalUserIds.push(member.email);
                    offset++;
                }
                if (member.email == currenUserEmail) {
                    if (member.is_admin === true) {
                        imOwnerGroup = true;
                    }
                }
            }
        } while (page.members.length > 0);
        return { selectedListLoaded, imOwnerGroup, originalUserIds };
    }
    static async checkMembership(groupId) {
        let subUrl = "/groups/check";
        const filtered = {
            groupId,
        };
        const response = await fetchApiInternal(subUrl, filtered);
        return response;
    }
    static async readById(groupId, alert) {
        let subUrl = "/groups/by_id";
        const filtered = {
            groupId,
        };
        let response = {
            ok: false,
            group: null,
        };
        try {
            response = await fetchApiInternal(subUrl, filtered, alert);
        } catch (err) { }
        return response;
    }
    static async pageMembers(groupId, max = MAX_PAGE_MEMBERS, offset = 0, alert) {
        let subUrl = "/groups/list_members";
        const filtered = {
            groupId,
            max,
            offset,
        };
        let response = {
            ok: false,
            members: [],
        };
        try {
            response = await fetchApiInternal(subUrl, filtered, alert);
        } catch (err) { }
        return response;
    }
    static async removeMe(groupId, alert) {
        let subUrl = "/groups/remove_me";
        const filtered = {
            "groupId": groupId
        };
        let response = {
            ok: false,
        };
        try {
            response = await fetchApiInternal(subUrl, filtered, alert);
        } catch (err) { }
        return response;
    }
    static async removeMember(groupId, member, alert) {
        let subUrl = "/groups/remove_member";
        const filtered = {
            "groupId": groupId,
            "member": member
        };
        let response = {
            ok: false,
        };
        response = await fetchApiInternal(subUrl, filtered, alert);
        return response;
    }
    static async addMember(groupId, member, isAdmin, alert) {
        let subUrl = "/groups/add_member";
        const filtered = {
            "groupId": groupId,
            "member": member,
            "is_admin": isAdmin,
        };
        let response = {
            ok: false,
        };
        response = await fetchApiInternal(subUrl, filtered, alert);
        return response;
    }
    static getChatId(email1, email2, connector = "-") {
        // Si alguno de los dos es grupo...
        const isGroup1 = /group:\d{14}_/.test(email1);
        const isGroup2 = /group:\d{14}_/.test(email2);
        if (isGroup1) {
            return GroupsSrv.getGroupCompoundId(email1, email2, connector);
        } else if (isGroup2) {
            return GroupsSrv.getGroupCompoundId(email2, email1, connector);
        } else {
            return GroupsSrv.getCompoundId(email1, email2, connector);
        }
    }
    static getCompoundId(email1, email2, connector = "-") {
        const e1 = email1.toLowerCase();
        const e2 = email2.toLowerCase();
        if (e1 > e2) {
            return `${e1}${connector}${e2}`;
        } else {
            return `${e2}${connector}${e1}`;
        }
    }
    static getGroupCompoundId(groupId, email, connector = "-") {
        const e1 = groupId.toLowerCase();
        const e2 = email.toLowerCase();
        return `${e1}${connector}${e2}`;
    }
    static async sendAttachment(chatId, imageRef, to) {
        const subUrl = "/msg/add_attachment";
        const filtered = {
            chatId,
            to,
        };
        let fecha = MyDates.toAAAAMMDDHHmmss(new Date());
        fecha = fecha.replace(/[\s]/g, "/");
        fecha = fecha.replace(/[:]/g, "_");
        let response = {};
        if (Platform.OS === 'web') {
            const prefix = imageRef.substring(0, 30);
            let mimeType = /^data:([^;]+);/.exec(prefix);
            if (mimeType == null) {
                throw new Error(`No se logró decodificar el tipo de archivo`);
            }
            mimeType = mimeType[1].toLowerCase();
            const MAPEO_MIME_TYPE = {
                "image/jpeg": { name: "imagen.jpg", type: "image" },
                "image/gif": { name: "imagen.gif", type: "image" },
                "image/tiff": { name: "imagen.tiff", type: "image" },
                "image/png": { name: "imagen.png", type: "image" },
                "video/mp4": { name: "video.mp4", type: "video" },
                "video/x-flv": { name: "video.flv", type: "video" },
                "application/x-mpegurl": { name: "video.m3u8", type: "video" },
                "video/3gpp": { name: "video.3gp", type: "video" },
                "video/quicktime": { name: "video.mov", type: "video" },
                "video/x-msvideo": { name: "video.avi", type: "video" },
                "video/x-ms-wmv": { name: "video.wmv", type: "video" },
            }
            const fileMeta = MAPEO_MIME_TYPE[mimeType];
            if (!fileMeta) {
                throw new Error(`Tipo de archivo "${mimeType}" no soportado`);
            }

            const path = `/${chatId}/${fecha}/${fileMeta.name}`;
            //console.log(`path = ${path}`);

            response = await uploadFile(imageRef, subUrl, filtered, `attachments`, path, "512", "128", "NONE", fileMeta.type);
        } else {
            const info = await FileSystem.getInfoAsync(imageRef);
            const partesNombre = /([^/]+$)/ig.exec(info.uri);
            if (partesNombre == null) {
                throw new Error(`No se logró deducir el tipo de archivo de "${info.uri}"`);
            }
            let extension = /\.([^.]+$)/ig.exec(partesNombre[0]);
            if (extension == null) {
                throw new Error(`No se logró deducir el tipo de archivo de "${partesNombre[0]}"`);
            }
            extension = extension[1].toLowerCase();
            const MAPEO_MIME_TYPE = {
                "jpeg": { name: "imagen.jpg", type: "image" },
                "gif": { name: "imagen.gif", type: "image" },
                "tiff": { name: "imagen.tiff", type: "image" },
                "png": { name: "imagen.png", type: "image" },
                "mp4": { name: "video.mp4", type: "video" },
                "flv": { name: "video.flv", type: "video" },
                "m3u8": { name: "video.m3u8", type: "video" },
                "3gp": { name: "video.3gp", type: "video" },
                "mov": { name: "video.mov", type: "video" },
                "avi": { name: "video.avi", type: "video" },
                "wmv": { name: "video.wmv", type: "video" },
            };
            const fileMeta = MAPEO_MIME_TYPE[extension];
            if (!fileMeta) {
                throw new Error(`Tipo de archivo "${extension}" no soportado`);
            }
            const path = `/${chatId}/${fecha}/${fileMeta.name}`;

            response = await uploadFile(imageRef, subUrl, filtered, `attachments`, path, "512", "128", "NONE", fileMeta.type);
        }
        return response;
    }
    static async createGroup(group, imageChanged, alert) {
        let subUrl = "/groups/create";
        const filtered = {
            name: group.name,
            description: group.description,
            created: new Date().getTime(),
        };
        let response = {
            ok: false,
        };

        if (imageChanged) {
            subUrl = "/groups/create_with_image";
            const image = group.picture;
            const responseTxt = await uploadFile(image, subUrl, filtered, `groups`, `/${md5(filtered.created)}`, "512", "128", "NONE");
            if (typeof responseTxt == "string") {
                response = JSON.parse(responseTxt);
            } else {
                response = responseTxt;
            }
        } else {
            response = await fetchApiInternal(subUrl, filtered, alert);
        }
        return response;
    }
    static async updateGroup(group, imageChanged, alert) {
        let subUrl = "/groups/update";
        const filtered = {
            id: group.id,
            name: group.name,
            description: group.description,
        };
        let response = {
            ok: false,
        };

        if (imageChanged) {
            subUrl = "/groups/update_with_image";
            const image = group.picture;
            const responseTxt = await uploadFile(image, subUrl, filtered, `groups`, `/${md5(group.created)}`, "512", "128", "NONE");
            response = JSON.parse(responseTxt);
        } else {
            response = await fetchApiInternal(subUrl, filtered, alert);
        }

        return response;
    }
    static async deleteGroup(idGroup, alert) {
        const subUrl = "/groups/remove";
        const payload = {
            "id": idGroup,
            "max": 1,
            "offset": 0
        };
        let response = {
            ok: false,
        };
        try {
            do {
                response = await fetchApiInternal(subUrl, payload, alert);
            } while (response.done !== true);
        } catch (err) { }
        return response;
    }
}