import styled from "styled-components/native";
import React from "react";
import theme from "../Theme";
import AssetsImg from "../AssetsImg";
import Button from "../screens/signup/Button";
import * as CssConstants from "../widgets/CssConstans";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { MountedComponent } from "../utils/MountedComponent";
import { saveData } from "../utils/MyLocalStorage";

const DOT_SIZE = 10;

export default class OnBoardingScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign(this.state, {
            index: 0,
        });
        this.steps = [
            {
                title: "DESCUBRE",
                description: "donaciones y oportunidades para emprendedores todos los días",
                image: AssetsImg.IMG.ONBOARDING1,
            },
            {
                title: "PARTICIPA",
                description: "para redimir donaciones totalmente gratis",
                image: AssetsImg.IMG.ONBOARDING2,
            },
            {
                title: "VOTA",
                description: "por el emprendedor que quieres que gane cada donación",
                image: AssetsImg.IMG.ONBOARDING3,
            },
            {
                title: "DONA O COMPARTE",
                description: "oportunidades que se estén ofreciendo a emprendedores",
                image: AssetsImg.IMG.ONBOARDING4,
            },
        ];
    }
    async goToNext() {
        if (this.state.index < this.steps.length - 1) {
            this.setState({ index: this.state.index + 1 });
        } else if (this.state.index == this.steps.length - 1) {
            await this.skip();
        }
    }
    goTo(indice) {
        this.setState({ index: indice });
    }
    async skip() {
        await saveData("myonboarding.", { done: "si" });
        this.reloadOldStateOrGoHome();
    }
    getDotColor(indice) {
        if (this.state.index == indice) {
            return { color: theme.__others__deep_orange };
        } else {
            return { color: theme.__background_dark_button };
        }
    }
    render() {
        const skip = this.skip.bind(this);
        const goToNext = this.goToNext.bind(this);
        const detailsButtonSkip = {
            label: "Skip",
            onPress: skip,
            autoWidth: false,
            bgColor: theme.__background_dark_button,
            myStyle: { color: "white" },
        };
        const detailsButtonContinue = {
            label: "Next",
            onPress: goToNext,
            autoWidth: false
        };
        const localStep = this.steps[this.state.index];
        return (
            <ParentContainer>
                <MyContent>
                    <Ambience
                        resizeMode="cover"
                        source={localStep.image}>
                    </Ambience>
                </MyContent>
                <MyFooter>
                    <AutoLayoutVerticalButton>
                        <Titulo>{localStep.title}</Titulo>
                        <Description>{localStep.description}</Description>
                        <AutoLayoutHorizontal>
                            <MyDot onPress={() => { this.goTo(0); }}>
                                <FontAwesomeIcon icon={"circle"} style={this.getDotColor(0)} size={DOT_SIZE} />
                            </MyDot>
                            <MyDot onPress={() => { this.goTo(1); }}>
                                <FontAwesomeIcon icon={"circle"} style={this.getDotColor(1)} size={DOT_SIZE} />
                            </MyDot>
                            <MyDot onPress={() => { this.goTo(2); }}>
                                <FontAwesomeIcon icon={"circle"} style={this.getDotColor(2)} size={DOT_SIZE} />
                            </MyDot>
                            <MyDot onPress={() => { this.goTo(3); }}>
                                <FontAwesomeIcon icon={"circle"} style={this.getDotColor(3)} size={DOT_SIZE} />
                            </MyDot>
                        </AutoLayoutHorizontal>
                        <Button details={detailsButtonContinue}></Button>
                        <Button details={detailsButtonSkip}></Button>
                    </AutoLayoutVerticalButton>
                </MyFooter>
            </ParentContainer>
        );
    }
}

const MyDot = styled.Pressable`
    padding: 10px;
`;

const Titulo = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H3Bold}
    width: 100%;
    color: ${props => props.theme.__others__white};
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
`;

const Description = styled.Text`
    ${CssConstants.BodyXlargeSemibold}
    width: 100%;
    color: ${props => props.theme.__others__white};
    text-align: center;
`;

const Ambience = styled.ImageBackground`
  width: 100%
  height: 100%;
  position: relative;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    margin: 40px auto;
`;

const AutoLayoutVerticalButton = styled.View`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyContent = styled.View`
    width: 100%;
    flex: 1;
`;

const MyFooter = styled.View`
    width: 100%;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    borderTopLeftRadius: 40px;
    borderTopRightRadius: 40px;
    background-color: ${props => props.theme.__background_dark};
    position: relative;
    bottom: 40px;
`;
