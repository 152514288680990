import React from "react";
import styled from "styled-components/native";

import Constants from "../../Constants"
import { MyDates } from "../../utils/MyDates";
import * as CssConstants from "../../widgets/CssConstans";


export default class TopDetail0 extends React.Component {
    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        const {
            donation,
        } = this.props.details;
        const countDownFormated = MyDates.getRemainingHoursCountDown(donation.end);
        return (
            <AutoLayoutHorizontal>
                <ContainerDetail>
                    <MainNumber>{donation.numWin}</MainNumber>
                    <DescriptionNumber>Ganador(es)</DescriptionNumber>
                </ContainerDetail>
                <Divider />
                <ContainerDetail>
                    <MainNumber>{countDownFormated}</MainNumber>
                    <DescriptionNumber>Expira en</DescriptionNumber>
                </ContainerDetail>
                <Divider />
                <ContainerDetail>
                    <MainNumber>{donation.participants}</MainNumber>
                    <DescriptionNumber>Participante(s)</DescriptionNumber>
                </ContainerDetail>
            </AutoLayoutHorizontal>
        );
    }
}

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
`;

const ContainerDetail = styled.View`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
`;

const MainNumber = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H4Bold}
    align-self: stretch;
    margin-top: -1px;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 28px;
    margin-bottom: 4px;
`;

const DescriptionNumber = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumRegular}
    align-self: stretch;
    font-weight: 400;
    color: ${props => props.theme.__greyscale__400};
    text-align: center;
    line-height: 19px;
`;

const Divider = styled.View`
    align-self: stretch;
    width: 1px;
    height: 100%;
    background-color: ${props => props.theme.__dark__dark_3};
`;