import { MyAnalytics } from '../config/myAnalytics';
import { uploadFile, fetchApi, fetchApiInternalRecursive } from '../srv/Http';
import MyLog from './MyLog';
import RealTime from './RealTime';

const PAGE_SIZE = 20;

const PREDEFINED_PATH = "/donations/ranked";

export class DonacionesSrv {
    constructor() {
        this.container = {};
        this.resetRanked();
        this.resetAccepted();
        this.resetWon();
    }
    static async seeVotes(donationId, alert) {
        const subUrl = "/donations/seevotes";
        const payload = {
            donation_id: donationId,
        };
        let response = null;
        try {
            response = await fetchApiInternalRecursive(subUrl, payload, alert, 100);
        } catch (err) {

        }
        return response;
    }
    static async setAdvertising(donationId, alert) {
        const subUrl = "/advertising/add";
        const payload = {
            donation_id: donationId,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {

        }
        return response;
    }
    static async setFinishDetail(donationId, comments, done, winner, alert) {
        const subUrl = "/donations/finishdetails";
        const payload = {
            donation_id: donationId,
            comments,
            done,
            winner
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {

        }
        return response;
    }
    static async rateStars(donationId, details, alert) {
        const subUrl = "/donations/rate";
        const payload = {
            donation_id: donationId,
            payload: details,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {

        }
        return response;
    }
    static async setPromo(donationId, promo, alert) {
        const subUrl = "/donations/setpromo";
        const payload = {
            donation_id: donationId,
            promo: promo,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    static async forceExpire(donationId, block, alert) {
        const subUrl = "/donations/forceexpire";
        const payload = {
            donation_id: donationId,
            block: block,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    static async fixForceExpire(donationId, alert) {
        const subUrl = "/donations/fixforceexpire";
        const payload = {
            donation_id: donationId,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    static async byId(donationId, alert, myRelation = false) {
        const subUrl = "/donations/id";
        const payload = {
            id: donationId,
            myRelation,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {

        }
        return response;
    }
    static async updateMyDesitions(donationId, desition, alert) {
        const subUrl = "/donations/dirty";
        const payload = {
            donation_id: donationId,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {

        }
        return response;
    }
    static async reSchedule(notificationId, donationId, dateStartMeet, winner, alert) {
        const subUrl = "/donations/reschedule";
        const payload = {
            notification_id: notificationId,
            donation_id: donationId,
            date_start_meet: dateStartMeet,
            winner,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    static async confirm(donationId, notificationId, alert) {
        const subUrl = "/donations/confirm";
        const payload = {
            donation_id: donationId,
            notification_id: notificationId,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    static async vote(donationId, desition, alert) {
        const subUrl = "/donations/vote";
        const payload = {
            donation_id: donationId,
            desition: desition,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    static async participants(donationId, alert, offset = 0) {
        const subUrl = "/donations/who";
        const payload = {
            donation_id: donationId,
            offset: offset,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {

        }
        return response;
    }
    static async readRelation(donationId, alert) {
        const subUrl = "/donations/relation";
        const payload = {
            donation_id: donationId,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {

        }
        return response;
    }
    static async reportInapropiate(donationId, type, alert) {
        const subUrl = "/donations/inapropiate";
        const payload = {
            donation_id: donationId,
            type: type,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {

        }
        return response;
    }
    static async blockDonation(donationId, alert) {
        const subUrl = "/donations/apply";
        const payload = {
            donation_id: donationId,
            desition: false,
            block: true,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
            RealTime.get().addDesition(response.relacion);
        } catch (err) {

        }
        return response;
    }
    static async apply(donationId, desition, alert) {
        const subUrl = "/donations/apply";
        const payload = {
            donation_id: donationId,
            desition: desition,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
            RealTime.get().addDesition(response.relacion);
        } catch (err) {

        }
        return response;
    }
    addNext(agregada, subUrl = PREDEFINED_PATH) {
        // MyLog.log(JSON.stringify(agregada), this);
        const self = this.container[subUrl];
        // extraigo los ids para no repetirlos
        const ids = [];
        for (let i = 0; i < self.list.length; i++) {
            const actual = self.list[i];
            ids.push(actual.id);
        }
        for (let i = 0; i < agregada.length; i++) {
            const actual = agregada[i];
            if (ids.indexOf(actual.id) < 0) {
                self.list.push(actual);
                ids.push(actual.id);
            }
        }
    }
    addPrevious(agregada, subUrl = PREDEFINED_PATH) {
        const self = this.container[subUrl];
        // extraigo los ids para no repetirlos
        const ids = [];
        for (let i = 0; i < self.list.length; i++) {
            const actual = self.list[i];
            ids.push(actual.id);
        }
        for (let i = 0; i < agregada.length; i++) {
            const actual = agregada[i];
            if (ids.indexOf(actual.id) < 0) {
                self.list.push(actual);
                self.list.unshift(actual);
            }
        }
    }
    async getCurrentList(subUrl = PREDEFINED_PATH) {
        const self = this.container[subUrl];
        return self.list;
    }
    async getList(alert, subUrl = PREDEFINED_PATH, max = PAGE_SIZE) {
        const self = this.container[subUrl];
        if (self.promesa == null) {
            self.promesa = new Promise(async (resolve, reject) => {
                const newOffset = Math.max(0, parseInt(self.offset - max / 2));
                const agregada = await DonacionesSrv.next(newOffset, max, alert, subUrl);
                this.addNext(agregada, subUrl);
                resolve(self.list);
                self.initiated = (self.list.length > 0);
                if (!self.initiated) {
                    // Se permite volver a hacer el fetch
                    self.promesa = null;
                }
            });
        }
        return self.promesa;
    }
    resetRanked() {
        this.resetGeneric(PREDEFINED_PATH);
    }
    resetAccepted() {
        this.resetGeneric("/donations/accepted");
    }
    resetMines() {
        this.resetGeneric("/donations/mines");
    }
    resetUser(email) {
        this.resetGeneric(`/donations/user?email=${email}`);
    }
    resetWon() {
        this.resetGeneric("/donations/won");
    }
    resetGeneric(myKey) {
        this.container[myKey] = {
            list: [],
            offset: 0,
            promesa: null,
            initiated: false,
        };
    }
    async fetchUserNext(max, email, alert) {
        return this.fetchInternal(max, true, alert, `/donations/user?email=${email}`);
    }
    async fetchMinesNext(max, alert) {
        return this.fetchInternal(max, true, alert, "/donations/mines");
    }
    async fetchAcceptedNext(max, alert) {
        return this.fetchInternal(max, true, alert, "/donations/accepted");
    }
    async fetchAcceptedPrevious(max, alert) {
        return this.fetchInternal(max, false, alert, "/donations/accepted");
    }
    async fetchWonNext(max, alert) {
        return this.fetchInternal(max, true, alert, "/donations/won");
    }
    async fetchWonPrevious(max, alert) {
        return this.fetchInternal(max, false, alert, "/donations/won");
    }
    async fetchNext(max, alert, subUrl) {
        return this.fetchInternal(max, true, alert, subUrl);
    }
    async fetchPrevious(max, alert, subUrl) {
        return this.fetchInternal(max, false, alert, subUrl);
    }
    async fetchInternal(max = PAGE_SIZE, direction, alert, subUrl = PREDEFINED_PATH) {
        MyLog.log(`fetchInternal max=${max}`, this);
        const self = this.container[subUrl];
        if (!self.initiated) {
            return this.getList(alert, subUrl, max);
        } else {
            // Tomo el máximo offset
            if (self.list.length > 0) {
                const ultimo = self.list[self.list.length - 1];
                const offset = ultimo.offset + 1;
                MyLog.log(`fetchInternal offset=${offset}`, this);
                let agregada;
                if (direction) {
                    agregada = await DonacionesSrv.next(offset, max, alert, subUrl);
                    this.addNext(agregada, subUrl);
                } else {
                    agregada = await DonacionesSrv.previous(offset, max, alert, subUrl);
                    this.addPrevious(agregada, subUrl);
                }
                return self.list;
            }
        }
    }
    static async next(offset, max, alert, subUrl) {
        MyLog.log(`next max=${max}`, this);
        return await DonacionesSrv.pageDonations(offset, max, "", alert, subUrl);
    }
    static async previous(offset, max, alert, subUrl) {
        MyLog.log(`previous max=${max}`, this);
        return await DonacionesSrv.pageDonations(offset, max, "back", alert, subUrl);
    }
    static async pageDonations(offset = 0, max = PAGE_SIZE, dir = "", alert, subUrl = PREDEFINED_PATH) {
        MyLog.log(`pageDonations max=${max}`, this);
        const payload = {
            offset: offset,
            max: max,
            dir: dir
        };
        let response = [];
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            console.log(err);
        }
        return response;
    }
    async myStatus(alert) {
        const payload = {};
        let response = null;
        try {
            response = await fetchApi("/status/mine", payload, false, alert);
        } catch (err) {
            console.log(err);
        }
        return response;
    }
    async userStatus(email, alert) {
        const payload = { email };
        let response = null;
        try {
            response = await fetchApi(`/status/user`, payload, false, alert);
        } catch (err) {
            console.log(err);
        }
        return response;
    }
    static async crear(payload) {
        const subUrl = "/donations/create";
        const image = payload.image;
        delete payload.image;
        const response = await uploadFile(image, subUrl, payload);
        // Se notifica que se creó
        MyAnalytics.sendEvent("add_donation", {
            type: payload.type,
            category: payload.category,
        });
        return response;
    }
}