import styled from "styled-components/native";
import MyLog from "../srv/MyLog";
import { MountedComponent } from "../utils/MountedComponent";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import theme from "../Theme";
import React from 'react'
import Config from "../utils/Config";


export default class StatusScreen extends MountedComponent {
    constructor(props) {
        super(props);
        const donorTypeName = this.props.route.params?.donorTypeName;
        const beePoints = this.props.route.params?.beePoints;
        const dropletColor = this.props.route.params?.dropletColor;
        this.state = Object.assign(this.state, {
            donorTypeName,
            beePoints,
            dropletColor,
            redaccion: [],
            redaccion2: []
        });

        Config.getValue("config").then((myConfig) => {
            this.setState({
                redaccion: myConfig.TEXTS.BENEFITS.TEXT1,
                redaccion2: myConfig.TEXTS.BENEFITS.TEXT2,
            });
        }).catch((err) => {

        });
    }
    async onUser(payloadUser) {
        if (payloadUser.user === null) {
            // Redirect to home
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        } else {
            // Do something
        }
    }
    async onShow() {

    }

    parseText(value, index) {
        if (value.type == "title") {
            return (<CompoundTitle key={index}>
                <MySubTitle>{value.bold}</MySubTitle>
                <OneSubText style={{ marginLeft: 4 }}>{value.normal}</OneSubText>
            </CompoundTitle>)
        } else if (value.type == "bullet") {
            return (<CompoundText key={index}>
                <FontAwesomeIcon
                    icon={"circle"}
                    style={{
                        color: "white",
                        marginRight: 10,
                        outline: "none"
                    }}
                    size={3} />
                <OneSubText>{value.txt}</OneSubText>
            </CompoundText>);
        } else if (value.type == "simple") {
            return (<CompoundText key={index}>
                <OneSubText>{value.txt}</OneSubText>
            </CompoundText>);
        }
    }

    render() {
        const redaccion = this.state.redaccion;
        const redaccion2 = this.state.redaccion2;
        const redaccionHtml = redaccion.map(this.parseText);
        const redaccion2Html = redaccion2.map(this.parseText);
        return (<ParentContainer>
            <MyContent>
                <MessagesScroll ref={ref => { this.scrollView = ref }} onContentSizeChange={() => { }}>
                    <MyTopContainer>
                        <MyTextsContainer>
                            <MyTitle>{"Estatus Actual"}</MyTitle>
                            <OneText>{this.state.donorTypeName}</OneText>
                            <OneText>{`Puntos Abeja ${this.state.beePoints}`}</OneText>
                        </MyTextsContainer>
                        <DropletContainer>
                            <FontAwesomeIcon
                                icon={"droplet"}
                                style={{
                                    color: this.state.dropletColor,
                                    outline: "none"
                                }}
                                size={30} />
                        </DropletContainer>
                    </MyTopContainer>
                    <HorizontalDivider></HorizontalDivider>
                    <MyTitle style={{ textAlign: "center" }}>{"Beneficios por estatus"}</MyTitle>
                    {redaccionHtml}
                    <HorizontalDivider></HorizontalDivider>
                    <MyTitle style={{ textAlign: "center" }}>{"¿Cómo ganar puntos abeja?"}</MyTitle>
                    {redaccion2Html}
                </MessagesScroll>
            </MyContent>
        </ParentContainer>);
    }
}

const MessagesScroll = styled.ScrollView`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 50px;
`;

const HorizontalDivider = styled.View`
    width: 100%;
    height: 1px;
    background-color: #2A2B39;
    margin: 15px 0;
`;

//line-height: 140%;
const OneText = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
`;

//line-height: 120%;
const MyTitle = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
`;

const CompoundTitle = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
`;

const CompoundText = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
`;

//line-height: 140%;
const MySubTitle = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
`;

//line-height: 140%;
const OneSubText = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
`;

const MyTextsContainer = styled.View`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const DropletContainer = styled.View`
    display: flex;
    flex-direction: row;
    width: 52px;
    align-items: center;
    justify-content: center;
`;

const MyTopContainer = styled.View`
    display: flex;
    flex-direction: row;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyContent = styled.View`
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
`;