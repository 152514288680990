import React from "react";
import styled from "styled-components/native";
import * as CssConstants from "./CssConstans";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

export default class MyCheckbox
  extends React.Component {
  constructor(props) {
    super(props);
    const { value, disabled } = props.details;
    this.state = {
      checked: false,
      disabled: disabled,
      value: (typeof value == "boolean" ? value : false),
    };
  }
  setValue(value) {
    this.setState({
      value: value,
    })
  }
  toggleCheckbox() {
    const nuevoValor = !this.state.value;
    this.setState({ value: nuevoValor });
    if (typeof this.props.details.onChange == 'function') {
      const evento = {
        type: "",
        name: "",
        id: "",
        value: nuevoValor,
        checked: nuevoValor,
        outerHTML: "",
        options: {},
        multiple: false
      };
      const setValueThis = this.setValue.bind(this);
      this.props.details.onChange({ target: evento, setValue: setValueThis });
    }
  }
  render() {
    const { label, type, alignment } = this.props.details;
    const toggleCheckbox = this.toggleCheckbox.bind(this);
    if (type == "switch") {
      return (
        <ContainerSwitch>
          {label !== null && label !== undefined && <ContainerLabel>{label}</ContainerLabel>}
          <Rectangle
            value={this.state.value}
            onPress={toggleCheckbox}
            disabled={this.state.disabled}
          >
            {this.state.value &&
              <FontAwesomeIcon icon={'check'} style={{
                color: 'white',
                marginLeft: 1,
                marginTop: 1,
              }} size={15} />
            }
          </Rectangle>
        </ContainerSwitch>
      );
    } else {
      return (
        <StyleTextThemeDarkStateUncheckedCom alignment={alignment}>
          <Rectangle
            value={this.state.value}
            onPress={toggleCheckbox}
            disabled={this.state.disabled}
          >
            {this.state.value &&
              <FontAwesomeIcon icon={'check'} style={{
                color: 'white',
                marginLeft: 1,
                marginTop: 1,
              }} size={15} />
            }
          </Rectangle>
          {label !== null && label !== undefined && <Unchecked>{label}</Unchecked>}
        </StyleTextThemeDarkStateUncheckedCom>
      );
    }
  }
}

const StyleTextThemeDarkStateUncheckedCom = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.alignment ? props.alignment : 'center'};
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: stretch;
`;

const Rectangle = styled.Pressable`
  ${CssConstants.Border3pxOthersDeepOrange}
  background: ${props => props.value ? props.theme.__others__deep_orange : props.theme.__background_dark};
  min-width: 24px;
  height: 24px;
  border-radius: 8px;
  margin-right: 10px;
`;

const Unchecked = styled.Text`
  ${CssConstants.ValignTextMiddle}
  ${CssConstants.BodyMediumSemibold}
  min-width: 81px;
  font-weight: 600;
  color: ${props => props.theme.__others__white};
  line-height: 19.6px;
`;

const ContainerSwitch = styled.View`
  display: flex;
  flex-direction: row;

  align-self: stretch;
`;

const ContainerLabel = styled.Text`
  ${CssConstants.ValignTextMiddle}
  ${CssConstants.BodyXlargeSemibold}
  flex: 1;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${props => props.theme.__others__white};
  line-height: 25.2px;
  padding-left: 10px;
`;