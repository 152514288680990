import React from "react";
import styled from "styled-components/native";
import Constants from "../../Constants"
import * as CssConstants from "../../widgets/CssConstans";

import Button from "./Button"
import Button4 from "./Button4"
import Button5 from "./Button5"
import AutoLayoutHorizontal from "./AutoLayoutHorizontal"
import Divider from "../../widgets/Divider";
import MyCheckbox from "../../widgets/MyCheckbox";
import AssetsImg from "../../AssetsImg";
import Button6 from "./Button6";

const DIMENSIONS = {
    width: Constants.SIZE.VIEWS.width,
    maxWidth: Constants.SIZE.VIEWS.maxWidth,
    height: "735px",
    margin: Constants.SIZE.VIEWS.margin,
};

//import ElementsNavbar from "./ElementsNavbar"
//import Button6 from "./Button6"
//<Button6 continueWithApple={button6Props.continueWithApple} />
//<Button4 details={facebookButton} alert={this.props.alert} />
export default class X5LightWelcomeScreen extends React.Component {
    render() {
        const {
            letsYouIn,
            or,
            yaEresMiembro,
            facebookButton,
            googleButton,
            appleButton,
            buttonProps,
            aceptaCondiciones,
            windowSize,
        } = this.props;
        const headerHeight = parseInt(windowSize.height * 0.3);
        return (
            <ContainerCenterHorizontal>
                <X5LightWelcomeScreenScreen>
                    <AutoLayoutVertical>
                        <HeaderImage
                            myHeight={headerHeight}
                            resizeMode="cover"
                            source={AssetsImg.IMPORTED.frame_7}></HeaderImage>
                        <LetsYouIn>{letsYouIn}</LetsYouIn>
                        <AutoLayoutVertical1>
                            <AutoLayoutVertical2>
                                <Button5 details={googleButton} alert={this.props.alert} />
                                <Button6 details={appleButton} alert={this.props.alert} />
                                <MyCheckbox details={aceptaCondiciones}></MyCheckbox>
                            </AutoLayoutVertical2>
                            <AutoLayoutHorizontal1>
                                <Divider />
                                <Or>{or}</Or>
                                <Divider />
                            </AutoLayoutHorizontal1>
                            <Button details={buttonProps}></Button>
                        </AutoLayoutVertical1>
                        <AutoLayoutHorizontal
                            details={yaEresMiembro}
                        />
                    </AutoLayoutVertical>
                </X5LightWelcomeScreenScreen>
            </ContainerCenterHorizontal>);
    }
};

const HeaderImage = styled.ImageBackground`
    width: 100%;
    height: ${props => props.myHeight}px;
    margin-bottom: 10px;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    position: relative;
    flex-direction: column;
    height: ${DIMENSIONS.height};
    width: ${DIMENSIONS.width};
    ${DIMENSIONS.maxWidth}
    margin: ${DIMENSIONS.margin};
    align-items: center;
    
`;

const LetsYouIn = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H2Bold}
    width: 100%;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 44px;
`;

const AutoLayoutVertical1 = styled.View`
    padding: 24px;
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    
    position: relative;
    align-self: stretch;
`;

const AutoLayoutVertical2 = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    justify-content: center;
    
    position: relative;
`;

const AutoLayoutHorizontal1 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 10px;
    position: relative;
    align-self: stretch;
`;

const Or = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXlargeSemibold}
    min-width: 18px;
    margin-top: -1px;
    font-weight: 600;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 25.2px;
`;

const X5LightWelcomeScreenScreen = styled.View`
    align-items: flex-start;
    background-color: ${props => props.theme.__background_dark};
    display: flex;
    flex-direction: column;
    position:relative;
    width: 100%;
    height: 100%;
`;

const ContainerCenterHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;