import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from 'react'
import styled from "styled-components/native";
import theme from "../Theme";
import Config from "../utils/Config";
import { MyDates } from "../utils/MyDates";
import * as CssConstants from "../widgets/CssConstans";
import MyCheckbox from "../widgets/MyCheckbox";

export default class MemberRow extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            selfInstance,
            goToPersonSpace,
            goToProfile,
            showTime,
            picture,
            name,
            donorType,//Use it later
            txt,
            d,
            viewed,
            selected,
            selectedChanged,
            selectedEditable
        } = this.props.details;

        let textoFecha = null;
        if (typeof d == "number") {
            const isToday = MyDates.isToday(d);
            const fecha = new Date(d);
            if (isToday) {
                textoFecha = MyDates.formatTime(fecha);
            } else {
                textoFecha = MyDates.formatDateSimple(fecha);
            }
        }

        const dotStyle = {
            position: "absolute",
            top: 0,
            right: -9,
            color: theme.__others__deep_orange,
        };

        return (
            <ElementsAccountList>
                <AvatarNameContainer>
                    <AvatarContainerParent>
                        <AvatarContainer colorDonorType={"#FFFFFF"} onPress={goToProfile}>
                            <Avatar source={{ uri: Config.getNewPath(picture, "_xs", showTime) }} />
                        </AvatarContainer>
                        {viewed === false && <FontAwesomeIcon icon={"circle"} style={dotStyle} size={13} />}
                    </AvatarContainerParent>
                    <NameLastMessageContainer onPress={goToPersonSpace}>
                        <OnlyNameContainer>
                            {name}
                        </OnlyNameContainer>
                        {typeof txt == "string" && <LastMessageContainer>
                            {txt}
                        </LastMessageContainer>}
                    </NameLastMessageContainer>
                    <IndicatorContainer onPress={goToPersonSpace}>
                        {selectedEditable !== false && <MyCheckbox details={{
                            alignment: "flex-end",
                            onChange: (event) => {
                                selectedChanged(event, selfInstance);
                            },
                            value: selected
                        }}></MyCheckbox>}
                    </IndicatorContainer>
                </AvatarNameContainer>
            </ElementsAccountList>
        );
    }
}

const IndicatorContainer = styled.Pressable`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`;

const OnlyNameContainer = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H6Bold}
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    line-height: 21.6px;
    margin-bottom: 4px;
`;

const LastMessageContainer = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumMedium}
    font-weight: 500;
    color: ${props => props.theme.__greyscale__400};
    line-height: 19.6px;
    flex: 1;
`;

const NameLastMessageContainer = styled.Pressable`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 4px;
`;

const AvatarNameContainer = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const ElementsAccountList = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 24px;
    padding-right: 5px;
`;

const AvatarContainerParent = styled.View`
    position:relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    
    width: 60px;
    height: 60px;
    margin-right: 20px;
`;

const AvatarContainer = styled.Pressable`
    width: 60px;
    height: 60px;
    border: 3px solid;
    border-color: ${props => props.colorDonorType};
    border-radius: 100px;
    overflow: hidden;
`;

const Avatar = styled.ImageBackground`
    width: 54px;
    height: 54px;
    background-size: contain;
    border-radius: 100px;
    overflow: hidden;
`;
