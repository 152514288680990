import React from 'react'
import { MountedComponent } from "../utils/MountedComponent";
import XSelect from '../screens/select/XSelect';
import { Formik } from 'formik';
import Constants from "../Constants";
import Config from '../utils/Config';
import MyLog from '../srv/MyLog';

class SelectScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.myref = React.createRef();
        this.state = Object.assign(this.state, {
            user: null,
            userBack: null,
            donationNew: {},
            donationType: null,
            data: {

            },
        });
    }
    async onUser(payloadUser) {
        if (payloadUser.user == null) {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        }
    }
    handleLocalNavigation(page, extra = {}) {
        const props = this.props;
        const state = props.navigation.getState();
        const routes = state.routes;
        const lastRoute = routes[routes.length - 1];
        let params = props.route.params;
        if (!params) {
            params = {};
        }
        // Se mezclan los datos extra
        Object.assign(params, extra);
        if (!(params.backPage instanceof Array)) {
            params.backPage = [lastRoute.name];
        } else {
            params.backPage.push(lastRoute.name);
        }
        props.navigation.navigate(page, params);
    }
    redirectWithParams(keys, values) {
        MyLog.log("redirectWithParams", this);
        if (this.props.route.params === undefined) {
            this.props.route.params = {};
        }
        for (let i = 0; i < keys.length; i++) {
            const aName = keys[i];
            this.props.route.params[aName] = values[aName];
        }
        this.myLocalGoBack(this.props.route.params);
    }
    myLocalGoBack(externalParams) {
        MyLog.log("myLocalGoBack", this);
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        let backPage = 'WDonationPage3';
        if (params.backPage instanceof Array && params.backPage.length > 0) {
            const last = params.backPage.pop();
            backPage = last;
        }
        MyLog.log(`navigate to ${backPage}`, this);
        this.props.navigation.navigate(backPage, externalParams);
    }
    initValue(fieldName, defaultValue) {
        if (this.props.route.params) {
            let oldValue = this.props.route.params[fieldName];
            if (defaultValue !== undefined && [undefined, null].indexOf(oldValue) >= 0) {
                oldValue = defaultValue;
            }
            this.state[fieldName] = oldValue;
            this.state.data[fieldName] = this.state[fieldName];
        }
    }
    cleanText(r) {
        r = r.replace(new RegExp(/[àáâãäå]/g), "a");
        r = r.replace(new RegExp(/[èéêë]/g), "e");
        r = r.replace(new RegExp(/[ìíîï]/g), "i");
        r = r.replace(new RegExp(/ñ/g), "n");
        r = r.replace(new RegExp(/[òóôõö]/g), "o");
        r = r.replace(new RegExp(/[ùúûü]/g), "u");
        r = r.replace(new RegExp(/[^a-z]/g), " ");//Solo se aceptan letras
        r = r.replace(new RegExp(/\s{2,}/g), " ");//Más de un espacio se reemplaza por uno solo
        return r;
    }
    ignoreConjunctions(arreglo) {
        const ignored = ["del", "de", "la", "el"];
        let i = 0;
        do {
            const parte = arreglo[i];
            if (parte.length <= 2 || ignored.indexOf(parte) >= 0) {
                arreglo.splice(i, 1);
            } else {
                i++;
            }
        } while (i < arreglo.length);
        return arreglo;
    }
    buscarTexto(txt, cities) {
        txt = txt.toLocaleLowerCase();
        txt = this.cleanText(txt);
        txt = txt.split(" ");
        txt = this.ignoreConjunctions(txt);
        const rta = [];
        if (txt.length == 0) {
            return rta;
        }
        for (let i = 0; i < cities.length; i++) {
            const city = cities[i];
            const theReg = city.reg;
            let todas = true;
            for (let j = 0; j < txt.length; j++) {
                const unTxt = txt[j];
                if (theReg.indexOf(unTxt) < 0) {
                    todas = false;
                    break;
                }
            }
            if (todas) {
                const cityClone = JSON.parse(JSON.stringify(city));
                delete cityClone.reg;
                rta.push(cityClone);
            }
        }
        return rta;
    }
    async getList(name, buscable = null) {
        let lista = await Config.getValue(name);
        if (typeof buscable == "string") {
            lista = this.buscarTexto(buscable, lista);
        }
        return lista;
    }
    addHighlighted(lista, current) {
        for (let i = 0; i < lista.length; i++) {
            const elemento = lista[i];
            if (current == elemento.value) {
                elemento.highlight = true;
            } else {
                elemento.highlight = false;
            }
        }
    }
    myRender(listaOpciones, instructions, onSubmit, validate, autoSubmit = false, searchFunction = null, actionDefinition = null) {
        return (
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={this.state.data}
                validate={validate}
                onSubmit={onSubmit}
            >
                {({ handleChange, handleBlur, submitForm, errors, values }) => (
                    <XSelect
                        ref={this.myref}
                        windowSize={{
                            width: this.state.width,
                            height: this.state.height,
                        }}
                        details={{
                            submit: submitForm,
                            instructions: instructions,
                            options: listaOpciones,
                            handleChange: handleChange,
                            handleBlur: handleBlur,
                            errors: errors,
                            values: values,
                            autoSubmit: autoSubmit,
                            searchFunction: searchFunction,
                            actionDefinition,
                        }}
                    />
                )}
            </Formik>
        )
    }
}

export default SelectScreen;