import React from "react";
import styled from "styled-components/native";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { StyleSheet } from 'react-native';

import theme from "../../Theme";
import IconlyLightArrowRight2 from "../wdonation2/IconlyLightArrowRight2";
import * as CssConstants from "../../widgets/CssConstans";

class FormOptionMenu
    extends React.Component {
    render() {
        const { label, icon, goToPage, currentValue, errors } = this.props.details;
        return (
            <AutoLayoutHorizontal1
                onPress={async () => {
                    await goToPage();
                }}>
                <AutoLayoutHorizontal2>
                    {typeof icon == "string" && <IconlyLight2User>
                        <FontAwesomeIcon icon={icon} style={[styles.icon]} size={28} />
                    </IconlyLight2User>}
                    <VerticalText>
                        <NmeroDeGanadores>{label}</NmeroDeGanadores>
                        {[null, undefined, ""].indexOf(currentValue) < 0 && <CurrentValue>{currentValue}</CurrentValue>}
                        {errors !== undefined && <ContainerError>{errors}</ContainerError>}
                    </VerticalText>
                </AutoLayoutHorizontal2>
                <IconlyLightArrowRight2 />
            </AutoLayoutHorizontal1>
        );
    }
}

export default FormOptionMenu;

const ContainerError = styled.Text`
  width: 100%;
  color: ${props => props.theme.__others__deep_orange};
  font-size: ${props => props.theme.__font_size_xl2};
  margin-top: 10px;
`;

const VerticalText = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%
`;

const CurrentValue = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumRegular}
    flex: 1;
    font-weight: normal;
    margin-left: 8px;
    color: ${props => props.theme.__others__white};
    line-height: 25.2px;
    padding-top: 4px;
`;

const AutoLayoutHorizontal1 = styled.Pressable`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    position: relative;
    align-self: stretch;
    margin-top: 10px;
    margin-bottom: 10px;
`;

// gap: 16px;
const AutoLayoutHorizontal2 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    flex: 1;
`;

const IconlyLight2User = styled.View`
    width: 28px;
    height: 28px;
`;

// white-space: nowrap;
const NmeroDeGanadores = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXlargeSemibold}
    flex: 1;
    font-weight: 600;
    margin-left: 8px;
    color: ${props => props.theme.__others__white};
    line-height: 25.2px;
    
`;

const styles = StyleSheet.create({
    icon: {
        color: theme.__others__white,
    }
});