import AsyncStorage from '@react-native-async-storage/async-storage';

export async function saveData(prefix, object) {
    try {
        for (const [key, value] of Object.entries(object)) {
            let myValue = value;
            if (typeof myValue !== 'string') {
                myValue = "" + myValue;
            }
            const myKey = prefix + key;
            // console.log(`${myKey}=${myValue}`);
            await AsyncStorage.setItem(myKey, myValue)
        }
    } catch (e) {
        alert(e.message);
    }
}

export async function readData(prefix, respuesta) {
    try {
        for (const [key, value] of Object.entries(respuesta)) {
            const myKey = prefix + key;
            let readed = await AsyncStorage.getItem(myKey);
            // console.log(`${myKey}=${readed}`);
            if (readed !== null && readed !== 'null') {
                if (readed === 'true') {
                    readed = true;
                } else if (readed === 'false') {
                    readed = false;
                }
                respuesta[key] = readed;
            }
        }
    } catch (e) {
        alert(e.message);
    }
    return respuesta;
};