import React, { Component } from 'react'
import { MountedComponent } from "../utils/MountedComponent";
import XForm from '../screens/wdonation3/XForm';
import { Formik } from 'formik';
import Constants from "../Constants";
import { hasMinLength, hasMinMaxLength } from '../utils/InputValidator';
import { MyDates } from '../utils/MyDates';
import { DonacionesSrv } from '../srv/DonacionesSrv';
import Config from '../utils/Config';
import CONFIG from '../Constants';

export default class WDonationScreen3 extends MountedComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign(this.state, {
            user: null,
            userBack: null,
            donationNew: {},
            data: {
                title: "",
                description: "",
                organicerme: true,
            }
        });
    }
    async onUser(payloadUser) {
        if (payloadUser.user == null) {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        } else {
            if (this.props.route.params && !this.props.route.params.organicername) {
                if (payloadUser.userBack.name) {
                    this.props.route.params.organicername = payloadUser.userBack.name;
                } else if (payloadUser.user.displayName) {
                    this.props.route.params.organicername = payloadUser.user.displayName;
                } else {
                    this.props.route.params.organicername = "";
                }
                this.setState({ organicername: this.props.route.params.organicername });
            }
        }
    }
    handleNavigation(page) {
        const props = this.props;
        return async function () {
            const copia = JSON.parse(JSON.stringify(props.route.params));
            props.navigation.navigate(page, copia);
        }
    }
    joinNotNullValues(arreglo) {
        let i = 0;
        do {
            const palabra = arreglo[i];
            if ([null, undefined, ""].indexOf(palabra) >= 0) {
                arreglo.splice(i, 1);
            } else {
                i++;
            }
        } while (arreglo.length > 0 && i < arreglo.length);
        return arreglo.join(" - ");
    }
    setMinMaxParam(minDate, maxDate, paramName) {
        const params = this.props.route.params;
        params.minDate = minDate.getTime();
        params.maxDate = maxDate.getTime();
        let dateStartMeet = parseInt(params[paramName]);
        if (isNaN(dateStartMeet)) {
            params[paramName] = undefined;
        } else {
            if (dateStartMeet < params.minDate) {
                params[paramName] = params.minDate;
            }
            if (dateStartMeet > params.maxDate) {
                params[paramName] = params.maxDate;
            }
        }
    }
    getMenuOptions() {
        const handleNavigation = this.handleNavigation.bind(this);
        const params = (this.props.route.params ? this.props.route.params : {});
        if (!("start" in params)) {
            params.start = new Date().getTime();
        }
        if (!(typeof params.organicerme == "boolean")) {
            params.organicerme = true;
        }
        if (!(typeof params.usevotes == "boolean")) {
            params.usevotes = false;//false es usar votos
        }
        if (!(typeof params.isfree == "boolean")) {
            params.isfree = true;
        }
        if (!(typeof params.price == "string")) {
            params.price = "0";
        }
        if (params.numWin === undefined) {
            params.numWin = 1;
        }
        let type = null;
        let isDonation = false;
        let isRepost = false;
        if (params.usevotes) {
            // Si no soy el donador o no quiero someter a votación, es un repost o aviso
            isRepost = true;
            type = Constants.LISTS.DONATION.TYPE.REPOST;
        } else {
            isDonation = true;
            type = Constants.LISTS.DONATION.TYPE.DONACION;
        }
        const listaCampos = [];
        // El organizador siempre se pregunta
        listaCampos.push({
            label: "Categoría", icon: "ellipsis-vertical",
            currentValue: Config.getCategoryValue(params.category),
            goToPage: this.handleNavigation("WDonationCategoryPage")
        });

        // Solo si yo lo organizo puedo decidir si hay o no votación
        listaCampos.push({
            label: "Concurso", icon: "trophy",
            currentValue: (params.usevotes ? "Ganadores ilimitados" : `${params.numWin} ganadores`),
            goToPage: this.handleNavigation("WDonationWinnersNewPage")
        });

        // La fecha y hora siempre se pregunta
        if (isDonation) {
            listaCampos.push({
                label: "Fecha de Oportunidad", icon: "calendar-days",
                currentValue: MyDates.formatDatetimeRange(params.dateStartMeet, params.dateEndMeet),
                goToPage: async () => {
                    const MY_CONFIG = await Config.getValue("config");
                    const start = new Date(parseInt(params.start));
                    const {
                        minDate,
                        maxDate
                    } = MyDates.get48MinMaxDonationRange(MY_CONFIG.DONATION_EXP_HOURS, start);
                    this.setMinMaxParam(minDate, maxDate, "dateStartMeet");
                    handleNavigation("WDonationDatesPagePre")();
                }
            });
        }
        if (isRepost) {
            listaCampos.push({
                label: "Fecha de Oportunidad", icon: "calendar-days",
                currentValue: MyDates.formatDatetimeRange(params.dateStartMeet, params.dateEndMeet),
                goToPage: async () => {
                    const minDate = new Date();
                    const maxDate = new Date();
                    maxDate.setFullYear(minDate.getFullYear() + 1);
                    this.setMinMaxParam(minDate, maxDate, "dateStartMeet");
                    handleNavigation("WDonationDatesPageRepost")();
                }
            });
        }
        // El lugar siempre se pregunta
        if (isDonation) {
            listaCampos.push({
                label: "Lugar", icon: "location-dot",
                currentValue: params.virtual ?
                    this.joinNotNullValues([Config.getVirtualValue(params.virtual), params.linkMeet]) :
                    this.joinNotNullValues([Config.getVirtualValue(params.virtual), Config.getCityValue(params.city), params.address]),
                goToPage: this.handleNavigation("WDonationPlaceMeetPage")
            });
        }
        if (isRepost) {
            listaCampos.push({
                label: "Lugar", icon: "location-dot",
                currentValue: params.virtual ?
                    this.joinNotNullValues([Config.getVirtualValue(params.virtual), params.linkMeet]) :
                    this.joinNotNullValues([Config.getVirtualValue(params.virtual), Config.getCityValue(params.city), params.address]),
                goToPage: this.handleNavigation("WDonationPlaceRepostPage")
            });
        }
        listaCampos.push({
            label: "Precio", icon: "cart-shopping",
            currentValue: (params.isfree ? 'Gratuito' : params.price),
            goToPage: this.handleNavigation("WDonationPricePage")
        });

        /*
        listaCampos.push({
            label: "Lugar", icon: "location-dot",
            currentValue: this.joinNotNullValues([Config.getCityValue(params.city), params.pickAddress, MyDates.formatDatetime(params.pickTime)]),
            goToPage: async () => {
                const MY_CONFIG = await Config.getValue("config");
                const start = new Date(parseInt(params.start));
                const {
                    minDate,
                    maxDate
                } = MyDates.get48MinMaxDonationRange(MY_CONFIG.DONATION_EXP_HOURS, start);
                this.setMinMaxParam(minDate, maxDate, "pickTime");
                handleNavigation("WDonationPlaceReceivePage")();
            }
        });
        */
        listaCampos.push({
            label: "Ajustes Avanzados", icon: "sliders",
            currentValue: "",
            goToPage: async () => {
                handleNavigation("WDonationAdvancedPage")();
            }
        });

        /*
        listaCampos.push({
            label: "Link para más información", icon: "link",
            currentValue: params.linkMore,
            goToPage: this.handleNavigation("WDonationLinkMorePage")
        });
        */

        return listaCampos;

    }
    render() {
        const props = this.props;
        const opcionesMenu = this.getMenuOptions();
        const isRepost = this.props.route.params.usevotes;
        let donationType = Constants.LISTS.DONATION.TYPE.DONACION;
        if (isRepost) {
            donationType = Constants.LISTS.DONATION.TYPE.REPOST;
        }
        return (
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={this.state.data}
                validate={values => {
                    const errors = {};
                    errors.title = hasMinMaxLength(values.title, 5, 60);
                    if (errors.title === undefined) { delete errors.title; } else { this.props.alert("Verifica el título. " + errors.title); return errors; }
                    errors.description = hasMinMaxLength(values.description, 5, 512);
                    if (errors.description === undefined) { delete errors.description; } else { this.props.alert("Verifica la descripción. " + errors.description); return errors; }
                    const REQUERIDOS = [];

                    // Se recalcula el tipo de donación/repost
                    const params = (this.props.route.params ? this.props.route.params : {});
                    if (params.usevotes) {
                        props.route.params.type = Constants.LISTS.DONATION.TYPE.REPOST;
                    } else {
                        props.route.params.type = Constants.LISTS.DONATION.TYPE.DONACION;
                    }

                    const esPresencial = [false, "false"].indexOf(props.route.params.virtual) >= 0;
                    const esVirtual = [true, "true"].indexOf(props.route.params.virtual) >= 0;

                    if ([Constants.LISTS.DONATION.TYPE.DONACION].indexOf(props.route.params.type) >= 0) {
                        // DONACIÓN
                        if (!params.usevotes) {
                            REQUERIDOS.push({ key: "numWin", type: "number", msg: "Debes seleccionar el Número de Ganadores" });
                        }
                        REQUERIDOS.push({ key: "dateStartMeet", type: ["string", "number"], msg: "Debes seleccionar la Fecha cuando darás tu Donación" });
                        REQUERIDOS.push({ key: "category", type: "string", msg: "Debes seleccionar la Categoría" });
                    } else if ([Constants.LISTS.DONATION.TYPE.REPOST].indexOf(props.route.params.type) >= 0) {
                        // AVISO o REPOST
                        REQUERIDOS.push({ key: "dateStartMeet", type: ["string", "number"], msg: "Debes seleccionar la Fecha de la Oportunidad" });
                        REQUERIDOS.push({ key: "category", type: "string", msg: "Debes seleccionar la Categoría" });
                    }
                    if (esPresencial) {
                        // Siempre que es presencial, la ciudad es obligatoria
                        REQUERIDOS.push({ key: "city", type: "string", hasLength: true, msg: "En la sección Lugar debes seleccionar la Ciudad" });
                        //REQUERIDOS.push({ key: "address", type: "string", hasLength: true, msg: "Debes seleccionar el Lugar de Encuentro" });
                    }
                    for (let i = 0; i < REQUERIDOS.length; i++) {
                        const aReq = REQUERIDOS[i];
                        const currentValue = props.route.params[aReq.key];
                        if (aReq.type instanceof Array) {
                            if (aReq.type.indexOf(typeof currentValue) < 0) {
                                errors[aReq.key] = aReq.msg;
                                this.props.alert(errors[aReq.key]);
                                break;
                            }
                        } else {
                            if (typeof currentValue != aReq.type) {
                                errors[aReq.key] = aReq.msg;
                                this.props.alert(errors[aReq.key]);
                                break;
                            }
                            if (aReq.hasLength) {
                                if (currentValue.length == 0) {
                                    errors[aReq.key] = aReq.msg;
                                    this.props.alert(errors[aReq.key]);
                                    break;
                                }
                            }
                        }
                    }

                    const start = parseInt(props.route.params.start);
                    // dateStartMeet
                    const dateStartMeet = parseInt(props.route.params.dateStartMeet);
                    if (!isNaN(dateStartMeet) && !isNaN(start)) {
                        if (dateStartMeet < start) {
                            errors['dateStartMeet'] = "La fecha y hora tentativa debe ser posterior a la fecha y hora de publicación.";
                            this.props.alert(errors['dateStartMeet']);
                        }
                    }

                    return errors;
                }}
                onSubmit={async (values) => {

                    const miDonacion = {};
                    miDonacion.image = props.route.params.image;
                    miDonacion.title = values.title;
                    miDonacion.description = values.description;
                    miDonacion.type = props.route.params.type;
                    miDonacion.usevotes = props.route.params.usevotes;
                    miDonacion.virtual = props.route.params.virtual;
                    miDonacion.linkMeet = props.route.params.linkMeet;
                    miDonacion.linkMore = props.route.params.linkMore;
                    miDonacion.organicername = props.route.params.organicername;
                    miDonacion.organicerme = props.route.params.organicerme;
                    miDonacion.city = props.route.params.city;
                    miDonacion.address = props.route.params.address;
                    //miDonacion.pickAddress = props.route.params.pickAddress;
                    miDonacion.start = props.route.params.start;
                    miDonacion.isfree = props.route.params.isfree;
                    miDonacion.price = props.route.params.price;// Se debe poder formatear...

                    const CAMPOS_NUMERICOS = [
                        "dateStartMeet",
                        "dateStartMeet2",
                        "dateEndMeet",
                        "pickTime",
                        "numWin",
                        "category",
                        "start",
                    ];

                    for (let i = 0; i < CAMPOS_NUMERICOS.length; i++) {
                        const llave = CAMPOS_NUMERICOS[i];
                        miDonacion[llave] = parseInt(props.route.params[llave]);
                        if (isNaN(miDonacion[llave])) {
                            miDonacion[llave] = null;
                        }
                    }

                    // Se chequea si la donación va al futuro
                    const timestamp = new Date().getTime();
                    if (miDonacion.start < timestamp) {
                        miDonacion.started = true;
                    } else {
                        miDonacion.started = false;
                    }

                    // Se manda a crear
                    const promesaCreacion = new Promise(async (resolve) => {
                        try {
                            if (false) {
                                delete miDonacion.image;
                                console.log(JSON.stringify(miDonacion, null, 4));
                            } else {
                                await DonacionesSrv.crear(miDonacion);
                                await this.props.alert(CONFIG.LISTS.DONATION.MAPA[miDonacion.type].createdSuccessfully, "¡Muy bien!");
                                this.props.navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'MyHomePage' }],
                                });
                            }
                        } catch (error) {
                            await this.handleHttpError(error, CONFIG.LISTS.DONATION.MAPA[miDonacion.type].createdError);
                        }
                        resolve();
                    });
                    props.loading(promesaCreacion);
                    return promesaCreacion;
                }}
            >
                {({ handleChange, handleBlur, submitForm, errors, values }) => (
                    <XForm
                        details={{
                            selectedImage: this.props.route.params ? this.props.route.params.image : null,
                            opcionesMenu: opcionesMenu,
                            submit: submitForm,
                            donationType: donationType,
                            titleProps: {
                                secret: false,
                                label: "Título",
                                onChangeText: handleChange('title'),
                                onBlur: handleBlur('title'),
                                errors: errors.title,
                                value: values.title,
                            },
                            detailProps: {
                                multiline: true,
                                numberOfLines: 3,
                                secret: false,
                                label: "Descripción...",
                                onChangeText: handleChange('description'),
                                onBlur: handleBlur('description'),
                                errors: errors.description,
                                value: values.description,
                            }
                        }}
                    />
                )}
            </Formik>
        )
    }
}