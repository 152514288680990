import React from "react";
import styled from "styled-components/native";
import {
  H2Bold,
  ValignTextMiddle,
} from "../../widgets/CssConstans";
import Constants from "../../Constants"

import Button from "../signup/Button"
import MyInput from "../../widgets/MyInput";

const DIMENSIONS = {
  width: Constants.SIZE.VIEWS.width,
  maxWidth: Constants.SIZE.VIEWS.maxWidth,
  height: "360px",
  margin: Constants.SIZE.VIEWS.margin,
};

export default class RecuperarClave extends React.Component {
  render() {
    const {
      titulo,
      inputEmail,
      buttonPpal,
    } = this.props;

    return (
      <ContainerCenterHorizontal>
        <RecuperaCLaveScreen>
          <AutoLayoutVertical>
            <RecuperaClaveTit>{titulo}</RecuperaClaveTit>
            <AutoLayoutVertical1>
              <AutoLayoutVertical2>
                <MyInput
                  details={inputEmail}
                />
                <GapComponent></GapComponent>
                <Button details={buttonPpal}></Button>
              </AutoLayoutVertical2>
            </AutoLayoutVertical1>
          </AutoLayoutVertical>
        </RecuperaCLaveScreen>
      </ContainerCenterHorizontal>
    );
  }
}

const GapComponent = styled.View`
  width: 100%;
  height: 10px;
`;

const AutoLayoutVertical = styled.View`
  display: flex;
  position: relative;
  flex-direction: column;
  height: ${DIMENSIONS.height};
  width: ${DIMENSIONS.width};
  ${DIMENSIONS.maxWidth}
  margin: ${DIMENSIONS.margin};
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 48px;
  background-color: ${props => props.theme.__background_dark};
`;

const RecuperaClaveTit = styled.Text`
  ${ValignTextMiddle}
  ${H2Bold}
  width: 100%;
  font-weight: 700;
  color: ${props => props.theme.__others__white};
  line-height: 44px;
`;

const AutoLayoutVertical1 = styled.View`
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

const AutoLayoutVertical2 = styled.View`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  align-items: center;
  justify-content: center;
  
  position: relative;
`;

const RecuperaCLaveScreen = styled.View`
    align-items: flex-start;
    background-color: ${props => props.theme.__background_dark};
    display: flex;
    flex-direction: column;
    height: 100%;
    position:relative;
    width: 100%;
`;

const ContainerCenterHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;