import React from "react";
import styled from "styled-components/native";
import * as CssConstants from "../../widgets/CssConstans";
import MyLog from "../../srv/MyLog";
import AssetsImg from "../../AssetsImg";
import MyInput from "../../widgets/MyInput";
import Button from "../signup/Button";
import Config from "../../utils/Config";
import FormOptionMenu from "../wdonation3/FormOptionMenu";
import Divider from "../../widgets/Divider";

export default class MyEditProfile extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            pageType,
            showTime,
            nameProps,
            photoProps,
            bioProps,
            emailProps,
            detailsButtonPrimary,
            detailsButtonSecondary,
            pickPhotoAction,
            logoutAction,
            eraseUserAction,
            showLogout,
            showEraseAccount,
            countryProps,
            cityProps,
            ciiuProps,
            institucionProps,
            enterpriseName,
            profesionName,
            webPage,
            instagramProps,
            pitchProps,
        } = this.props.details;

        let sourceImage = AssetsImg.IMG.DEFAVATAR;
        if (photoProps.url) {
            sourceImage = { uri: Config.getNewPath(photoProps.url, "", showTime) };
        }

        const showAllFields = (pageType != "create");

        return (
            <ParentContainer>
                <MyContent>
                    <AutoLayoutVertical>
                        <Avatar source={sourceImage}></Avatar>
                        <TextoCambiarFotoButton onPress={pickPhotoAction}>
                            <TextoCambiarFoto>Cambiar foto de perfil</TextoCambiarFoto>
                        </TextoCambiarFotoButton>
                        <ContenedorInput>
                            <ContainerLabel style={{ fontSize: 22 }} >{"Información personal:"}</ContainerLabel>
                        </ContenedorInput>
                        <ContenedorInput>
                            <ContainerLabel>{"Nombres:"}</ContainerLabel>
                            <MyInput details={nameProps}></MyInput>
                        </ContenedorInput>
                        {showAllFields && <ContenedorInput>
                            <ContainerLabel>{"Profesión:"}</ContainerLabel>
                            <MyInput details={profesionName}></MyInput>
                        </ContenedorInput>}
                        <ContenedorInput>
                            <ContainerLabel>{"Biografía:"}</ContainerLabel>
                            <MyInput details={bioProps}></MyInput>
                        </ContenedorInput>
                        <ContenedorInput>
                            <ContainerLabel>{"Correo:"}</ContainerLabel>
                            <MyInput details={emailProps}></MyInput>
                        </ContenedorInput>
                        {showAllFields && <ContenedorInput>
                            <FormOptionMenu details={institucionProps} />
                        </ContenedorInput>}
                        {showAllFields && <Divider marginTop={"10px"} marginBottom={"20px"} />}
                        {showAllFields && <ContenedorInput>
                            <ContainerLabel style={{ fontSize: 22 }} >{"Información de tu empresa o emprendimiento:"}</ContainerLabel>
                        </ContenedorInput>}
                        {showAllFields && <ContenedorInput>
                            <ContainerLabel>{"Nombre:"}</ContainerLabel>
                            <MyInput details={enterpriseName}></MyInput>
                        </ContenedorInput>}
                        {showAllFields && <ContenedorInput>
                            <ContainerLabel>{"Elevator pitch:"}</ContainerLabel>
                            <MyInput details={pitchProps}></MyInput>
                        </ContenedorInput>}
                        {showAllFields && <ContenedorInput>
                            <ContainerLabel>{"Página web:"}</ContainerLabel>
                            <MyInput details={webPage}></MyInput>
                        </ContenedorInput>}
                        {showAllFields && <ContenedorInput>
                            <ContainerLabel>{"Usuario de Instagram:"}</ContainerLabel>
                            <MyInput details={instagramProps}></MyInput>
                        </ContenedorInput>}
                        {showAllFields && <ContenedorInput>
                            <FormOptionMenu details={ciiuProps} />
                        </ContenedorInput>}
                        {showAllFields && <ContenedorInput>
                            <FormOptionMenu details={countryProps} />
                        </ContenedorInput>}
                        {showAllFields && cityProps != null && <ContenedorInput>
                            <FormOptionMenu details={cityProps} />
                        </ContenedorInput>}
                        {showLogout == true && <TextoCambiarFotoButton onPress={logoutAction}>
                            <TextoCambiarFoto>Logout</TextoCambiarFoto>
                        </TextoCambiarFotoButton>}
                        {showLogout == true && <TextoCambiarFotoButton onPress={eraseUserAction}>
                            <TextoCambiarFoto>Borrar mi cuenta para siempre</TextoCambiarFoto>
                        </TextoCambiarFotoButton>}
                    </AutoLayoutVertical>
                </MyContent>
                <MyFooter>
                    <ContenedorBotones>
                        <Button details={detailsButtonPrimary}></Button>
                        {detailsButtonSecondary && <Button details={detailsButtonSecondary}></Button>}
                    </ContenedorBotones>
                </MyFooter>
            </ParentContainer>
        );
    }
}

const ContainerLabel = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXlargeSemibold}
    flex: 1;
    margin-bottom: 10px;
    font-weight: 600;
    color: ${props => props.theme.__others__white};
    line-height: 25.2px;
    padding-left: 10px; 
`;

const MyContent = styled.ScrollView`
    width: 100%;
    flex: 1;
    padding-left: 24px;
    padding-right: 24px;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyFooter = styled.View`
    width: 100%;
    padding: 24px;
`;

const ContenedorBotones = styled.View`
    width: 100%;
`;

const ContenedorInput = styled.View`
    width: 100%;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
`;

const TextoCambiarFotoButton = styled.Pressable`
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
`;

const TextoCambiarFoto = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumBold}
    width: 100%;
    text-align: center;
    color: ${props => props.theme.__alerts__status__info};
`;

const Avatar = styled.ImageBackground`
    width: 140px;
    height: 140px;
    border-radius: 100px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    margin-top: 24px;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;