import React from "react";
import styled from "styled-components/native";

import * as CssConstants from "../../widgets/CssConstans";
import FormOptionMenu from "./FormOptionMenu";
import Button from "../signup/Button";
import Config from "../../utils/Config";
import Divider from "../../widgets/Divider";
import MyInput from "../../widgets/MyInput";


const IMAGE_HEIGHT = "173px";
const TEXTAREA_HEIGHT = "103px";

class XForm extends React.Component {
    render() {
        const CONFIG = Config.getValueSync("config");
        const {
            selectedImage,
            submit,
            opcionesMenu,
            titleProps,
            detailProps,
            donationType
        } = this.props.details;

        detailProps.myHeight = TEXTAREA_HEIGHT;

        const listItems = opcionesMenu.map((elemento, index) =>
            <FormOptionMenu key={index} details={elemento} />
        );

        return (
            <ParentContainer>
                <MyContent>
                    <AutoLayoutVertical>
                        <TextContainer>
                            <PhotoContainer1
                                source={{ uri: selectedImage }}
                                imageStyle={{ borderRadius: 8 }}
                                resizeMode="cover"
                                blurRadius={5}
                            >
                                <PhotoContainer
                                    imageStyle={{ borderRadius: 8 }}
                                    source={{ uri: selectedImage }}
                                    resizeMode="contain" />
                            </PhotoContainer1>
                            <StyledInputCommon>
                                <StyledInput>
                                    <MyInput details={titleProps}></MyInput>
                                </StyledInput>
                                <StyledInput>
                                    <MyInput details={detailProps}></MyInput>
                                </StyledInput>
                            </StyledInputCommon>
                        </TextContainer>
                        <Divider marginTop={"10px"} marginBottom={"10px"} />
                        {listItems}
                        <Divider marginTop={"10px"} marginBottom={"10px"} />
                        <ButtomContainer>
                            {donationType == "repost" &&
                                <PublicacinActivaPor48hrs>Publicación activa hasta fecha de finalización de oportunidad </PublicacinActivaPor48hrs>
                            }
                            {donationType != "repost" &&
                                <PublicacinActivaPor48hrs>Publicación activa por {CONFIG.DONATION_EXP_HOURS}hrs</PublicacinActivaPor48hrs>
                            }
                        </ButtomContainer>
                    </AutoLayoutVertical>
                </MyContent>
                <MyFooter>
                    <AutoLayoutVertical>
                        <AutoLayoutHorizontal>
                            <Button details={{
                                label: "Publicar",
                                onPress: submit,
                            }}></Button>
                        </AutoLayoutHorizontal>
                    </AutoLayoutVertical>
                </MyFooter>
            </ParentContainer>
        );
    }
}

export default XForm;

const PhotoContainer1 = styled.ImageBackground`
    width: 80px;
    height: ${IMAGE_HEIGHT};
`;

const StyledInputCommon = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

// height: 56px;
const StyledInput = styled.View`
    margin-left: 10px;
    margin-bottom: 10px;
    padding-right: 90px;
    width: 100%;
`;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyContent = styled.ScrollView`
    width: 100%;
    flex: 1;
`;

const MyFooter = styled.View`
    width: 100%;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

//gap: 20px;
const AutoLayoutVertical = styled.View`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 24px;
    width: 100%;
`;

//gap: 16px;
const TextContainer = styled.View`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
    position: relative;
    align-self: stretch;

`;

const MaskGroup = styled.View`
    width: 80px;
    height: 56px;
`;

const PhotoContainer = styled.ImageBackground`
    width: 80px;
    height: ${IMAGE_HEIGHT};
`;

//gap: 16px;
const ButtomContainer = styled.View`
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: stretch;
`;

// white-space: nowrap;
const PublicacinActivaPor48hrs = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXlargeSemibold}
    flex: 1;
    margin-top: 0px;
    font-weight: 600;
    color: ${props => props.theme.__others__white};
    line-height: 25px;
`;
