import SelectScreen from './SelectScreen';

export default class WDonationCategoryScreen extends SelectScreen {
    constructor(props) {
        super(props);
        this.state.list = this.props.route.params?.list;
        this.state.keyName = this.props.route.params?.keyName;
        if (!(typeof this.state.list == "string")) {
            this.state.list = "categories";
        }
        if (!(typeof this.state.keyName == "string")) {
            this.state.keyName = "category";
        }
        this.state[this.state.keyName] = this.props.route.params[this.state.keyName];
        this.state.myOptions = [];
        this.init();
    }
    selectChoice(elemento) {
        this.props.route.params[this.state.keyName] = elemento.value;
        const objeto = {};
        objeto[this.state.keyName] = elemento.value;
        this.setState(objeto);
    }
    onSubmit(values) {
        if ([null, undefined].indexOf(this.state[this.state.keyName]) >= 0) {
            this.props.alert("Para continuar debes seleccionar al menos una opción");
            return;
        }
        super.myLocalGoBack(this.props.route.params);
        //this.props.navigation.navigate('WDonationPage3', this.props.route.params);
    }
    async init() {
        const selectChoice = this.selectChoice.bind(this);
        const allOptions = await super.getList(this.state.list);
        const myOptions = [];
        for (let i = 0; i < allOptions.length; i++) {
            const una = allOptions[i];
            myOptions.push(
                {
                    izq: { label: una.txt },
                    value: una.key,
                    onPress: selectChoice,
                }
            );
        }
        this.setState({ myOptions: myOptions });
    }

    render() {
        const autoSubmit = true;
        const onSubmit = this.onSubmit.bind(this);
        super.addHighlighted(this.state.myOptions, this.state[this.state.keyName]);
        return super.myRender(this.state.myOptions, "", onSubmit, null, autoSubmit);
    }
}