import React from "react";
import styled from "styled-components/native";

import * as CssConstants from "../../widgets/CssConstans";
import Config from "../../utils/Config";

/*

</MainComponent1>
*/

export default class DonationBig extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            title,
            category,
            img,
        } = this.props.details;
        const { windowSize } = this.props;
        const myHeight = parseInt((7 / 12) * windowSize.height);
        return (
            <MyPressable
                onPress={() => {
                    this.props.goToDonation(this.props.details, -1);
                }}>
                <MainComponent1
                    myHeight={myHeight}
                    source={{ uri: img }}
                    resizeMode="cover"
                    blurRadius={10}
                >
                    <MainComponent
                        myHeight={myHeight}
                        source={{ uri: img }}
                        resizeMode="contain">
                        <EmptyFront></EmptyFront>
                        <BottomFront>
                            <AutoLayoutVertical>
                                <Texto1>{title}</Texto1>
                                <Texto2>{Config.getCategoryValue(category)}</Texto2>
                            </AutoLayoutVertical>
                        </BottomFront>
                    </MainComponent>
                </MainComponent1>
            </MyPressable>
        );
    }
}

const HEIGHT_CONTAINER_TXT = "159px"

const MyPressable = styled.Pressable`

`;

const MainComponent1 = styled.ImageBackground`
    width: 100%;
    height: ${props => props.myHeight}px;
`;

const MainComponent = styled.ImageBackground`
    display: flex;
    flex-direction: column;
    height: ${props => props.myHeight}px;
    align-items: flex-start;
    justify-content: center;
    
    position: relative;
    align-self: stretch;
`;

const EmptyFront = styled.View`
    flex: 1;
    width: 100%;
`;

// No tengo ni idea por qué acá toca poner 150px
const BottomFront = styled.View`
    width: 100%;
    height: ${HEIGHT_CONTAINER_TXT};
    
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${HEIGHT_CONTAINER_TXT};
    padding: 35px 24px;
    background-color: ${props => props.theme.__eerie_black_mine};

`;

const Texto1 = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H4Bold}
    width: 100%;
    height: 59px;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 28.8px;
`;

const Texto2 = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXlargeSemibold}
    width: 100%;
    font-weight: 600;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 25.2px;
`;