import React from 'react'
import { MountedComponent } from "../utils/MountedComponent";
import XPageWithMenu1 from '../screens/wdonation2/XPageWithMenu1';

export default class WDonationScreen1 extends MountedComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign(this.state, {
            user: null,
            userBack: null,
            donationNew: {},
            donationType: null,
        });
    }
    async onUser(payloadUser) {
        if (payloadUser.user == null) {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        }
    }
    async submit() {
        // Propaga los params
        this.props.navigation.navigate('WDonationPage2', this.props.route.params);
    }
    render() {
        const submit = this.submit.bind(this);
        const setState = this.setState.bind(this);
        const details = {
            selectedImage: this.props.route.params ? this.props.route.params.image : null,
            submit: submit,
            donationType: this.state.donationType,
            radioToggle: (myNewValue) => {
                setState({ donationType: myNewValue });
            }
        };
        return (
            <XPageWithMenu1
                details={details}
            />
        )
    }
}