import React from "react";
import styled from "styled-components/native";
import AssetsImg from "../../AssetsImg";

import Constants from "../../Constants"
import Divider from "../../widgets/Divider";
import Button from "../signup/Button";
import * as CssConstants from "../../widgets/CssConstans";
import MySimpleMenuItem from "./MySimpleMenuItem";


class XPageWithMenu extends React.Component {
    render() {
        const {
            donationType,
            radioToggle,
            selectedImage,
            submit
        } = this.props.details;
        return (
            <ParentContainer1
                source={{ uri: selectedImage }}
                resizeMode="cover"
                blurRadius={10}
            >
                <ParentContainer
                    source={{ uri: selectedImage }}
                    resizeMode="contain"
                >
                    <MyContent></MyContent>
                    <MyFooter>
                        <AutoLayoutVertical>
                            <Frame></Frame>
                            <TipoDeDonacin>Tipo de Donación</TipoDeDonacin>
                            <Divider marginBottom={"24px"} />
                            <MySimpleMenuItem details={{
                                icon: "compass",
                                value: Constants.LISTS.DONATION.TYPE.SRV_VIRT,
                                currentValue: donationType,
                                label: "Servicio Virtual",
                                radioToggle: radioToggle,
                            }}></MySimpleMenuItem>
                            <MySimpleMenuItem details={{
                                icon: "ticket",
                                value: Constants.LISTS.DONATION.TYPE.SRV_PRE,
                                currentValue: donationType,
                                label: "Servicio Presencial",
                                radioToggle: radioToggle,
                            }}></MySimpleMenuItem>
                            <MySimpleMenuItem details={{
                                icon: "shopping-bag",
                                value: Constants.LISTS.DONATION.TYPE.PRODUCTO,
                                currentValue: donationType,
                                label: "Producto",
                                radioToggle: radioToggle,
                            }}></MySimpleMenuItem>
                            <Button details={{ label: "Siguiente", onPress: submit }}></Button>
                        </AutoLayoutVertical>
                    </MyFooter>
                </ParentContainer>
            </ParentContainer1>
        );
    }
}

export default XPageWithMenu;

const ParentContainer1 = styled.ImageBackground`
width: 100%;
height: 100%;
`;

const ParentContainer = styled.ImageBackground`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
`;

const MyContent = styled.View`
width: 100%;
flex: 1;
`;

// justify-content: 'flex-end'; crash
const MyFooter = styled.View`
width: 100%;
`;

// gap: 24px;
// width: 428px;
// position: fixed;
// top: 0;
// left: 0;
const AutoLayoutVertical = styled.View`
    ${CssConstants.Border1pxDarkDark3}
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    
    padding: 8px 24px 48px;

    
    background-color: ${props => props.theme.__dark__dark_1};
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    overflow: hidden;
  `;

const Frame = styled.View`
    margin-bottom: 24px;
    min-width: 38px;
    height: 3px;
    background-color: ${props => props.theme.__dark__dark_3};
    border-radius: 100px;
`;

// white-space: nowrap;
// width: 380px;
const TipoDeDonacin = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H5Bold}
    margin-bottom: 24px;
    width: 100%;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 28.8px;
    
`;

