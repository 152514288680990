import Config from '../utils/Config';
import CONFIG from '../Constants';
import { isValidAddress } from '../utils/InputValidator';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_3 = "virtual"; // Se valida acá
const FIELD_KEY_NAME_1 = "address";// Se valida acá

const FIELD_KEY_NAME_2 = "city";// Se selecciona en otra página, por eso acá no se propaga
const FIELD_KEY_NAME_4 = "linkMeet";// Se selecciona en otra página, por eso acá no se propaga

export default class WDonationPlaceMeetScreen extends SelectScreen {
    constructor(props) {
        super(props);
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        this.initValue(FIELD_KEY_NAME_1);
        this.initValue(FIELD_KEY_NAME_3, false);
    }
    onSubmit(values) {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        params[FIELD_KEY_NAME_1] = values[FIELD_KEY_NAME_1];
        params[FIELD_KEY_NAME_3] = values[FIELD_KEY_NAME_3];
        super.myLocalGoBack(params);
    }
    validate(values) {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        const errors = {};
        if (params.type == "repost") {
            if (this.state.data[FIELD_KEY_NAME_3]) {
                // Es virtual
            } else {
                // Es presencial
                errors[FIELD_KEY_NAME_1] = isValidAddress(values[FIELD_KEY_NAME_1]);
                if (errors[FIELD_KEY_NAME_1] === undefined) { delete errors[FIELD_KEY_NAME_1]; }
            }
        } else {
            if (this.state.data[FIELD_KEY_NAME_3]) {
                // Es virtual, Lorena dice que no es requerido
                //if (!params[FIELD_KEY_NAME_4]) {
                //    errors[FIELD_KEY_NAME_4] = "Debes seleccionar el Link de Videoconferencia";
                //}
            } else {
                // Es presencial
                errors[FIELD_KEY_NAME_1] = isValidAddress(values[FIELD_KEY_NAME_1]);
                if (errors[FIELD_KEY_NAME_1] === undefined) { delete errors[FIELD_KEY_NAME_1]; }
                if (!params[FIELD_KEY_NAME_2]) {
                    errors[FIELD_KEY_NAME_2] = "Debes seleccionar una Ciudad";
                }
            }
        }
        const llaves = Object.keys(errors);
        if (llaves.length > 0) {
            this.props.alert(errors[llaves[0]]);
        }
        return errors;
    }
    render() {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        const handleLocalNavigationThis = super.handleLocalNavigation.bind(this);
        const listaOpciones = [{
            izq: {},
            der: {
                type: "radio",
                details: {
                    type: "switch",
                    label: "Virtual",
                    value: this.state[FIELD_KEY_NAME_3],
                    disabled: false,
                    onChange: (payload) => {
                        const nuevo = { data: this.state.data };
                        // nuevo[FIELD_KEY_NAME_3] = payload.target.value;
                        // Se debe colocar el valor en data porque es lo que ve Formik
                        nuevo.data[FIELD_KEY_NAME_3] = payload.target.value;
                        this.setState(nuevo);
                    },
                    name: FIELD_KEY_NAME_3,
                }
            }
        }];

        let labelLink = "Link de Videoconferencia";
        let pageLink = "WDonationLinkPage";
        if (params.type == "repost") {
            labelLink = "Link de Oportunidad";
            pageLink = "WRepostLinkPage";
        }

        if (this.state.data[FIELD_KEY_NAME_3]) {
            // Es virtual
            /*
            listaOpciones.push({
                izq: {},
                der: {
                    type: "page",
                    details: {
                        label: labelLink, icon: "link",
                        currentValue: params.linkMeet,
                        goToPage: () => {
                            handleLocalNavigationThis(pageLink);
                        }
                    }
                }
            });
            */
        } else {
            // Es presencial
            listaOpciones.push({
                izq: {},
                der: {
                    type: "page",
                    details: {
                        label: "Ciudad",
                        //icon: "tree-city",
                        name: FIELD_KEY_NAME_2,
                        currentValue: Config.getCityValue(params.city),
                        goToPage: () => {
                            handleLocalNavigationThis("WDonationCityPage");
                        }
                    },
                }
            });
            listaOpciones.push({
                izq: { label: "Dirección" },
                der: {
                    type: "input-text",
                    details: {
                        name: FIELD_KEY_NAME_1,
                    }
                }
            });
        }

        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = "Ingresa el lugar en donde la oportunidad va a tomar lugar";
        return super.myRender(listaOpciones, instructions, onSubmit, validate);
    }
}