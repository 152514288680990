import React from "react";
import styled from "styled-components/native";
import { 
    Border1pxGreyscale200, 
    BodyLargeSemibold 
} from "../../widgets/CssConstans";
import Frame9 from "../svg/Frame9"
import { SocialGoogle } from "../../utils/hooks/SocialGoogle"

export default class Button5
    extends React.Component {
    render() {
        const { label, webClientId, acceptTerms } = this.props.details;
        return (
            <SocialGoogle alert={this.props.alert} webClientId={webClientId} acceptTerms={acceptTerms} mystyle={{ width: "100%" }}>
                <TypeLoginType2SocialType3GoogleStyl>
                    <AutoLayoutHorizontal>
                        <Frame9></Frame9>
                        <ContinueWithGoogle>{label}
                        </ContinueWithGoogle>
                    </AutoLayoutHorizontal>
                </TypeLoginType2SocialType3GoogleStyl>
            </SocialGoogle>
        );
    }
}

const TypeLoginType2SocialType3GoogleStyl = styled.View`
    ${Border1pxGreyscale200}
    display: flex;
    flex-direction: column;
    width: 100%;
    
    align-items: center;
    justify-content: center;
    
    padding: 18px 32px;
    background-color: ${props => props.theme.__background_dark_button};
    border-radius: 16px;
    margin: 8px 8px 8px 0px;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-flow: row;
    
    align-items: center;
    justify-content: center;
    
`;

const ContinueWithGoogle = styled.Text`
${BodyLargeSemibold}
min-width: 161px;
font-weight: 600;
color: ${props => props.theme.__others__white};
line-height: 22.4px;
margin-left: 12px;
`;