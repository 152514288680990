export class SimpleObj {
    static MIN_LENGTH_TOKENS = 3;
    static MAX_LENGTH_TOKENS = 50;
    static LISTA_NEGRA_TOKENS = ['de', 'en', 'con', 'para', 'el', 'él', 'la', 'sin', 'mas', 'ella', 'ellos', 'es', 'un', 'una'];

    static convertMapToArray(myMap) {
        const keys = Object.keys(myMap);
        const response = [];
        for (let i = 0; i < keys.length; i++) {
            const myKey = parseInt(keys[i]);
            if (!isNaN(myKey)) {
                response[myKey] = myMap[keys[i]];
            }
        }
        return response;
    }
    static getValue(obj, key, myDefault = undefined) {
        let current = obj;
        const canIterate = (some) => {
            return (typeof some == "object" && some !== null);
        };
        if (!canIterate(current)) {
            return myDefault;
        }
        const partes = key.split(".");
        while (canIterate(current) && partes.length > 0) {
            const firstKey = partes.splice(0, 1)[0];
            current = current[firstKey];
        }
        if (current !== undefined && partes.length == 0) {
            return current;
        } else {
            return myDefault;
        }
    }
    static createBasic(todo, llave, llave2) {
        const llave2EsNumero = (/^\d+$/.exec(llave2) != null);
        if (!(llave in todo)) {
            if (llave2EsNumero) {
                todo[llave] = [];
            } else {
                todo[llave] = {};
            }
        } else {
            const indice = todo[llave];
            if (indice instanceof Array && !llave2EsNumero) {
                //migrar de arreglo a objeto
                const temporal = {};
                for (let i = 0; i < indice.length; i++) {
                    temporal[i] = indice[i];
                }
                todo[llave] = temporal;
            }
        }
        return todo;
    }
    static recreate(todo, llave, valor) {
        const partes = llave.split(".");
        let indice = todo;
        for (let i = 0; i < partes.length - 1; i++) {
            const parte = partes[i];
            if (!(parte in indice)) {
                SimpleObj.createBasic(indice, parte, partes[i + 1]);
            }
            indice = indice[parte];
        }
        const ultimaLlave = partes[partes.length - 1];
        if (typeof valor == "object" && valor !== null) {
            if (!indice[ultimaLlave]) {
                if (valor instanceof Array) {
                    indice[ultimaLlave] = [];
                } else {
                    indice[ultimaLlave] = {};
                }
            } else {
                if (!(valor instanceof Array) && indice[ultimaLlave] instanceof Array) {
                    //migrar de arreglo a objeto
                    const temporal = indice[ultimaLlave];
                    const nuevo = {};
                    for (let i = 0; i < temporal.length; i++) {
                        const valorLocal = temporal[i];
                        if (valorLocal !== null) {
                            nuevo[i] = valorLocal;
                        }
                    }
                    indice[ultimaLlave] = nuevo;
                }
            }
        } else {
            indice[ultimaLlave] = valor;
        }
        return todo;
    }

    //SimpleObj.partirTexto("dfbdfhg", true, false);
    static partirTexto(q, buscable = true, permisivo = false) {
        //solo minusculas
        q = q.toLowerCase();
        //Quito caracteres no validos
        if (!permisivo) {
            q = q.replace(/[^\w\d\sá-úü]/g, '');
        }
        //console.log(q);
        //Reemplazar tildes dieresis 
        q = q.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
        let tokens = q.split(/\s+/);
        // Elimino textos con tamaño pequeño
        tokens = tokens.filter((word) => { return word.length >= SimpleObj.MIN_LENGTH_TOKENS });
    
        // Elimino textos con tamaño grande
        tokens = tokens.filter((word) => { return word.length <= SimpleObj.MAX_LENGTH_TOKENS });
    
        // Elimino los que pertenecen a la lista negra
        tokens = tokens.filter((word) => { return SimpleObj.LISTA_NEGRA_TOKENS.indexOf(word) < 0 });
    
        if (buscable) {
            // Se debe partir en pedazos mas pequeños cada palabra
            tokens = tokens.reduce((acc, word, index) => {
                let temp = word;
                do {
                    acc.push(temp);
                    temp = temp.substring(0, temp.length - 1);
                } while (temp.length >= SimpleObj.MIN_LENGTH_TOKENS);
                return acc;
            }, []);
    
            //Elimino duplicados
            tokens = tokens.filter((word, index) => { return (index == tokens.indexOf(word)) });
        } else {
            tokens = tokens.filter((word, index) => { return (index == tokens.indexOf(word)) });
        }
    
        return tokens;
    }
};