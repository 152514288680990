import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from 'react'
import styled from "styled-components/native";
import AssetsImg from "../AssetsImg";
import Button from "../screens/signup/Button";
import OutlineArrowLeft from "../screens/svg/OutlineArrowLeft";
import { fetchApi } from "../srv/Http";
import MyLog from "../srv/MyLog";
import theme from "../Theme";
import { MountedComponent } from "../utils/MountedComponent";
import { MyFileService } from "../utils/MyFileService";
import * as CssConstants from "../widgets/CssConstans";
import MemberRow from "../widgets/MemberRow";
import MyInput from "../widgets/MyInput";

class SearchPeopleScreen extends MountedComponent {
    constructor(props) {
        super(props);
        MyLog.log(`Constructor`, this);
        let selectedList = this.props.route.params.members;
        this.pageType = this.props.route.params.pageType;
        if (!(selectedList instanceof Array)) {
            selectedList = [];
        }
        this.state = Object.assign(this.state, {
            searchedList: [],
            selectedList,
            hasMoreSelectedList: false,
            isBottomMenuOpened: false,
        });
    }
    setBottomMenu(value) {
        this.setState({ isBottomMenuOpened: value });
    }
    setBottomMenuClosed() {
        this.setBottomMenu(false);
    }
    async onUser(payloadUser) {
        if (payloadUser.user === null) {
            // Redirect to home
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        } else {
            await this.registerPush();
        }
    }
    async onShow() {
        
    }
    static async checkViewed(id, alert) {
        const subUrl = "/msg/checkviewed";
        const payload = {
            id,
        };
        let response = null;
        try {
            response = await fetchApi(subUrl, payload, false, alert);
        } catch (err) {
            throw err;
        }
        return response;
    }
    backAction() {
        this.backOrGoHome();
    }
    async continueToCreateGroup() {
        if (this.pageType == "edit") {
            //Just go back
            this.props.navigation.goBack();
        } else {
            // Valida que haya al menos un miembro
            if (this.state.selectedList.length == 0) {
                this.props.alert("Debes seleccionar al menos un participante");
                return;
            }
            // Prepara el argumento para la siguiente página
            const params = {
                members: this.state.selectedList,
            };
            // Redirección a la siguiente página
            this.props.navigation.push('CreateGroupPage', params);
        }
    }
    async cleanAction() {
        this.setState({ searchedList: [] });
    }
    async searchAction(texto) {
        MyLog.log(`Search ${texto}`);
        if (!(typeof texto == "string") || texto.trim().length < 3) {
            this.props.alert("Debes escribir el nombre de la persona que deseas buscar");
            return;
        }
        const payload = {
            q: texto,
        };
        const subUrl = "/users/search";
        const promesa = fetchApi(subUrl, payload, false, this.props.alert);
        this.props.loading(promesa);
        const response = await promesa;
        this.setState({ searchedList: response.response });
    }
    async goToPersonSpace(payload) {
        const soyYoMismo = (this.state?.user?.email == payload.email);
        if (!soyYoMismo) {
            if (payload.viewed === false) {
                // Marcar la como vista...
                const promesa = SearchPeopleScreen.checkViewed(payload.id,);
                this.props.loading(promesa);
                await promesa;
                payload.viewed = true;
            }
            const params = payload;
            this.props.navigation.navigate('MessengerPage', params);
        }
    }
    goToProfile(payload) {
        this.props.navigation.push('ProfilePage', payload);
    }
    renderPerson(acc, ele, ix, prefix, goToPersonSpaceThis, goToProfileThis, isSelected = false) {
        const nuevo = <MemberRow
            key={`${prefix}-${ele.email}`}
            details={{
                selfInstance: ele,
                showTime: this.showTime,
                goToPersonSpace: () => {
                    //goToPersonSpaceThis(ele);
                },
                goToProfile: () => {
                    //goToProfileThis(ele);
                },
                picture: ele.picture,
                name: ele.name,
                txt: ele.txt,
                countUnread: ele.countUnread,
                d: ele.d,
                viewed: ele.viewed,
                selectedEditable: (ele.is_admin !== true),
                selected: isSelected,
                selectedChanged: (event, element) => {
                    const value = event.target.value;
                    if (typeof value == "boolean") {
                        if (value) {
                            // Se debe agregar a la lista de seleccionados
                            this.state.selectedList.push(element);
                        } else {
                            // Se debe quitar de la lista de seleccionados
                            const indice = this.state.selectedList.indexOf(element);
                            if (indice >= 0) {
                                this.state.selectedList.splice(indice, 1);
                            }
                        }
                        this.setState({ selectedList: this.state.selectedList });
                    }
                },
            }}></MemberRow>;
        acc.push(nuevo);
        return acc;
    }
    async goToCreateDonation(payload) {
        if (!(await this.alertLogedUser())) {
            return;
        }
        const nuevaImagen = await MyFileService.pickFile();
        if (typeof nuevaImagen == "string") {
            const nuevaDonacion = {
                image: nuevaImagen,
                type: payload.donationType,
            };
            // Go to next page
            this.props.navigation.reset({ index: 0, routes: [{ name: 'WDonationPage3', params: nuevaDonacion }] });
        }
    }
    render() {
        const backActionThis = this.backAction.bind(this);
        const searchActionThis = this.searchAction.bind(this);
        const cleanActionThis = this.cleanAction.bind(this);
        const goToPersonSpaceThis = this.goToPersonSpace.bind(this);
        const goToProfileThis = this.goToProfile.bind(this);
        const setBottomMenuClosedThis = this.setBottomMenuClosed.bind(this);
        const continueToCreateGroupThis = this.continueToCreateGroup.bind(this);

        const selectedEmails = [];

        const selectedListElements = this.state.selectedList.reduce((acc, el, ix) => {
            selectedEmails.push(el.email);
            return this.renderPerson(acc, el, ix, "selected", goToPersonSpaceThis, goToProfileThis, true);
        }, []);


        const searchedListElements = this.state.searchedList.filter((element) => {
            return selectedEmails.indexOf(element.email) < 0;
        }).reduce((acc, el, ix) => {
            return this.renderPerson(acc, el, ix, "searched", goToPersonSpaceThis, goToProfileThis, false);
        }, []);

        return (
            <ParentContainer>
                <MyContent>
                    <Header>
                        <ContenedorFlecha onPress={backActionThis}>
                            <OutlineArrowLeft></OutlineArrowLeft>
                        </ContenedorFlecha>
                        <ContenedorTitulo style={{ textAlign: 'center', flex: 1 }}>
                            {"Añadir Participantes"}
                        </ContenedorTitulo>
                    </Header>
                    <MyFooter>
                        <BottomWidget details={{
                            searchAction: searchActionThis,
                            cleanAction: cleanActionThis,
                        }}></BottomWidget>
                    </MyFooter>
                    <MultiScroll>
                        <MessagesScroll ref={ref => { this.scrollView1 = ref }}
                            onContentSizeChange={() => { }}>
                            <TituloSeccion>
                                <TextoSeccion>Participantes</TextoSeccion>
                            </TituloSeccion>
                            {selectedListElements}
                        </MessagesScroll>
                        <MessagesScroll ref={ref => { this.scrollView2 = ref }}
                            onContentSizeChange={() => { }}>
                            <TituloSeccion>
                                <TextoSeccion>Resultados de búsqueda</TextoSeccion>
                            </TituloSeccion>
                            {searchedListElements}
                        </MessagesScroll>
                    </MultiScroll>
                </MyContent>
                {this.state.isBottomMenuOpened && <BigBackground onPress={setBottomMenuClosedThis}></BigBackground>}
                <MyFooterBottom>
                    <AutoLayoutVerticalBottom>
                        <AutoLayoutHorizontalBottom>
                            <Button details={{
                                label: "Siguiente",
                                onPress: () => {
                                    continueToCreateGroupThis();
                                },
                            }}></Button>
                        </AutoLayoutHorizontalBottom>
                    </AutoLayoutVerticalBottom>
                </MyFooterBottom>
            </ParentContainer >
        );
    }
}

export default SearchPeopleScreen;

const ParentContainer = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyContent = styled.View`
    width: 100%;
    flex: 1;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
`;

const Header = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
`;

const ContenedorFlecha = styled.Pressable`
    width: 28px;
    height: 28px;
    border: 0;
`;

const ContenedorTitulo = styled.Text`
    ${CssConstants.H4Bold}
    color: ${props => props.theme.__others__white};
    margin-left: 16px;
`;

const MultiScroll = styled.View`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const MessagesScroll = styled.ScrollView`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MyFooter = styled.View`
    padding-top: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

const TituloSeccion = styled.View`
    padding-top: 10px;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const TextoSeccion = styled.Text`
    ${CssConstants.H6Bold}
    color: ${props => props.theme.__others__white};
    flex: 1;
`;

const BigBackground = styled.Pressable`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
`;

const MyFooterBottom = styled.View`
    width: 100%;
`;

const AutoLayoutVerticalBottom = styled.View`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 24px;
    width: 100%;
    border: 1px solid;
    border-color: rgb(42, 43, 57);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
`;

const AutoLayoutHorizontalBottom = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

class BottomWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
        }
        this.child = React.createRef();
    }
    render() {
        const {
            searchAction,
            cleanAction
        } = this.props.details;

        const localSearch = async () => {
            MyLog.log(`Buscar #{this.state.text}`);
            if (typeof searchAction == "function") {
                searchAction(this.state.text);
            }
        };

        const messageProps = {
            secret: false,
            label: "Busca personas aquí ...",
            onChangeText: (texto) => {
                this.setState({
                    text: texto,
                });
            },
            onBlur: () => {

            },
            onSubmit: localSearch,
            value: "",
            rightIcon: "times",
            rightCallback: () => {
                this.child.current.doEmpty();
                cleanAction();
            },
        }

        return (
            <AutoLayoutHorizontal>
                <InputTextContainer>
                    <MyInput ref={this.child} details={messageProps}></MyInput>
                </InputTextContainer>
                <SendButton onPress={localSearch}>
                    <Group
                        imageStyle={{ borderRadius: 100 }}
                        resizeMode="cover"
                        source={AssetsImg.IMG.YELLOW_BUTTON}>
                        <IconlyBoldStar>
                            <Discovery>
                                <FontAwesomeIcon icon={"magnifying-glass"} style={{ color: theme.__others__white, outline: "none" }} size={16} />
                            </Discovery>
                        </IconlyBoldStar>
                    </Group>
                </SendButton>
            </AutoLayoutHorizontal>
        );
    }
}

const SendButton = styled.Pressable`
    width: 56px;
    height: 56px;
`;

const InputTextContainer = styled.View`
    display: flex;
    flex: 1;
    padding-right: 12px;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const Group = styled.ImageBackground`
    width: 56px;
    height: 56px;
    border-radius: 100px;
`;

const IconlyBoldStar = styled.View`
    width: 24px;
    height: 24px;
    top: 18px;
    left: 18px;
    display: flex;
    padding: 3px 1px;
    align-items: flex-end;
`;

const Discovery = styled.View`
    width: 20px;
    height: 20px;
`;
