const theme = {
    __others__black: "rgba(0, 0, 0, 1)",
    __greyscale__900: "rgba(33, 33, 33, 1)",
    __greyscale__700: "rgba(97, 97, 97, 1)",
    __greyscale__600: "rgba(117, 117, 117, 1)",
    __greyscale__500: "rgba(158, 158, 158, 1)",
    __greyscale__400: "rgba(189, 189, 189, 1)",
    __greyscale__300: "rgba(224, 224, 224, 1)",
    __greyscale__200: "rgba(238, 238, 238, 1)",
    __greyscale__100: "rgba(245, 245, 245, 1)",
    __greyscale__50: "rgba(249, 249, 249, 1)",
    __vermilion: "rgba(255, 77, 0, 0.078)",
    __primary500: "rgba(255, 77, 103, 1)",
    __others__deep_orange: "rgba(255, 87, 38, 1)",
    __primary100: "rgba(255, 237, 240, 1)",
    __alerts__status__info: "rgba(36, 107, 253, 1)",

    __popup_menu_color: "rgba(35,38,45,1)",

    __rejected_cross: "rgba(104,107,111, 1)",
    __rejected_background: "rgba(26,27,34, 0.7)",

    __eerie_black_2: "rgba(26, 27, 34, 0.8)",
    __eerie_black_mine: "rgba(26, 27, 34, 0.5)",
    __eerie_black: "rgba(26, 27, 34, 0.149)",

    __background_dark: "rgba(26, 26, 34, 1)",
    __border_dark_buttons: "rgb(42, 43, 57)",
    __background_dark_button: "rgb(35, 37, 47)",

    __font_size_12px: "12px",
    __font_size_10px: "10px",
    __font_size_m2: "14px",
    __font_size_l2: "16px",
    __font_size_xl2: "18px",
    __font_size_xl: "22px",
    __font_size_xxl4: "24px",
    __font_size_xxl: "32px",
    __font_size_xxl3: "32px",
    __font_size_xxl2: "40px",

    __font_family_inter: "\"Urbanist\"",
    __font_family_inter_regular: "\"Urbanist\"",
    __font_family_sf_pro_text_regular: "\"Urbanist\"",
    __font_family_urbanist: "\"Urbanist\"",
    __font_family_urbanist_regular: "\"Urbanist\"",

    __dark__dark_1: "rgba(26, 27, 34, 1)",
    __dark__dark_2: "rgba(35, 37, 47, 1)",
    __dark__dark_3: "rgba(42, 43, 57, 1)",
    __others__white: "rgba(255, 255, 255, 1)",
    __greyscale__800: "rgba(66, 66, 66, 1)",
    __link_blue: "rgba(36, 107, 253, 1)",

};

export default theme;