import { MyDates } from '../utils/MyDates';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_1 = "start";

export default class WDonationPublishDateScreen extends SelectScreen {
    constructor(props) {
        super(props);
        const ahora = new Date();
        let MAX_DAYS = this.props.route.params?.config?.DONATION_MAX_FUTURE_PUBLISH_DAYS;
        if (!(typeof MAX_DAYS == "number")) {
            MAX_DAYS = 30;
        }
        this.maxPublishDate = new Date(ahora.setDate(ahora.getDate() + MAX_DAYS))
        super.initValue(FIELD_KEY_NAME_1);
    }
    onSubmit(values) {
        let params = this.props.route.params;
        if (!params) {
            params = {};
        }
        params[FIELD_KEY_NAME_1] = values[FIELD_KEY_NAME_1];
        super.myLocalGoBack(params);
    }
    validate(values) {
        const errors = {};
        const fecha1 = parseInt(values[FIELD_KEY_NAME_1]);
        if (isNaN(fecha1)) {
            errors[FIELD_KEY_NAME_1] = "Para continuar debes ingresar una fecha y hora de publicación";
        }
        return errors;
    }
    render() {
        const listaOpciones = [
            {
                izq: { label: "Fecha/hora de Publicación " },
                der: {
                    type: "datetime",
                    details: {
                        minDate: new Date(),
                        maxDate: this.maxPublishDate,
                        name: FIELD_KEY_NAME_1,
                    }
                }
            },
        ];
        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = `Selecciona una fecha máximo ${MyDates.formatDateSimple(this.maxPublishDate)}.`;
        return super.myRender(listaOpciones, instructions, onSubmit, validate);
    }
}