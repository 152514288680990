import React from 'react'
import { MountedComponent } from "../utils/MountedComponent";
import { Users } from '../srv/Users'
import { DonacionesSrv } from "../srv/DonacionesSrv";
import MyAccepted from '../screens/home/MyAccepted';
import { MyFileService } from '../utils/MyFileService';
import MyLog from '../srv/MyLog';

const PAGE_SIZE = 20;

export default class MyAcceptedScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.donationSrv = new DonacionesSrv();
        this.promesaCurrentUser = null;
        this.state = Object.assign(this.state, {
            user: null,
            userBack: null,
            acceptedDonations: null,
            wonDonations: null,
            donations: null,
        });
    }
    async onShow() {

    }
    async onLeave() {
        this.donationSrv.resetAccepted();
        this.donationSrv.resetWon();
    }
    async onUser(payloadUser) {
        this.fetchNextAcceptedDonations();
        this.fetchNextWonDonations();
    }
    async goToDonationAccepted(donation, index) {
        const params = {
            donations: this.state.acceptedDonations,
            index: index,
            origin: "MyAcceptedScreen",
            currentUserEmail: this.state.user?.email,
        };
        this.props.navigation.push('WDonationDetailAcceptedPage', params);
    }
    async goToDonationWon(donation, index) {
        const params = {
            donations: this.state.wonDonations,
            index: index,
            origin: "MyAcceptedScreen",
            currentUserEmail: this.state.user?.email,
        };
        this.props.navigation.push('WDonationDetailWonPage', params);
    }
    completeDonationList(list) {
        for (let i = 0; i < list.length; i++) {
            const donacion = list[i];
            donacion.otherUser = Users.getDefaultUser();
        }
    }
    async fetchNextAcceptedDonations() {
        const promesa = this.donationSrv.fetchAcceptedNext(PAGE_SIZE, this.props.alert);
        this.props.loading(promesa);
        const currentList = await promesa;
        this.completeDonationList(currentList);
        this.setState({ acceptedDonations: currentList });
    }
    async fetchNextWonDonations() {
        const promesa = this.donationSrv.fetchWonNext(PAGE_SIZE, this.props.alert);
        this.props.loading(promesa);
        const currentList = await promesa;
        this.completeDonationList(currentList);
        this.setState({ wonDonations: currentList });
    }
    async goToCreateDonation(payload) {
        const nuevaImagen = await MyFileService.pickFile();
        if (typeof nuevaImagen == "string") {
            const nuevaDonacion = {
                image: nuevaImagen,
                type: payload.donationType,
            };
            // Go to next page
            this.props.navigation.reset({ index: 0, routes: [{ name: 'WDonationPage3', params: nuevaDonacion }] });
        }
    }
    render() {
        const goToDonationAccepted = this.goToDonationAccepted.bind(this);
        const goToDonationWon = this.goToDonationWon.bind(this);
        const fetchNextAcceptedDonations = this.fetchNextAcceptedDonations.bind(this);
        const fetchNextWonDonations = this.fetchNextWonDonations.bind(this);
        const goToCreateDonation = this.goToCreateDonation.bind(this);

        const details = {
            showTime: this.showTime,
            picture: this.state.userBack?.picture,
            homeAction: async () => {
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyHomePage' }] });
            },
            notificationAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("notificationAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'NotificationsPage' }] });
            },
            plusAction: async (payload) => {
                await goToCreateDonation(payload);
            },
            chatAction: async () => {
                if (!(await this.alertLogedUser())) {
                    return;
                }
                MyLog.log("chatAction", this);
                this.props.navigation.reset({ index: 0, routes: [{ name: 'PeoplePage' }] });
            },
            profileAction: async () => {
                const params = {
                    email: this.state.user.email,
                };
                this.props.navigation.reset({ index: 0, routes: [{ name: 'ProfilePage', params: params }] });
            },
            visitAcceptedDonationsAction: async () => {
                this.props.navigation.reset({ index: 0, routes: [{ name: 'MyAcceptedPage' }] });
            },
            fetchNextAcceptedDonations: fetchNextAcceptedDonations,
            fetchNextWonDonations: fetchNextWonDonations,
            goToDonationAccepted: goToDonationAccepted,
            goToDonationWon: goToDonationWon,
            acceptedDonations: [],
            wonDonations: [],
            userRealTime: this.state.userRealTime,
        };

        if (this.state.acceptedDonations instanceof Array) {
            const copia = JSON.parse(JSON.stringify(this.state.acceptedDonations));
            details.acceptedDonations = copia;
        }

        if (this.state.wonDonations instanceof Array) {
            const copia = JSON.parse(JSON.stringify(this.state.wonDonations));
            details.wonDonations = copia;
        }

        return (
            <MyAccepted
                alert={this.props.alert}
                windowSize={{
                    width: this.state.width,
                    height: this.state.height,
                }}
                details={details}
            />
        )
    }
}