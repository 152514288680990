import React from "react";
import styled from "styled-components/native";
import theme from "../../Theme";
import {
  BodyLargeBold,
  ValignTextMiddle
} from "../../widgets/CssConstans";

export default class Button
  extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
    };
  }
  render() {
    const { label, onPress, bgColor, charColor, autoWidth, myStyle } = this.props.details;

    const submitAction = async () => {
      try {
        this.setState({ submitting: true });
        await onPress();
      } catch (e) {

      }
      this.setState({ submitting: false });
    }

    return (
      <TypeButtonType2SecondaryType3Rounde
        style={myStyle}
        bgColor={bgColor ? bgColor : theme.__others__deep_orange}
        onPress={submitAction}
        disabled={this.state.submitting}
        autoWidth={autoWidth}
      >
        <Button1 charColor={charColor ? charColor : theme.__others__white}>{label}</Button1>
      </TypeButtonType2SecondaryType3Rounde>
    );
  }
}

const TypeButtonType2SecondaryType3Rounde = styled.Pressable`
  display: flex;
  ${props => (props.autoWidth === true ? "" : "width: 100%;")};
  height: 55px;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 18px 18px;
  background-color: ${props => props.bgColor};
  border-radius: 100px;
`;

const Button1 = styled.Text`
  ${ValignTextMiddle}
  ${BodyLargeBold}
  flex: 1;
  align-self: stretch;
  margin-top: -2.5px;
  margin-bottom: -0.5px;
  font-weight: 700;
  color: ${props => (props.charColor ? props.charColor : props.theme.__others__white)};
  text-align: center;
  line-height: 22.4px;

`;