import React from 'react'
import { MountedComponent } from "../utils/MountedComponent";
import MainVote from '../screens/vote/MainVote';
import { DonacionesSrv } from '../srv/DonacionesSrv';
import RealTime from '../srv/RealTime';
import MyLog from '../srv/MyLog';
import MyShare from '../srv/MyShare';

/**
 * Esta página requiere:
 * params.donation
 * params.mode
 */
export default class VoteScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign(this.state, {
            user: null,
            userBack: null,
            donations: null,
            participants: [],
            myVotedEmail: null,
            relation: null,
        });
        this.actualizarParticipantsThis = this.actualizarParticipants.bind(this);
        this.startRefreshMyDesitions();
        this.subscriptionKey = "participants";
    }
    startRefreshMyDesitions() {
        const params = this.props.route.params;
        const self = this;
        if (this.isActiveRefresh) {
            return;
        } else {
            // De tiempo en tiempo pide actualizar
            async function updateOnce() {
                const donation = params.donation;
                return await DonacionesSrv.updateMyDesitions(donation.id);
            }
            function definirTimeout() {
                setTimeout(async () => {
                    const respuesta = await updateOnce();
                    if (respuesta != null) {
                        if (
                            "processed" in respuesta &&
                            (respuesta.processed instanceof Array) &&
                            respuesta.processed.length > 0
                        ) {
                            definirTimeout();
                        } else {
                            self.isActiveRefresh = false;
                        }
                    }
                }, 500);
            }
            definirTimeout();
            self.isActiveRefresh = true;
        }
    }
    async onUser(payloadUser) {
        MyLog.log("onUser()", this);
        const promesaCargue = this.loadInformation(this.state.user != null);
        this.props.loading(promesaCargue);
    }
    async onShowAndUser() {
        MyLog.log("onShow()", this);
        const donation = this.props.route.params.donation;
        const payload = {
            donation_id: donation.id,
        };
        RealTime.get().addListener(this.subscriptionKey, this.actualizarParticipantsThis, payload);
    }
    async onLeave() {
        MyLog.log("onLeave()", this);
        RealTime.get().removeListener(this.subscriptionKey, this.actualizarParticipantsThis);
    }
    async loadMoreParticipants() {
        if (this.subscriptionKey == "participants_all") {
            return;
        }
        RealTime.get().removeListener(this.subscriptionKey, this.actualizarParticipantsThis);
        this.subscriptionKey = "participants_all";
        const donation = this.props.route.params.donation;
        const payload = {
            donation_id: donation.id,
        };
        RealTime.get().addListener(this.subscriptionKey, this.actualizarParticipantsThis, payload);
    }
    actualizarParticipants(participants) {
        const copia = JSON.parse(JSON.stringify(participants));
        this.setState({ participants: copia });
    }
    async loadInformation(userLoged) {
        const donation = this.props.route.params.donation;
        let promesaMiOpinion = null;
        if (userLoged) {
            promesaMiOpinion = DonacionesSrv.readRelation(donation.id, this.props.alert);
        } else {
            promesaMiOpinion = Promise.resolve(null);
        }
        const response = await promesaMiOpinion;

        if (response != null) {
            if (!("relacion" in response)) {
                response.relacion = {
                    desition: false,
                };
            }
        }
        this.setState({
            relation: response,
            myVotedEmail: response?.desition?.desition,// Este es el email que voté la última vez
        });
    }
    async shareDonation() {
        await MyShare.share(this.props.route.params.donation.id, this.props.alert);
    }
    async voteDonation() {
        if (!(await this.alertLogedUser())) {
            return;
        }
        const myVotedEmail = this.state.myVotedEmail;
        if (typeof myVotedEmail !== "string" || myVotedEmail.length == 0) {
            this.props.alert("Primero debes seleccionar quien tu quieres que gane la donación.");
            return;
        }
        const promiseVote = DonacionesSrv.vote(this.props.route.params.donation.id, myVotedEmail, this.props.alert);
        this.props.loading(promiseVote);
        try {
            await promiseVote;
            this.startRefreshMyDesitions();
            await this.props.alert("Tu votación ha sido guardada exitósamente", "¡Excelente!");
            
            // No me parece que deba regresar al home pero es lo que se pidió...
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        } catch (err) { }
    }
    organizeParticipants() {
        const response = {
            participants: [],
            me: {},
            count: 0,
        };
        const mode = this.props.route.params.mode;
        response.me = this.state?.relation?.relacion;
        if (!response.me) {
            response.me = {};
        }
        const emailActual = this.state.email;
        let numWin = this.props.route.params?.donation?.numWin;
        const myVotedEmail = this.state.myVotedEmail;
        response.participants = JSON.parse(JSON.stringify(this.state.participants));
        response.count = response.participants.length;

        if (typeof numWin !== "number") {
            numWin = 0;
        }

        // Se busca el indice del usuario actual en la lista de participantes
        let indiceUsuarioActual = -1;
        for (let i = 0; i < response.participants.length; i++) {
            const actual = response.participants[i];
            if (actual.email == emailActual) {
                indiceUsuarioActual = i;
            }
        }
        if (this.state?.relation?.relacion?.desition === true) {
            if (response.me && response.me.name && indiceUsuarioActual < 0) {
                indiceUsuarioActual = response.participants.length;
                response.participants.push(response.me);
            }
        }
        // Se calcula quién tiene más votos para definir la proporcionalidad entre ellos
        let maxVotes = -1;
        for (let i = 0; i < response.participants.length; i++) {
            const actual = response.participants[i];
            actual.rate = 0;
            // Se asinga cuál es el seleccionado
            actual.selected = (myVotedEmail == actual.email);
            // Se calcula cuál es el máximo de votos.
            if (actual.votes > maxVotes) {
                maxVotes = actual.votes;
            }
        }
        if (maxVotes > 0) {
            for (let i = 0; i < response.participants.length; i++) {
                const actual = response.participants[i];
                actual.rate = parseInt(100 * actual.votes / maxVotes);
            }
        }

        // Organizo de arriba a abajo por cantidad de votos y luego por quién aplicó primero
        response.participants.sort((a, b) => {
            let diffVotos = (b.votes - a.votes);
            if (diffVotos == 0) {
                // Reviso quien aplicó primero
                let diffTime = (a.created - b.created);
                return diffTime;
            }
            return diffVotos;
        });

        for (let i = 0; i < response.participants.length; i++) {
            const actual = response.participants[i];
            actual.podium = (i < numWin);
        }

        // Saco el usuario actual de la lista...
        if (mode == 1 && indiceUsuarioActual >= 0) {
            response.me = response.participants[indiceUsuarioActual];
            response.participants.splice(indiceUsuarioActual, 1);
        }

        return response;
    }
    goToPersonSpace(payload) {
        const soyYoMismo = (this.state?.user?.email == payload.email);
        if (!soyYoMismo) {
            const params = payload;
            //this.props.navigation.navigate('MessengerPage', params);
            this.props.navigation.push('ProfilePage', params);
        }
    }
    getParticipantDetailByEmail(email) {
        const participants = this.state.participants;
        for (let j = 0; j < participants.length; j++) {
            const participant = participants[j];
            if (participant.email == email) {
                return participant;
            }
        }
        return null;
    }
    async selectVoteReal(email) {
        const donation = this.props.route.params.donation;
        if (donation.done === true) {
            // Ignore action
            return;
        }
        // Primero validar si está logeado
        if (!(await this.alertLogedUser())) {
            return;
        }
        // Preguntar la confirmación
        let participant = this.getParticipantDetailByEmail(email);
        if (!participant) {
            participant = { name: "¿?" };
        }
        const options = {
            title: "¿Seguro?",
            message: `Tu voto es por ${participant.name}`,
            acceptLabel: "Sí",
            cancelLabel: "No"
        };
        const response = await super.confirm(options);
        if (response === false) {
            return;
        }
        this.setState({ myVotedEmail: email });
        this.voteDonation();
    }
    render() {
        const shareDonation = this.shareDonation.bind(this);
        const voteDonation = this.voteDonation.bind(this);
        const loadMoreParticipants = this.loadMoreParticipants.bind(this);
        const goToPersonSpaceThis = this.goToPersonSpace.bind(this);
        const selectVoteRealThis = this.selectVoteReal.bind(this);

        // 0: No user preffered
        // 1: I appear in the top
        const mode = this.props.route.params.mode;
        let counterParticipants = this.state?.relation?.donation?.users;
        const donation = this.props.route.params.donation;
        const userBack = this.state.userBack;

        const procesed = this.organizeParticipants();

        if (procesed.count == 0) {
            counterParticipants = 0;
        } else {
            if (typeof counterParticipants == "number") {
                counterParticipants = Math.max(counterParticipants, procesed.count);
            } else {
                counterParticipants = procesed.count;
            }
        }

        const details = {
            showTime: this.showTime,
            participants: procesed.participants,
            donation: donation,
            me: {
                email: userBack?.email,
                name: userBack?.name,
                picture: userBack?.picture,
                donorType: userBack?.donorType,

                podium: procesed.me.podium,
                rate: procesed.me.rate,
                votes: procesed.me.votes,
                selected: procesed.me.selected,
            },
            pageParticipants: () => {
                loadMoreParticipants();
            },
            selectVote: selectVoteRealThis,
            goToPersonSpace: goToPersonSpaceThis,
            shareAction: shareDonation,
        };
        details.donation.participants = counterParticipants;

        details.mode = mode;
        /*
        if (details.mode == 1) {
            details.labelAction = "Comparte";
            details.submit = shareDonation;
        } else {
            details.labelAction = "Confirma Votación";
            details.submit = voteDonation;
        }*/
        details.labelAction = "Confirma Votación";
        details.submit = voteDonation;
        return (
            <MainVote style={this.props.style} details={details} />
        )
    }
}
