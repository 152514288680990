import { DonacionesSrv } from '../srv/DonacionesSrv';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_1 = "tiempo_ok";
const FIELD_KEY_NAME_2 = "consistencia_ok";
const FIELD_KEY_NAME_3 = "dominio_ok";
const FIELD_KEY_NAME_4 = "completitud_ok";
const FIELD_KEY_NAME_5 = "comments";

export default class WSurveyWinnerScreen extends SelectScreen {
    constructor(props) {
        super(props);
        if (this.props.route.params === undefined) {
            this.props.route.params = {};
        }
        super.initValue(FIELD_KEY_NAME_1, null);
        super.initValue(FIELD_KEY_NAME_2, null);
        super.initValue(FIELD_KEY_NAME_3, null);
        super.initValue(FIELD_KEY_NAME_4, null);
        super.initValue(FIELD_KEY_NAME_5, null);
    }
    sayGoodBye() {
        const actions = [
            {
                label: "Aceptar",
                onPress: (finish) => {
                    // Go home
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'MyHomePage' }],
                    });
                }
            }
        ];
        const onlyMyActions = true;
        this.props.alert("Gracias por tu participación", "Listo!", actions, onlyMyActions);
    }
    cancelSurvey() {
        console.log("Cancel survey");
        this.sayGoodBye();
    }
    async onSubmit(values) {
        const STARS_KEYS = [FIELD_KEY_NAME_1, FIELD_KEY_NAME_2, FIELD_KEY_NAME_3, FIELD_KEY_NAME_4];
        const completo = {};
        const notification = this.props.route.params?.notification;
        if (!notification) {
            this.props.alert("La notificación está incompleta");
        } else {
            const donationId = notification.payload.donation.id;
            let stars = 1;
            for (let i = 0; i < STARS_KEYS.length; i++) {
                const key = STARS_KEYS[i];
                const valor = this.state.data[key];
                completo[key] = valor;
                if (valor === 1) {
                    stars++;
                }
            }
            completo.stars = stars;
            completo[FIELD_KEY_NAME_5] = values[FIELD_KEY_NAME_5];
            try {
                const promesa = DonacionesSrv.rateStars(donationId, completo, this.props.alert);
                this.props.loading(promesa);
                await promesa;
                this.sayGoodBye();
            } catch (err) {

            }
        }
    }
    validate(values) {
        const errors = {};
        let errorCount = 0;
        const todos = [FIELD_KEY_NAME_1, FIELD_KEY_NAME_2, FIELD_KEY_NAME_3, FIELD_KEY_NAME_4];
        for (let i = 0; i < todos.length; i++) {
            const key = todos[i];
            if (typeof values[key] !== "number") {
                errors[key] = "Escoge al menos una opción";
                errorCount++;
            }
        }
        /*
        const hayValor = ["", null, undefined].indexOf(values[FIELD_KEY_NAME_5]) < 0;
        if (!hayValor) {
            errors[FIELD_KEY_NAME_5] = "El comentario es requerido";
            errorCount++;
        }
        */

        if (errorCount > 0) {
            this.props.alert("Verifica los campos");
        }

        return errors;
    }
    assignValue(key, event) {
        const nuevo = { data: this.state.data };
        nuevo.data[key] = event.target.value;
        this.setState(nuevo);
    }
    render() {
        const assignValueThis = this.assignValue.bind(this);
        const listaOpciones = [
            {
                izq: { label: "¿Recibiste la donación en el tiempo acordado?" },
                der: {
                    type: "radiobutton",
                    details: {
                        name: FIELD_KEY_NAME_1,
                        options: [
                            { txt: "Sí", val: 1 },
                            { txt: "No", val: 0 },
                        ],
                        onChange: (event) => {
                            assignValueThis(FIELD_KEY_NAME_1, event);
                        }
                    }
                }
            },
            {
                izq: { label: "¿La donación era lo que se describía?" },
                der: {
                    type: "radiobutton",
                    details: {
                        name: FIELD_KEY_NAME_2,
                        options: [
                            { txt: "Sí", val: 1 },
                            { txt: "No", val: 0 },
                        ],
                        onChange: (event) => {
                            assignValueThis(FIELD_KEY_NAME_2, event);
                        }
                    }
                }
            },
            {
                izq: { label: "¿El donador conocía lo que estaba ofreciendo?" },
                der: {
                    type: "radiobutton",
                    details: {
                        name: FIELD_KEY_NAME_3,
                        options: [
                            { txt: "Sí", val: 1 },
                            { txt: "No", val: 0 },
                        ],
                        onChange: (event) => {
                            assignValueThis(FIELD_KEY_NAME_3, event);
                        }
                    }
                }
            },
            {
                izq: { label: "¿Recibiste la completitud de la donación?" },
                der: {
                    type: "radiobutton",
                    details: {
                        name: FIELD_KEY_NAME_4,
                        options: [
                            { txt: "Sí", val: 1 },
                            { txt: "No", val: 0 },
                        ],
                        onChange: (event) => {
                            assignValueThis(FIELD_KEY_NAME_4, event);
                        }
                    }
                }
            },
            {
                izq: { label: "¿Más comentarios?" },
                der: {
                    type: "input-text",
                    details: {
                        name: FIELD_KEY_NAME_5,
                        multiline: true,
                        numberOfLines: 3,
                    }
                }
            },
        ];
        const cancelSurveyThis = this.cancelSurvey.bind(this);
        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = "Completa los campos";
        const autoSubmit = false;
        const searchFunction = null;
        const actionDefinition = {
            primary: {
                label: "Enviar",
            },
            /*secondary: {
                label: "Saltar",
                onPress: cancelSurveyThis,
            }*/
        };
        return super.myRender(listaOpciones, instructions, onSubmit, validate, autoSubmit, searchFunction, actionDefinition);
    }
}
