import React from "react";
import styled from "styled-components/native";
import AssetsImg from "../../AssetsImg";
import * as CssConstants from "../../widgets/CssConstans";
import Radio from "./Radio";
import { StyleSheet } from 'react-native';
import theme from "../../Theme";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

class MySimpleMenuItem extends React.Component {
  render() {
    const {
      icon,
      label,
      subLabel,
      localAction,
    } = this.props.details;
    return (
      <AutoLayoutHorizontal onPress={localAction}>
        <AutoLayoutHorizontal1>
          <Group
            imageStyle={{ borderRadius: 100 }}
            resizeMode="cover"
            source={AssetsImg.IMG.YELLOW_BUTTON}>
            <IconlyBoldStar>
              <Discovery>
                <FontAwesomeIcon icon={icon} style={[styles.icon]} size={16} />
              </Discovery>
            </IconlyBoldStar>
          </Group>
          <LabelsContainer>
            <ServicioVirtual>{label}</ServicioVirtual>
            <ServicioVirtualSub>{subLabel}</ServicioVirtualSub>
          </LabelsContainer>
        </AutoLayoutHorizontal1>
      </AutoLayoutHorizontal>
    )
  };
}

export default MySimpleMenuItem;

// gap: 12px;
// width: 380px;
const AutoLayoutHorizontal = styled.Pressable`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
  `;

// gap: 20px;
const AutoLayoutHorizontal1 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    flex: 1;
  `;

const Group = styled.ImageBackground`
    position: relative;
    min-width: 60px;
    height: 60px;
    border-radius: 100px;
  `;

const LabelsContainer = styled.View`
  flex: 1;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

// white-space: nowrap;
const ServicioVirtual = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H6Bold}
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    line-height: 21.6px;
  `;

const ServicioVirtualSub = styled.Text`
  ${CssConstants.ValignTextMiddle}
  ${CssConstants.BodyMediumSemibold}
  font-weight: 300;
  color: ${props => props.theme.__others__white};
  line-height: 21.6px;
`;

// gap: 12px;
const AutoLayoutHorizontal2 = styled.View`
    display: flex;
    align-items: center;
    
    position: relative;
  `;

// gap: 20px;
const AutoLayoutHorizontal3 = styled.View`
    display: flex;
    width: 348px;
    align-items: center;
    
    position: relative;
  `;

// gap: 20px;
const AutoLayoutHorizontal4 = styled.View`
    display: flex;
    align-items: center;
    
    position: relative;
    flex: 1;
  `;

const IconlyBoldStar = styled.View`
  position: relative;
  width: 24px;
  height: 24px;
  top: 18px;
  left: 18px;
  display: flex;
  padding: 3px 1px;
  align-items: flex-end;
`;

const Discovery = styled.View`
  width: 20px;
  height: 20px;
`;

const styles = StyleSheet.create({
  icon: {
    color: theme.__others__white,
  }
});