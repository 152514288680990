import MyLog from '../srv/MyLog';
import CONFIG from '../Constants';
import { isValidURLWithInstagramUser } from '../utils/InputValidator';
import SelectScreen from './SelectScreen';

const FIELD_KEY_NAME_1 = "organicerme"; // Se valida acá
const FIELD_KEY_NAME = "organicername"; // Se valida acá

export default class WDonationOrganicerScreen extends SelectScreen {
    static organicername = "";
    constructor(props) {
        super(props);
        this.state[FIELD_KEY_NAME] = this.props.route.params[FIELD_KEY_NAME];
        this.state.data[FIELD_KEY_NAME] = this.state[FIELD_KEY_NAME];
        this.state[FIELD_KEY_NAME_1] = this.props.route.params[FIELD_KEY_NAME_1];
        this.state.data[FIELD_KEY_NAME_1] = this.state[FIELD_KEY_NAME_1];
    }
    onSubmit(values) {
        this.props.route.params[FIELD_KEY_NAME] = values[FIELD_KEY_NAME];
        this.props.route.params[FIELD_KEY_NAME_1] = values[FIELD_KEY_NAME_1];
        super.myLocalGoBack(this.props.route.params);
    }
    validate(values) {
        MyLog.log("validate", this);
        const errors = {};
        const hayValor = ["", null, undefined].indexOf(values[FIELD_KEY_NAME]) < 0;
        if (!hayValor) {
            errors[FIELD_KEY_NAME] = "El nombre del organizador es requerido";
        }
        return errors;
    }
    async onUser(payloadUser) {
        super.onUser(payloadUser);
        WDonationOrganicerScreen.organicername = "";
        if (payloadUser.userBack.name) {
            WDonationOrganicerScreen.organicername = payloadUser.userBack.name;
        } else if (payloadUser.user.displayName) {
            WDonationOrganicerScreen.organicername = payloadUser.user.displayName;
        }
    }
    onChangeRadio(payload) {
        const nuevo = { data: this.state.data };
        // nuevo[FIELD_KEY_NAME_1] = payload.target.value;
        // Se debe colocar el valor en data porque es lo que ve Formik
        nuevo.data[FIELD_KEY_NAME_1] = payload.target.value;
        nuevo[FIELD_KEY_NAME_1] = payload.target.value;
        if (payload.target.value === true) {
            nuevo.data[FIELD_KEY_NAME] = WDonationOrganicerScreen.organicername;
        } else {
            nuevo.data[FIELD_KEY_NAME] = "";
        }
        this.props.route.params[FIELD_KEY_NAME] = nuevo.data[FIELD_KEY_NAME];
        nuevo[FIELD_KEY_NAME] = nuevo.data[FIELD_KEY_NAME];
        this.setState(nuevo);
        if (this.myref?.current?.myrefs && FIELD_KEY_NAME in this.myref?.current?.myrefs) {
            this.myref.current.myrefs[FIELD_KEY_NAME].current.setValue(nuevo[FIELD_KEY_NAME]);
        }
    }
    render() {
        const onChangeThis = this.onChangeRadio.bind(this);
        const listaOpciones = [
            {
                izq: {},
                der: {
                    type: "radio",
                    details: {
                        type: "switch",
                        label: "TÚ",
                        value: this.state[FIELD_KEY_NAME_1],
                        disabled: false,
                        onChange: onChangeThis,
                        name: FIELD_KEY_NAME_1,
                    }
                }
            },
            {
                izq: { label: "Nombre de Organizador" },
                der: {
                    type: "input-text",
                    details: {
                        name: FIELD_KEY_NAME,
                        editable: !this.state[FIELD_KEY_NAME_1],
                    }
                }
            }
        ];
        const onSubmit = this.onSubmit.bind(this);
        const validate = this.validate.bind(this);
        const instructions = "Comparte quién es el que está brindando esta oportunidad.";
        return super.myRender(listaOpciones, instructions, onSubmit, validate);
    }
}