import React from "react";
import styled from "styled-components/native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import OptionReject from "./OptionReject";
import OptionsAccept from "./OptionsAccept";
import ElementsAccountList from "./ElementsAccountList";
import Divider from "../../widgets/Divider";
import ElementsCommentsList from "./ElementsCommentsList";
import ElementsCommentsList2 from "./ElementsCommentsList2";
import Stars from "./Stars";
import CONFIG from "../../Constants";
import theme from "../../Theme";
import { Dimensions } from 'react-native'
import * as CssConstants from "../../widgets/CssConstans";
import MyTag from "../../widgets/MyTag";
import { MyDates } from "../../utils/MyDates";

export default class Second2MenuBottom
    extends React.Component {
    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({ time: Date.now() });
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    getVirtualFeature(virtual) {
        if (virtual) {
            return CONFIG.LISTS.DONATION.VIRTUAL["true"];
        } else {
            return CONFIG.LISTS.DONATION.VIRTUAL["false"];
        }
    }

    getTimeoutText(end) {
        return MyDates.getRemainingHoursCountDown(end, "Vencida");
    }
    getExpirationTimeText(end) {
        return MyDates.formatDatetime(end);
    }
    render() {
        const {
            middle,
            button1,
            donation,
        } = this.props.details;
        const {
            closeAction,
            goToPersonSpace
        } = this.props;
        let halfWindowsHeight = parseInt(Dimensions.get('window').height * 0.4);
        let reseniasHtml = [];
        if (middle.resenias instanceof Array) {
            reseniasHtml = middle.resenias.map((resenia, index) => {
                return (<MyOneResenia goToPersonSpace={goToPersonSpace} key={resenia.email} details={resenia}></MyOneResenia>)
            });
        }
        const vieneDePerfil = ["ProfilePage"].indexOf(this.props.origin) >= 0;
        const hayResenias = reseniasHtml.length > 0;
        const puedeVotar = (middle.won !== true && middle.canParticipate && middle.type != CONFIG.LISTS.DONATION.TYPE.REPOST);
        if (puedeVotar) {
            // Se achica el scroll porque va a aparecer el corazón y la equiz
            halfWindowsHeight = parseInt(Dimensions.get('window').height * 0.3);
        }
        const virtualFeature = this.getVirtualFeature(middle.virtual);
        let precio = null;
        if (typeof middle.isfree == "boolean") {
            if (middle.isfree) {
                precio = "Gratis";
            } else {
                precio = `$${middle.price}`;
            }
        }
        return (
            <AutoLayoutVertical>
                <Frame onPress={closeAction}></Frame>
                <ElementsAccountList details={middle} closeAction={closeAction} />
                <AutoLayoutHorizontal2>
                    <AutoLayoutHorizontal3>
                        <IconStar>
                            <FontAwesomeIcon icon={virtualFeature.icon} style={{ color: "#FFFFFF" }} size={24} />
                        </IconStar>
                        <Text>{virtualFeature.txt}</Text>
                    </AutoLayoutHorizontal3>
                    {middle.type === CONFIG.LISTS.DONATION.TYPE.REPOST && <AutoLayoutHorizontal3>
                        <IconStar>
                            <FontAwesomeIcon icon={"calendar-days"} style={{ color: "#FFFFFF" }} size={24} />
                        </IconStar>
                        <Text>{this.getExpirationTimeText(middle.end)}</Text>
                    </AutoLayoutHorizontal3>}
                    {middle.type !== CONFIG.LISTS.DONATION.TYPE.REPOST && <AutoLayoutHorizontal3>
                        <IconStar>
                            <FontAwesomeIcon icon={"trophy"} style={{ color: "#FFFFFF" }} size={24} />
                        </IconStar>
                        <Text>{`${middle.numWin} ganador(es)`}</Text>
                    </AutoLayoutHorizontal3>}
                    <AutoLayoutHorizontal3>
                        <IconStar>
                            <FontAwesomeIcon icon={"cart-shopping"} style={{ color: "#FFFFFF" }} size={24} />
                        </IconStar>
                        <Text>{precio}</Text>
                    </AutoLayoutHorizontal3>
                    {middle.type === CONFIG.LISTS.DONATION.TYPE.DONACION && <AutoLayoutHorizontal3>
                        <MyTag details={{ icon: "clock-rotate-left", txt: this.getTimeoutText(middle.end) }}></MyTag>
                    </AutoLayoutHorizontal3>}
                </AutoLayoutHorizontal2>
                <MessagesScroll
                    nestedScrollEnabled={true}
                    style={{ maxHeight: halfWindowsHeight }}
                    ref={ref => { this.scrollView = ref }}
                    onContentSizeChange={() => { }}>
                    <Divider marginTop={"24px"} marginBottom={"24px"} />
                    {middle.organicerme === false && <ElementsCommentsList details={middle}></ElementsCommentsList>}
                    {middle.organicerme === false && <Divider marginTop={"24px"} marginBottom={"24px"} />}
                    <ElementsCommentsList2 details={middle} />
                    {hayResenias && <Divider marginTop={"24px"} marginBottom={"24px"} />}
                    {hayResenias && <ContainerResenias>
                        <ReseniasTitle>{"Reseñas"}</ReseniasTitle>
                        {reseniasHtml}
                    </ContainerResenias>}
                </MessagesScroll>
                {puedeVotar && <AutoLayoutHorizontal>
                    <OptionReject details={{ rejectAction: button1.rejectAction, donation }} />
                    <OptionsAccept details={{ acceptAction: button1.acceptAction, useHandHearth: false, disabled: (middle.desition == true) }} />
                </AutoLayoutHorizontal>}
            </AutoLayoutVertical>
        );
    }
}

const Text = styled.Text`
    ${CssConstants.BodyMediumSemibold}
    font-weight: 600;
    color: ${props => props.theme.__others__white};
    line-height: 19.6px;
    margin-left: 8px;
`;

const IconStar = styled.View`
    min-width: 24px;
    height: 24px;
`;

const AutoLayoutHorizontal2 = styled.View`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    margin-top: 12px;
`;

const AutoLayoutHorizontal3 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    margin-right: 24px;
    margin-bottom: 5px;
`;

class MyOneResenia extends React.Component {
    render() {
        const {
            stars,
            opinion,
            email,
            name,
            picture,
            donorType
        } = this.props.details;
        const {
            goToPersonSpace
        } = this.props;
        const iconStyleStarOn = { marginLeft: 2, marginRight: 2, color: theme.__others__white, outline: "none" };
        const iconStyleStarOff = { marginLeft: 2, marginRight: 2, color: "rgba(255, 255, 255, 0.3)", outline: "none" };

        const myDroplet = CONFIG.getDropletFeature(donorType);

        return (
            <OneResenia>
                <ReseniaTop>
                    <CircledPhoto onPress={() => {
                        goToPersonSpace({ email, name });
                    }}>
                        <MyPhoto resizeMode="cover" source={{ uri: picture }}>
                        </MyPhoto>
                    </CircledPhoto>
                    <ReseniaName>
                        <ReseniaName1>{name}</ReseniaName1>
                        <ReseniaName2>{myDroplet.txt}</ReseniaName2>
                    </ReseniaName>
                </ReseniaTop>
                <ReseniaBody>{opinion}</ReseniaBody>

                <ReseniaStars>
                    <FontAwesomeIcon icon={"star"} style={stars > 0 ? iconStyleStarOn : iconStyleStarOff} size={11} />
                    <FontAwesomeIcon icon={"star"} style={stars > 1 ? iconStyleStarOn : iconStyleStarOff} size={11} />
                    <FontAwesomeIcon icon={"star"} style={stars > 2 ? iconStyleStarOn : iconStyleStarOff} size={11} />
                    <FontAwesomeIcon icon={"star"} style={stars > 3 ? iconStyleStarOn : iconStyleStarOff} size={11} />
                    <FontAwesomeIcon icon={"star"} style={stars > 4 ? iconStyleStarOn : iconStyleStarOff} size={11} />
                </ReseniaStars>
            </OneResenia>
        );
    }
}

const MyPhoto = styled.ImageBackground`
    width: 100%;
    height: 100%;
`;

const ReseniaName2 = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
`;

//line-height: 140%;
const ReseniaName1 = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 4px;
`;

const ReseniaName = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
`;

const CircledPhoto = styled.Pressable`
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 100px;
    overflow: hidden;
`;

const ReseniaStars = styled.View`
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

//line-height: 140%;
const ReseniaBody = styled.Text`
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
`;

const ReseniaTop = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 12px;
`;

const OneResenia = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

//line-height: 140%;
const ReseniasTitle = styled.Text`
    margin: 0 0 24px 0;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
`;

const ContainerResenias = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const MessagesScroll = styled.ScrollView`
    width: 100%;
`;

const AutoLayoutVertical = styled.View`
    display: flex;
    z-index: 0;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    padding: 8px 24px 48px;
    
    top: 25px;
    background-color: ${props => props.theme.__dark__dark_1};
    borderTopLeftRadius: 24px;
    borderTopRightRadius: 24px;
    overflow: hidden;
    border: 1px;
    border-color: ${props => props.theme.__dark__dark_3};
`;

const Frame = styled.Pressable`
    min-width: 38px;
    height: 3px;
    background-color: ${props => props.theme.__dark__dark_3};
    border-radius: 100px;

`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    height: 93px;
    align-items: center;
    justify-content: center;

    padding: 14px 24px 20px;
    position: relative;
    align-self: stretch;
    margin-top: 20px;

`;

const AutoLayoutHorizontalNoPadding = styled.View`
    display: flex;
    flex-direction: row;
    max-height: 93px;
    align-items: center;
    justify-content: center;

    padding: 5px 24px 5px;
    position: relative;
    align-self: stretch;
    margin-top: 20px;

`;