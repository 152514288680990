import React, { Component } from 'react'
import { StyleSheet, LogBox, Platform, Linking } from 'react-native';
import * as Notifications from 'expo-notifications';
import * as TaskManager from 'expo-task-manager';
import { EventEmitter } from "eventemitter3";
import styled from "styled-components/native";
import theme from "./components/comunidad/Theme";

import myFirebase from './components/comunidad/config/firebase';
import * as Font from 'expo-font';
//import { SafeAreaView } from 'react-native-safe-area-context';

import Navigator from './components/comunidad/Navigator'
import Config from './components/comunidad/utils/Config';
import LoadingPage from './components/comunidad/pages/LoadingPage';
import * as IconLibrary from "./components/comunidad/IconLibrary";
import BackgroundPage from './components/comunidad/pages/BackgroundPage';
import MyLog from './components/comunidad/srv/MyLog';
import CONFIG from './components/comunidad/Constants';
import MyModal from './components/comunidad/widgets/MyModal';

if (Platform.OS !== 'web') {
  const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK';
  MyLog.log("MyPush:2running static");
  TaskManager.defineTask(BACKGROUND_NOTIFICATION_TASK, ({ data, error, executionInfo }) => {
    MyLog.log("MyPush:2backgroundHandler");
    MyLog.log(JSON.stringify(data));
  });
  Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK);
  MyLog.log("MyPush:2running static OK");
}

const STATE_PENDING = "pending...";
const STATE_OK = "ok!";
const STATE_RETRY = "retrying...";

const BACKOFF_MIN = 1000;//1 segundo
const BACKOFF_MAX = 1000 * 60 * 60;//1 hora
const BACKOFF_OFFLINE = 5000;

class App extends Component {
  constructor(props) {
    super(props);
    this.myEvents = new EventEmitter();
    //this.myEvents.removeAllListeners();
    this.state = {
      assetsLoaded: false,
      isOffline: false,
      versionMismatch: false,
      versionMismatchDetail: {},
      OFFLINE: null,
      states: [
        { key: "fuentes", label: "Fuentes", state: STATE_PENDING, },
        { key: "config", label: "Configuración", state: STATE_PENDING, },
        { key: "categorias", label: "Listas", state: STATE_PENDING, },
        { key: "instituciones", label: "Listas", state: STATE_PENDING, },
        { key: "firebase", label: "Seguridad", state: STATE_PENDING, },
      ],
    };
    this.loadStatus = {
      fuentes: { data: null, backoff: 0 },
      config: { data: null, backoff: 0, backoffOfline: 0 },
      categorias: { data: null, backoff: 0 },
      instituciones: { data: null, backoff: 0 },
      firebase: { data: null, backoff: 0 },
    };
    this.myEvents.on("ok", (payload) => {
      MyLog.log(`Ok ${payload.key}...`, this);
      const stateRef = this.loadStatus[payload.key];
      stateRef.backoff = 0;
      stateRef.data = payload.data;
      const llaves = Object.keys(this.loadStatus);
      let someIsNull = false;
      for (let i = 0; i < llaves.length; i++) {
        const llave = llaves[i];
        if (this.loadStatus[llave].data === null) {
          someIsNull = true;
          break;
        }
      }
      if (!someIsNull) {
        this.myEvents.emit("finish", {});
      }
      this.setMyState(payload.key, STATE_OK);
      this.setState({ states: this.state.states });
    });
    this.myEvents.on("finish", (payload) => {
      MyLog.log(`Finish all ...`, this);
      // Evalúo si está offline
      const stateRefConfig = this.loadStatus.config;
      this.processOfflineValue(stateRefConfig);
      this.processRequiredVersion(stateRefConfig);
    });
    this.myEvents.on("error", (payload) => {
      // retry the key...
      this.setMyState(payload.key, STATE_RETRY);
      this.setState({ states: this.state.states });
      const stateRef = this.loadStatus[payload.key];
      stateRef.backoff = stateRef.backoff + 1;
      MyLog.log(`Retry ${payload.key}... ${stateRef.backoff}`);
      setTimeout(() => {
        this.configurePromise(payload.key, payload.myFunc, payload.argumentos);
      }, Math.min(BACKOFF_MIN * stateRef.backoff, BACKOFF_MAX));
    });
  }
  compareStringNumbers(actualString, targetString) {
    const actualNumber = parseInt(actualString);
    const targetNumber = parseInt(targetString);
    return actualNumber - targetNumber;
  }
  processRequiredVersion(stateRefConfig) {
    if (Platform.OS == 'web') {
      // No aplica para la web
      return;
    }
    const configuracion = stateRefConfig.data;
    const actualVersion = CONFIG.VERSION;
    const targetVersion = configuracion.UPDATE.VERSION_TARGET;
    const mode = configuracion.UPDATE.VERSION_RESTRICTION;

    //First is greater
    const targetParts = /\s*(\d+)\.(\d+)\.(\d+)\s*/.exec(targetVersion);
    const actualParts = /\s*(\d+)\.(\d+)\.(\d+)\s*/.exec(actualVersion);

    if (targetParts == null || actualParts == null) {
      return;
    }
    const diff0 = this.compareStringNumbers(actualParts[1], targetParts[1]);
    if (diff0 > 0) {
      // El 1er digito del actual es mayor del necesario
      return;
    } else if (diff0 == 0) {
      // El 1er digito es igual...
      const diff1 = this.compareStringNumbers(actualParts[2], targetParts[2]);
      if (diff1 > 0) {
        // El 2do digito del actual es mayor del necesario
        return;
      } else if (diff1 == 0) {
        // El 2do digito es igual...
        const diff2 = this.compareStringNumbers(actualParts[3], targetParts[3]);
        if (diff2 >= 0) {
          // El 3er digito del actual es mayor o igual que el necesario
          return;
        } else {
          MyLog.log(`La versión ${actualVersion} no cumple el 3er digito de ${targetVersion}`);
        }
      } else {
        MyLog.log(`La versión ${actualVersion} no cumple el 2do digito de ${targetVersion}`);
      }
    } else {
      MyLog.log(`La versión ${actualVersion} no cumple el 1er digito de ${targetVersion}`);
    }

    const upgradeAction = async () => {
      // Open link according to OS
      let url = configuracion.UPDATE.android;
      let appStoreName = "Play Store";
      if (Platform.OS == 'ios') {
        // Open ios
        url = configuracion.UPDATE.ios;
        appStoreName = "Apple Store"
      }
      let canOpen = await Linking.canOpenURL(url);
      if (!canOpen) {
        // Alertar!
        this.setState({
          localAlertDetail: {
            title: "Ups!",
            description: `Por favor ve al ${appStoreName} y busca 'PANAL COMUNIDAD'.`,
            buttons: [
              {
                label: "Aceptar",
                onPress: () => {
                  this.setState({
                    localAlertDetail: null
                  });
                },
              }
            ],
          }
        });
      } else {
        Linking.openURL(url);
      }
    };
    const upgradeButton = {
      label: "Descargar",
      type: "primary",
      onPress: upgradeAction,
    };

    if (mode == "MANDATORY") {
      this.setState({
        versionMismatch: true,
        versionMismatchDetail: {
          title: "Ups!",
          description: "Es necesario que actualices la aplicación.",
          buttons: [
            upgradeButton
          ],
        }
      });
    } else if (mode == "OPTIONAL") {
      this.setState({
        versionMismatch: true,
        versionMismatchDetail: {
          title: "Ups!",
          description: "Es necesario que actualices la aplicación.",
          buttons: [
            upgradeButton,
            {
              label: "Recordarme después",
              type: "secondary",
              onPress: () => {
                this.setState({
                  versionMismatch: false
                });
              },
            }
          ],
        }
      });
    }
  }
  processOfflineValue(stateRefConfig) {
    const configuracion = stateRefConfig.data;
    const OFFLINE = configuracion.OFFLINE;
    const nuevoEstado = {
      assetsLoaded: true,
      isOffline: false,
      OFFLINE: null,
    };
    if (typeof OFFLINE == "string" && OFFLINE.trim().length > 0) {
      MyLog.log("Is offline...", this);
      nuevoEstado.isOffline = true;
      nuevoEstado.OFFLINE = OFFLINE;
      stateRefConfig.backoffOfline = stateRefConfig.backoffOfline + 1;
      setTimeout(() => {
        // Continue asking for config...
        this.configurePromise("config", Config.getValue, ["config", false]);

        MyLog.log(`Retry config... ${stateRefConfig.backoffOfline}`);
      }, Math.min(BACKOFF_OFFLINE * stateRefConfig.backoffOfline), BACKOFF_MAX);
    } else {
      stateRefConfig.backoffOfline = 0;
    }
    setTimeout(() => {
      this.setState(nuevoEstado);
    }, 300);
  }
  setMyState(key, value) {
    const estados = this.state.states;
    for (let i = 0; i < estados.length; i++) {
      const estado = estados[i];
      if (estado.key == key) {
        estado.state = value;
        break;
      }
    }
  }
  configurePromise(key, myFunc, argumentos = []) {
    const payload = {
      key: key,
      myFunc: myFunc,
      argumentos: argumentos,
    };
    let thePromise;
    if (myFunc instanceof Promise) {
      thePromise = myFunc;
    } else {
      thePromise = myFunc(...argumentos);
    }
    thePromise
      .then((response) => {
        if (response === null) {
          payload.data = true;
        } else {
          payload.data = response;
        }
        this.myEvents.emit("ok", payload);
      })
      .catch((error) => {
        payload.data = error;
        this.myEvents.emit("error", payload);
      });
  }

  async loadResourcesAsync() {
    this.configurePromise("fuentes", this.loadFonts);
    this.configurePromise("config", Config.getValue, ["config"]);
    this.configurePromise("categorias", Config.getValue, ["categories"]);
    this.configurePromise("instituciones", Config.getValue, ["instituciones"]);
    this.configurePromise("firebase", myFirebase);
  }

  async loadFonts() {
    await Font.loadAsync({
      Urbanist: require('./assets/fonts/Urbanist-VariableFont_wght.ttf'),
    });
  }

  componentDidMount() {
    // http://localhost:19006/
    const id_token = Config.getQueryParam("id_token");
    const sinIdToken = [null, undefined, "", "null"].indexOf(id_token) >= 0;
    this.loadResourcesAsync().catch((err) => {
      if (!sinIdToken) {
        alert(`Ups, reintenta más tarde...${sinIdToken} ${id_token} ${err.message}`);
      }
    });
  }
  render() {
    let myRoot;
    myRoot = <Navigator style={styles.container}></Navigator>;
    if (!this.state.assetsLoaded) {
      myRoot = (<LoadingPage></LoadingPage>);
    }
    if (this.state.isOffline) {
      myRoot = (<BackgroundPage src={this.state.OFFLINE}></BackgroundPage>)
    }
    if (this.state.versionMismatch) {
      myRoot = (<ContainerLoading>
        <MyModal details={this.state.versionMismatchDetail} close={() => { }}></MyModal>
        {[null, undefined].indexOf(this.state.localAlertDetail) < 0 && <MyModal details={this.state.localAlertDetail} close={() => { }}></MyModal>}
      </ContainerLoading>)
    }
    return (myRoot);
    /*
    return (<SafeAreaView style={{ flex: 1, backgroundColor: 'red' }}>
      {{ myRoot }}
    </SafeAreaView>);
    */
  }
}

const ContainerLoading = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.__others__white};
    width: 100%;
    height: 100%;
`;

const styles = StyleSheet.create({
  container: {

  },
});

export default App;
