import { collection, query, where, onSnapshot, orderBy, limit, doc } from "firebase/firestore";
import myFirebase from "../config/firebase";
import CONFIG from "../Constants";
import { fetchApi } from "./Http";
import MyLog from "./MyLog";
import { Users } from "./Users";

export default class RealTime {
    static instance = null;
    static MAX_PARTICIPANTS_VIEW = 20;
    static get() {
        if (RealTime.instance == null) {
            RealTime.instance = new RealTime();
        }
        return RealTime.instance;
    }
    constructor() {
        this.userRealTimeInfo = {
            hasAlerts: false,
            hasMessages: false,
        };
        this.userRealTimeSubscribers = [];
        this.myDesitions = null;
        this.hasMessagesUnsubscribe = null;
        this.hasAlertsUnsubscribe = null;
        this.user = null;
        this.subscribers = {};
        const onUser = this.onUser.bind(this);
        myFirebase.then((firebase) => {
            firebase.addListener(onUser, true);
        });
    }
    listenToUserRealTime(myFun) {
        if (this.userRealTimeSubscribers.indexOf(myFun) < 0) {
            this.userRealTimeSubscribers.push(myFun);
        }
        const copia = JSON.parse(JSON.stringify(this.userRealTimeInfo));
        try {
            myFun(copia);
        } catch (err) {
            console.log(err.message);
        }
        return () => {
            const indice = this.userRealTimeSubscribers.indexOf(myFun);
            if (indice >= 0) {
                this.userRealTimeSubscribers.splice(indice, 1);
            }
        };
    }
    notifyUserRealTime() {
        const copia = JSON.parse(JSON.stringify(this.userRealTimeInfo));
        for (let i = 0; i < this.userRealTimeSubscribers.length; i++) {
            try {
                this.userRealTimeSubscribers[i](copia);
            } catch (err) {
                MyLog.log(err.message, this);
            }
        }
    }
    onUser(payloadUser) {
        MyLog.log("RealTime.onUser", this);
        this.user = payloadUser.user;
        if (!this.user) {
            MyLog.log(`RealTime.onUser not this.user`, this);
            this.myDesitions = null;
            if (typeof this.hasMessagesUnsubscribe == "function") {
                this.hasMessagesUnsubscribe();
                this.hasMessagesUnsubscribe = null;
            }
            if (typeof this.hasGroupMessagesUnsubscribe == "function") {
                this.hasGroupMessagesUnsubscribe();
                this.hasGroupMessagesUnsubscribe = null;
            }
            if (typeof this.hasAlertsUnsubscribe == "function") {
                this.hasAlertsUnsubscribe();
                this.hasAlertsUnsubscribe = null;
            }
        } else {
            this.listenHasMessages(this.user, (value) => {
                this.userRealTimeInfo.hasMessages = value;
                this.notifyUserRealTime();
            });
            this.listenHasGroupMessages(this.user, (value) => {
                this.userRealTimeInfo.hasGroupMessages = value;
                this.notifyUserRealTime();
            });
            this.listenHasAlerts(this.user, (value) => {
                this.userRealTimeInfo.hasAlerts = value;
                this.notifyUserRealTime();
            });
            MyLog.log(`RealTime.onUser yes this.user`, this);
            const isNull = this.myDesitions == null;
            const isArray = (this.myDesitions instanceof Array);
            let esVacio = false;
            if (isArray) {
                esVacio = (this.myDesitions.length == 0);
            }
            MyLog.log(`RealTime: isNull=${isNull} isArray=${isArray} esVacio=${esVacio}`, this);
            if (isNull || (!isArray || esVacio)) {
                MyLog.log(`2`, this);
                const llave = "donations";
                const registro = this.subscribers[llave];
                if (registro && registro.last != null) {
                    // Se forza a que refreseque
                    this.refreshDonations();
                }
            }
        }
    }
    async listenHasGroupMessages(user, callback) {
        if (this.configuringGroupHasMessages === true) {
            return;
        }
        this.configuringGroupHasMessages = true;
        if (typeof this.hasGroupMessagesUnsubscribe == "function") {
            this.hasGroupMessagesUnsubscribe();
            this.hasGroupMessagesUnsubscribe = null;
        }
        const ENV = CONFIG.ENV;
        const { db } = await myFirebase;
        const q = query(collection(db, `${ENV}-alert`), where("own", "==", user.email), where("type", "==", "chat_group"), where("viewed", "==", false), limit(1));
        this.hasGroupMessagesUnsubscribe = onSnapshot(q, async (querySnapshot) => {
            if (querySnapshot != null) {
                let counter = 0;
                querySnapshot.forEach((doc) => {
                    counter++;
                });
                if (counter > 0) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
        });
        this.configuringGroupHasMessages = false;
    }
    async listenHasMessages(user, callback) {
        if (this.configuringHasMessages === true) {
            return;
        }
        this.configuringHasMessages = true;
        if (typeof this.hasMessagesUnsubscribe == "function") {
            this.hasMessagesUnsubscribe();
            this.hasMessagesUnsubscribe = null;
        }
        const ENV = CONFIG.ENV;
        const { db } = await myFirebase;
        const q = query(collection(db, `${ENV}-alert`), where("own", "==", user.email), where("type", "==", "chat"), where("viewed", "==", false), limit(1));
        this.hasMessagesUnsubscribe = onSnapshot(q, async (querySnapshot) => {
            if (querySnapshot != null) {
                let counter = 0;
                querySnapshot.forEach((doc) => {
                    counter++;
                });
                if (counter > 0) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
        });
        this.configuringHasMessages = false;
    }
    async listenHasAlerts(user, callback) {
        if (this.configuringHasAlerts === true) {
            return;
        }
        this.configuringHasAlerts = true;
        if (typeof this.hasAlertsUnsubscribe == "function") {
            this.hasAlertsUnsubscribe();
            this.hasAlertsUnsubscribe = null;
        }
        const ENV = CONFIG.ENV;
        const { db } = await myFirebase;
        const q = query(collection(db, `${ENV}-alert`), where("own", "==", user.email), where("viewed", "==", false), limit(1));
        this.hasAlertsUnsubscribe = onSnapshot(q, async (querySnapshot) => {
            if (querySnapshot != null) {
                let counter = 0;
                querySnapshot.forEach((doc) => {
                    counter++;
                });
                if (counter > 0) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
        });
        this.configuringHasAlerts = false;
    }
    addDesition(oneDesition) {
        // Revisa si no hay otra decision para la misma donación
        const donation_id = oneDesition.donation_id;
        let indiceEncontrado = -1;
        for (let i = 0; i < this.myDesitions.length; i++) {
            const decision = this.myDesitions[i];
            if (decision.donation_id == donation_id) {
                indiceEncontrado = i;
                break;
            }
        }
        if (indiceEncontrado >= 0) {
            this.myDesitions.splice(indiceEncontrado, 1);
        }
        this.myDesitions.push(oneDesition);
        this.promesaDesitions = Promise.resolve(this.myDesitions);
    }
    static isAutorBloqued(donacion_id, bloquedList) {
        const autor = donacion_id.replace(/[-_]\d{8}[-_]\d{6}[-_]\d{3}$/ig, "");
        return (bloquedList.indexOf(autor) >= 0);
    }
    async getLast(llave) {
        this.promesaDesitions = this.loadAllMyActiveDesitions();
        const desitions = await this.promesaDesitions;
        const firebaseRef = await myFirebase;
        const bloqued = await firebaseRef.getBloquedUsers();
        const mapaDesitions = {};
        for (let i = 0; i < desitions.length; i++) {
            const decision = desitions[i];
            mapaDesitions[decision.donation_id] = decision;
        }
        const registro = this.subscribers[llave];
        const AHORA = new Date().getTime();
        let copia = [];
        if (registro && registro.last != null) {
            copia = JSON.parse(JSON.stringify(registro.last));
            if (llave == "donations") {
                // Filtro las vencidas, bloqueadas
                copia = copia.filter((donacion) => {
                    const cumpleFecha = (donacion.end >= AHORA);

                    let isBloqued = false;
                    if (donacion.id in mapaDesitions) {
                        const rel = mapaDesitions[donacion.id];
                        if ([true, "true"].indexOf(rel.block) >= 0) {
                            isBloqued = true;
                        }
                    }

                    const isAuthorBloqued = RealTime.isAutorBloqued(donacion.id, bloqued);

                    return (cumpleFecha && !isBloqued && !isAuthorBloqued);
                });
                // Ordenar por promo desc, created desc
                copia.sort((a, b) => {
                    const diff = b.promo - a.promo;
                    if (diff != 0) {
                        return diff;
                    }
                    return b.created - a.created;
                });
                // Se le agrega la decision y el usuario por defecto
                for (let i = 0; i < copia.length; i++) {
                    const donacion = copia[i];
                    donacion.otherUser = Users.getDefaultUser();
                    if (donacion.id in mapaDesitions) {
                        donacion.rel = mapaDesitions[donacion.id];
                    }
                }
            }
        }
        return copia;
    }
    removeListener(llave, funcion) {
        MyLog.log(`removeListener ${llave}`, this);
        const registro = this.subscribers[llave];
        if (registro && registro.callbacks instanceof Array) {
            const indice = registro.callbacks.indexOf(funcion);
            if (indice >= 0) {
                registro.callbacks.splice(indice, 1);
            }
            MyLog.log(`indice=${indice} && ${registro.callbacks.length}`, this);
            if (registro.callbacks.length == 0) {
                if (typeof registro.unsubscribe == "function") {
                    MyLog.log("registro.unsubscribe();", this);
                    registro.unsubscribe();
                    registro.unsubscribe = null;
                }
            }
        }
    }
    async loadAllMyActiveDesitions() {
        const PAGE_SIZE = 30;
        MyLog.log("loadAllMyActiveDesitions", this);
        if (this.user == null) {
            MyLog.log(`RealTime: skip no user`, this);
            return [];
        }
        if (!(this.myDesitions instanceof Array)) {
            MyLog.log("fetching desitions", this);
            this.myDesitions = [];
            let response = [];
            do {
                const payload = {
                    offset: this.myDesitions.length,
                    max: PAGE_SIZE,
                };
                response = await fetchApi("/donations/desitions", payload, false);
                this.myDesitions = this.myDesitions.concat(response);
            } while (response.length >= PAGE_SIZE);
        }
        return this.myDesitions;
    }
    async refreshDonations(querySnapshot = null) {
        const llave = "donations";
        const registro = this.subscribers[llave];
        if (querySnapshot != null) {
            const donations = [];
            querySnapshot.forEach((doc) => {
                const temp = doc.data();
                temp.id = doc.id;
                donations.push(temp);
            });
            registro.last = donations;
        }
        const procesada = await this.getLast(llave);
        for (let i = 0; i < registro.callbacks.length; i++) {
            registro.callbacks[i](procesada);
        }
    }
    async addListener(llave, funcion, payload = {}) {
        const ENV = CONFIG.ENV;
        if (!(llave in this.subscribers)) {
            this.subscribers[llave] = {
                callbacks: [],
                unsubscribe: null,
                last: null,
            }
        }
        const registro = this.subscribers[llave];
        if (registro.callbacks.indexOf(funcion) < 0) {
            registro.callbacks.push(funcion);
        }
        MyLog.log(`registro.unsubscribe=${typeof registro.unsubscribe}`, this);
        if (registro.unsubscribe === null) {
            registro.unsubscribe = true;
            const { db } = await myFirebase;
            if (llave == "donations") {
                const q = query(collection(db, `${ENV}-donation`), where("started", "==", true), where("done", "==", false));
                registro.unsubscribe = onSnapshot(q, async (querySnapshot) => {
                    await this.refreshDonations(querySnapshot);
                });
            } else if (["participants", "participants_all"].indexOf(llave) >= 0) {
                const condiciones = [
                    where("donation_id", "==", payload.donation_id),
                    where("desition", "==", true),
                    orderBy("votes", "desc"),
                    orderBy("created", "asc"),
                ];
                if (llave == "participants") {
                    condiciones.push(limit(RealTime.MAX_PARTICIPANTS_VIEW));
                }
                const q = query(collection(db, `${ENV}-donation_usr`), ...condiciones);
                registro.unsubscribe = onSnapshot(q, async (querySnapshot) => {
                    const participantes = [];
                    querySnapshot.forEach((doc) => {
                        const temp = doc.data();
                        temp.id = doc.id;
                        participantes.push(temp);
                    });
                    registro.last = participantes;
                    for (let i = 0; i < registro.callbacks.length; i++) {
                        registro.callbacks[i](participantes);
                    }
                });
            }
        }
    }
    static getConversationId(email1, email2) {
        const e1 = email1.toLowerCase();
        const e2 = email2.toLowerCase();
        if (e1 > e2) {
            return `${e1}-${e2}`;
        } else {
            return `${e2}-${e1}`;
        }
    }
    static getGroupConversationId(groupId, email) {
        const e1 = groupId.toLowerCase();
        const e2 = email.toLowerCase();
        return `${e1}-${e2}`;
    }
    async listenPersonMessengerConversation(currentUserEmail, otherUserEmail, funcionCallback) {
        const conversationId = RealTime.getConversationId(currentUserEmail, otherUserEmail);
        return await this.listenMessengerConversation(conversationId, funcionCallback);
    }
    async listenGroupMessengerConversation(currentUserEmail, groupId, funcionCallback) {
        const conversationId = RealTime.getGroupConversationId(groupId, currentUserEmail);
        return await this.listenMessengerConversation(conversationId, funcionCallback);
    }
    async listenMessengerConversation(conversationId, funcionCallback) {
        const ENV = CONFIG.ENV;
        const { db } = await myFirebase;
        const myCollection = collection(db, `${ENV}-messenger_last`);
        const myDoc = doc(myCollection, conversationId);
        const unsubscribe = onSnapshot(myDoc, async (querySnapshot) => {
            funcionCallback(querySnapshot.data());
        });
        return unsubscribe;
    }
}
