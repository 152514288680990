import React, { Component } from 'react'
import { Linking, Platform } from 'react-native';
import { Dimensions } from 'react-native';
import { DonacionesSrv } from '../srv/DonacionesSrv';
import myFirebase from '../config/firebase';
import MyLog from '../srv/MyLog';
import { readData, saveData } from './MyLocalStorage';
import CONFIG from '../Constants';
import { Users } from '../srv/Users';
import { MyPush } from '../config/myPush';
import { NotificationsSrv } from '../srv/NotificationsSrv';
import { EventEmitter } from "eventemitter3";
import RealTime from '../srv/RealTime';

let staticLastRedirect = null;

export class MountedComponent extends Component {
    _isMounted = false;
    static isPushRegistered = false;
    static last_redirect = null;
    static localState = null;
    static lastNextPopUp = null;
    constructor(props) {
        super(props);
        const myWindow = Dimensions.get('window');
        this.state = {};
        this.state.width = myWindow.width;
        this.state.height = myWindow.height;
        this.state.userRealTime = {};
        this.myEvents = new EventEmitter();
        this.onShowAndUserEvent = new EventEmitter();
        this.onShowAndUserState = {
            onUser: false,
            onShow: false,
        };
        this.isRegisteringPush = false;
        MyPush.navigation = this.props.navigation;
        MyPush.alert = this.props.alert.bind(this);
        MyPush.popUpNotification = this.props.popUpNotification.bind(this);
        MyPush.myEvents = this.myEvents;

        this.firebasePromise = myFirebase;

        this.onShowAndUserEvent.on("onUser", () => {
            this.onShowAndUserState.onUser = true;
            if (typeof this.onShowAndUser == "function") {
                this.onShowAndUser();
            }
        });
        this.onShowAndUserEvent.on("onShow", () => {
            this.onShowAndUserState.onShow = true;
            if (typeof this.onShowAndUser == "function") {
                this.onShowAndUser();
            }
        });

        const handleNewUserThis = this.handleNewUser.bind(this);
        const handleNavigationThis = this.handleNavigation.bind(this);
        const myNavigatorFocusActionsThis = this.myNavigatorFocusActions.bind(this);
        const myNavigatorBlurActionsThis = this.myNavigatorBlurActions.bind(this);
        Linking.getInitialURL().then(url => {
            handleNavigationThis({ url: url }, "no event");
        });

        if (!this.eventsBinded) {
            this.unsubscribeNavigatorFocus = this.props.navigation.addListener('focus', async () => {
                await myNavigatorFocusActionsThis(handleNewUserThis, handleNavigationThis);
            });
            this.unsubscribeNavigatorBlur = this.props.navigation.addListener('blur', async () => {
                await myNavigatorBlurActionsThis(handleNewUserThis);
            });
            this.eventsBinded = true;
        }
    }
    componentDidMount() {
        if (typeof this.props.onMount == "function") {
            this.props.onMount(this);
        }
    }
    componentWillUnmount() {
        if (typeof this.props.onUnMount == "function") {
            this.props.onUnMount(this);
        }
    }
    async myNavigatorFocusActions(handleNewUser, handleNavigationThis) {
        MyLog.log(`focus`, this);
        this.fireOnUserAndFinishRedirects();
        this.showTime = new Date().getTime();
        this.subscribeResize();
        await this.checkQueryParams();
        await this.askOpenApp();
        this.firebase = await this.firebasePromise;
        this.firebase.addListener(handleNewUser);
        const payloadUser = await this.firebase.promesaUsuario;
        this.handleNewUser(payloadUser);// debo usar await??
        if (typeof this.onShow == "function") {
            await this.onShow();
            this.onShowAndUserEvent.emit("onShow", "");
        }

        this.removeUrlListener = Linking.addEventListener('url', handleNavigationThis);
        const userRealTimeUpdateThis = this.userRealTimeUpdate.bind(this);
        this.removeUserRealTime = RealTime.get().listenToUserRealTime(userRealTimeUpdateThis);
    }
    async myNavigatorBlurActions(handleNewUser) {
        MyLog.log(`blur`, this);
        this.myEvents.removeAllListeners("pushArrived");
        this.myEvents.removeAllListeners("hasUser");
        this.myEvents.removeAllListeners("noRedirects");
        this.myEvents.removeAllListeners("firedBoth");
        this.unSubscribeResize();
        //this.unsubscribeNavigatorFocus();
        //this.unsubscribeNavigatorBlur();
        if (!this.firebase) {
            this.firebase = await this.firebasePromise;
        }

        this.firebase.removeListener(handleNewUser);
        if (typeof this.onLeave == "function") {
            await this.onLeave();
        }
        if (this.removeUrlListener) {
            this.removeUrlListener.remove();
        }
        if (this.removeUserRealTime) {
            this.removeUserRealTime();
        }
    }
    async userRealTimeUpdate(valor) {
        this.setState({ userRealTime: valor });
    }
    fireOnUserAndFinishRedirects() {
        let hasUser = false;
        let noRedirects = false;
        let pushArrived = false;
        const myLocalFun = () => {
            if (hasUser && noRedirects) {
                if (!pushArrived) {
                    this.myEvents.emit("firedBoth", "");
                }
            }
        };
        this.myEvents.once("pushArrived", () => {
            MyLog.log("once pushArrived", this);
            pushArrived = true;
            myLocalFun();
        });
        this.myEvents.once("hasUser", () => {
            MyLog.log("once hasUser", this);
            hasUser = true;
            myLocalFun();
        });
        this.myEvents.once("noRedirects", () => {
            MyLog.log("once noRedirects", this);
            noRedirects = true;
            myLocalFun();
        });
        this.myEvents.once("firedBoth", async () => {
            const PUSH_STATES = ["MyHomePage"];
            const currentState = this.getCurrentState();
            if (PUSH_STATES.indexOf(currentState) < 0) {
                return;
            }
            MyLog.log("once firedBoth", this);
            const notificationsSrv = new NotificationsSrv(this.props.navigation);
            const has = await notificationsSrv.has();
            MyLog.log("once has:" + JSON.stringify(has), this);
            if (has.has) {
                this.props.navigation.navigate('NotificationsPage', {});
            }
        });
    }
    getCurrentState() {
        const state = this.props.navigation.getState();
        const routes = state.routes;
        const lastRoute = routes[routes.length - 1];
        return lastRoute;
    }
    backOrGoHome() {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('MyHomePage');
        }
    }
    async onUserDefaultGoHome(payloadUser) {
        if (payloadUser.user !== null) {
            const onboardingData = await readData("createprofile.", { done: "no" });
            if (onboardingData.done != "si") {
                this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'CreateProfilePage' }],
                });
            } else {
                this.reloadOldStateOrGoHome();
            }
        }
    }
    reloadOldStateOrGoHome() {
        const hasOldState = this.hasOldState();
        MyLog.log(`hasOldState=${hasOldState}`, this);
        if (hasOldState) {
            this.reloadOldState();
        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'MyHomePage' }],
            });
        }
    }
    defineStepBackState() {
        const lastRoute = this.getCurrentState();
        const name = lastRoute.name;
        const params = lastRoute.params;
        MountedComponent.localState = {
            name,
            params,
        };
        //MyLog.log(`Save to go back to ${JSON.stringify(MountedComponent.localState)}`, this);
    }
    hasOldState() {
        return (MountedComponent.localState != null);
    }
    reloadOldState() {
        const localState = MountedComponent.localState;
        MountedComponent.localState = null;
        this.props.navigation.reset({
            index: 0,
            routes: [{ name: localState.name, params: localState.params }],
        });
    }
    async registerPush(askAgain = false) {
        if (this.isRegisteringPush === true) {
            return;
        }
        this.isRegisteringPush = true;
        if (Platform.OS !== "web") {
            console.log(`MountedComponent.isPushRegistered=${MountedComponent.isPushRegistered} askAgain=${askAgain}`);
            if (!MountedComponent.isPushRegistered || askAgain) {
                const confirmFun = this.confirm.bind(this);
                try {
                    const seLogoro = await new MyPush().registerNewToken(this.props.alert, confirmFun, this.props.loading, askAgain);
                    if (seLogoro) {
                        MountedComponent.isPushRegistered = true;
                    }
                } catch (err) {
                    this.props.alert(err.message);
                }
            }
        }
        this.isRegisteringPush = false;
    }
    subscribeResize() {
        this._isMounted = true;
        if (Platform.OS === 'web') {
            this.refHandleResize = this.handleResize.bind(this);
            window.addEventListener('resize', this.refHandleResize);
        }
        this.handleResize();
    }
    unSubscribeResize() {
        this._isMounted = false;
        if (Platform.OS === 'web') {
            window.removeEventListener('resize', this.refHandleResize);
        }
    }
    async askUserToLogin(outerMessage = "Selecciona una opción") {
        return new Promise((resolve) => {
            this.props.alertOpen({
                title: outerMessage,
                description: "Selecciona una opción",
                buttons: [
                    {
                        label: "Iniciar Sesión o Registrarme",
                        type: "primary",
                        onPress: async () => {
                            resolve(false);
                            this.defineStepBackState();
                            this.props.navigation.navigate('RegisterScreenPage');
                        }
                    },
                    {
                        label: "Cancelar",
                        type: "secondary",
                        onPress: async () => {
                            resolve(false);
                        }
                    },
                ]
            });
        });
    }
    async alertLogedUser(verifyAccount = true, outerMessage = null) {
        return new Promise(async (resolve) => {
            if (this.state.user == null) {
                const valor = await this.askUserToLogin(outerMessage);
                resolve(false);
            } else {
                if (verifyAccount) {

                    if (this.state.emailVerified !== true) {
                        const valor = await this.reenviarCorreoVerificacion();
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                } else {
                    resolve(true);
                }
            }
        });
    }
    async handleNewUser(payloadUser) {
        this.setState({ ...payloadUser });
        if (payloadUser.user != null) {
            this.myEvents.emit("hasUser", "");
            const ahora = new Date().getTime();
            // TODO switch comment
            const MAX_DIFF_AMONG_POPUPS = 1000 * 60;
            // const MAX_DIFF_AMONG_POPUPS = 1000 * 10;
            // Se evita en las páginas que heredan de detail...
            const EXCEPCIONES = ["WDonationDetailPage", "WDonationDetailWonPage", "WDonationDetailAcceptedPage"];
            if (EXCEPCIONES.indexOf(this.getCurrentState().name) < 0) {
                if (MountedComponent.lastNextPopUp == null || ahora - MountedComponent.lastNextPopUp > MAX_DIFF_AMONG_POPUPS) {
                    if (this.loadingNextPopUp !== true) {
                        this.loadingNextPopUp = true;
                        MountedComponent.lastNextPopUp = ahora;
                        const notificationsSrv = new NotificationsSrv(this.props.navigation);
                        const notifications = await notificationsSrv.nextPopUp(this.props.alert, this.props.loading);
                        if (notifications.length > 0) {
                            this.props.popUpNotification(notifications[0]);
                        }
                    }
                }
            }
        }
        setTimeout(async () => {
            if (typeof this.onUser == "function") {
                await this.onUser(payloadUser);
                this.onShowAndUserEvent.emit("onUser", "");
            }
        }, 0);
    }
    isAdmin() {
        const fixedAdmins = [
            "greciapanal@gmail.com",
        ];
        if (typeof this.state.email == "string") {
            const estaEnListaAdmins = (fixedAdmins.indexOf(this.state.email) >= 0);
            return this.state.email.endsWith("@panal.co") || estaEnListaAdmins;
        }
        return false;
    }
    handleResize() {
        // screen | window
        const myWindow = Dimensions.get('window');
        const newSize = {
            width: myWindow.width,
            height: myWindow.height,
        };
        this.setState(newSize);
    }
    /**
     * 
     * @param {*} url 
     * @param {*} donation_id 
     * @param {*} source 
     * @returns if was handled by this method
     */
    async commonRedirect(url, donation_id, source = "event") {
        // chequeo si ya vio el intro
        const lastRoute = this.getCurrentState();

        if (lastRoute.name != "OnBoardingPage") {
            const myonboarding = await readData("myonboarding.", { done: "no" });
            if (myonboarding.done != "si") {
                this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'OnBoardingPage' }],
                });
                return true;
            }
        }
        if (typeof donation_id == "string" && donation_id.length > 0) {
            // Se excluyen ciertas páginas de la redirección
            const EXEPCIONES = [
                "CreateProfilePage",
                "OnBoardingPage",
                "RegisterScreenPage",
                "SignUpPage",
                "SignInPage",
                "RecuperarClavePage",
            ];

            if (EXEPCIONES.indexOf(lastRoute.name) >= 0) {
                return false;
            }
            const esDiferente = (("" + staticLastRedirect) != ("" + url));
            MyLog.log(`esDiferente ${esDiferente} staticLastRedirect ${staticLastRedirect} != url ${url}`);
            if (esDiferente) {
                // Se debe cargar la donación
                donation_id = donation_id.replace(/https?:\/\/.*/i, "").trim();
                donation_id = donation_id.replace(/[-_](\d{8})[-_](\d{6})[-_](\d{3})$/ig, "-$1-$2-$3");
                Users.setRefererMail(donation_id);// Se guarda el referido
                MyLog.log(`url=${url} byId ${JSON.stringify(donation_id)}`);
                const promesa = DonacionesSrv.byId(donation_id);
                this.props.loading(promesa);
                const donation = await promesa;
                donation.id = donation_id;
                // Se debe hacer el redirect hacia el detalle de la donación
                const params = {
                    donations: [donation],
                    index: 0,
                    is_shared: 1,
                };
                staticLastRedirect = url;
                this.props.navigation.push('WDonationDetailPage', params);
                return true;
            }
        }
        return false;
    }
    async handleNavigation(event, source = "event") {
        let isHandled = false;
        if (Platform.OS !== 'web') {
            if (event && typeof event.url == "string") {
                const gruposArgs = /WDonationDetailPage\/([^/]+)$/i.exec(event.url);
                if (gruposArgs != null) {
                    const donation_id = gruposArgs[1];
                    const wasHandled = await this.commonRedirect(event.url, donation_id, source);
                    isHandled = wasHandled;
                }
                const gruposArgsLogin = /SignInPage\/([^/]+)$/i.exec(event.url);
                if (gruposArgsLogin != null) {
                    //this.props.alert(event.url);
                    const id_token = decodeURIComponent(gruposArgsLogin[1]);
                    const firebase = await this.firebasePromise;
                    firebase.makeAppleLogin(id_token);
                    isHandled = true;
                }
            }
            if (!isHandled) {
                this.myEvents.emit("noRedirects", "");
            }
        }
    }
    async checkQueryParams() {
        // Se valida qué hay query params
        let isHandled = false;
        if (Platform.OS === 'web') {
            const url = new URL(window.location.href);
            const donation_id = url.searchParams.get('donation_id');
            const wasHandled = await this.commonRedirect(url, donation_id);
            isHandled = wasHandled;
            if (!isHandled) {
                this.myEvents.emit("noRedirects", "");
            }
        }
    }
    async askOpenApp() {
        if (Platform.OS === 'web') {
            const AHORA = new Date().getTime();
            const GAP = 1000 * 60 * 60; // 1 hora
            const preferences = await readData("preferences.", { open: "", timestamp: ("" + AHORA) });
            const timestamp = parseInt(preferences.timestamp);
            if (preferences.open == "" || (AHORA - timestamp) > GAP) {
                const params = this.props.route.params;
                if (params && params.is_shared && params.donations instanceof Array) {
                    const donation = params.donations[0];
                    this.props.alertOpen({
                        title: "¡Hola!",
                        description: "¿Deseas abrir la aplicación de Comunidad o continuar en la web?",
                        buttons: [
                            {
                                label: "Abrir Aplicación",
                                type: "primary",
                                onPress: async () => {
                                    await saveData("preferences.", { open: "web", timestamp: ("" + AHORA) });
                                    Linking.openURL(`${CONFIG.INTENT_DOMAIN}://WDonationDetailPage/${donation.id}`);
                                }
                            },
                            {
                                label: "Seguir en la web",
                                type: "secondary",
                                onPress: async () => {
                                    await saveData("preferences.", { open: "web", timestamp: ("" + AHORA) });
                                }
                            },
                        ]
                    });
                }
            }
        }
    }
    async reenviarCorreoVerificacion() {
        return new Promise((resolve) => {
            this.props.alertOpen({
                title: "Antes de continuar...",
                description: `Verifica tu correo ${this.state.email}. OJO: Si ya lo aceptaste presiona "Confirmar".`,
                buttons: [
                    {
                        label: "Reenviar Correo",
                        type: "secondary",
                        onPress: async () => {
                            this.props.alertClose();
                            const promesa = new Promise(async (resolveIn) => {
                                try {
                                    this.firebase = await this.firebasePromise;
                                    this.firebase.emailVerification().then(() => {
                                        this.props.alert(`Se reenvió correctamente el correo a ${this.state.email}`);
                                        resolveIn();
                                        resolve(true);
                                    }).catch((error) => {
                                        MyLog.log(error.message, this);
                                        resolveIn();
                                        resolve(false);
                                    });
                                    resolveIn();
                                } catch (errorIn) {
                                    resolveIn();
                                }
                            });
                            this.props.loading(promesa);
                        }
                    },
                    {
                        label: "Confirmar",
                        type: "primary",
                        onPress: async () => {
                            await this.firebase.forceUpdateIdToken();
                            resolve(null);
                        }
                    },
                    {
                        label: "Cancelar",
                        type: "secondary",
                        onPress: () => {
                            this.props.alertClose();
                            resolve(null);
                        }
                    }
                ],
            });
        });
    }
    async notificarReenvioDeCorreo() {
        return new Promise((resolve) => {
            this.props.alertOpen({
                title: "Confirmación",
                description: `Sigue las instrucciones del correo que te hemos enviado. Verifica el spam.`,
                buttons: [
                    {
                        label: "Logout",
                        type: "primary",
                        onPress: async () => {
                            this.props.alertClose();
                            await this.logout();
                            resolve();
                        }
                    },
                    {
                        label: "Cancelar",
                        type: "secondary",
                        onPress: () => {
                            this.props.alertClose();
                            resolve();
                        }
                    }
                ],
            });
        });
    }
    async confirm(options) {
        return new Promise((resolve) => {
            this.props.alertOpen({
                title: (options.title ? options.title : "Confirmación"),
                description: options.message,
                buttons: [
                    {
                        label: (options.acceptLabel ? options.acceptLabel : "Aceptar"),
                        type: "primary",
                        onPress: async () => {
                            this.props.alertClose();
                            resolve(true);
                        }
                    },
                    {
                        label: (options.cancelLabel ? options.cancelLabel : "Cancelar"),
                        type: "secondary",
                        onPress: () => {
                            this.props.alertClose();
                            resolve(false);
                        }
                    }
                ],
            });
        });
    }
    async handleHttpError(error, message) {
        console.log(error);//Intencional no MyLog
        if (error == 428) {
            await this.logout();
            await this.askUserToLogin();
        } else if (error == 424) {
            const exitoso = await this.reenviarCorreoVerificacion();
            if (exitoso === true) {
                await this.notificarReenvioDeCorreo();
            }
        } else {
            this.props.alert(message)
        }
    }
    async logout() {
        this.firebase = await this.firebasePromise;
        const promesaLogout = this.firebase.logout();
        this.props.loading(promesaLogout);
        await promesaLogout;
        // this.props.alert("Has salido de tu sesión", "Listo!");
        this.props.navigation.reset({
            index: 0,
            routes: [{ name: 'MyHomePage' }],
        });
    }
}
