import * as ImagePicker from 'expo-image-picker';

export class MyFileService {

    // https://github.com/expo/expo/tree/main/packages/expo-image-picker#installation-in-bare-react-native-projects
    // https://docs.expo.dev/versions/latest/sdk/imagepicker/#imagepickeroptions
    // https://docs.expo.dev/versions/latest/sdk/imagepicker/#imageinfo
    static async pickFile(mediaTypes = ImagePicker.MediaTypeOptions.Images, detailedResponse = false) {
        const result = await ImagePicker.launchImageLibraryAsync({
            allowsEditing: false,
            mediaTypes: mediaTypes,
            base64: false,
            allowsMultipleSelection: false,
            //aspect: [4, 3],
            //quality: 1,
        });
        if (!result.canceled) {
            if (result.assets instanceof Array) {
                const actual = result.assets[0];
                if (detailedResponse) {
                    return actual;
                } else {
                    return actual.uri;
                }
            }
        }
        return null;
    }
}