import React from "react";
import styled from "styled-components/native";
import { StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import theme from "../../Theme";

export default class OpcionAbrir
    extends React.Component {
    render() {
        const { 
            openAction,
        } = this.props;
        return (
            <AutoLayoutHorizontal onPress={openAction}>
                <IconlyLightArrowDownCircle>
                    <FontAwesomeIcon icon={"angle-up"} style={[styles.icon]} size={28} />
                </IconlyLightArrowDownCircle>
            </AutoLayoutHorizontal>
        );
    }
}

const styles = StyleSheet.create({
    icon: {
        color: theme.__others__white,
    }
});

const AutoLayoutHorizontal = styled.Pressable`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    position: relative;
    
`;

const IconlyLightArrowDownCircle = styled.View`
    width: 28px;
    height: 28px;
`;
