
/*
const GAP_ms = 500;
Si quieres tener en cuenta solo la primera invocación e ignorar las repetidas invocaciones con intervalos de menos de gap:
new MyThrottle(GAP_ms, true);

Si quieres tener en cuenta solo la última invocación entre repetidas invocaciones con intervalos de menos de gap:
new MyThrottle(GAP_ms, false);
*/
export class MyThrottle {
    constructor(timeGap, useFirst = true, useCall = false) {
        this.timeGap = timeGap;
        this.useFirst = useFirst;
        this.useCall = useCall;
        this.lastCalledTime = 0;
        this.lastInvocation = 0;
        this.isCalling = false;
        this.isIgnoring = false;
        this.argumentos = null;
    }

    throttle(promisedFunction, argumentos) {
        this.argumentos = argumentos;
        const afterGap = () => {
            if (this.useFirst) {
                if (calledTime == this.lastCalledTime) {
                    // Listen again
                    this.isIgnoring = false;
                } else {
                    //console.log("Ignoring...");
                }
            } else {
                this.isIgnoring = false;
            }
        };
        const calledTime = new Date().getTime();
        this.lastCalledTime = calledTime;
        if (this.useFirst && this.isIgnoring) {
            setTimeout(afterGap, this.timeGap);
        }
        if (this.isCalling || this.isIgnoring) {
            // Ignore
            //console.log("Ignoring...");
            return;
        }
        const afterCall = () => {
            this.lastInvocation = new Date().getTime();
            this.isCalling = false;
        };
        if (this.useFirst) {
            // Use first
            this.isCalling = true;
            this.isIgnoring = true;
            promisedFunction(this.argumentos).finally(afterCall);
            setTimeout(afterGap, this.timeGap);
        } else {
            // Use last
            setTimeout(() => {
                // Esto verifica si es el último llamado
                if (this.useCall) {
                    const diff = (new Date().getTime() - this.lastInvocation);
                    if (diff > this.timeGap) {
                        // Call!
                        this.isCalling = true;
                        promisedFunction(this.argumentos).finally(afterCall);
                    }
                } else {
                    if (calledTime == this.lastCalledTime) {
                        // Call!
                        this.isCalling = true;
                        promisedFunction(this.argumentos).finally(afterCall);
                    } else {
                        //console.log("Ignoring...");
                    }
                }
            }, this.timeGap);
        }
    }
}