import * as React from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { getAuth, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import styled from "styled-components/native";
import { Pressable, Platform } from 'react-native'
import Constants from '../../Constants';
//import * as ExpoConstants from "expo-constants";

WebBrowser.maybeCompleteAuthSession();
/*
if (ExpoConstants.manifest) {
    ExpoConstants.manifest.originalFullName = Constants.projectNameForProxy;
}
*/

/*
Este sí es!
*/
function SocialGoogle(props) {
    const [request, response, promptAsync] = Google.useIdTokenAuthRequest(
        {
            clientId: props.webClientId,
        },
        {
            useProxy: false,
            projectNameForProxy: Constants.projectNameForProxy
        }
    );

    React.useEffect(() => {
        if (response?.type === 'success') {
            const { id_token } = response.params;
            const auth = getAuth();
            const credential = GoogleAuthProvider.credential(id_token);
            signInWithCredential(auth, credential)
                .then(() => { })
                .catch((error) => {
                    if (error.code == "auth/account-exists-with-different-credential") {
                        alert("Ya existe una cuenta con el mismo correo");
                    } else if (error.code == "auth/invalid-credential") {
                        alert("Credencial inválida");
                    } else if (error.code == "auth/operation-not-allowed") {
                        alert("Operación no permitida");
                    } else if (error.code == "auth/user-disabled") {
                        alert("Usuario inhabilitado");
                    } else if (error.code == "auth/user-not-found") {
                        alert("Usuario no existe");
                    } else if (error.code == "auth/wrong-password") {
                        alert("Clave errónea");
                    } else if (error.code == "auth/invalid-verification-code") {
                        alert("Código de verificación inválido");
                    } else if (error.code == "auth/invalid-verification-id") {
                        alert("Id de verificación inválido");
                    } else {
                        alert(error.code);
                    }
                });
        }
    }, [response]);

    return (
        <Pressable
            disabled={!request}
            onPress={() => {
                if (!props.acceptTerms) {
                    props.alert(Constants.TXT.ALERT.primero_acepta);
                    return;
                }
                promptAsync();
            }}
            style={props.mystyle}
        >
            {props.children}
        </Pressable>
    );
}

const TypeTouchableLocal = styled.Pressable`
    
`;

export { SocialGoogle };