import React from "react";
import styled from "styled-components/native";
import { StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import theme from "../../Theme";
import * as CssConstants from "../../widgets/CssConstans";
import Constants from "../../Constants"
import AssetsImg from "../../AssetsImg";
import Divider from "../../widgets/Divider";
import MySimpleMenuItem from "../wdonation2/MySimpleMenuItem";
import Button from "../signup/Button";
import MyLog from "../../srv/MyLog";

export default class BottomBar
    extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            donationType: null,
        };
    }
    togglePopUpMenu() {
        if (this.props.useDirectDonation === true) {
            //Manda a crear de una vez la donación
            this.props.details.plusAction({
                donationType: Constants.LISTS.DONATION.TYPE.DONACION,
            });
        } else {
            if (typeof this.props.setBottomMenu == "function") {
                this.props.setBottomMenu(true);
            }
        }
    }
    render() {
        const {
            homeAction,
            notificationAction,
            plusAction,
            chatAction,
            profileAction,
            visitAcceptedDonationsAction,
            useDirectDonation,
        } = this.props.details;

        const togglePopUpMenuThis = this.togglePopUpMenu.bind(this);
        const addDonation = () => {
            togglePopUpMenuThis();
            plusAction({
                donationType: Constants.LISTS.DONATION.TYPE.DONACION,
            });
        };
        const addRepost = () => {
            togglePopUpMenuThis();
            plusAction({
                donationType: Constants.LISTS.DONATION.TYPE.REPOST,
            });
        };

        let hasAlerts = false;
        let hasMessages = false;
        if (this.props.userRealTime) {
            hasAlerts = this.props.userRealTime.hasAlerts;
            hasMessages = (this.props.userRealTime.hasMessages || this.props.userRealTime.hasGroupMessages);
        }
        return (
            <ActiveMenuDarkShortsComponentBottom>
                <AutoLayoutHorizontal>
                    <ContainerMenuIcon onPress={homeAction}>
                        <IconlyLightSearch icon={"home"} color={this.props.route == "MyHome" ? theme.__others__deep_orange : theme.__others__white} />
                        <SubText>Home</SubText>
                    </ContainerMenuIcon>
                    <ContainerMenuIcon onPress={notificationAction}>
                        {hasAlerts === true && <DotContainer>
                            <FontAwesomeIcon icon={"circle"} style={{ color: theme.__others__deep_orange, position: "absolute", right: -21 }} size={10} />
                        </DotContainer>}
                        <IconlyLightSearch icon={"bell"} color={this.props.route == "Notifications" ? theme.__others__deep_orange : theme.__others__white} />
                        <SubText>{"Notificaciones"}</SubText>
                    </ContainerMenuIcon>
                    <IconoCentral>
                        <Frame style={{ marginTop: 6 }} onPress={togglePopUpMenuThis}>
                            <IconlyCurvedPlus>
                                <FontAwesomeIcon icon={"plus"} style={{ color: theme.__others__white }} size={29} />
                            </IconlyCurvedPlus>
                        </Frame>
                        <SubText style={{ marginTop: 2 }}>Ayudar</SubText>
                    </IconoCentral>
                    <ContainerMenuIcon onPress={chatAction}>
                        {hasMessages === true && <DotContainer>
                            <FontAwesomeIcon icon={"circle"} style={{ color: theme.__others__deep_orange, position: "absolute", right: -21 }} size={10} />
                        </DotContainer>}
                        <IconlyLightSearch icon={"comment-dots"} color={this.props.route == "People" ? theme.__others__deep_orange : theme.__others__white} />
                        <SubText>{"Chats"}</SubText>
                    </ContainerMenuIcon>
                    <ContainerMenuIcon onPress={visitAcceptedDonationsAction}>
                        <IconlyLightSearch icon={"heart"} color={this.props.route == "MyAccepted" ? theme.__others__deep_orange : theme.__others__white} />
                        <SubText>Mis donaciones</SubText>
                    </ContainerMenuIcon>
                </AutoLayoutHorizontal>
                <HomeIndicator />
                {this.props.popupState === true && <AutoLayoutVerticalMenu>
                    <FrameMenu></FrameMenu>
                    <MenuTitulo>Crear</MenuTitulo>
                    <Divider marginBottom={"24px"} />
                    <MySimpleMenuItem details={{
                        icon: "droplet",
                        label: "DONACIÓN",
                        subLabel: "Oportunidades que obsequias",
                        localAction: addDonation,
                    }}></MySimpleMenuItem>
                    <MySimpleMenuItem details={{
                        icon: "volume-up",
                        label: "AVISO",
                        subLabel: "Oportunidades ofrecidas",
                        localAction: addRepost,
                    }}></MySimpleMenuItem>
                </AutoLayoutVerticalMenu>
                }
            </ActiveMenuDarkShortsComponentBottom>
        );
    }
}

const DotContainer = styled.View`
    position: relative;
    max-width: 1px;
    max-height: 1px;
`;

const AutoLayoutVerticalMenu = styled.View`
    ${CssConstants.Border1pxDarkDark3}
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 8px 24px 0px;
    background-color: ${props => props.theme.__dark__dark_1};
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;

const FrameMenu = styled.View`
    margin-bottom: 24px;
    min-width: 38px;
    height: 3px;
    background-color: ${props => props.theme.__dark__dark_3};
    border-radius: 100px;
`;

const MenuTitulo = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.H5Bold}
    margin-bottom: 24px;
    width: 100%;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    text-align: center;
    line-height: 28.8px;
`;

/*
const PopUp01aElementText = styled.Text`
    ${CssConstants.BodyXlargeSemibold}
    flex: 1;
    color: ${props => props.theme.__others__white};
    margin-top: 5px;
    margin-right: 10px;
`;

const DownAngleArrow = styled.ImageBackground`
    width: 40px;
    height: 18px;
`;

const PopUp01aElementIcon = styled.View`
    
`;

const PopUp01aElement = styled.Pressable`
    display: flex;
    flex-direction: row;

`;

const PopUp01a = styled.View`
    width: 262px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    background-color: ${props => props.theme.__popup_menu_color};
    borderTopLeftRadius: 16px;
    borderTopRightRadius: 16px;
    borderBottomLeftRadius: 16px;
    borderBottomRightRadius: 16px;
`;

const PopUp01b = styled.View`
    align-items: center;
    justify-content: center;
`;

const PopUp01 = styled.View`
    position: absolute;
    bottom: 15px;
    left: -131px;
    display: flex;
    flex-direction: column;
`;

const MyPopUp = styled.View`
    width: 1px;
    height: 1px;
    position: relative;
`;
*/

const ActiveMenuDarkShortsComponentBottom = styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 8px 0px 0px;
    background-color: ${props => props.theme.__dark__dark_1};
    borderTopLeftRadius: 16px;
    borderTopRightRadius: 16px;
    border: 1px solid;
    border-color: ${props => props.theme.__dark__dark_3};
    position: relative;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    height: 48px;
    align-items: center;
    justify-content: center;
    padding: 0px 0px;
    position: relative;
    align-self: stretch;

`;

const ContainerMenuIcon = styled.Pressable`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
`;

const HomeIndicator = styled.View`
    align-self: stretch;
    min-width: 428px;
    height: 34px;
`;

const SubText = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyXsmallMedium}
    align-self: stretch;
    font-weight: 500;
    color: ${props => props.theme.__greyscale__500};
    text-align: center;
`;

class IconlyLightSearch
    extends React.Component {
    render() {
        const {
            icon,
            color,
        } = this.props;
        return (
            <IconlyLightSearch1>
                <Group>
                    <OverlapGroup>
                        <Ellipse>
                            <FontAwesomeIcon icon={icon} style={{ color: color }} size={24} />
                        </Ellipse>
                    </OverlapGroup>
                </Group>
            </IconlyLightSearch1>
        );
    }
}

const IconlyLightSearch1 = styled.View`
    min-width: 24px;
    height: 24px;
`;

const Group = styled.View`
    position: relative;
    height: 19px;
    top: 3px;
    left: 3px;
    display: flex;
    align-items: flex-start;
    min-width: 19px;
`;

const OverlapGroup = styled.View`
    width: 20px;
    height: 21px;
    position: relative;
    margin-left: -1px;
`;

const Ellipse = styled.View`
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    border-color: ${props => props.theme.__greyscale__500};
`;

const IconoCentral = styled.View`
    display: flex;
    flex: 1;
    
    align-items: center;
    justify-content: center;
`;

const Frame = styled.Pressable`
    position: relative;
    min-width: 40px;
    height: 40px;
    background-color: ${props => props.theme.__others__deep_orange};
    border-radius: 1000px;
    overflow: hidden;

`;

const IconlyCurvedPlus = styled.View`
    position: absolute;
    width: 40px;
    height: 40px;
    top: 5px;
    left: 5px;
`;
