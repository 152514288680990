import React from "react";
import styled from "styled-components/native";
import { Dimensions } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import Constants from "../../Constants"
import * as CssConstants from "../../widgets/CssConstans";

export default class ElementsCommentsList
    extends React.Component {

    render() {
        const {
            profile,
            donorType,
            type,
            organicername,
        } = this.props.details;

        return (
            <TypeCaptionsComponentCommentsList>
                <AutoLayoutHorizontal>
                    <AutoLayoutHorizontal1>
                        <Username>{"Organizador"}</Username>
                    </AutoLayoutHorizontal1>
                </AutoLayoutHorizontal>
                <AutoLayoutHorizontal>
                    <AutoLayoutHorizontal1>
                        <ContenedorTextoProfile>{organicername}</ContenedorTextoProfile>
                    </AutoLayoutHorizontal1>
                </AutoLayoutHorizontal>
            </TypeCaptionsComponentCommentsList>
        );
    }
}

const TypeCaptionsComponentCommentsList = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
`;

const AutoLayoutHorizontal = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
`;

const AutoLayoutHorizontal1 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
`;

const Username = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyLargeBold}
    align-self: stretch;
    margin-top: -1px;
    margin-right: 5px;
    font-weight: 700;
    color: ${props => props.theme.__others__white};
    line-height: 22.4px;
`;

const ContenedorTextoProfile = styled.Text`
    ${CssConstants.ValignTextMiddle}
    ${CssConstants.BodyMediumRegular}
    align-self: stretch;
    font-weight: 400;
    color: ${props => props.theme.__others__white};
    line-height: 19.6px;
    width: 100%;
`;