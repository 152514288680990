import React, { Component } from 'react';
import { View, StyleSheet, Platform, StatusBar } from 'react-native';
import Constants from 'expo-constants';

class StatusBarBackground extends Component {
    render() {
        return (
            <View style={[styles.statusBarBackground, this.props.style || {}]}>
            </View>
        );
    }
}

const HEIGHT_OFFSET = {
    //'ios': 0,
    //'ios': 20,
    'ios': Constants.statusBarHeight,
    'android': 0,
    //'android': StatusBar.currentHeight,
    //'android': Constants.statusBarHeight,
    'windows': 0,
    'macos': 0,
    'web': 0,
};

const styles = StyleSheet.create({
    statusBarBackground: {
        height: HEIGHT_OFFSET[Platform.OS],
        backgroundColor: "rgba(26, 26, 34, 1)",
    }

})

export default StatusBarBackground;