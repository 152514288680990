import React from "react";
import styled from "styled-components/native";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import * as CssConstants from "./CssConstans";
import theme from "../Theme";

export default class MyInput
  extends React.Component {
  constructor(props) {
    super(props);
    const { secret, value } = props.details;
    this.state = {
      isFocused: false,
      isSecret: (secret === true),
      errmsgs: [],
      value: (typeof value == "string" ? value : ''),
    };
    this.textInputRef = React.createRef();
  }
  setValue(value) {
    this.setState({
      value
    });
  }
  doEmpty() {
    this.setState({
      value: ""
    });
  }
  generalStyle(invertColors) {
    const resultado = {
      backgroundColor: theme.__dark__dark_2,
    }
    if (invertColors === true) {
      resultado.backgroundColor = theme.__greyscale__200;
      if (this.state.isFocused === true) {
        resultado.backgroundColor = theme.__greyscale__50;
        resultado.borderWidth = 1;
        resultado.borderColor = "rgba(255, 77, 0, 1)";
      }
    } else {
      if (this.state.isFocused === true) {
        resultado.backgroundColor = "rgba(255, 77, 0, 0.1)";
        resultado.borderWidth = 1;
        resultado.borderColor = "rgba(255, 77, 0, 1)";
      }
    }
    return resultado;
  }

  render() {
    const {
      label,
      leftIcon,
      rightIcon,
      rightCallback,
      secret,
      onChangeText,
      onSubmit,
      onFocus,
      onBlur,
      errors,
      multiline,
      numberOfLines,
      myHeight,
      editable,
      invertColors,
    } = this.props.details;
    return (
      <InputContainer onPress={() => {
        this.textInputRef.current?.focus();
      }}>
        <SubContainer
          multiline={multiline === true}
          myHeight={myHeight}
          style={this.generalStyle(invertColors)}
        >
          {!!leftIcon &&
            <ContainerLeftIcon>
              <FontAwesomeIcon icon={leftIcon} style={{ color: theme.__greyscale__500, }} size={15} />
            </ContainerLeftIcon>
          }
          <Label
            ref={this.textInputRef}
            readOnly={!(editable !== false)}
            multiline={multiline === true}
            mycolor={invertColors === true ? theme.__greyscale__900 : theme.__greyscale__500}
            rows={typeof numberOfLines == 'number' ? numberOfLines : null}
            value={this.state.value}
            placeholder={label}
            placeholderTextColor={theme.__greyscale__500}
            secureTextEntry={this.state.isSecret}
            onFocus={(e) => {
              this.setState({ isFocused: true });
              if (typeof onFocus == "function") {
                onFocus(e);
              }
            }}
            onBlur={(e) => {
              this.setState({ isFocused: false });
              if (typeof onBlur == "function") {
                onBlur(e);
              }
            }}
            onChangeText={text => {
              this.setState({ value: text });
              if (typeof onChangeText == "function") {
                onChangeText(text);
              }
            }}
            onSubmitEditing={() => {
              if (typeof onSubmit == "function") {
                onSubmit(this.state.value);
              }
            }}
          ></Label>
          {!!rightIcon &&
            <ContainerRightIcon
              onPress={() => {
                if (typeof rightCallback == "function") {
                  rightCallback();
                } else {
                  if (secret === true) {
                    this.setState({ isSecret: !this.state.isSecret });
                  }
                }
              }}>
              <FontAwesomeIcon icon={rightIcon} style={{ color: theme.__greyscale__500, }} size={15} />
            </ContainerRightIcon>
          }
        </SubContainer>
        {errors !== undefined && <ContainerError>{errors}</ContainerError>}
      </InputContainer>
    );
  }
}

const ContainerLeftIcon = styled.View`
  min-width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const ContainerRightIcon = styled.Pressable`
  min-width: 20px;
  height: 20px;
  margin-left: 8px;
`;

const ContainerError = styled.Text`
  width: 100%;
  color: ${props => props.theme.__others__deep_orange};
  font-size: ${props => props.theme.__font_size_xl2};
  margin-top: 10px;
`;

// height: 100%;
const InputContainer = styled.Pressable`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
`;

const SubContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${props => (props.multiline == true ? "20px" : "0px 20px")};
  ${props => (props.myHeight ? `min-height: ${props.myHeight}; max-height: ${props.myHeight};` : "min-height: 60px;")}
  
  align-items: center;
  position: relative;
  
  border-radius: 12px;
`;

const Label = styled.TextInput`
  ${CssConstants.ValignTextMiddle}
  ${CssConstants.BodyXlargeSemibold}
  border-width:0px;
  border:none;
  flex: 1;
  font-weight: 400;
  ${props => props.multiline ? "height: 100%;" : ""}
  color: ${props => props.mycolor};
`;