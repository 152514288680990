import React from "react";
import styled from "styled-components/native";

import FooterDescription from "./FooterDescription";
import OpcionAbrir from "./OpcionAbrir";

export default class ComponenteTop
    extends React.Component {
    render() {
        const {
            details,
            openAction,
        } = this.props;

        return (
            <TypeStorySeenListComponentAccountLi>
                <FooterDescription openAction={openAction} details={details} />
                <OpcionAbrir openAction={openAction} />
            </TypeStorySeenListComponentAccountLi>
        );
    }
}

const TypeStorySeenListComponentAccountLi = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    
    position: relative;
    padding-bottom: 10px;
`;