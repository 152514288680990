import React from "react";
import styled from "styled-components/native";

class Divider
    extends React.Component {
    render() {
        const {
            marginBottom,
            marginTop,
        } = this.props;
        return <ThemeDarkDivider marginBottom={marginBottom} marginTop={marginTop} ></ThemeDarkDivider>;
    }
}

export default Divider;

const ThemeDarkDivider = styled.View`
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0px")};
    margin-top: ${props => (props.marginTop ? props.marginTop : "0px")};
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.__dark__dark_3};
`;