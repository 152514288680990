import React from "react";
import styled from "styled-components/native";
import { StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import theme from "../../Theme";

export default class OpcionCerrar
    extends React.Component {
    render() {
        const {
        } = this.props;
        return (
            <IconsPrimarydotsVertical>
                <IconlyLightArrowRight>
                    <FontAwesomeIcon icon={"angle-down"} style={[styles.icon]} size={28} />
                </IconlyLightArrowRight>
            </IconsPrimarydotsVertical>
        );
    }
}

const styles = StyleSheet.create({
    icon: {
        color: theme.__others__white,
    }
});

const IconsPrimarydotsVertical = styled.View`
position: relative;
min-width: 28px;
height: 28px;

`;

const IconlyLightArrowRight = styled.View`
width: 28px;
height: 28px;
`;