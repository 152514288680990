import React from "react";
import styled from "styled-components/native";
import { StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import * as CssConstants from "./CssConstans";
import theme from "../Theme";
import AssetsImg from "../AssetsImg";

class ElementsNavbar
    extends React.Component {
    render() {
        const {
            left,
            right,
            text,
        } = this.props.details;

        const myShadow = {
            color: "white",
            textShadowColor: 'black',
            textShadowOffset: { width: 0, height: 1 },
            textShadowRadius: 3,
            position: "absolute",
        };
        const iconStyle = {
            color: "white",
        };
        return (
            <ThemeDarkComponentNavbar>
                <AutoLayoutHorizontal1>
                    {(left) && <IconlyLightCloseSquare onPress={left.action}>
                        {left.icon && <FontAwesomeIcon icon={left.icon} style={iconStyle} size={28} />}
                        {left.svgIcon && left.svgIcon}
                    </IconlyLightCloseSquare>}
                </AutoLayoutHorizontal1>
                <AutoLayoutHorizontal>
                    {(typeof text == "string") && <TitleSection style={myShadow}>{text}</TitleSection>}
                </AutoLayoutHorizontal>
                <AutoLayoutHorizontal1>
                    {(right) && <IconlyLightCloseSquare2 onPress={right.action}>
                        {right.icon && <FontAwesomeIcon icon={right.icon} style={iconStyle} size={28} />}
                        {right.svgIcon && right.svgIcon}
                    </IconlyLightCloseSquare2>}
                </AutoLayoutHorizontal1>
            </ThemeDarkComponentNavbar>
        );
    }
}

export default ElementsNavbar;

// gap: 12px;
// background-color: ${props => props.theme.__eerie_black_mine};
const ThemeDarkComponentNavbar = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 24px;
    padding-left: 24px;
    width: 100%;
    height: 70px;
`;

//gap: 16px;
const AutoLayoutHorizontal = styled.View`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

`;
const TitleSection = styled.Text`
    ${CssConstants.BodyXlargeSemibold}
    flex: 1;
    font-weight: 600;
    color: ${props => props.theme.__others__white};
    line-height: 25.2px;
    text-align: left;
    width: 100%;
`;

//gap: 20px;
const AutoLayoutHorizontal1 = styled.View`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const IconlyLightCloseSquare = styled.Pressable`
    min-width: 28px;
    height: 28px;
`;

const IconlyLightCloseSquare2 = styled.Pressable`
    min-width: 35px;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;