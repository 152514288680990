import React, { Component } from 'react'
import RecuperarClave from '../screens/recuperar/RecuperarClave';
import { validInputEmail, validInputPassword } from '../utils/InputValidator';
import { Formik } from 'formik';
import {
    sendPasswordResetEmail
} from 'firebase/auth';
import { MountedComponent } from "../utils/MountedComponent";
import myFirebase from '../config/firebase';
import Constants from '../Constants';
import CONFIG from '../Constants';

export default class RecuperarClaveScreen extends MountedComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign(this.state, {
            loading: true,
            config: {},
            data: { email: '' }
        });
    }
    render() {
        return (
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={this.state.data}
                validate={values => {
                    const errors = {};
                    errors.email = validInputEmail(values.email);
                    if (errors.email === undefined) { delete errors.email; }
                    return errors;
                }}
                onSubmit={async (values) => {
                    const promesaLogin = new Promise(async (resolve) => {
                        try {
                            // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#sendpasswordresetemail
                            const firebase = await myFirebase;
                            await sendPasswordResetEmail(firebase.auth, values.email);
                            this.props.alert("Hemos enviado a tu correo las instrucciones para recuperar tu contraseña. Mira en spam.");
                            this.props.navigation.navigate('SignInPage');
                        } catch (error) {
                            if (error.code == "auth/missing-android-pkg-name") {
                                this.props.alert("Packete de android incorrecto");
                            } else if (error.code == "auth/invalid-email") {
                                this.props.alert("Correo inválido");
                            } else if (error.code == "auth/missing-continue-uri") {
                                this.props.alert("Falta url de continuación");
                            } else if (error.code == "auth/missing-ios-bundle-id") {
                                this.props.alert("Bundle Id de ios incorrecto");
                            } else if (error.code == "auth/invalid-continue-uri") {
                                this.props.alert("Inválida url de continuación");
                            } else if (error.code == "auth/unauthorized-continue-uri") {
                                this.props.alert("Url no autorizada");
                            } else if (error.code == "auth/user-not-found") {
                                this.props.alert("Correo no encontrado, prueba crear la cuenta");
                            } else {
                                this.props.alert(error.code);
                            }
                        }
                        resolve();
                    });

                    this.props.loading(promesaLogin);

                    return promesaLogin;
                }}
            >
                {({ handleChange, handleBlur, submitForm, errors, values, setFieldValue }) => (
                    <RecuperarClave
                        titulo={
                            <React.Fragment>Recuperación{"\n"}de contraseña</React.Fragment>
                        }
                        inputEmail={{
                            label: "Email",
                            leftIcon: "envelope",
                            onChangeText: handleChange('email'),
                            onBlur: handleBlur('email'),
                            errors: errors.email,
                            value: values.email,
                        }}
                        buttonPpal={{
                            label: "Enviar correo de recuperación",
                            onPress: submitForm,
                        }}
                    />
                )}
            </Formik>
        )
    }
}